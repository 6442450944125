import React from 'react';
import { Wrapper } from './ContextMenuItem.styles';

const ContextMenuItem = ({children, icon, onClick}: {children: any, icon?: string, onClick?: () => void}) => {
    return <Wrapper className="tooltip-item" onClick={(e) => {

        if (onClick){
            onClick();
        }
    }}>
        {icon
            ? <div className="icon"><img src={icon} alt="icon"/></div>
            : ''}
        {children}
    </Wrapper>;
}

export default ContextMenuItem;
