import {ProceedingType} from '../organizations/organizations.model';
import {BodyMemberType} from '../bodies/bodies.model';
import {EventModerator} from "../../containers/Event/EventParticipants/participantsProvider";

export interface EventBody {
    bodyId: string;
    proceedings: ProceedingType,
    name: string;
    startDate?: string | null;
    finishDate?: string | null;

    type: EventType;
}

export interface EventList {
    id: string;
    body: {
        id: string;
        name: string;
    };
    proceedings: ProceedingType;
    name: string;
    startDate: string;
    finishDate: string | null;
    type: EventType;
}

export enum EventType {
    LIVE = "Live",
    CIRCULAR = "Circular",
}

export enum EventPermission {
    canDecideOnVoteOutcome = 'canDecideOnVoteOutcome',
    canManageDocument = 'canManageDocument',
    canManageAgenda = 'canManageAgenda',
    canChangeDescription = 'canChangeDescription',
    canManageVote = 'canManageVote',
    canManageParticipants = 'canManageParticipants',
    canManageModerators = 'canManageModerators',
    canManageGuests = 'canManageGuests',
    canStartEvent = 'canStartEvent',
    canFinishEvent = 'canFinishEvent',
    canActivateAgendaItem = 'canActivateAgendaItem',
    canCastVote = 'canCastVote',
    canAssignChairman = 'canAssignChairman',
    canEnterVoteInPendingStatus = 'canEnterVoteInPendingStatus',
    canReadDocument = 'canReadDocument',
    canAssignDecidingOnVoteOutcome = 'canAssignDecidingOnVoteOutcome',
    canSeeNavigationButtons = 'canSeeNavigationButtons',
}

export enum ReportGenerationStatus {
    InProgress = 'InProgress', Complete = 'Complete', Failed = 'Failed'
}

export interface EventModel {
    error: string | null;
    content: {
        id: string;
        basic: EventList;
        description: EventDescription;
        agenda: EventAgenda;
        meetingStatus: MeetingStatus;
        meetingStartedTimestamp: string | null;
        chairmanReference: { participantId: string; guestId: string, moderatorId: string } | null;
        voteOutcomeMakerReference: { participantId: string; guestId: string } | null;
        type: EventType;
        canAssignVoteOutcomeMaker: boolean;
        canModifyParticipants: boolean;
        canManageAgenda: boolean;
        canChangeAgendaItem: boolean;
        canCreateVote: boolean;
        hasActiveVote: boolean,
        canBeUpdated: boolean,
    } | null,
    reportGenerationProgress: {
        eventId: string;
        generated: number;
        total: number;
        status: ReportGenerationStatus;
    } | null,
    participant: EventParticipantContext | null;
    attendanceOptions: AttendanceOption[];
    permissions: {
        [EventPermission.canManageAgenda]: boolean;
        [EventPermission.canChangeDescription]: boolean;
        [EventPermission.canManageVote]: boolean;
        [EventPermission.canManageParticipants]: boolean;
        [EventPermission.canManageModerators]: boolean;
        [EventPermission.canManageGuests]: boolean;
        [EventPermission.canStartEvent]: boolean;
        [EventPermission.canFinishEvent]: boolean;
        [EventPermission.canActivateAgendaItem]: boolean;
        [EventPermission.canCastVote]: boolean;
        [EventPermission.canAssignChairman]: boolean;
        [EventPermission.canDecideOnVoteOutcome]: boolean;
        [EventPermission.canManageDocument]: boolean;
        [EventPermission.canEnterVoteInPendingStatus]: boolean;
        [EventPermission.canReadDocument]: boolean;
        [EventPermission.canAssignDecidingOnVoteOutcome]: boolean;
        [EventPermission.canSeeNavigationButtons]: boolean;
    }
}

export interface EventParticipantContext {
    id: string;
}

export enum MeetingStatus {
    Draft = 'Draft', InProgress = 'InProgress', Finished = 'Finished', FinishRequested = 'FinishRequested',
}

export interface EventDescription {
    value: string;
}

export interface EventAgenda {
    canActivate: boolean;
    items: AgendaPoint[];
}

export interface AgendaPoint {
    id: string;
    title: string;

    description: string | null;
    activated: boolean;
    closingItem: boolean;
    votes: Voting[];
}


export enum VotingType {
    Open = 'Open',
    Secret = 'Secret',
}

export enum VotingModeType {
    relativeMajority = 'relative-majority',
    absoluteMajority = 'absolute-majority',
    qualifiedMajority = 'qualified-majority',
    mostThreeQuarters = 'most-three-quarters',
    mostTwoThirds = 'most-two-thirds',
    consensus = 'consensus',
    percentMajority = 'percent-majority',
    fractionMajority = 'fraction-majority',
    numberMajority = 'number-majority',
}

export enum VotingQuorumType {
    all = 'all',
    atLeastHalf = 'at-least-half',
    atLeastTwoThirds = 'at-least-two-thirds',
    atLeastTreeQuarters = 'at-least-tree-quarters',
    other = 'other',
    percentMinimum = 'percent-minimum',
    fractionMinimum = 'fraction-minimum',
    numberMinimum = 'number-minimum',

}


export interface VotingBody {
    documentId: string | null;
    name: string;
    description: string;
    type: string;
    mode: {
        type: string;
    },
    content: string | null;
    quorum: {
        type: string;
    }
}


export interface Voting {
    id: string;
    documentId: string;
    content: string | null;
    name: string;
    description: string;
    type: VotingType;
    mode: {
        type: string;
        modeType?: {
            type: string;
            number: number;
            percent: number;
            numerator: number;
            denominator: number;
        };
    };
    quorum?: {
        type: string;
        quorumType?: {
            type: string;
            number: number;
            percent: number;
            numerator: number;
            denominator: number;
        };
    };
    status: VotingStatus;
    startTimestamp: string;
    finishTimestamp: string;
    hasReportAvailable: boolean;
    availableActions: {
        canChangePosition: boolean;
        canChangeAgendaItem: boolean;
        canBeDuplicated: boolean;
        canExcludeParticipant: boolean;
        canAddVoterSpecialMarker: boolean;
        canEnterVoting: boolean;
        canDecideOnOutcome: boolean;
        canBeCanceled: boolean;
        canShowResults: boolean;
        canBeUpdated: boolean;
        canBeDeleted: boolean;
        canBeActivated: boolean;
        canBeCast: boolean;
        canBeFinished: boolean;
        canBeClosed: boolean;
    },
    open: boolean;
    excludedParticipants: string[];
    castedVotesCount: number;
    allVotesCount: number;
    outcomeReason: string | null;
    voteCasts: VoteCast[];
    results: VotingResults | null;
}

export interface VotingResultsDetails {
    total: number;
    fractionOfAllCastedVotes: number;
    fractionOfAllSignedVotes: number;
    fractionOfAllBodyVotes: number;
}

export interface VotingResults {
    totals: {
        casted: number;
        signed: number;
        inBody: number;
        noVote: number;
        absent: number;
    };
    forResults: VotingResultsDetails;
    againstResults: VotingResultsDetails;
    abstainResults: VotingResultsDetails;
    signedButNotVotingResults: VotingResultsDetails;
    id: string;
}

export interface VoteCast {
    participantId: string;
    participantName: string;
    voteCount: number;
    choice: VotingChoice;
    castedVote: boolean;
    signed: boolean;
    specialMarkers: SpecialMarker[];
}

export enum VotingStatus {
    Pending = 'Pending',
    InProgress = 'InProgress',
    Passed = 'Passed',
    NotPassed = 'NotPassed',
    Canceled = 'Canceled',
    Finished = 'Finished'
}

export enum VotingChoice {
    For = 'For', Against = 'Against', Abstain = 'Abstain'
}

export interface EventModeratorsResponse {
    moderators: EventModerator[];
    canModify: boolean;
}
export interface EventGuestResponse {
    guests: EventGuest[];
    canModify: boolean;
}
export interface EventParticipantResponse {
    participants: EventParticipant[] | null;
    canModify: boolean;
    stats: {
        all: number;
        notSentInvitation: number;
        sentInvitation: number;
        signed: number;
        blocked: number;
    },
    signedShareBodyPercentage: number | null;
    signedVotes: number;
    allVotes: number;


}


export interface EventParticipant {
    bodyId: string;
    bodyMemberId: string;
    eventId: string;
    bodyMemberName: string;
    bodyMemberType: BodyMemberType;
    name: string;
    share: number;
    sharePercentage: number | null;
    voteCount: number;
    invitationEmail: string;
    status: EventParticipantStatus;
    blocked: boolean;
    id: string;
    email: string;
    phoneNumber: string;
    position: string;
    authorizationDocumentId: string | null;
    representative: Representative;
}

export enum EventParticipantStatus {
    NotSentInvitation = 'NotSentInvitation',
    SentInvitation = 'SentInvitation',
    Signed = 'Signed',
    Blocked = 'Blocked',
}

export interface EventMember {
    id: string;
    name: string;
    type: BodyMemberType;
    added: boolean;
}

export interface VoteParticipant {
    id: string;
    bodyMemberType: BodyMemberType;
    bodyMemberName: string;
    excluded: {
        participantId: string;
        bodyMemberName: string;
        excludedByName: string;
        timestamp: string;
    } | null;
    specialMarkers: SpecialMarker[]
}

export enum SpecialMarker {
    RequiredVote = 'RequiredVote',
    DecidingVote = 'DecidingVote'
}

export interface EventParticipantFilters {
    bodyMemberNameToSearch?: string;
    invitationEmailToSearch?: string;
    nameToSearch?: string;
    status?: EventParticipantStatus;
}

export enum MemberAction {
    Add = 'Add', Delete = 'Delete'
}

export interface MemberModifyAction {
    id: string;
    action: MemberAction;
}


export interface EventGuest extends EventGuestBody {
    eventId: string;
    id: string;
    status: EventGuestStatus;
    phoneNumber: string;
    name: string;
}

export interface EventGuestInvitation {
    guest: {
        firstName: string;
        lastName: string;
        status: EventGuestStatus;
        phoneNumber: string | null;
    };
    event: {
        id: string;
        name: string;
        description: string;
        startDate: string;
        status: string;
        bodyName: string;
        organizationName: string;
        organizationId: string;
    }
}

export interface EventParticipantInvitation {
    participant: {
        bodyMemberName: string;
        bodyMemberType: BodyMemberType;
        firstName: string;
        lastName: string;
        share: number;
        voteCount: number;
        alreadySigned: boolean;
        id: string;
    };
    event: {
        anonymousAuthorizationAvailable: string;
        id: string;
        name: string;
        description: string;
        startDate: string;
        finishDate: string;
        status: MeetingStatus;
        bodyName: string;
        organizationName: string;
        organizationId: string;
    }
    invitationUrl: string;
}

export interface EventGuestBody {
    firstName: string;
    lastName: string;
    email: string;
    comment: string;
}

export enum EventGuestStatus {
    Active = 'Active',
    Pending = 'Pending',
}

export interface EventParticipantInvitationAcceptBody {
    firstName: string;
    lastName: string;
    pesel: string;
    position: string;
    phoneNumber: string;
    authorizationDocumentId: string;
    representative: Representative;
}


export enum Representative {
    Personally = 'Personally',
    Plenipotentiary = 'Plenipotentiary',
}

export interface AttendanceOption {
    attendeeName: string;
    participantId: string;
    invitationUrl: string;
}

export interface EventInvitationStatus {
    result: {
        success: boolean,
        consumedCredit: {
            description: string,
        } | null
    },
    participant: EventParticipant;
}

export interface EventTimerData {
    startIn: {
        days: number,
        hours: number,
        minutes: number,
        seconds: number
    },
    canSignIn: boolean,
    canStart: boolean,
}
