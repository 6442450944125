import React, {useState} from "react";
import {Wrapper} from "./OutcomeDecision.styles";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import Modal from "../../../../components/Modal";
import OutcomeConfirmation from "./OutcomeConfirmation";

const OutcomeDecision = ({itemId, voteId}:{itemId: string, voteId: string}) =>{
    const {t} = useBaseScreenHook();

    const [passed, setPassed] = useState<boolean | null>(null);

    return <Wrapper>
        {/*<div className="hint">*/}
        {/*    {t('outcomeDecision.hint')}*/}
        {/*</div>*/}

        <div className="buttons">
            <button className={'button success'} onClick={() => setPassed(true)}>
                {t('outcomeDecision.success')}
            </button>
            <button className={'button warn'} onClick={() => setPassed(false)}>
                {t('outcomeDecision.notSuccess')}
            </button>
        </div>

        <Modal width={800} show={passed != null} onClose={() => setPassed(null)}>
            <OutcomeConfirmation itemId={itemId} voteId={voteId} passed={passed}
                               onClose={() => setPassed(null)}/>
        </Modal>
    </Wrapper>
}

export default OutcomeDecision;