
import React from "react";
import {Wrapper} from "./EventTimer.styled";
import {useTimer} from "../../../../logic/core/timer.hook";

const EventTimer = ({startDate, finishDate}:{startDate: Date, finishDate: Date | null}) => {
    const [days, hours, minutes, seconds] = useTimer(finishDate ?? startDate, !!finishDate);

    return <Wrapper>
        <div className="timer">
            {
                days === '00' ? null : `${days}:`
            }
            {
                hours === '00' ? null : `${hours}:`
            }
            {minutes}:{seconds}
        </div>

    </Wrapper>
}

export default EventTimer;