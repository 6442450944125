import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Failure } from './failure';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../components/ErrorModal';

export const useBaseScreenHook = () => {
    const [t, i18n] = useTranslation('common');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Failure | null>(null);
    const navigate = useNavigate();

    const renderError = () => {
        return <ErrorModal failure={error} onClose={() => setError(null)}/>
    }

    return {t, i18n, loading, setLoading, error, setError, navigate, renderError,}
}
