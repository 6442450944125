import styled from 'styled-components';

export const Wrapper = styled.div`

    .title-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .title {
        font-size: 2.5rem;
      }
    }
  
  .menu {
    display: flex;
    justify-content: flex-end;
    .remove-btn {
      color: var(--errorColor)
    }
  }

`
