import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: var(--fontMutedColor);
    text-align: left;
  }
  .move-label {
    text-align: left;
    margin-bottom: 10px;
    display: block;
  }
  .points {
    text-align: left;
    gap: 10px;
    display: flex;
    flex-direction: column;
    .point {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        .name {
          background: var(--disabledColor);
        }
      }

      &.selected,
      &:hover {
        .name {
          background: var(--lightBlueColor);
        }
      }
      .no {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        color: var(--fontMutedColor);
        font-weight: bold;
      }
      .name {
        padding: 5px 10px;
        min-height: 52px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--disabledColor);
        border-radius: 5px;
        gap: 20px;
        transition: all .2s ease-in-out;

       
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
  }
`