import React, { useEffect, useState } from 'react';
import { Wrapper } from './OrganizationBodiesScreen.styles';
import { useTranslation } from 'react-i18next';
import iconPlusWhite from '../../../assets/icon-plus-white.svg'
import { Table } from '../../../components/Table/table';
import ContextMenu from '../../../components/ContextMenu';
import iconMenu from '../../../assets/icon-menu.svg'
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';
import Modal from '../../../components/Modal';
import OrganizationBodiesCreateModal from './OrganizationBodiesCreateModal';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorModal from '../../../components/ErrorModal';
import { Failure } from '../../../logic/core/failure';
import { deleteBody, getBodies } from '../../../logic/bodies/bodies';
import { Body } from '../../../logic/bodies/bodies.model';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Loader from '../../../components/Loader';
import { AppRoutes } from '../../../logic/core/routes';

const OrganizationBodiesScreen = () => {
    const [t] = useTranslation('common');
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [bodyToDelete, setBodyToDelete] = useState<Body | null>(null);
    const {organizationId} = useParams();
    const [error, setError] = useState<Failure | null>(null);
    const [bodies, setBodies] = useState<Body[] | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        _getBodies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const _getBodies = () => {
        if (!organizationId) {
            return;
        }
        getBodies(organizationId).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setBodies(res.data!);

        });
    }

    const _deleteBody = () => {
        deleteBody(organizationId!, bodyToDelete!.id).then((res) => {
            setBodyToDelete(null);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            _getBodies();
        })
    }

    const _createBodyModalClosed = (refreshData: boolean) => {
        setOpenCreateModal(false);
        if (refreshData) {
            _getBodies();
        }
    }

    const _editBody = (body: Body) => {
        navigate(`${body.id}/${AppRoutes.settings}`);
    }

    const  _openBody = (body: Body) => {
        navigate(`${body.id}/${AppRoutes.members}`);
    }

    return <Wrapper>

        <div className="title-bar">
            <h1 className="title">{t('bodies.title')}</h1>

            <button className="button icon" onClick={() => setOpenCreateModal(true)}>
                <img src={iconPlusWhite} alt="icon"/>
                {t('bodies.createBtn')}
            </button>
        </div>

        <Table>
            <thead>
            <tr>
                <th>
                    {t('bodies.name')}
                </th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {
                bodies?.map((body) => {
                    return <tr key={body.id} onClick={() => _openBody(body)} className="pointer">
                        <td>
                            {body.name}
                        </td>
                        <td>
                            <div className="menu">
                                <ContextMenu icon={iconMenu}>
                                    <ContextMenuItem onClick={() =>_editBody(body)}>
                                        {t('bodies.editBtn')}
                                    </ContextMenuItem>
                                    <ContextMenuItem onClick={() => setBodyToDelete(body)}><span className="remove-btn">
                                {t('bodies.removeBtn')}
                                </span>
                                    </ContextMenuItem>
                                </ContextMenu>
                            </div>
                        </td>
                    </tr>
                })
            }

            </tbody>

        </Table>
        {
            bodies ? null : <Loader/>

        }
        <Modal width={700} show={openCreateModal} onClose={() => setOpenCreateModal(false)} backDropClose={false}>
            <OrganizationBodiesCreateModal onClose={(refreshData: boolean) => _createBodyModalClosed(refreshData)}/>
        </Modal>

        <Modal width={600} show={!!bodyToDelete} onClose={() => setBodyToDelete(null)}>
            <ConfirmationModal
                reject={() => setBodyToDelete(null)}
                accept={() => _deleteBody()}
                title={`${t('bodies.deleteModalTitle')} ${bodyToDelete?.name ?? ''}?`}
                body={t('bodies.deleteModalBody')}
            />
        </Modal>

        <ErrorModal failure={error} onClose={() => setError(null)}/>

    </Wrapper>
}

export default OrganizationBodiesScreen;
