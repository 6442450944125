import {DataObject} from "../core/dataObject";
import {Failure} from "../core/failure";
import HttpClient from "../httpClient/httpClient";
import {handleError} from "../core/handleError";
import {AxiosError} from "axios";
import {AvailableEvent, ContextDocumentType, Document, DocumentBody, DocumentFilers, IdName} from "./documents.model";

export async function userOrganizationDocuments(filters: DocumentFilers, abortController?: AbortController): Promise<DataObject<Failure, Document[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/users/documents`, {
            params: {...filters},
            signal: abortController?.signal
        });
        return new DataObject<Failure, Document[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function participantOrganizationDocuments(organizationId: string, filters: DocumentFilers, abortController?: AbortController): Promise<DataObject<Failure, Document[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/as-participant/documents`, {
            params: {...filters},
            signal: abortController?.signal
        });
        return new DataObject<Failure, Document[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function userAvailableOrganizations(type: ContextDocumentType): Promise<DataObject<Failure, IdName[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/users/documents/available-organizations`, {
            params: {type},
        });
        return new DataObject<Failure, IdName[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function userAvailableBodies(type: ContextDocumentType): Promise<DataObject<Failure, IdName[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/users/documents/available-bodies`, {
            params: {type},
        });
        return new DataObject<Failure, IdName[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function userAvailableEvents(): Promise<DataObject<Failure, AvailableEvent[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/users/documents/available-events`);
        return new DataObject<Failure, AvailableEvent[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function participantAvailableOrganizations(organizationId: string, type: ContextDocumentType): Promise<DataObject<Failure, IdName[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/as-participant/documents/available-organizations`, {
            params: {type},
        });
        return new DataObject<Failure, IdName[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function participantAvailableBodies(organizationId: string, type: ContextDocumentType): Promise<DataObject<Failure, IdName[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/as-participant/documents/available-bodies`, {
            params: {type},
        });
        return new DataObject<Failure, IdName[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function participantAvailableEvents(organizationId: string, ): Promise<DataObject<Failure, AvailableEvent[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/as-participant/documents/available-events`);
        return new DataObject<Failure, AvailableEvent[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function organizationAvailableBodies(organizationId: string, type: ContextDocumentType): Promise<DataObject<Failure, IdName[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/documents/available-bodies`, {
            params: {type},
        });
        return new DataObject<Failure, IdName[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function organizationAvailableEvents(organizationId: string): Promise<DataObject<Failure, AvailableEvent[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/documents/available-events`);
        return new DataObject<Failure, AvailableEvent[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function organizationDocuments(organizationId: string, filters: DocumentFilers, abortController?: AbortController): Promise<DataObject<Failure, Document[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/documents`, {
            params: {
                ...filters,
            },
            signal: abortController?.signal,
        });
        return new DataObject<Failure, Document[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function eventDocuments(organizationId: string, eventId: string, filters: DocumentFilers, abortController?: AbortController): Promise<DataObject<Failure, Document[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/events/${eventId}/documents`, {
            params: {
                ...filters,
            },
            signal: abortController?.signal,
        });
        return new DataObject<Failure, Document[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function addDocumentAnnotation(organizationId: string, eventId: string, documentId: string, content: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${organizationId}/events/${eventId}/documents/${documentId}/annotations`, {
            content,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}



export async function deleteOrganizationDocument(organizationId: string, documentId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/${organizationId}/documents/${documentId}`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function deleteEventDocument(organizationId: string, eventId: string, documentId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/${organizationId}/events/${eventId}/documents/${documentId}`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export function organizationDocumentUrl(document: Document): string {
    return `/organizations/${document.organizationId!}/documents/${document.id}`
}
export function eventDocumentUrl(organizationId: string, eventId: string, document: Document): string {
    return `/organizations/${organizationId}/events/${eventId}/documents/${document.id}`
}
export function participantDocumentUrl(organizationId: string, document: Document): string {
    return `/organizations/${organizationId}/as-participant/documents/${document.id}`;
}

export function userDocumentUrl(document: Document): string {
    return `/organizations/users/documents/${document.id}`;
}

export async function addNewDocument(organizationId: string, documentBody: DocumentBody): Promise<DataObject<Failure, string | null>> {
    try {
        const body: FormData = new FormData();
        body.append('file', documentBody.file);
        documentBody.bodyIds.forEach((id) => {
            body.append('bodyIds', id);
        })
        if (documentBody.name) {
            body.append('name', documentBody.name);
        }
        const res = await HttpClient.post(`/organizations/${organizationId}/documents`, body);
        return new DataObject<Failure, string>(null, res.data.id);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}