import React, {useContext, useEffect, useState} from 'react';
import {Wrapper} from './OrganizationDataInvitationEmails.styles';
import {Link, useParams} from 'react-router-dom';
import iconAdd from '../../../../assets/icon-plus-white.svg';
import iconMenu from '../../../../assets/icon-menu.svg';
import {Table} from '../../../../components/Table/table';
import ContextMenu from '../../../../components/ContextMenu';
import ContextMenuItem from '../../../../components/ContextMenu/ContextMenuItem';
import {useBaseScreenHook} from '../../../../logic/core/base-screen.hook';
import Modal from '../../../../components/Modal';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import OrganizationDataInvitationEmailsNewEmail from './OrganizationDataInvitationEmailsNewEmail';
import {
    deleteInvitationEmail,
    getInvitationEmails,
    resendInvitationEmail
} from '../../../../logic/organizations/organizations';
import {InvitationEmail, InvitationEmailStatus} from '../../../../logic/organizations/organizations.model';
import {ToastContext} from "../../../../components/Toast/toastStateProvider";
import {ModalType} from "../../../../components/Toast";
import Loader from "../../../../components/Loader";

const OrganizationDataInvitationEmails = () => {
    const {t, setError, renderError} = useBaseScreenHook();
    const [addModal, setAddModal] = useState<boolean>(false);
    const [emailToDelete, setEmailToDelete] = useState<InvitationEmail | null>(null);
    const [emails, setEmails] = useState<InvitationEmail[] | null>(null);
    const {organizationId} = useParams();
    const [, setToastState] = useContext(ToastContext);

    const [sending, setSending] = useState<Set<string>>(new Set());

    useEffect(() => {
        _getInvitationEmails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getInvitationEmails = async () => {
        const res = await getInvitationEmails(organizationId!);

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setEmails(res.data!);
    }

    const _deleteEmail = () => {
        deleteInvitationEmail(organizationId!, emailToDelete!.id).then((res) => {
            setEmailToDelete(null);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            _getInvitationEmails();
        })
    }
    const _resendInvitationEmail = (email: string) => {
        sending.add(email);
        setSending(new Set(sending));
        resendInvitationEmail(organizationId!, email).then((res) => {

            sending.delete(email);
            setSending(new Set(sending));
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setToastState(
                {
                    type: ModalType.success,
                    message: t('organizationDataInvitationEmails.resentActivationEmail'),
                }
            )
        })
    }


    const _modalClosed = (refresh: boolean) => {
        setAddModal(false);
        if (refresh) {
            _getInvitationEmails();
        }
    }


    const _renderStatus = (status: InvitationEmailStatus) => {
        switch (status) {
            case InvitationEmailStatus.Active:
                return <div className="status active"> {t('organizationDataInvitationEmails.statusActive')}</div>
            case InvitationEmailStatus.Pending:
                return <div className="status pending"> {t('organizationDataInvitationEmails.statusPending')}</div>
            default:
                return <div className="status notactive"> {t('organizationDataInvitationEmails.statusNotActive')}</div>
        }
    }

    return <Wrapper>
        <div className="bar">
            <div className="left">
                <div className="title">
                    {t('organizationDataInvitationEmails.title')}
                </div>
                <div className="description">
                    {t('organizationDataInvitationEmails.description')} <Link to={''}>
                    {t('organizationDataInvitationEmails.descriptionRolesLink')}
                </Link>.
                </div>
            </div>
            <button className={'button icon'} onClick={() => setAddModal(true)}>
                <img src={iconAdd} alt="icon"/>
                {t('organizationDataInvitationEmails.addEmailBtn')}
            </button>

        </div>

        <Table>
            <tbody>
            {
                emails?.map((e) => {
                    return <tr key={e.id + '-' + e.email}>
                        <td>
                            {e.email}
                        </td>
                        <td>
                            <div className="inline-box">
                                {
                                    e.status === InvitationEmailStatus.Pending
                                        ? <button className={'button text'}
                                                  disabled={sending.has(e.email)}
                                                  onClick={() => _resendInvitationEmail(e.email)}>
                                            {
                                                sending.has(e.email) ?
                                                    <Loader/> : t('organizationDataInvitationEmails.resend')
                                            }</button>
                                        : null
                                }

                                {_renderStatus(e.status)}
                            </div>
                        </td>
                        <td>
                            <div className="action">
                                <ContextMenu icon={iconMenu}>
                                    {
                                        [
                                            <ContextMenuItem key="delete" onClick={() => setEmailToDelete(e)}>
                                        <span className="delete">
                                            {t('organizationDataInvitationEmails.deleteBtn')}
                                        </span>
                                            </ContextMenuItem>
                                        ]
                                    }
                                </ContextMenu>
                            </div>
                        </td>
                    </tr>
                })
            }


            </tbody>
        </Table>

        <Modal width={700} onClose={() => setAddModal(false)} show={addModal} backDropClose={false}>
            <OrganizationDataInvitationEmailsNewEmail onClose={(refresh) => _modalClosed(refresh)}/>
        </Modal>

        <Modal width={600} show={!!emailToDelete} onClose={() => setEmailToDelete(null)}>
            <ConfirmationModal
                reject={() => setEmailToDelete(null)}
                accept={() => _deleteEmail()}
                title={`${t('organizationDataInvitationEmails.deleteModalTitle')} ${emailToDelete?.email ?? ''}?`}
                body={t('organizationDataInvitationEmails.deleteModalBody')}
            />
        </Modal>

        {renderError()}
    </Wrapper>
}

export default OrganizationDataInvitationEmails;
