import { capitalLettersReqExp, specialCharsRegExp } from './patterns';
export interface PasswordStrong {
    minLength: boolean;
    capitalLetters: boolean;
    specialChars: boolean;
}
export const passwordStrongValidator = (password: string | null): PasswordStrong | null => {

    if (!password || password.length < 8 || !password.match(capitalLettersReqExp) || !password.match(specialCharsRegExp)) {
        return {
            minLength: password ? password.length < 8 :  true,
            capitalLetters: !password?.match(capitalLettersReqExp),
            specialChars: !password?.match(specialCharsRegExp),
        }
    }
    return null;
}
