import React, {useEffect, useState} from 'react';
import {Wrapper} from './OrganizationBillingsPaymentHistoryScreen.styles';
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import {Failure} from "../../../../logic/core/failure";
import {CreditsPaymentHistory} from "../../../../logic/credits/credits.model";
import {getCreditsPaymentHistory} from "../../../../logic/credits/credits";
import ErrorModal from "../../../../components/ErrorModal";
import Loader from "../../../../components/Loader";
import {Table} from "../../../../components/Table/table";

const OrganizationBillingsPaymentHistoryScreen = () => {
    const {t, i18n} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [error, setError] = useState<Failure | null>(null);
    const [history, setHistory] = useState<CreditsPaymentHistory[] | null>(null);
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const numberFormatter = new Intl.NumberFormat(i18n.language, {minimumFractionDigits: 2,  maximumFractionDigits: 2}).format;

    useEffect(() => {
        _getCreditsHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const _getCreditsHistory = () => {
        if (!organizationId) {
            return;
        }
        getCreditsPaymentHistory(organizationId).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            setHistory(res.data!);
        });
    }


    return <Wrapper>
        <div className="title">
            {t('organizationBillings.paymentHistoryScreen.title')}
        </div>

        <div className="list-description">
            {t('organizationBillings.paymentHistoryScreen.listDescription')}
        </div>

        <Table>
                <thead>
                <tr>
                    <th>
                        Lp.
                    </th>
                    <th>{t('organizationBillings.paymentHistoryScreen.paidTimestamp')}</th>
                    <th>{t('organizationBillings.paymentHistoryScreen.totalPriceGross')}</th>
                    <th>{t('organizationBillings.paymentHistoryScreen.product')}</th>
                    <th>{t('organizationBillings.paymentHistoryScreen.description')}</th>
                    <th>{t('organizationBillings.paymentHistoryScreen.status')}</th>
                </tr>
                </thead>

                <tbody>
                {
                    history ? history.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.payment.paidTimestamp ? dateFormatter(new Date(item.payment.paidTimestamp)) : '-'}</td>
                            <td>{numberFormatter(item.summary.totalPriceGross)} zł</td>
                            <td>
                                {
                                    item.summary.credits.map((credit, creditIndex) => {
                                        return <div key={creditIndex} className="product-name">
                                            {credit.definition.name} x {credit.quantity}
                                        </div>
                                    })
                                }
                            </td>
                            <td>{item.summary.description}</td>
                            <td>
                                {
                                    item.payment.paid
                                        ? t('organizationBillings.paymentHistoryScreen.statusPaid')
                                        : t('organizationBillings.paymentHistoryScreen.statusNotPaid')
                                }
                            </td>
                        </tr>
                    }) : null
                }
                </tbody>

        </Table>

        {
            history ? null : <Loader/>
        }

        <div className="info">
            <div className='info-title'>{t('organizationBillings.paymentHistoryScreen.infoTitle')}:</div>
            {t('organizationBillings.paymentHistoryScreen.info')} <a
            href="mailto:platnosci@votenti.pl">platnosci@votenti.pl</a>
        </div>

        <ErrorModal failure={error} onClose={() => setError(null)}/>

    </Wrapper>
}

export default OrganizationBillingsPaymentHistoryScreen;
