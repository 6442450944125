import { createContext, Dispatch, Reducer, useContext, useReducer } from 'react';
import { User, UserRole } from './logic/auth/auth.model';
import { Organization } from './logic/organizations/organizations.model';

type IsUserRole = (organizationId: string) => boolean;

export interface AppState {
    user: User | null;
    organizations: Organization[] | null;
}

const initialState: AppState = {
    user: null,
    organizations: null,
}

export enum AppStateActionType {
    setUser = 'setUser',
    setOrganizations = 'setOrganizations',
}

export interface AppStateAction {
    type: AppStateActionType;
    state: AppState;
}

export const AppStateContext = createContext<[AppState, Dispatch<AppStateAction>, IsUserRole, IsUserRole, IsUserRole]>([initialState, () => null, () => false, () => false, () => false]);

export const appStateReducer = (state: AppState, action: AppStateAction): AppState => {
    if (action.type === AppStateActionType.setUser) {
        return {
            ...state,
            user: action.state.user,
        }
    }else if (action.type === AppStateActionType.setOrganizations) {
        return {
            ...state,
            organizations: action.state.organizations,
        }
    }
    return {
        ...state,
    };
}


const AppStateProvider = ({children}: { children: any }) => {

    // const [state, setState] = useState(initialState);
    const [state, dispatch] = useReducer<Reducer<AppState, AppStateAction>>(appStateReducer, initialState);

    const isAdmin: IsUserRole = (organizationId: string) => {
        return state.user?.roles.some((r) => r.organizationId === organizationId && r.role === UserRole.Administrator) || false;
    }
    const isSuperAdmin: IsUserRole = (organizationId: string) => {
        return state.user?.roles.some((r) => r.organizationId === organizationId && r.role === UserRole.SuperAdministrator) || false;
    }
    const isEmployee: IsUserRole = (organizationId: string) => {
        return state.user?.roles.some((r) => r.organizationId === organizationId && r.role === UserRole.Employee) || false;
    }

    return (
        <AppStateContext.Provider
            value={[state, dispatch, isAdmin, isSuperAdmin, isEmployee]}>{children}</AppStateContext.Provider>
    )
}

export default AppStateProvider;


export function useAppStateContext() {
    const context = useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useAppStateContext must be used within TestProvider')
    }
    return context
}

