import styled from 'styled-components';

export const Wrapper = styled.div`
  .descriptions {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 20px;
  }
  
  .data {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    .box {
      padding: 10px 20px;
      border-radius: 8px;
      background: var(--bgLighColor);
      color: var(--fontMutedColor);
      .label {
        font-size: 1.2rem;
        margin-bottom: 5px;
        text-align: center;
      }
      .value {
        text-align: center;
        font-size: 2rem;
        font-weight: 500;
        height: 24px;
      }
    }
  }
  
  .table-wrapper {
    border-top: 1px solid var(--disabledColor);
    //overflow: auto;
    .body-name {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    .remove-btn {
      color: var(--errorColor)
    }
  }
  .filter-input {
    margin-top: 10px;
    .value {
      height: 30px;
      input {
        padding: 0 10px;
      }
    }
  }
  
  .data-loader {
    width: 24px;
    height: 24px;
  }
`
