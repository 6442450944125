import styled from 'styled-components';

export const Wrapper = styled.div`
  .not-logged-in {
    display: flex;
    gap: 50px;
    h5 {
      font-size: 1.4rem;
      line-height: 2.1rem;

      margin-bottom: 20px;
    }
    .submit-button {
      margin: 20px 0;
      width: 100%;
      &.join {
        margin-bottom: 0;
      }
    }
    .login {
      width: 365px;
      .inline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .forgot-password {
          font-size: 1.2rem;
          color: var(--fontMutedColor);
          text-decoration: none;
        }
      }


    }

    .register {
      width: 432px;
      .description-points {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .point {
          display: flex;
          gap: 10px;
          align-items: baseline;
          font-size: 1.4rem;
          line-height: 2.1rem;
        }
      }

      .or-separator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: var(--fontMutedColor);
        font-size: 1.4rem;
        position: relative;
        .label {
          background: #fff;
          padding: 0 40px;
          position: relative;
          z-index: 1;
        }
        &::before {
          content: '';
          height: 1px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: var(--fontMutedColor);
          left: 0;
          right: 0;
        }
      }

    }


  }
  .list-signed {
    font-size: 1.2rem;
    text-align: center;
    color: var(--errorColor);
  }
`