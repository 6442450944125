import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;

  .invitation-for {
    margin-bottom: 50px;
    .label {
      color: var(--fontMutedColor);
      margin-bottom: 5px;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    .name {
      color: var(--fontColor);
      font-weight: 600;
      margin-bottom: 5px;
    }
    .votes,
    .share {
      .label {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }
  }

  
`