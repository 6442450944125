import styled from 'styled-components';

export const Wrapper = styled.div`

    .title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      font-weight: bold;
    }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
`