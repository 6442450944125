import React, {useEffect, useState} from 'react';
import {ConfirmEditModal, Wrapper} from './OrganizationBodyAddMemberModal.styles';
import FormField from '../../../../../../components/FormField';
import Radio from '../../../../../../components/Radio';
import iconCompany from "../../../../../../assets/icon-company.svg"
import iconPerson from "../../../../../../assets/icon-person.svg"
import Input from '../../../../../../components/Input';

import iconArrowDown from '../../../../../../assets/icon-arrow-down.svg'
import iconArrowUp from '../../../../../../assets/icon-arrow-up.svg'
import iconPlus from '../../../../../../assets/icon-plus.svg'
import {
    BodyMember,
    BodyMemberOrganization,
    BodyMemberPersonal,
    BodyMemberType
} from '../../../../../../logic/bodies/bodies.model';
import {useBaseScreenHook} from '../../../../../../logic/core/base-screen.hook';
import {useForm} from '../../../../../../logic/core/form.hook';
import {createBodyMember, getBody, updateBodyMember} from '../../../../../../logic/bodies/bodies';
import {useParams} from 'react-router-dom';
import {Violations} from '../../../../../../logic/core/failures';
import {LegalFormBodyType} from "../../../../../../logic/organizations/organizations.model";
import { updateBodyMemberForEvent } from '../../../../../../logic/events/events';
import Modal from "../../../../../../components/Modal";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";

const OrganizationBodyAddMemberModal = ({
                                            onClose,
                                            contextBodyId,
                                            contextOrganizationId,
                                            contextEventId,
                                            memberToEdit,
                                        }: { memberToEdit: BodyMember | null, contextEventId?: string, contextBodyId?: string, contextOrganizationId?: string, onClose: (refresh: boolean, memberId?: string) => void }) => {
    let {bodyId, organizationId} = useParams();
    bodyId = contextBodyId ?? bodyId;
    organizationId = contextOrganizationId ?? organizationId;
    const {t, loading, setLoading, setError, renderError} = useBaseScreenHook();
    const [confirmEventEdit,    setConfirmEventEdit] = useState<'all' | 'forEvent' | null>(null);
    const {formControl, formValue, renderValidationMessage, setValidations, formValid} = useForm<BodyMember>({
        type: {
            value: memberToEdit?.type ?? BodyMemberType.personMember,
        },
        firstName: {
            value: (memberToEdit as BodyMemberPersonal)?.firstName ?? '',
        },
        lastName: {
            value: (memberToEdit as BodyMemberPersonal)?.lastName ?? '',
        },
        pesel: {
            value: (memberToEdit as BodyMemberPersonal)?.pesel ?? '',
        },
        organizationName: {
            value: (memberToEdit as BodyMemberOrganization)?.organizationName ?? '',
            disabled: true,
        },
        nip: {
            value: (memberToEdit as BodyMemberOrganization)?.nip ?? '',
            disabled: true,
        },
        votesNumber: {
            value: memberToEdit?.votesNumber ?? 0,

        },
        shares: {
            value: memberToEdit?.shares ?? 0,
        },
        email: {
            value: memberToEdit?.email ?? '',
        },
        address: {
            disabled: true,
            controls: {
                country: {
                    value: (memberToEdit as BodyMemberOrganization)?.address?.country ?? '',
                },
                city: {
                    value: (memberToEdit as BodyMemberOrganization)?.address?.city ?? '',
                },
                street: {
                    value: (memberToEdit as BodyMemberOrganization)?.address?.street ?? '',
                },
                postalCode: {
                    value: (memberToEdit as BodyMemberOrganization)?.address?.postalCode ?? '',
                },
            }
        },
        addressee: {
            controls: {
                firstName: {
                    value: memberToEdit?.addressee?.firstName ?? '',
                },
                lastName: {
                    value: memberToEdit?.addressee?.lastName ?? '',
                },
                position: {
                    value: (memberToEdit as BodyMemberOrganization)?.addressee?.position ?? '',
                    disabled: true,
                },
                phoneNumber: {
                    value: memberToEdit?.addressee?.phoneNumber ?? '',
                },
            }
        }
    });

    useEffect(() => {
        getBody(organizationId!, bodyId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            if (res.data?.type === LegalFormBodyType.Capital) {
                formControl('shares').enable();
            } else {
                formControl('shares').disable();
            }
        })

        if (memberToEdit?.type === BodyMemberType.organizationMember) {
            _setOrganizationMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [companyAddressOpened, setCompanyAddressOpened] = useState<boolean>(false);
    const _submit = async (eventId?: string) => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        let res;

        let body: BodyMember;
        if (formValue.type === BodyMemberType.personMember) {
            body = {
                type: formValue.type,
                firstName: formValue.firstName || null,
                lastName: formValue.lastName || null,
                pesel: formValue.pesel || null,
                votesNumber: formValue.votesNumber === 0 ? 0 : formValue.votesNumber || null,
                shares: formValue.shares === 0 ? 0 : formValue.shares || null,
                email: formValue.email || null,
                addressee: {
                    firstName: formValue.addressee?.firstName || null,
                    lastName: formValue.addressee?.lastName || null,
                    phoneNumber: formValue.addressee?.phoneNumber || null,
                }
            }
        } else {
            body = {
                type: formValue.type,
                organizationName: formValue.organizationName || null,
                nip: formValue.nip || null,
                votesNumber: formValue.votesNumber === 0 ? 0 : formValue.votesNumber || null,
                shares: formValue.shares === 0 ? 0 : formValue.shares || null,
                email: formValue.email || null,
                address: {
                    city: formValue.address?.city ?? null,
                    country: formValue.address?.country ?? null,
                    postalCode: formValue.address?.postalCode ?? null,
                    street: formValue.address?.street ?? null,
                },
                addressee: {
                    firstName: formValue.addressee?.firstName || null,
                    lastName: formValue.addressee?.lastName || null,
                    phoneNumber: formValue.addressee?.phoneNumber || null,
                    position: formValue.addressee?.position || null,
                }

            }
        }

        if (memberToEdit) {
            if (eventId) {
                res = await updateBodyMemberForEvent(organizationId!, eventId, memberToEdit.id!, body);
            } else {
                res = await updateBodyMember(bodyId!, memberToEdit.id!, body);
            }
        } else {
            res = await createBodyMember(bodyId!, body);
        }

        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose(true);
    }

    const _setPersonalMember = () => {
        formControl('type').patchValue(BodyMemberType.personMember);
        formControl('organizationName').disable();
        formControl('nip').disable();
        formControl('addressee.position').disable();
        formControl('address').disable();
        formControl('firstName').enable();
        formControl('lastName').enable();
        formControl('pesel').enable();
    }
    const _setOrganizationMember = () => {
        formControl('type').patchValue(BodyMemberType.organizationMember);
        formControl('organizationName').enable();
        formControl('nip').enable();
        formControl('addressee.position').enable();
        formControl('address').enable();
        formControl('firstName').disable();
        formControl('lastName').disable();
        formControl('pesel').disable();
    }

    // const _notificationDataChanged = (): boolean => {
    //
    //     if (
    //         memberToEdit?.email !== formValue.email ||
    //         memberToEdit?.addressee?.firstName !== formValue.addressee?.firstName ||
    //         memberToEdit?.addressee?.lastName !== formValue.addressee?.lastName ||
    //         memberToEdit?.addressee?.phoneNumber !== formValue.addressee?.phoneNumber ||
    //         (memberToEdit as BodyMemberOrganization)?.addressee?.position !== (formValue as BodyMemberOrganization).addressee?.position
    //     ) {
    //         return true;
    //     }
    //     return false;
    //
    // }

    if (!bodyId) {
        return <Wrapper> No bodyId provided! </Wrapper>
    }

    return <Wrapper>

        <h1 className="title">
            {
                memberToEdit
                    ? t('organizationBodyAddMemberModal.titleEdit')
                    : t('organizationBodyAddMemberModal.title')
            }
        </h1>

        <div className="type">

            <FormField>
                <Radio labelElement={<div className="label">
                    <img src={iconPerson} alt="icon"/>
                    {t('organizationBodyAddMemberModal.personMember')}

                </div>}>
                    <input type="radio" value={BodyMemberType.personMember} name="type"
                           checked={formControl('type').value === BodyMemberType.personMember}
                           onChange={() => {
                               _setPersonalMember()
                           }
                           }/>

                </Radio>
            </FormField>
            <FormField>
                <Radio labelElement={<div className="label">
                    <img src={iconCompany} alt="icon"/>
                    {t('organizationBodyAddMemberModal.organizationMember')}

                </div>}>
                    <input type="radio" value={BodyMemberType.organizationMember} name="type"
                           checked={formControl('type').value === BodyMemberType.organizationMember}
                           onChange={() => {
                               _setOrganizationMember()
                           }
                           }/>

                </Radio>
            </FormField>

        </div>

        <div className="form">

            <div className="section-title">
                {t('organizationBodyAddMemberModal.memberData')}
            </div>

            {
                formControl('type').value === BodyMemberType.personMember
                    ? <>
                        <div className="row">
                            <FormField>
                                <Input label={`${t('organizationBodyAddMemberModal.firstName')} *`}>
                                    <input type="text" value={formControl('firstName').value}
                                           onChange={(e) => formControl('firstName').patchValue(e.target.value)}/>
                                </Input>
                                {renderValidationMessage('firstName')}
                            </FormField>
                            <FormField>
                                <Input label={`${t('organizationBodyAddMemberModal.lastName')} *`}>
                                    <input type="text" value={formControl('lastName').value}
                                           onChange={(e) => formControl('lastName').patchValue(e.target.value)}/>
                                </Input>
                                {renderValidationMessage('lastName')}
                            </FormField>
                        </div>

                        <div className="row">
                            <FormField>
                                <Input label={`${t('organizationBodyAddMemberModal.pesel')}`}>
                                    <input type="string" value={formControl('pesel').value}
                                           onChange={(e) => formControl('pesel').patchValue(e.target.value)}/>
                                </Input>
                                {renderValidationMessage('pesel')}
                            </FormField>
                        </div>
                    </>
                    : <>
                        <div className="row">
                            <FormField>
                                <Input label={`${t('organizationBodyAddMemberModal.organizationName')} *`}>
                                    <input type="text" value={formControl('organizationName').value}
                                           onChange={(e) => formControl('organizationName').patchValue(e.target.value)}/>
                                </Input>
                                {renderValidationMessage('organizationName')}

                            </FormField>
                        </div>
                        <div className="row">
                            <FormField>
                                <Input label={`${t('organizationBodyAddMemberModal.nip')}`}>
                                    <input type="string" value={formControl('nip').value}
                                           onChange={(e) => formControl('nip').patchValue(e.target.value)}/>
                                </Input>
                                {renderValidationMessage('nip')}

                            </FormField>
                        </div>

                        {
                            companyAddressOpened
                                ? <>

                                    <div className="row">
                                        <FormField>
                                            <Input label={`${t('organizationBodyAddMemberModal.country')}`}>
                                                <input type="text" value={formControl('address.country').value}
                                                       onChange={(e) => formControl('address.country').patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('address.country')}

                                        </FormField>
                                    </div>
                                    <div className="row">
                                        <FormField>
                                            <Input label={`${t('organizationBodyAddMemberModal.street')}`}>
                                                <input type="text" value={formControl('address.street').value}
                                                       onChange={(e) => formControl('address.street').patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('address.street')}

                                        </FormField>
                                    </div>
                                    <div className="row">
                                        <div className="zip-code">
                                            <FormField>
                                                <Input label={`${t('organizationBodyAddMemberModal.postalCode')}`}>
                                                    <input type="text" value={formControl('address.postalCode').value}
                                                           onChange={(e) => formControl('address.postalCode').patchValue(e.target.value)}/>
                                                </Input>
                                                {renderValidationMessage('address.postalCode')}

                                            </FormField>
                                        </div>
                                        <FormField>
                                            <Input label={`${t('organizationBodyAddMemberModal.city')}`}>
                                                <input type="text" value={formControl('address.city').value}
                                                       onChange={(e) => formControl('address.city').patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('address.city')}

                                        </FormField>
                                    </div>

                                </>
                                : <button className="button text icon" onClick={() => setCompanyAddressOpened(true)}>
                                    <img src={iconPlus} alt="icon"/>
                                    {t('organizationBodyAddMemberModal.addOrganizationAddress')}
                                </button>
                        }

                    </>
            }
            <div className="row">

                <FormField>
                    <div className="data-values">
                        <Input>
                            <input type="number" value={formControl('votesNumber').value} step={1}
                                   onChange={(e) => formControl('votesNumber').patchValue(e.target.value)}/>
                            <div className="arrows">
                                <img src={iconArrowUp} alt="up"
                                     onClick={() => formControl('votesNumber').patchValue(formControl('votesNumber').value + 1)}/>
                                <img src={iconArrowDown} alt="down"
                                     onClick={() => formControl('votesNumber').patchValue(formControl('votesNumber').value - 1)}/>
                            </div>
                        </Input>
                        <div className="label">
                            {t('organizationBodyAddMemberModal.votesNumber')}
                        </div>
                    </div>
                    {renderValidationMessage('votesNumber')}

                </FormField>

                {
                    formControl('shares').control.disabled
                        ? null
                        : <FormField>
                            <div className="data-values">

                                <Input>
                                    <input type="number" value={formControl('shares').value} step={1}
                                           disabled={formControl('shares').control.disabled}
                                           onChange={(e) => formControl('shares').patchValue(e.target.value)}/>
                                    <div className="arrows">
                                        <img src={iconArrowUp} alt="up"
                                             onClick={() => formControl('shares').patchValue(formControl('shares').value + 1)}/>
                                        <img src={iconArrowDown} alt="down"
                                             onClick={() => formControl('shares').patchValue(formControl('shares').value - 1)}/>
                                    </div>
                                </Input>
                                <div className="label">
                                    {t('organizationBodyAddMemberModal.shares')}
                                </div>
                            </div>
                            {renderValidationMessage('shares')}

                        </FormField>
                }


            </div>


            <div className="section-title">
                {t('organizationBodyAddMemberModal.notificationData')}

            </div>
            <div className="section-subtitle">
                {t('organizationBodyAddMemberModal.baseData')}

            </div>
            <div className="row">
                <FormField>
                    <Input label={t('organizationBodyAddMemberModal.email') + ' *'}>
                        <input type="email" value={formControl('email').value}
                               onChange={(e) => formControl('email').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('email')}

                </FormField>
            </div>
            <div className="section-subtitle">
                {t('organizationBodyAddMemberModal.recipientData')}
            </div>
            <div className="row">
                <FormField>
                    <Input label={t('organizationBodyAddMemberModal.recipientFirstName')}>
                        <input type="text" value={formControl('addressee.firstName').value}
                               onChange={(e) => formControl('addressee.firstName').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('addressee.firstName')}

                </FormField>
                <FormField>
                    <Input label={t('organizationBodyAddMemberModal.recipientLastName')}>
                        <input type="text" value={formControl('addressee.lastName').value}
                               onChange={(e) => formControl('addressee.lastName').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('addressee.lastName')}

                </FormField>
            </div>

            {
                formControl('type').value === BodyMemberType.organizationMember
                    ? <div className="row">
                        <FormField>
                            <Input label={t('organizationBodyAddMemberModal.recipientPosition')}>
                                <input type="text" value={formControl('addressee.position').value}
                                       onChange={(e) => formControl('addressee.position').patchValue(e.target.value)}/>
                            </Input>
                            {renderValidationMessage('addressee.position')}

                        </FormField>
                    </div>
                    : null
            }

            <div className="row">
                <FormField>
                    <Input label={t('organizationBodyAddMemberModal.recipientPhoneNumber')}>
                        <input type="string" value={formControl('addressee.phoneNumber').value}
                               onChange={(e) => formControl('addressee.phoneNumber').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('addressee.phoneNumber')}

                </FormField>
            </div>
        </div>

        <div className="buttons">
            <button className="button text muted" onClick={() => onClose(false)}>
                {t('organizationBodyAddMemberModal.cancelBtn')}
            </button>

            {
                contextEventId
                    ? <>
                        <button className={'button outline'} disabled={loading} onClick={() => {
                            setConfirmEventEdit('forEvent')
                        }}>
                            {t('organizationBodyAddMemberModal.saveForThisEvents')}
                        </button>
                        <button className={'button'} disabled={loading} onClick={() => {
                            setConfirmEventEdit('all')
                        }}>
                            {t('organizationBodyAddMemberModal.saveForAllEvents')}
                        </button>
                    </>
                    : <button className={'button'} disabled={loading} onClick={() => _submit()}>
                        {t('organizationBodyAddMemberModal.saveBtn')}
                    </button>
            }

        </div>

        <Modal width={600} show={!!confirmEventEdit} onClose={() => setConfirmEventEdit(null)}>
            <ConfirmEditModal>
                <ConfirmationModal
                    reject={() => setConfirmEventEdit(null)}
                    accept={() => _submit(confirmEventEdit === 'forEvent' ? contextEventId : undefined)}
                    title={t('organizationBodyAddMemberModal.confirmEditForEventTitle')}
                    body={t('organizationBodyAddMemberModal.confirmEditForEventBody')}
                    acceptLabel={t('organizationBodyAddMemberModal.confirmEditForEventAcceptLabel')}
                />
            </ConfirmEditModal>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default OrganizationBodyAddMemberModal;
