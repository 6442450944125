import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--bgLighColor);
  overflow: hidden;
  border-radius: 5px;
  .title {
    padding: 8px 20px;
    text-align: left;
    font-size: 2rem;
    background: #fff;
    margin-bottom: 20px;
    color: var(--fontMutedColor);
  }
  .document-wrapper {
    overflow: auto;
    padding: 0 20px 20px;
    max-height: calc(100vh - 300px);
  }
`