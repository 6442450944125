import React, {FormEvent} from "react";
import {Wrapper} from "./notLoggedIn.styles";
import FormField from "../../../../components/FormField";
import Input from "../../../../components/Input";
import Checkbox from "../../../../components/Checkbox";
import {Link, useParams} from "react-router-dom";
import {AppRoutes} from "../../../../logic/core/routes";
import checkPoint from "../../../../assets/icon-check.svg";
import {login} from "../../../../logic/auth/auth";
import {AuthError, Violations} from "../../../../logic/core/failures";
import {useForm} from "../../../../logic/core/form.hook";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useAppStateHook} from "../../../../logic/core/appState.hook";
import {EventParticipantInvitation} from "../../../../logic/events/events.model";

const NotLoggedIn = ({invitation, joinAction}: { invitation: EventParticipantInvitation, joinAction: () => void }) => {
    const {setError, renderError, t, loading, setLoading, navigate} = useBaseScreenHook();
    const {getMe} = useAppStateHook();
    const {participantId} = useParams();
    const listSigned: boolean = invitation.participant.alreadySigned;
    const {
        renderValidationMessage,
        formControl,
        formValid,
        formValue,
        setValidations
    } = useForm<{ username: string, password: string, rememberMe: boolean }>({
        username: {
            value: ''
        },
        password: {
            value: ''
        },
        rememberMe: {
            value: false
        },
    })
    const _register = () => {
        navigate(`/${AppRoutes.register}?participantInvitationId=${participantId}`);
    }
    const _submit = async (e: FormEvent) => {
        e.preventDefault();
        if (!formValid()) {
            return;
        }
        setLoading(true);

        const res = await login(formValue.username, formValue.password, formValue.rememberMe);
        if (res.isViolations()) {
            setLoading(false);
            setValidations((res.failure! as Violations).getValidations())
            return;
        }


        if (res.isFailure()) {
            setLoading(false);
            if (res.failure?.is(AuthError.getCode)) {
                setError(new AuthError(t('errors.BadCredentials')));
            } else {
                setError(res.failure!);
            }
            return;
        }
        const resMe = await getMe();
        setLoading(false);
        if (resMe.isFailure()) {
            setError(resMe.failure!);
            return;
        }

    }
    return <Wrapper>
        <div className="not-logged-in">

            <div className="login">
                <h5>
                    Dołącz przez swoje konto
                </h5>

                <FormField>
                    <Input label={t('login.emailLabel')}>
                        <input type="text"
                               value={formControl('username').value}
                               onChange={(e) => formControl('username').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('username')}
                </FormField>

                <FormField>
                    <Input label={t('login.passwordLabel')}>
                        <input type="password"
                               value={formControl('password').value}
                               onChange={(e) => formControl('password').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('password')}
                </FormField>

                <div className="inline">
                    <Checkbox label={t('login.keepLoggedInLabel')}>
                        <input type="checkbox" checked={formControl('rememberMe').value}
                               onChange={() => formControl('rememberMe').patchValue(!formControl('rememberMe').value)}/>
                    </Checkbox>

                    <Link to={`/${AppRoutes.forgetPassword}`}
                          className="forgot-password">{t('login.forgotPassword')}</Link>
                </div>

                <button type="button" className="button submit-button" disabled={loading} onClick={_submit}>
                    {t('login.submitButton')}
                </button>
            </div>

            <div className="register">
                <h5>
                    Nie posiadasz konta Votenti?
                </h5>


                <div className="description-points">

                    <div className="point">
                        <img src={checkPoint} alt="check"/>
                        <div className="text">
                            Archiwizuj w jednym miejscu dokumenty z posiedzeń i głosowań, w których uczestnikiem
                            jest {invitation.event.organizationName} <a href="https://staging.votenti.pl"
                                                                        target="_blank" rel="noreferrer">więcej
                            informacji &gt;</a>
                        </div>
                    </div>
                    <div className="point">
                        <img src={checkPoint} alt="check"/>
                        <div className="text">
                            Twórz posiedzenia i głosowania dla {invitation.event.organizationName} oraz innych
                            organizacji <a href="https://staging.votenti.pl" target="_blank" rel="noreferrer">więcej
                            informacji &gt;</a>
                        </div>
                    </div>
                    <div className="point">
                        <img src={checkPoint} alt="check"/>

                        Zaproś do konta organizacji swoich współpracowników
                    </div>

                </div>

                <button type="button" className="button submit-button accent" disabled={loading}
                        onClick={_register}>
                    Zarejestruj się za darmo
                </button>

                {
                    invitation.event.anonymousAuthorizationAvailable ? <>
                        <div className="or-separator">
                            <div className="label">
                                lub dołącz bez logowania
                            </div>
                        </div>

                        <button type="button" className="button submit-button join" disabled={loading || listSigned}
                                onClick={joinAction}>
                            Dołącz bez logowania
                        </button>
                    </> : null

                }
                {
                    listSigned ? <div className="list-signed">
                        Lista została już podpisana
                    </div> : null
                }
            </div>

        </div>
        {renderError()}
    </Wrapper>
}


export default NotLoggedIn;