import styled from 'styled-components';

export const Wrapper = styled.div`
.title {
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 30px;
}
  
  .body {
    margin-bottom: 40px;
    color: var(--fontMutedColor);
  }
  
  .buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
  }
`
