import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 30px;
  @media screen and (max-width: 768px) {
    padding: 0 0;
  }

  &.edit {
    padding: 0 10px;
    @media screen and (max-width: 768px) {
      padding: 0 0;
    }
  }


  .organization-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--fontMutedColor);
    margin-bottom: 10px;
    word-break: break-word;
  }

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .data-values {
    display: flex;
    align-items: center;
    gap: 20px;

    input {
      padding-right: 60px !important;
      width: 180px !important;
    }
  }

  .buttons {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .edit-buttons {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 200px;

    .delete {
      color: var(--errorColor);
    }
  }

  .next-step-info {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
  }

  .arrows {
    position: absolute;
    right: 30px;
    top: 0;
    display: flex;
    flex-direction: column;
    bottom: 0;
    align-items: center;
    justify-content: center;

    img {
      padding: 5px 0;
    }
  }
  .type-label {
    margin-bottom: 10px;
  }
`
