import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;

    .meeting-status {
      .status {
        background: var(--bgLighColor);
        border-radius: 5px;
        padding: 10px;

        .status-text {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 1.2rem;
          color: var(--fontMutedColor);

          span {
            text-transform: lowercase;
          }

          .dot {
            width: 15px;
            height: 15px;
            flex-shrink: 0;
            border-radius: 50%;

            &.Draft {
              background: var(--fontMutedColor);
            }

            &.InProgress {
              background: var(--mainColor);
            }

            &.Finished {
              background: var(--successColor);
            }

            &.FinishRequested {
              background: #000;
            }
          }
        }

        .documents-counter {
          margin: 10px auto;
          text-align: center;
          font-size: 2.5rem;
          color: var(--fontMutedColor);
          font-weight: 500;
        }

      }
    }
  }

  .sub-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
.event-description {
  margin-bottom: 30px;
}
  .list {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .agenda {
      .point {
        display: flex;
        align-items: center;
        gap: 10px;

        .no {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 30px;
          color: var(--fontMutedColor);
          font-weight: bold;
        }

        .name {
          padding: 5px 10px;
          min-height: 52px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid var(--disabledColor);
          border-radius: 5px;
          gap: 20px;
        }

        .add-voting {
          margin-right: 0;
          margin-left: auto;
        }

        &.active {
          .name {
            .title {
              color: var(--mainColor);
              font-weight: bold;
            }
          }
        }
      }

      .next-point-button {
        padding-top: 20px;
        padding-left: 40px;
      }

      .votes {
        margin-top: 10px;
        margin-left: 30px;
        border-left: 5px solid transparent;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &.active {
          border-left: 5px solid var(--mainColor);

          .vote {
            background: #fff;
          }
        }

        .vote {
          padding: 10px;
          background: var(--bgLighColor);
          border-radius: 8px;
          box-shadow: 0 3px 4px #0000001A;

          .vote-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            .name {
              display: flex;
              align-items: center;
              gap: 10px;

              .dot {
                width: 15px;
                height: 15px;
                flex-shrink: 0;
                border-radius: 50%;

                &.Pending {
                  background: var(--waringColor);
                }

                &.InProgress {
                  background: var(--mainColor);
                }

                &.Passed {
                  background: var(--successColor);
                }

                &.Finished {
                  background: #000;
                }

                &.Canceled {
                  background: var(--fontMutedColor);
                }

                &.NotPassed {
                  background: var(--errorColor);
                }
              }
            }

          }

          .content-wrapper {
            display: flex;
            justify-content: space-between;

            .left-content {
              .actions {
                margin-top: 10px;
                display: flex;
                gap: 20px;
              }

              .outcome-decision,
              .exclude-members {
                margin-top: 10px;
                font-size: 1.2rem;
              }
            }

            .right-content {
              .votes-summary {
                text-align: right;
                margin-top: 10px;
                font-size: 1.2rem;
                .casted-vote{
                  margin-bottom: 2px;
                  color: var(--successColor);
                  &.pending{
                    color: var(--waringColor);
                  }
                } 
                .casted-votes-count {

                  color: var(--fontMutedColor);
                }
              }
            }
          }

        }
      }
    }

    .delete {
      color: var(--errorColor);
    }

  }
    
    .nav-buttons {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 20px;
    }

`

export const WarnMessage = styled.div`
  color: var(--errorColor);
  font-weight: 500;
`
