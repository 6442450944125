import React from 'react';
import { Wrapper } from './OrganizationBillingsScreen.styles';
import { NavLink, Outlet } from 'react-router-dom';
import { useBaseScreenHook } from '../../../logic/core/base-screen.hook';
import { AppRoutes } from '../../../logic/core/routes';

const OrganizationBillingsScreen = () => {
    const {t} = useBaseScreenHook();
    return <Wrapper>

        <div className="menu">
            <NavLink to={AppRoutes.billings}>{t('organizationBillings.billings')}</NavLink>
            <NavLink to={AppRoutes.paymentHistory}>{t('organizationBillings.paymentHistory')}</NavLink>
            <NavLink to={AppRoutes.pricing}>{t('organizationBillings.pricing')}</NavLink>
        </div>
        <div className="outlet">
            <Outlet/>
        </div>

    </Wrapper>
}

export default OrganizationBillingsScreen;
