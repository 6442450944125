import React, { useEffect, useState } from 'react';
import { Wrapper } from './NewOrganizationModal.styles';
import Input from '../Input';
import FormField from '../FormField';
import { getLegalForms } from '../../logic/organizations/organizations';
import { LegalForm, OrganizationBody } from '../../logic/organizations/organizations.model';
import Select from '../Select';
import { ModalCloseFunction } from '../Modal';
import { Violations } from '../../logic/core/failures';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import { useForm } from '../../logic/core/form.hook';
import { AppRoutes } from '../../logic/core/routes';
import { useAppStateHook } from '../../logic/core/appState.hook';

const NewOrganizationModal = ({onClose}: { onClose: ModalCloseFunction }) => {

    const {formControl, formValue, renderValidationMessage, formValid, setValidations} = useForm<OrganizationBody>({
        name: {
            value: '',
        },
        legalFormId: {
            value: '',
        },
        invitationEmail: {
            value: '',
        }
    });
    const [legalForms, setLegalForms,] = useState<LegalForm[] | null>(null);

    const {t, loading, setLoading, setError, navigate, renderError,} = useBaseScreenHook();

    const {createOrganization, getMe} = useAppStateHook();

    useEffect(() => {
        getLegalForms().then((res) => {
            if (res.data) {
                setLegalForms(res.data);
            }
        })
    }, [])


    const submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        const res = await createOrganization({
            name: formValue.name || null,
            legalFormId: formValue.legalFormId || null,
            invitationEmail: formValue.invitationEmail || null,
        });

        if (res.isViolations()) {
            setLoading(false);
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setLoading(false);
            setError(res.failure!);
            return;
        }

        await getMe();

        onClose();
        if (res.data) {
            navigate(`${AppRoutes.organization}/${res.data}`)
        }


    }

    return <Wrapper>
        <p className="description">
            {t('newOrganizationModal.description')}
        </p>

        <FormField>
            <Input label={t('newOrganizationModal.name') + ' *'}>
                <input type="text"
                       value={formControl('name').value}
                       onChange={(e) => formControl('name').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('name')}
        </FormField>

        <FormField>
            <Select label={t('newOrganizationModal.legalForm') + ' *'}
                    currentValue={formControl('legalFormId').value}
                    onChange={formControl('legalFormId').patchValue}
            >
                <option/>
                {legalForms?.map((p) => {
                    return <option value={p.id} key={p.id}>{p.label}</option>
                })}
            </Select>
            {renderValidationMessage('legalFormId')}
        </FormField>

        <FormField>
            <div className="label">
                {t('newOrganizationModal.email')}
            </div>
            <div className="hint">
                {t('newOrganizationModal.emailHint')}
            </div>
            <Input label="">
                <input type="email" value={formControl('invitationEmail').value}
                       onChange={(e) => formControl('invitationEmail').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('invitationEmail')}
        </FormField>

        <button className={'button'} onClick={submit} disabled={loading}>
            {t('newOrganizationModal.createButton')}
        </button>

        {renderError()}
    </Wrapper>


}

export default NewOrganizationModal;
