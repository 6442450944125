import NotLoggedBaseScreen from '../../components/NotLoggedBaseScreen';
import { Wrapper } from './RegisterConfirmEmailScreen.styles';
import { Link, useSearchParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import { resendActivationLink } from '../../logic/auth/auth';
import React, { useContext, useEffect, useState } from 'react';
import { ModalType } from '../../components/Toast';
import { AppRoutes } from '../../logic/core/routes';
import { ToastContext } from '../../components/Toast/toastStateProvider';


const RegisterScreen = () => {
    const [params] = useSearchParams();
    const {t, navigate, loading, setLoading, setError, renderError} = useBaseScreenHook();
    const [email, setEmail] = useState<string | null>(null);
    const [, setToastState] = useContext(ToastContext);

    useEffect(() => {
        const email = params.get('email');
        if (!email) {
            navigate(`/${AppRoutes.login}`);
            return;
        }

        setEmail(email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const send = async () => {

        setLoading(true);
        const res = await resendActivationLink(email!);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setToastState({
            type: ModalType.success,
            message: t('registerConfirm.resentActivationEmail'),
        })
    }

    return <NotLoggedBaseScreen>
        <Wrapper>

            <h2 className="title">
                {t('registerConfirm.title')}
            </h2>
            <p>

                <Trans t={t}>
                    {t('registerConfirm.emailInfo', {
                        email: params.get('email'),
                    })}
                </Trans>

            </p>

            <p>
                {t('registerConfirm.info1')}
            </p>

            <p>
                {t('registerConfirm.info2')}
            </p>

            <button className={'button'} onClick={send} disabled={loading}>
                {t('registerConfirm.submitButton')}
            </button>

            <p>
                {t('registerConfirm.needHelpInfo')} <Link to="">{t('registerConfirm.needHelpLink')}</Link>
            </p>

            {renderError()}

        </Wrapper>
    </NotLoggedBaseScreen>
};

export default RegisterScreen;
