import styled from 'styled-components';

export const Wrapper = styled.tr`
  .annotation-name {
    overflow: hidden;
    padding: 0 10px;

    .helper {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .decoration {
      height: 2px;
      width: 30px;
      background: var(--disabledColor);
      position: relative;

      &::before {
        content: '';
        width: 2px;
        height: 100px;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        transform: translateY(50%);
        background: var(--disabledColor);
      }

      &.last {
        &::before {
          transform: translateY(0);
        }
      }
    }
  }

`