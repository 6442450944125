import React, { useEffect, useState } from 'react';
import { Wrapper } from './OrganizationEventsScreen.styles';
import { useBaseScreenHook } from '../../../logic/core/base-screen.hook';
import { Table } from '../../../components/Table/table';
import { useParams } from 'react-router-dom';
import { getEvents } from '../../../logic/events/events';
import { EventList } from '../../../logic/events/events.model';
import Loader from '../../../components/Loader';
import { initSortableResponse, SortableResponse } from '../../../logic/core/sortableResponse.model';
import Paginator, { PaginationChanged } from '../../../components/Paginator';
import { AppRoutes } from '../../../logic/core/routes';

const OrganizationEventsScreen = () => {
    const {t, renderError, setError, i18n, navigate} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [events, setEvents] = useState<SortableResponse<EventList>>(initSortableResponse);

    useEffect(() => {
        _getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, events?.number, events?.size])

    const _getEvents = async () => {

        const res = await getEvents(organizationId!, events?.number, events?.size);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setEvents(res.data!);
    }
    const _paginationChanged: PaginationChanged = async (size, page) => {
        setEvents({
            ...events!,
            totalPages: Math.round(events!.totalElements / size),
            number: page,
            size,
        })
    }
    const _goToEvent = (e: EventList) => {
        localStorage.setItem('event-source-location', window.location.pathname + window.location.search);
        navigate(`/${AppRoutes.organization}/${organizationId!}/${AppRoutes.event}/${e.id}`);
    }

    return <Wrapper>
        <div className="title">
            {t('events.title')}
        </div>

        <Table>
            <thead>
            <tr>
                <th>
                    {t('events.name')}
                </th>
                <th></th>
                <th>
                    {t('events.body')}
                </th>
                <th>
                    {t('events.start')}
                </th>
                <th>
                    {t('events.finish')}
                </th>
                <th>
                    {t('events.type')}
                </th>
            </tr>
            </thead>

            <tbody>
            {
                events?.content?.map((e) => {
                    return <tr key={e.id} className="pointer" onClick={() => _goToEvent(e)}>
                        <td>
                            {e.name}

                        </td>
                        <td>
                            {
                                e.proceedings ? t(`events.${e.proceedings}`) : null
                            }
                        </td>
                        <td>
                            {e.body.name}
                        </td>
                        <td>
                            {new Intl.DateTimeFormat(i18n.language, {
                                dateStyle: 'short',
                                timeStyle: 'short'
                            }).format(new Date(e.startDate))}
                        </td>
                        <td>
                            {e.finishDate ? new Intl.DateTimeFormat(i18n.language, {
                                dateStyle: 'short',
                                timeStyle: 'short'
                            }).format(new Date(e.finishDate)) : '-'}
                        </td>
                        <td>
                            {t(`events.type${e.type}`)}

                        </td>
                    </tr>
                })
            }

            </tbody>
        </Table>
        {
            events ? null : <Loader/>

        }

        {events
            ? <Paginator paging={events} onChanged={_paginationChanged} elementsFound={events.content.length}
                         pageSizes={[10, 20, 50]}/>
            : null
        }
        {renderError()}
    </Wrapper>
}

export default OrganizationEventsScreen;
