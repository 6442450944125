import styled from 'styled-components';

export const Wrapper = styled.div`
  .toast {
    position: fixed;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    transition: all 0.1s ease-in-out;
    z-index: 999;

    &.enter-done {
      opacity: 1;
      pointer-events: visible;

      .modal-content {
        transform: translate(0, 0);

      }
    }

    &.exit {
      opacity: 0;

      .modal-content {
        transform: translate(0, -200px);

      }
    }

    .modal-content {
      border-radius: 5px;
      max-height: 100%;
      overflow: auto;
      background-color: var(--successColor);
      color: #fff;
      transition: all 0.3s ease-in-out;
      position: relative;
      transform: translate(0, -200px);
      padding: 10px 20px;
      min-width: 300px;
      &.error {
        background-color: var(--errorColor);
      }

      &.warn {
        background-color: var(--waringColor);
      }

      &.success {
      }
      
      &.info {
        background-color: var(--mainColor);
      }
.message {
  font-weight: 400;
}
      .close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
      

    }
  }
`;
