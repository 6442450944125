import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1.4rem;
  .title {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }
  
  strong {
    font-weight: 700;
  }
  
  a {
    text-decoration: none;
    color: var(--mainColor);
  }
`
