import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .description {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    gap: 20px;
  }
  

`