import ReactDOM from "react-dom";
import React, { useEffect } from 'react';
import { CSSTransition } from "react-transition-group";
import { Wrapper } from './Modal.styles';
import IconClose from '../../assets/icon-close.svg';
export type ModalCloseFunction = () => void;

export enum ModalType {
    error = 'error',
    warn = 'warn',
    success = 'success',
    info = 'info',
}

export interface ModalOptions {
    type?: ModalType;
    onClose?: ModalCloseFunction,
    show: boolean,
    title?: string,
    width?: number;
    children?: any;
    backDropClose?: boolean;
    reset?: boolean;
}

const Modal = (props: ModalOptions) => {
    const nodeRef = React.useRef(null);

    useEffect(() => {
        const handleKeyboardEvent = (e: KeyboardEvent) => {
            if (e.code === 'Escape' && props.onClose) {
                props.onClose();
            }
        };
        window.addEventListener('keydown', handleKeyboardEvent, false);
        return () => {
            window.removeEventListener('keydown', handleKeyboardEvent, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{enter: 0, exit: 100}}
            nodeRef={nodeRef}
        >
            <Wrapper>
                <div className="modal" onClick={() => props.backDropClose === false ? null : props?.onClose?.()} ref={nodeRef}>
                    <div className={`modal-content ${props.type} ${props.reset ? ' reset' : ''}`} onClick={e => e.stopPropagation()} style={props.width ? {width: `${props.width}px`} : {}}>
                        {
                            props.onClose
                                ?<div className="close" onClick={props.onClose}>
                                    <img src={IconClose} alt="close"/>
                                </div>
                                : null
                        }
                        {props.title ? (
                            <h1 className="modal-title">{props.title}</h1>
                        ) : null}
                        {props.children ? (
                            <div className="modal-body">{props.children}</div>
                        ) : null}
                    </div>
                </div>
            </Wrapper>
        </CSSTransition>,
        document.getElementById('root') as any
    );
}

export default Modal;
