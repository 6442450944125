import React, {useEffect, useState} from "react";
import {Wrapper} from "./EventDocuments.styles";
import {
    AvailableEvent,
    ContextDocumentType,
    Document,
    DocumentFilers,
    IdName
} from "../../../../logic/documents/documents.model";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {
    userAvailableBodies, userAvailableEvents,
    userAvailableOrganizations, userDocumentUrl,
    userOrganizationDocuments
} from "../../../../logic/documents/documents";
import {Canceled} from "../../../../logic/core/failures";
import {downloadByUrl, saveFile} from "../../../../logic/files/files.service";
import {Table} from "../../../../components/Table/table";
import Select from "../../../../components/Select";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";
import FilesPreview from "../../../../components/FilesPreview";
import {Link} from "react-router-dom";
import {AppRoutes} from "../../../../logic/core/routes";
import Annotations from "../../../../components/Annotations";

let inputDebouncer: ReturnType<typeof setTimeout> | null = null;
let abortController: AbortController | null = null;


const UserEventDocuments = () => {
    const [availableFilters, setAvailableFilters] = useState<{ organizations: IdName[], bodies: IdName[], events: AvailableEvent[] } | null>(null);
    const [filters, setFilters] = useState<DocumentFilers>({
        type: ContextDocumentType.Event
    });
    const {renderError, setError, i18n, t} = useBaseScreenHook();
    const [downloading, setDownloading] = useState<Set<string>>(new Set([]));
    const [preview, setPreview] = useState<{ url: string, urls?: string[] } | null>(null);

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const [documents, setDocuments] = useState<Document[] | null>(null);
    useEffect(() => {
        _getDocuments(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getAvailableFilters = (filters: DocumentFilers) => {
        Promise.all([
            userAvailableOrganizations(ContextDocumentType.Event),
            userAvailableBodies(ContextDocumentType.Event),
            userAvailableEvents(),
        ]).then((res) => {
            const [
                organizationsRes,
                bodiesRes,
                eventsRes,
            ] = res;
            const failure = res.find(r => r.isFailure());
            if (failure) {
                setError(failure.failure!);
                return;
            }
            const _availabelFilters = {
                organizations: organizationsRes.data!,
                bodies: bodiesRes.data!,
                events: eventsRes.data!,
            }
            setAvailableFilters(_availabelFilters);
            if (!filters.eventId && _availabelFilters.events.length) {
                _setFilters({
                    ...filters,
                    eventId: _availabelFilters.events[_availabelFilters.events.length - 1].id,
                })
            }
        })

    }

    const cleatInputDebouncer = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
        if (abortController) {
            abortController.abort();
            abortController = null;
        }
    }

    const _setFilters = (filters: DocumentFilers) => {
        setFilters(filters);
        setDocuments(null);
        cleatInputDebouncer();
        inputDebouncer = setTimeout(() => {
            _getDocuments(filters)
        }, 300);
    }

    const _getDocuments = (filters: DocumentFilers) => {
        abortController = new AbortController();
        _getAvailableFilters(filters);
        if (!filters.eventId) {
            return;
        }
        userOrganizationDocuments(filters, abortController).then((res) => {
            if (res.isFailure()) {
                if (res.failure?.is(Canceled.getCode)) {
                    return;
                }
                setError(res.failure!);
                return
            }
            setDocuments(res.data!);
        });
    }

    const _download = async (d: Document) => {
        downloading.add(d.id)
        setDownloading(new Set(Array.from(downloading)));
        const res = await downloadByUrl(userDocumentUrl(d));
        downloading.delete(d.id)
        setDownloading(new Set(Array.from(downloading)));
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        const saveRes = await saveFile(res.data!.data!, res.data?.name!, res.data?.type!);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }


    }


    return <Wrapper>

        <p className="description">
            Poniżej znajdziesz udostępnione przez organizatora dokumenty z głosowań, w których wiziąłeś/wzięłać udział jako uczestnik.
        </p>

        <Table>
            <thead>
            <tr>
                <th>
                    Wybierz organizację
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.organizationId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    organizationId: e
                                })}
                        >
                            <option/>
                            {availableFilters?.organizations?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
                <th>
                    Wybierz organ
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.bodyId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    bodyId: e
                                })}
                        >
                            <option/>
                            {availableFilters?.bodies?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
                <th>
                    <div className="event-label">
                        {t('documents.selectEvent')}

                        {
                            filters.eventId
                                ?<Link
                            to={`/${AppRoutes.organization}/${availableFilters?.events.find(e => e.id === filters.eventId)?.organizationId}/${AppRoutes.event}/${filters.eventId}/${AppRoutes.documents}`}
                            target={'_blank'}>{t('documents.goToEvent')}</Link> : null }
                    </div>
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.eventId ?? ''}
                                onChange={(id) => {
                                    _setFilters({
                                        ...filters,
                                        eventId: id
                                    })
                                }}
                        >
                            {availableFilters?.events?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>

            </tr>
            </thead>
        </Table>
        <Table className={'multibody'}>
            <thead>
            <tr>
                <th>{t('documents.date')}</th>
                <th>{t('documents.name')}</th>
                <th>{t('documents.category')}</th>
                <th></th>
            </tr>
            </thead>

            {
                documents?.map(d => {
                    return <tbody key={d.id}>
                    <tr>
                        <td>
                            {dateFormatter(new Date(d.timestamp))}
                        </td>
                        <td>
                            {d.name}
                        </td>
                        <td>
                            {d.categoryName ?? '-'}
                        </td>
                        <td>
                            <div className="actions">

                                <button className="button text" onClick={() => setPreview({
                                    url: userDocumentUrl(d),
                                    urls: documents?.map(userDocumentUrl)
                                })}>
                                    {t('documents.preview')}

                                </button>
                                <button className="button text" disabled={downloading.has(d.id)}
                                        onClick={() => _download(d)}>
                                    {downloading.has(d.id) ? <Loader/> : t('documents.download')}
                                </button>


                            </div>

                        </td>
                    </tr>
                    <Annotations
                        document={d}
                        onPreview={(doc) => {
                            setPreview({
                                url: userDocumentUrl(doc),
                                urls: d.annotations?.map(userDocumentUrl)
                            })
                        }}
                        onDownload={_download}
                        downloading={downloading}
                    />
                    </tbody>
                })
            }
        </Table>
        {
            documents
                ? documents.length ? null : t('documents.emptyList')
                : availableFilters?.events.length
                    ? <Loader/>
                    : t('documents.emptyList')
        }

        <Modal width={800} show={!!preview} reset={true} onClose={() => {
            setPreview(null);
        }} backDropClose={false}>
            <FilesPreview url={preview?.url} urls={preview?.urls} onClose={() => setPreview(null)}/>
        </Modal>

        {renderError()}
    </Wrapper>
}
export default UserEventDocuments;
