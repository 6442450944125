import React, {useContext, useEffect} from "react";
import {Wrapper} from "./invitation-email-verification.styled";
import Loader from "../../components/Loader";
import {verificationInvitationEmail} from "../../logic/organizations/organizations";
import {useParams, useSearchParams} from "react-router-dom";
import {useBaseScreenHook} from "../../logic/core/base-screen.hook";
import {ToastContext} from "../../components/Toast/toastStateProvider";
import {ModalType} from "../../components/Toast";

const InvitationEmailVerificationScreen = () => {
const {navigate} = useBaseScreenHook();
    const {email} = useParams();
    const [params] = useSearchParams();
    const token = params.get('token');
    const [, setToastState] = useContext(ToastContext);

    useEffect(() => {
        if (token && email) {
            verificationInvitationEmail(token, email).then(res => {
                if (res.isFailure()) {
                    setToastState({
                        type: ModalType.error,
                        message: res.failure?.message ?? '',
                    })
                } else {
                    setToastState({
                        type: ModalType.success,
                        message: 'E-email został poprawnie potwierdzony',
                    })
                }

                navigate('/');
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, token])

    return <Wrapper>

        <Loader/>


    </Wrapper>
}

export default InvitationEmailVerificationScreen;