import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ModalType } from './index';

export interface ToastState {
    type: ModalType;
    message: string | null;
}

const initialState: ToastState = {
    type: ModalType.error,
    message: null,
}

export const ToastContext = createContext<[ToastState, Dispatch<SetStateAction<ToastState>>]>([initialState, () => null]);


const ToastStateProvider = ({children}: { children: any }) => {

    const [state, setState] = useState(initialState)


    return (
        <ToastContext.Provider value={[state, setState]}>{children}</ToastContext.Provider>
    )
}

export default ToastStateProvider;
