import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: left;
    .label {
      margin-left: 11px;
      margin-bottom: 0;
      min-height: 22px;
      display: flex;
      gap: 5px;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
  }
  input + span {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
  input + span::before {
    content: '';
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 50%;
    border: 1px solid var(--disabledColor);
    cursor: pointer;
    background: #fff;
  }

  input:checked + span {
    &::after {
      position: absolute;
      left: 6px;
      top: 6px;
      content: '';
      display: inline-block;
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      border-radius: 50%;
      cursor: pointer;
      background: var(--mainColor);
    }
  }
  
`
