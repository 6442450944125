import styled from 'styled-components';

export const Wrapper = styled.div`
.timer {
  margin: 10px auto;
  text-align: center;
  font-size: 2.5rem;
  color: var(--fontMutedColor);
  font-weight: 500;
}
`