import styled from 'styled-components';

export const Wrapper = styled.div`

  .info {
    border-collapse: collapse;
    font-size: 1.2rem;
    margin-bottom: 20px;
    td {
      vertical-align: top;
    }
    tr {
      td {
        padding-bottom: 10px;
      }
      td:first-child {
        color: var(--fontMutedColor);
        padding-right: 50px;
      }
      td:last-child {
        font-weight: bold;
      }
    }
    .host-data {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-weight: bold;
    }
    .organization-data {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 1.2rem;
      color: var(--fontMutedColor);
    }
  }

  .event-description {
    margin-bottom: 10px;
  }
`