import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  input + span {
    display: inline-block;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background: var(--disabledColor);
    cursor: pointer;
    position: relative;
  }
  input + span::before {
    content: '';
    display: inline-block;
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    border-radius: 50%;
    cursor: pointer;
    background: #f9f9f9;
    position: absolute;
    top: 3px;
    left: 3px;
    transform: translateX(0);
    transition: .2s all;
  }
  
  input:checked + span {
    background: var(--mainColor);
    &::before {
      transform: translateX(20px);
    }
  }
  
  input:disabled + span {
    cursor: not-allowed;
    opacity: .6;
  }
  
  .label {
    margin-left: 11px;
  }
`
