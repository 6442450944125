export interface IdName {
    id: string;
    name: string;
}
export interface AvailableEvent extends IdName {
    organizationId: string;
}
export enum ContextDocumentType {
    Organization = "Organization", Event = "Event"
}
export interface DocumentFilers {
    organizationId?: string;
    bodyId?: string;
    eventId?: string;
    type?: ContextDocumentType;
}

export interface Document {
    name: string;
    fileId: string;
    contentType: string;
    timestamp: string;
    organizationId: string;
    bodyIds: string[];
    eventId: string;
    categoryName: string;
    id: string;
    type: ContextDocumentType;
    annotations?: Document[] | null;
}

export interface DocumentBody{
    file: File;
    name: string;
    bodyIds: string[];

}