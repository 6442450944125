import { useEffect, useState } from 'react';

export const useTimer = (fromDate: Date | null, countdown: boolean = false) => {

    const [timer, setTimer] = useState<number>(0);

    useEffect(() => {
        if (countdown) {
            setTimer(fromDate ? new Date(fromDate).getTime() - new Date().getTime() : 0)
        } else {
            setTimer(fromDate ? new Date().getTime() - new Date(fromDate).getTime() : 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!fromDate) {
            return;
        }
        const fromDateTime = new Date(fromDate).getTime();
        const interval = setInterval(() => {
            let time: number = 0;
            if (countdown) {
                time = fromDateTime - new Date().getTime();
            } else {
                time = new Date().getTime() - fromDateTime;
            }

            setTimer(Math.max(time, 0));

        }, 100);

        return () => clearInterval(interval);
    }, [fromDate, countdown]);

    return _getReturnValues(timer);
};

const _getReturnValues = (timer: number) => {
    const days = _fixNumber(Math.floor(timer / (1000 * 60 * 60 * 24)));
    const hours = _fixNumber(Math.floor(
        (timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    ));
    const minutes = _fixNumber(Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = _fixNumber(Math.floor((timer % (1000 * 60)) / 1000));

    const time: number = timer;

    return [days, hours, minutes, seconds, time];
};

const _fixNumber = (number: number): string => {
    if (Math.abs(number) < 10) {
        return `0${number}`;
    }
    return `${number}`
}



