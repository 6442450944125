import {AppStateActionType, useAppStateContext} from '../../appSateProvider';
import {OrganizationBody, OrganizationUpdateBody} from '../organizations/organizations.model';
import {cloneDeep} from 'lodash';
import {
    createOrganization,
    getOrganization,
    getOrganizations, getPublicOrganization,
    updateOrganization
} from '../organizations/organizations';
import {me} from '../auth/auth';
import {useParams} from "react-router-dom";

export const useAppStateHook = () => {
    const [appState, setAppState] = useAppStateContext();
    const {organizationId} = useParams();

    const _createOrganization = async (body: OrganizationBody) => {
        const res = await createOrganization(body)

        if (!res.isFailure()) {
            await _getOrganizations();
        }

        return res;
    }
    const _updateOrganization = async (id: string, body: OrganizationUpdateBody) => {
        const res = await updateOrganization(id, body)

        if (!res.isFailure()) {
            await _getOrganizations();
        }

        return res;
    }

    const _getOrganizations = async () => {
        const res = await getOrganizations()
        if (res.data) {
            setAppState({
                type: AppStateActionType.setOrganizations,
                state: {
                    ...cloneDeep(appState),
                    organizations: res.data,
                }
            })
        }
        return res;
    }
    const _getOrganization = async () => {
        if (appState.user) {
            return getOrganization(organizationId!)
        } else {
            return getPublicOrganization(organizationId!);
        }
    }

    const _me = async () => {

        const res = await me();
        if (res.data) {
            setAppState({
                type: AppStateActionType.setUser,
                state: {
                    ...cloneDeep(appState),
                    user: res.data,
                }
            })
        }
        return res;
    }

    return {
        appState,
        setAppState,
        createOrganization: _createOrganization,
        getOrganizations: _getOrganizations,
        updateOrganization: _updateOrganization,
        getOrganization: _getOrganization,
        getMe: _me
    }
}
