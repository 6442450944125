import React from 'react';
import ValidationMessage from '../ValidationMessage';
import { passwordStrongValidator } from '../../logic/core/password-strength';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import { FormControl } from '../../logic/core/form.hook';
import { Wrapper } from './PasswordStrengthValidator.styles';

const PasswordStrengthValidator = ({control, formTouched}: {control: FormControl, formTouched: boolean}) => {
    const {t} = useBaseScreenHook();
    return <Wrapper><ValidationMessage show={(!!passwordStrongValidator(control.value))}>

        <div className={`password-validations ${formTouched ? 'error' : ''}`}>
            <div
                className={`password-validation ${passwordStrongValidator(control.value)?.minLength ? 'not-valid' : ''}`}>
                <div className="dot"></div>
                {t('register.passwordValidation.minLength')}
            </div>
            <div
                className={`password-validation ${passwordStrongValidator(control.value)?.capitalLetters ? 'not-valid' : ''}`}>
                <div className="dot"></div>
                {t('register.passwordValidation.capitalLetters')}
            </div>
            <div
                className={`password-validation ${passwordStrongValidator(control.value)?.specialChars ? 'not-valid' : ''}`}>

                <div className="dot"></div>
                {t('register.passwordValidation.specialChars')}
            </div>
        </div>

    </ValidationMessage>
    </Wrapper>
}

export default PasswordStrengthValidator;
