import React, {useEffect, useState} from "react";
import {Wrapper} from "./Participants.styles";
import iconAdd from "../../../../assets/icon-plus-white.svg";
import {
    EventInvitationStatus,
    EventParticipant,
    EventParticipantFilters,
    EventParticipantStatus,
    Representative
} from "../../../../logic/events/events.model";
import {Table} from "../../../../components/Table/table";
import Input from "../../../../components/Input";
import {BodyMember, BodyMemberType} from "../../../../logic/bodies/bodies.model";
import iconCompany from "../../../../assets/icon-company.svg";
import iconPerson from "../../../../assets/icon-person.svg";
import ContextMenu from "../../../../components/ContextMenu";
import iconMenu from "../../../../assets/icon-menu.svg";
import ContextMenuItem from "../../../../components/ContextMenu/ContextMenuItem";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";
import EventMembersModal from "../EventMembersModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import {StatusModalConfirmationWrapper} from "../../../OrganizationScreen/OrganizationUsers/OrganizationUsers.styles";
import OrganizationBodyPreviewMemberModal
    from "../../../OrganizationScreen/OrganizationBodiesScreen/OrganizationBodyDetails/OrganizationBodiesDetailsMembers/OrganizationBodyPreviewMemberModal";
import {
    blockParticipant,
    deleteParticipant,
    getEventBodyMember,
    makeChairman,
    makeOutcomeMaker,
    removeChairman,
    removeOutcomeMaker,
    sendParticipantInvitation,
    unBlockParticipant,
    useEvent
} from "../../../../logic/events/events";
import {useParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useEventParticipantsStateHook} from "../participantsHooh";
import SendAllInvitationModal from "./SendAllInvitationModal";
import OrganizationBodyAddMemberModal
    from "../../../OrganizationScreen/OrganizationBodiesScreen/OrganizationBodyDetails/OrganizationBodiesDetailsMembers/OrganizationBodyAddMemberModal";
import SendInvitationSummaryModal from "./SendInvitationSummaryModal";

let inputDebouncer: ReturnType<typeof setTimeout> | null = null;
let abortController: AbortController | null = null;
const Participants = () => {
    const {participantsState, getParticipants, clearParticipants} = useEventParticipantsStateHook();
    const {organizationId, eventId} = useParams();
    const {t, renderError, setError, i18n} = useBaseScreenHook();
    const [filters, setFilters] = useState<EventParticipantFilters>({});
    const [editMembersModal, setEditMemberModal] = useState<boolean>(false);
    const [openAllInvitationModal, setOpenAllInvitationModal] = useState<boolean>(false);
    const [openInvitationSummaryModal, setOpenInvitationSummaryModal] = useState<EventInvitationStatus[] | null>(null);
    const [participantToDelete, setParticipantToDelete] = useState<EventParticipant | null>(null);
    const [participantToBlock, setParticipantToBlock] = useState<EventParticipant | null>(null);
    const [participantToUnBlock, setParticipantToUnBlock] = useState<EventParticipant | null>(null);
    const [participantToPreview, setParticipantToPreview] = useState<EventParticipant | null>(null);
    const [participantToEdit, setParticipantToEdit] = useState<BodyMember | null>(null);
    const [participantToMakeChairman, setParticipantToMakeChairman] = useState<EventParticipant | null>(null);
    const [participantToRemoveChairman, setParticipantToRemoveChairman] = useState<EventParticipant | null>(null);
    const [participantToRemoveOutcomeMaker, setParticipantToRemoveOutcomeMaker] = useState<EventParticipant | null>(null);
    const [participantToMakeOutcomeMaker, setParticipantToMakeOutcomeMaker] = useState<EventParticipant | null>(null);
    const [sendingInvitations, setSendingInvitations] = useState<Set<string>>(new Set([]));
    const {event, organization} = useEvent();
    const cleatInputDebouncer = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
        if (abortController) {
            abortController.abort();
            abortController = null;
        }
    }

    useEffect(() => {
        _getParticipants();
        return () => {
            cleatInputDebouncer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    const _getParticipants = async (currentFilters?: EventParticipantFilters) => {
        const res = await getParticipants(currentFilters ?? filters);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
    }
    const _closeEditMemberModal = (refresh: boolean) => {
        if (refresh) {
            _getParticipants();
        }
        setEditMemberModal(false);
    }

    const _deleteParticipant = async () => {
        const res = await deleteParticipant(organizationId!, eventId!, participantToDelete!.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
        setParticipantToDelete(null);
        _getParticipants();
    }
    const _blockParticipant = async () => {
        const res = await blockParticipant(organizationId!, eventId!, participantToBlock!.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
        setParticipantToBlock(null);
        _getParticipants();
    }
    const _unBlockParticipant = async () => {
        const res = await unBlockParticipant(organizationId!, eventId!, participantToUnBlock!.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
        setParticipantToUnBlock(null);
        _getParticipants();
    }

    const _setFilters = (filters: EventParticipantFilters) => {
        setFilters(filters);

        clearParticipants();
        cleatInputDebouncer();
        inputDebouncer = setTimeout(() => {
            _getParticipants(filters);
        }, 300);
    }
    const _setStatusFilter = (status?: EventParticipantStatus) => {
        _setFilters({
            ...filters,
            status,
        })
    }
    const _sendInvitation = async (participant: EventParticipant) => {
        sendingInvitations.add(participant.id)
        setSendingInvitations(new Set(Array.from(sendingInvitations)));
        const res = await sendParticipantInvitation(organizationId!, eventId!, participant.id);
        sendingInvitations.delete(participant.id)
        setSendingInvitations(new Set(Array.from(sendingInvitations)));

        if (res.isFailure()) {
            setError(res.failure!);
            return
        }

        setOpenInvitationSummaryModal([res.data!]);
        _getParticipants();
    }
    const percentFormat = new Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format;
    const _sendAllInvitations = async () => {
        setOpenAllInvitationModal(true);
    }

    const _setChairman = async () => {
        const res = await makeChairman(organizationId!, eventId!, participantToMakeChairman!.id);
        setParticipantToMakeChairman(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _removeChairman = async () => {
        const res = await removeChairman(organizationId!, eventId!);
        setParticipantToRemoveChairman(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _setOutcomeMaker = async () => {
        const res = await makeOutcomeMaker(organizationId!, eventId!, participantToMakeOutcomeMaker!.id);
        setParticipantToMakeOutcomeMaker(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _removeOutcomeMaker = async () => {
        const res = await removeOutcomeMaker(organizationId!, eventId!);
        setParticipantToRemoveOutcomeMaker(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _getBodyMemberToEdit = async (p: EventParticipant) => {
        const res = await getEventBodyMember(organizationId!, eventId!, p.bodyMemberId);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setParticipantToEdit(res.data!);
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    const canModify: boolean = participantsState.participants?.canModify ?? false;

    return <Wrapper>

        <div className="sub-title">
            {participantsState.participants?.signedShareBodyPercentage !== null
                ? <div className="data-box">
                    <div className="value">
                        {
                            percentFormat(participantsState.participants!.signedShareBodyPercentage) + '%'
                        }
                    </div>

                    <div className="label">
                        {t('eventParticipants.sumShare')}
                    </div>
                </div>
                : null}

            <div className="data-box">
                <div className="value">
                    {participantsState.participants?.signedVotes} / {participantsState.participants?.allVotes}
                </div>

                <div className="label">
                    {t('eventParticipants.votesAll')}
                </div>
            </div>
            {
                canModify
                    ? <button className={'button icon'} onClick={() => setEditMemberModal(true)}>
                        <img src={iconAdd} alt="icon"/>
                        {t('eventParticipants.membersEditBtn')}
                    </button>
                    : null
            }

        </div>

        <p className="description">
            {t('eventParticipants.listDescription')}

        </p>

        <div className="filters">
            <div className={`filter all ${!filters.status ? 'active' : ''}`} onClick={() => _setStatusFilter()}>
                {t('eventParticipants.filters.all')} {participantsState.participants?.stats?.all}
            </div>
            <div
                className={`filter not-sent ${filters.status === EventParticipantStatus.NotSentInvitation ? 'active' : ''}`}
                onClick={() => _setStatusFilter(EventParticipantStatus.NotSentInvitation)}>
                {t('eventParticipants.filters.NotSentInvitation')} {participantsState.participants?.stats?.notSentInvitation}
            </div>
            <div className={`filter sent ${filters.status === EventParticipantStatus.SentInvitation ? 'active' : ''}`}
                 onClick={() => _setStatusFilter(EventParticipantStatus.SentInvitation)}>
                {t('eventParticipants.filters.SentInvitation')} {participantsState.participants?.stats?.sentInvitation}
            </div>
            <div className={`filter signed ${filters.status === EventParticipantStatus.Signed ? 'active' : ''}`}
                 onClick={() => _setStatusFilter(EventParticipantStatus.Signed)}>
                {t('eventParticipants.filters.Signed')} {participantsState.participants?.stats?.signed}
            </div>
            <div className={`filter blocked ${filters.status === EventParticipantStatus.Blocked ? 'active' : ''}`}
                 onClick={() => _setStatusFilter(EventParticipantStatus.Blocked)}>
                {t('eventParticipants.filters.Blocked')} {participantsState.participants?.stats?.blocked}
            </div>
        </div>
        <Table>
            <thead>
            <tr>
                <th className='type'></th>
                <th>
                    {t('eventParticipants.bodyMember')}
                    <div className="filter-input">
                        <Input>
                            <input type="text" value={filters.bodyMemberNameToSearch ?? ''}
                                   onChange={(e) => _setFilters({
                                       ...filters,
                                       bodyMemberNameToSearch: e.target.value
                                   })}/>
                        </Input>
                    </div>
                </th>
                <th>

                    {t('eventParticipants.share')}
                </th>
                <th>
                    {t('eventParticipants.votes')}
                </th>
                <th>
                    {t('eventParticipants.invitationEmail')}
                    <div className="filter-input">
                        <Input>
                            <input type="text" value={filters.invitationEmailToSearch ?? ''}
                                   onChange={(e) => _setFilters({
                                       ...filters,
                                       invitationEmailToSearch: e.target.value
                                   })}/>
                        </Input>
                    </div>
                </th>
                <th>
                    {t('eventParticipants.participant')}

                    <div className="filter-input">
                        <Input>
                            <input type="text" value={filters.nameToSearch ?? ''} onChange={(e) => _setFilters({
                                ...filters,
                                nameToSearch: e.target.value
                            })}/>
                        </Input>
                    </div>
                </th>
                <th>
                    {t('eventParticipants.participantState')}
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                !participantsState.participants?.participants
                    ? <tr>
                        <td colSpan={8}>
                            <Loader/>
                        </td>
                    </tr>
                    : !participantsState.participants?.participants.length
                        ? <tr>
                            <td colSpan={8}>
                                Brak wyników
                            </td>
                        </tr>
                        : null
            }
            {
                participantsState.participants?.participants?.map((p) => {
                    return <tr key={p.id}>
                        <td>
                            {
                                p.bodyMemberType === BodyMemberType.organizationMember
                                    ? <img src={iconCompany} alt=""/>
                                    : <img src={iconPerson} alt=""/>
                            }
                        </td>
                        <td>
                            {p.bodyMemberName}
                        </td>
                        <td>
                            {p.sharePercentage === null ? '-' : `${percentFormat(p.sharePercentage)}%`}
                        </td>
                        <td>
                            {p.voteCount}
                        </td>
                        <td>
                            {p.invitationEmail}
                        </td>
                        <td>
                            <div
                                className={`name ${p.bodyMemberType === BodyMemberType.personMember && p.representative === Representative.Personally && p.bodyMemberName !== p.name ? 'invalid' : ''} ${p.representative === Representative.Plenipotentiary ? 'plenipotentiary' : ''}`}>
                                {p.name ?? '---'}

                                <div className="invalid-tooltip">
                                    Inne dane logowania. Sprawdź dane zalogowanej osoby.
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="statuses">
                                <div className={`status ${p.status}`}>
                                    {t(`eventParticipants.participantStatus.${p.status}`)}
                                </div>
                                {
                                    event?.content?.chairmanReference?.participantId === p.id
                                        ? <div className="status">
                                            {t(`eventParticipants.chairman`)}
                                        </div>
                                        : null
                                }

                                {
                                    event?.content?.voteOutcomeMakerReference?.participantId === p.id
                                        ? <div className="status">
                                            {t(`eventParticipants.outcomeMaker`)}
                                        </div>
                                        : null
                                }

                            </div>
                        </td>
                        <td>
                            <div className="actions">
                                {
                                    p.status === EventParticipantStatus.Blocked || !canModify
                                        ? null
                                        : <button className={'button text'} onClick={() => _sendInvitation(p)}
                                                  disabled={sendingInvitations.size > 0}>
                                            {
                                                sendingInvitations.has(p.id)
                                                    ? <Loader className={'small'}/>
                                                    : p.status === EventParticipantStatus.SentInvitation || p.status === EventParticipantStatus.Signed
                                                    ? t('eventParticipants.resend')
                                                    : t('eventParticipants.send')
                                            }
                                        </button>
                                }

                                <div className="menu">
                                    <ContextMenu icon={iconMenu}>
                                        {
                                            canModify
                                                ? <ContextMenuItem onClick={() => _getBodyMemberToEdit(p)}>
                                                    {t('eventParticipants.edit')}
                                                </ContextMenuItem>
                                                : null
                                        }

                                        <ContextMenuItem onClick={() => setParticipantToPreview(p)}>
                                            {t('eventParticipants.preview')}

                                        </ContextMenuItem>
                                        {
                                            canModify && event?.permissions.canAssignChairman ?
                                                event?.content?.chairmanReference?.participantId === p.id
                                                    ?
                                                    <ContextMenuItem onClick={() => setParticipantToRemoveChairman(p)}>
                                                        {t('eventParticipants.removeChairman')}
                                                    </ContextMenuItem>
                                                    : <ContextMenuItem onClick={() => setParticipantToMakeChairman(p)}>
                                                        {t('eventParticipants.setChairman')}
                                                    </ContextMenuItem>
                                                : null
                                        }
                                        {
                                            canModify && event?.permissions.canAssignDecidingOnVoteOutcome ?
                                                event?.content?.canAssignVoteOutcomeMaker
                                                    ? event?.content?.voteOutcomeMakerReference?.participantId === p.id
                                                        ?
                                                        <ContextMenuItem
                                                            onClick={() => setParticipantToRemoveOutcomeMaker(p)}>
                                                            {t('eventParticipants.removeOutcomeMaker')}
                                                        </ContextMenuItem>
                                                        : <ContextMenuItem
                                                            onClick={() => setParticipantToMakeOutcomeMaker(p)}>
                                                            {t('eventParticipants.setOutcomeMaker')}
                                                        </ContextMenuItem>
                                                    : null
                                                : null
                                        }
                                        {
                                            canModify && p.status !== EventParticipantStatus.Blocked
                                                ? <ContextMenuItem onClick={() => setParticipantToBlock(p)}>
                                                    {t('eventParticipants.block')}

                                                </ContextMenuItem>
                                                : null
                                        }
                                        {
                                            canModify && p.status === EventParticipantStatus.Blocked
                                                ? <ContextMenuItem onClick={() => setParticipantToUnBlock(p)}>
                                                    {t('eventParticipants.unblock')}

                                                </ContextMenuItem>
                                                : null
                                        }

                                        {
                                            canModify
                                                ? <ContextMenuItem onClick={() => setParticipantToDelete(p)}>
                                            <span className={'remove-btn'}>
                                                {t('eventParticipants.removeParticipant')}
                                            </span>
                                                </ContextMenuItem>
                                                : null
                                        }


                                    </ContextMenu>
                                </div>
                            </div>
                        </td>
                    </tr>
                })
            }

            </tbody>
        </Table>

        {
            canModify
                ? <div className="send-all-invitations">
                    <button className="button" onClick={_sendAllInvitations}>
                        {t('eventParticipants.sendAllInvitations')}
                    </button>
                </div>
                : null
        }


        <Modal width={700} show={editMembersModal} onClose={() => setEditMemberModal(false)}>
            <EventMembersModal onClose={_closeEditMemberModal}/>
        </Modal>

        <Modal width={700} show={openAllInvitationModal} onClose={() => setOpenAllInvitationModal(false)}>
            <SendAllInvitationModal onClose={(result) => {
                setOpenAllInvitationModal(false);
                if (result.length) {
                    setOpenInvitationSummaryModal(result);
                }
                _getParticipants();
            }}
                                    event={event!}
                                    organization={organization!}
            />
        </Modal>

        <Modal width={700} show={!!openInvitationSummaryModal} onClose={() => setOpenInvitationSummaryModal(null)}>
            <SendInvitationSummaryModal onClose={() => {
                setOpenInvitationSummaryModal(null)
            }}
                                        invitationResults={openInvitationSummaryModal!}
            />
        </Modal>

        <Modal width={600} show={!!participantToDelete} onClose={() => setParticipantToDelete(null)}>
            <ConfirmationModal
                reject={() => setParticipantToDelete(null)}
                accept={() => _deleteParticipant()}
                body={t('eventParticipants.deleteParticipantBody')}
                title={event?.content?.chairmanReference?.participantId === participantToDelete?.id
                    ? t('eventParticipants.deleteChairmanTitle', {name: participantToDelete?.bodyMemberName})
                    : t('eventParticipants.deleteParticipantTitle', {name: participantToDelete?.bodyMemberName})}
            />
        </Modal>
        <Modal width={600} show={!!participantToBlock} onClose={() => setParticipantToBlock(null)}>
            <ConfirmationModal
                reject={() => setParticipantToBlock(null)}
                accept={() => _blockParticipant()}
                acceptLabel={t('eventParticipants.blockParticipantAcceptLabel')}
                body={t('eventParticipants.blockParticipantBody', {name: participantToBlock?.bodyMemberName})}
                title={t('eventParticipants.blockParticipantTitle', {name: participantToBlock?.bodyMemberName})}
            />
        </Modal>
        <Modal width={600} show={!!participantToUnBlock} onClose={() => setParticipantToUnBlock(null)}>
            <StatusModalConfirmationWrapper className={'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setParticipantToUnBlock(null)}
                    accept={() => _unBlockParticipant()}
                    acceptLabel={t('eventParticipants.unBlockParticipantAcceptLabel')}
                    body={t('eventParticipants.unBlockParticipantBody', {name: participantToUnBlock?.bodyMemberName})}
                    title={t('eventParticipants.unBlockParticipantTitle', {name: participantToUnBlock?.bodyMemberName})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!participantToMakeChairman} onClose={() => setParticipantToMakeChairman(null)}>
            <StatusModalConfirmationWrapper className={'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setParticipantToMakeChairman(null)}
                    accept={() => _setChairman()}
                    acceptLabel={t('eventParticipants.makeChairmanAcceptLabel')}
                    body={t('eventParticipants.makeChairmanBody', {name: participantToMakeChairman?.bodyMemberName})}
                    title={t('eventParticipants.makeChairmanTitle', {name: participantToMakeChairman?.bodyMemberName})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!participantToRemoveChairman} onClose={() => setParticipantToRemoveChairman(null)}>
            <StatusModalConfirmationWrapper>
                <ConfirmationModal
                    reject={() => setParticipantToRemoveChairman(null)}
                    accept={() => _removeChairman()}
                    acceptLabel={t('eventParticipants.removeChairmanAcceptLabel')}
                    body={t('eventParticipants.removeChairmanBody', {name: participantToMakeChairman?.bodyMemberName})}
                    title={t('eventParticipants.removeChairmanTitle', {name: participantToMakeChairman?.bodyMemberName})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!participantToMakeOutcomeMaker}
               onClose={() => setParticipantToMakeOutcomeMaker(null)}>
            <StatusModalConfirmationWrapper className={'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setParticipantToMakeOutcomeMaker(null)}
                    accept={() => _setOutcomeMaker()}
                    acceptLabel={t('eventParticipants.makeOutcomeMakerAcceptLabel')}
                    title={t('eventParticipants.makeOutcomeMakerTitle', {name: participantToMakeOutcomeMaker?.bodyMemberName})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!participantToRemoveOutcomeMaker}
               onClose={() => setParticipantToRemoveOutcomeMaker(null)}>
            <StatusModalConfirmationWrapper>
                <ConfirmationModal
                    reject={() => setParticipantToRemoveOutcomeMaker(null)}
                    accept={() => _removeOutcomeMaker()}
                    acceptLabel={t('eventParticipants.removeOutcomeMakerAcceptLabel')}
                    title={t('eventParticipants.removeOutcomeMakerTitle', {name: participantToRemoveOutcomeMaker?.bodyMemberName})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal reset={false} onClose={() => setParticipantToPreview(null)} show={!!participantToPreview}
               backDropClose={false}>
            <OrganizationBodyPreviewMemberModal
                participant={participantToPreview}
                onClose={() => setParticipantToPreview(null)}
            />
        </Modal>

        <Modal width={700} onClose={() => setParticipantToEdit(null)} show={!!participantToEdit} backDropClose={false}>
            <OrganizationBodyAddMemberModal memberToEdit={participantToEdit}
                                            contextBodyId={event?.content?.basic.body.id}
                                            contextEventId={eventId!}
                                            onClose={() => setParticipantToEdit(null)}/>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default Participants;
