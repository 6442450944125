import React, {useEffect, useState} from "react";
import {Wrapper} from "./OrganizationDocuments.styles";
import {Table} from "../../../../components/Table/table";
import Select from "../../../../components/Select";
import {
    deleteOrganizationDocument,
    organizationAvailableBodies,
    organizationDocuments, organizationDocumentUrl
} from "../../../../logic/documents/documents";
import {ContextDocumentType, DocumentFilers, IdName, Document} from "../../../../logic/documents/documents.model";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import addIcon from '../../../../assets/icon-plus-white.svg'
import {Canceled} from "../../../../logic/core/failures";
import ContextMenu from "../../../../components/ContextMenu";
import ContextMenuItem from "../../../../components/ContextMenu/ContextMenuItem";
import Modal from "../../../../components/Modal";
import AddNewOrganizationDocument from "../AddNewOrganizationDocument";
import iconMenu from "../../../../assets/icon-menu.svg";
import Loader from "../../../../components/Loader";
import {downloadByUrl, saveFile} from "../../../../logic/files/files.service";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import FilesPreview from "../../../../components/FilesPreview";
import Annotations from "../../../../components/Annotations";

let inputDebouncer: ReturnType<typeof setTimeout> | null = null;
let abortController: AbortController | null = null;


const OrganizationOrganizationDocuments = () => {
    const [availableFilters, setAvailableFilters] = useState<{ bodies: IdName[] } | null>(null);
    const [filters, setFilters] = useState<DocumentFilers>({
        type: ContextDocumentType.Organization
    });
    const {renderError, setError, i18n, t} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [documents, setDocuments] = useState<Document[] | null>(null);
    const [openNewDocumentModal, setOpenNewDocumentModal] = useState<boolean>(false);
    const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null);
    const [preview, setPreview] = useState<{ url: string, urls?: string[] } | null>(null);
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;

    const [downloading, setDownloading] = useState<Set<string>>(new Set([]));

    useEffect(() => {
        _getDocuments(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getAvailableFilters = () => {
        Promise.all([
            organizationAvailableBodies(organizationId!, ContextDocumentType.Organization),
        ]).then((res) => {
            const [
                bodiesRes,
            ] = res;
            const failure = res.find(r => r.isFailure());
            if (failure) {
                setError(failure.failure!);
                return;
            }

            setAvailableFilters({
                bodies: bodiesRes.data!,
            })
        })

    }

    const cleatInputDebouncer = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
        if (abortController) {
            abortController.abort();
            abortController = null;
        }
    }

    const _setFilters = (filters: DocumentFilers) => {
        setFilters(filters);
        setDocuments(null);
        cleatInputDebouncer();
        inputDebouncer = setTimeout(() => {
            _getDocuments(filters);
        }, 300);
    }

    const _getDocuments = (filters: DocumentFilers) => {
        abortController = new AbortController();
        _getAvailableFilters();

        organizationDocuments(organizationId!, filters, abortController).then((res) => {
            if (res.isFailure()) {
                if (res.failure?.is(Canceled.getCode)) {
                    return;
                }
                setError(res.failure!);
                return
            }
            setDocuments(res.data!);
        });
    }
    const _download = async (d: Document) => {
        downloading.add(d.id)
        setDownloading(new Set(Array.from(downloading)));
        const res = await downloadByUrl(organizationDocumentUrl(d));
        downloading.delete(d.id)
        setDownloading(new Set(Array.from(downloading)));
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        const saveRes = await saveFile(res.data!.data!, res.data?.name!, res.data?.type!);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }


    }

    const _delete = async (d: Document) => {
        const res = await deleteOrganizationDocument(d.organizationId, d.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setDocumentToDelete(null);
        _getDocuments(filters);
    }

    return <Wrapper>

        <p className="description">
            {t('documents.organizationDocumentsDescription')}
        </p>

        <Table>
            <thead>
            <tr>
                <th>
                    {t('documents.selectBody')}

                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.bodyId ?? ''}
                                onChange={(id) => {
                                    _setFilters({
                                        ...filters,
                                        bodyId: id
                                    })
                                }}
                        >
                            <option/>
                            {availableFilters?.bodies?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
                <th className={'new-doc-cell'}>
                    <div className="new-doc-button">
                        <button className="button icon" onClick={() => setOpenNewDocumentModal(true)}>
                            <img src={addIcon} alt="icon"/>
                            {t('documents.newDocTitle')}

                        </button>
                    </div>
                </th>
            </tr>
            </thead>
        </Table>
        <Table className={'multibody'}>
            <thead>
            <tr>
                <th> {t('documents.date')}</th>
                <th> {t('documents.document')}</th>
                <th> {t('documents.body')}</th>
                <th></th>
            </tr>
            </thead>
            {
                documents?.map(d => {
                    return <tbody key={d.id}><tr>
                        <td>
                            {dateFormatter(new Date(d.timestamp))}
                        </td>
                        <td>
                            {d.name}
                        </td>
                        <td>
                            {
                                d.bodyIds.map((id) => {
                                    return <div
                                        key={d.id + '-' + id}>{availableFilters?.bodies.find(b => b.id === id)?.name}</div>;
                                })
                            }
                        </td>
                        <td>
                            <div className="actions">

                                <button className="button text" onClick={() => setPreview({
                                    url: organizationDocumentUrl(d),
                                    urls: documents?.map(organizationDocumentUrl)
                                })}>
                                    {t('documents.preview')}

                                </button>
                                <button className="button text" disabled={downloading.has(d.id)}
                                        onClick={() => _download(d)}>
                                    {downloading.has(d.id) ? <Loader/> : t('documents.download')}
                                </button>

                                <ContextMenu icon={iconMenu}>
                                    <ContextMenuItem onClick={() => setDocumentToDelete(d)}>
                                        <span className={'delete'}>{t('documents.delete')}</span>
                                    </ContextMenuItem>
                                    <></>
                                </ContextMenu>
                            </div>

                        </td>
                    </tr>

                    <Annotations
                        document={d}
                        onDelete={setDocumentToDelete}
                        onPreview={(doc) => {
                            setPreview({
                                url: organizationDocumentUrl(doc),
                                urls: d.annotations?.map(organizationDocumentUrl,)
                            })
                        }}
                        onDownload={_download}
                        downloading={downloading}
                    />
                    </tbody>

                })
            }


        </Table>
        {
            documents ? documents.length ? null : t('documents.emptyList') : <Loader/>
        }
        <Modal width={750} show={openNewDocumentModal} onClose={() => setOpenNewDocumentModal(false)}
               backDropClose={false}>
            <AddNewOrganizationDocument onClose={() => {
                _getDocuments(filters);
                setOpenNewDocumentModal(false);
            }
            }/>
        </Modal>

        <Modal width={600} show={!!documentToDelete} onClose={() => setDocumentToDelete(null)}>
            <ConfirmationModal
                reject={() => setDocumentToDelete(null)}
                accept={() => _delete(documentToDelete!)}
                title={`${t('documents.confirmDocumentDeletionTitle')}`}
            />
        </Modal>
        <Modal width={800} show={!!preview} reset={true} onClose={() => {
            setPreview(null);
        }} backDropClose={false}>
            <FilesPreview url={preview?.url} urls={preview?.urls} onClose={() => setPreview(null)}/>
        </Modal>
        {renderError()}
    </Wrapper>
}

export default OrganizationOrganizationDocuments;
