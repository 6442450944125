import React, {useEffect, useState} from "react";
import {Wrapper} from "./OrganizationParticipantDocumentsScreen.styled";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {AppRoutes} from "../../../logic/core/routes";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import {useAppStateContext} from "../../../appSateProvider";
import {Organization} from "../../../logic/organizations/organizations.model";
import {getOrganization} from "../../../logic/organizations/organizations";
import Loader from "../../../components/Loader";

const OrganizationParticipantDocumentsScreen = () => {
    const {t} = useBaseScreenHook();
    const [, , isAdmin, isSuperAdmin] = useAppStateContext();
    const {organizationId} = useParams();
    const {navigate, renderError, setError} = useBaseScreenHook();
    const [organization, setOrganization] = useState<Organization | null>(null)
    useEffect(() => {
        getOrganization(organizationId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setOrganization(res.data!);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const _goToEmailUpdate = () => {
        navigate(`/${AppRoutes.organization}/${organizationId}/${AppRoutes.host}/${AppRoutes.data}/${AppRoutes.invitationEmails}`)
    }
    if (!organization) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }
    return <Wrapper>
        <div className="description">
            Poniżej znajdziesz dokumenty z wydarzeń (posiedzeń i głosowań obiegowych) oraz dokumenty udostępnione przez organizację, w którj organach zasiada {organization.name}.
        </div>

        <div className="spacer">
            {
                isAdmin(organizationId!) || isSuperAdmin(organizationId!) ?
                    <button className="button text email-update" onClick={_goToEmailUpdate}>
                        Zaktualizuj adres e-email, na który organizatorzy mają wysyłać zaproszenia
                        dla {organization.name}.
                    </button>
                    : null
            }
        </div>
        <div className="document-tabs">
            <NavLink className={`tab`} to={AppRoutes.events}>
                {t('documents.events')}
            </NavLink>
            <NavLink className={`tab`} to={AppRoutes.organization}>
                {t('documents.organization')}
            </NavLink>
        </div>

        <Outlet/>

    </Wrapper>
}

export default OrganizationParticipantDocumentsScreen;