import React, {useEffect, useState} from 'react';
import {Wrapper} from './OrganizationDataDetails.styles';
import {useBaseScreenHook} from '../../../../logic/core/base-screen.hook';
import {Organization} from '../../../../logic/organizations/organizations.model';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import OrganizationDataDetailsEditModal from './OrganizationDataDetailsEditModal';
import {useAppStateHook} from "../../../../logic/core/appState.hook";

const OrganizationDataDetails = () => {
    const {t, renderError, setError} = useBaseScreenHook();
    const [data, setData] = useState<Organization | null>(null);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
const {getOrganization} = useAppStateHook();
    useEffect(() => {

        _getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _modalClosed = (refresh: boolean) => {
        setEditModalOpen(false);
        if (refresh) {
            _getData();
        }
    }

    const _getData = () => {
        getOrganization().then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setData(res.data!);
        })
    }

    return <Wrapper>
        {
            data
                ? <>
                    <div className="title">
                        {t('organizationDataDetails.title')}
                    </div>

                    <p className="description">
                        {t('organizationDataDetails.description')}
                    </p>


                    <div className="data">
                        <div className="label">
                            {t('organizationDataDetails.name')}
                        </div>
                        <div className="value">
                            {data.name}
                        </div>
                        <div className="label">
                            {t('organizationDataDetails.legalForm')}
                        </div>
                        <div className="value">
                            {data.legalForm.label}
                        </div>
                        <div className="label">
                            {t('organizationDataDetails.nip')}
                        </div>
                        <div className="value">
                            {data.nip ?? '-'}
                        </div>
                        <div className="label">
                            {t('organizationDataDetails.country')}
                        </div>
                        <div className="value">
                            {data.address?.country ?? '-'}

                        </div>
                        <div className="label">
                            {t('organizationDataDetails.street')}
                        </div>
                        <div className="value">
                            {data.address?.street ?? '-'}

                        </div>
                        <div className="label">
                            {t('organizationDataDetails.postalCode')}
                        </div>
                        <div className="value">
                            {data.address?.postalCode ?? '-'}

                        </div>
                        <div className="label">
                            {t('organizationDataDetails.city')}
                        </div>
                        <div className="value">
                            {data.address?.city ?? '-'}

                        </div>
                        <div className="label">
                            {t('organizationDataDetails.phoneNumber')}
                        </div>
                        <div className="value">
                            {data.phoneNumber?? '-'}

                        </div>
                        <div className="label">
                            {t('organizationDataDetails.email')}
                        </div>
                        <div className="value">
                            {data.email?? '-'}

                        </div>
                        <div className="label">
                            {t('organizationDataDetails.www')}
                        </div>
                        <div className="value">
                            {data.webPage?? '-'}

                        </div>

                    </div>

                    <button className="button text" onClick={() => setEditModalOpen(true)}>
                        {t('organizationDataDetails.changeBtn')}
                    </button>
                </>
                : <Loader/>
        }
        {renderError()}

        <Modal onClose={() => setEditModalOpen(false)} show={editModalOpen} backDropClose={false}>
            <OrganizationDataDetailsEditModal data={data!} onClose={(refresh) => _modalClosed(refresh)}/>
        </Modal>
    </Wrapper>
}

export default OrganizationDataDetails;
