import styled from 'styled-components';

export const Wrapper = styled.div`
  .organization-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--fontMutedColor);
    margin-bottom: 10px;
    word-break: break-word;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .buttons {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
  }
  .next-step {
    margin-top: 30px;
    text-align: left;
    font-size: 1.2rem;
    color: var(--fontMutedColor);
  }
`
