import React from 'react';
import { Wrapper } from './OrganizationUserDetails.styles';
import Input from '../../../../components/Input';
import { addUser, updateUser } from '../../../../logic/organizations/organizations';
import { Violations } from '../../../../logic/core/failures';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import { useForm } from '../../../../logic/core/form.hook';
import FormField from '../../../../components/FormField';
import { useParams } from 'react-router-dom';
import { OrganizationUser, OrganizationUserBody, UserRole } from '../../../../logic/auth/auth.model';
import Select from '../../../../components/Select';


const OrganizationUserDetails = ({onClose, userToEdit}: {onClose: (refresh: boolean) => void, userToEdit: OrganizationUser | null}) => {
    const {organizationId} = useParams();
    const {t, loading, setLoading, renderError, setError} = useBaseScreenHook();
    const {formControl, renderValidationMessage, formValid, formValue, setValidations} = useForm<OrganizationUserBody>({
        email: {
            value: userToEdit?.email || ''
        },
        firstName: {
            value: userToEdit?.firstName || ''
        },
        lastName: {
            value: userToEdit?.lastName || ''
        },
        role: {
            value: userToEdit?.role || ''
        },
        
    })

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        let res;
        if (userToEdit) {
            res = await updateUser(organizationId!, userToEdit.id, formValue)
        } else {
            res = await addUser(organizationId!, formValue)
        }

        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose(true);
    }


    return <Wrapper>
        <div className="title">
            { userToEdit ? t('organizationUserDetails.editTitle') : t('organizationUserDetails.title')}
        </div>

        <FormField>
            <Input label={t('organizationUserDetails.email') + ' *'}>
                <input type="text"
                       disabled={!!userToEdit}
                       value={formControl('email').value}
                       onChange={(e) => formControl('email').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('email')}
        </FormField>

        <div className="row">

            <FormField>
                <Input label={t('organizationUserDetails.firstName') + ' *'}>
                    <input type="text"
                           disabled={userToEdit?.permissions.canBeUpdated === false}
                           value={formControl('firstName').value}
                           onChange={(e) => formControl('firstName').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('firstName')}
            </FormField>

            <FormField>
                <Input label={t('organizationUserDetails.lastName') + ' *'}>
                    <input type="text"
                           disabled={userToEdit?.permissions.canBeUpdated === false}
                           value={formControl('lastName').value}
                           onChange={(e) => formControl('lastName').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('lastName')}
            </FormField>

        </div>

        <FormField>
            <Select label={t('organizationUserDetails.permission') + ' *'}
                    disabled={userToEdit?.permissions.canRoleBeUpdated === false}
                    currentValue={formControl('role').value}
                    onChange={formControl('role').patchValue}
            >
                <option/>
                {
                    Object.values(UserRole).filter((role)=> role !== UserRole.SuperAdministrator).map((role) => {
                        return <option key={role} value={role}>{t(`roles.${role}`)}</option>
                    })
                }
            </Select>
            {renderValidationMessage('role')}
        </FormField>

        <FormField>
            <div className="hint">
                {t('organizationUserDetails.permissionDescription')}
            </div>
        </FormField>

        {
            userToEdit
                ? <div className={'buttons'}>
                    <button className={'button text muted'} onClick={() => onClose(false)} disabled={loading}>
                        {t('organizationUserDetails.cancelBtn')}
                    </button>

                    <button className={'button'} onClick={_submit} disabled={loading}>
                        {t('organizationUserDetails.saveBtn')}
                    </button>
                </div>
                : <button className={'button'} onClick={_submit} disabled={loading}>
                    {t('organizationUserDetails.addBtn')}
                </button>
        }


        {renderError()}
    </Wrapper>
}

export default OrganizationUserDetails;
