import { Address } from '../bodies/bodies.model';

export interface Organization {
    id: string;
    name: string;
    legalForm: LegalForm;
    nip: string | null;
    address: Address | null;
    phoneNumber: string | null;
    email: string | null;
    webPage: string | null;
}

export interface OrganizationBody {
    name: string | null;
    legalFormId: string | null;
    invitationEmail: string | null;
}

export interface OrganizationUpdateBody extends Omit<Organization, 'id' | 'legalForm'> {

}

export interface LegalForm {
    id: string;
    label: string;
    bodies: LegalFormBody[];
}

export interface LegalFormBody {
    id: string;
    label: string;
    selectableProceedingType: boolean;
    type: LegalFormBodyType | null;
}

export enum LegalFormBodyType {
    Capital = 'Capital',
    Personal = 'Personal',
}
export enum ProceedingType {
    ORDINARY = 'ORDINARY',
    EXTRAORDINARY = 'EXTRAORDINARY'
}
export enum OrganizationRole {
    Host = 'host',
    Participant = 'participant'
}

export interface InvitationEmail {
    email: string;
    organizationId: string;
    id: string;
    status: InvitationEmailStatus;
}

export enum InvitationEmailStatus {
    NotActive = 'NotActive',
    Active = 'Active',
    Pending = 'Pending',
}
