import {createContext, Dispatch, Reducer, useContext, useReducer} from 'react';
import {
    EventGuestResponse,
    EventModeratorsResponse,
    EventParticipantResponse
} from "../../../logic/events/events.model";


export interface EventParticipantsState {
    participants: EventParticipantResponse | null;
    moderators: EventModeratorsResponse | null;
    guests: EventGuestResponse | null;
}

export interface EventModerator {
    id: string;
    name: string;
    email: string;
}


const initialState: EventParticipantsState = {
    participants: null,
    moderators: null,
    guests: null,
}

export enum EventParticipantsStateStateActionType {
    setParticipants= 'setParticipants',
    setModerators= 'setModerators',
    setGuests= 'setGuests',
}

export interface EventParticipantsStateAction {
    type: EventParticipantsStateStateActionType;
    state: EventParticipantsState;
}

export const EventParticipantsStateContext = createContext<[EventParticipantsState, Dispatch<EventParticipantsStateAction>]>([initialState, () => null]);

export const eventParticipantsStateReducer = (state: EventParticipantsState, action: EventParticipantsStateAction): EventParticipantsState => {
    if (action.type === EventParticipantsStateStateActionType.setParticipants) {
        return {
            ...state,
            participants: action.state.participants,
        }
    } else if (action.type === EventParticipantsStateStateActionType.setModerators) {
        return {
            ...state,
            moderators: action.state.moderators,
        }
    } else if (action.type === EventParticipantsStateStateActionType.setGuests) {
        return {
            ...state,
            guests: action.state.guests,
        }
    }
    return {
        ...state,
    };
}


const EventParticipantsStateStateProvider = ({children}: { children: any }) => {

    const [state, dispatch] = useReducer<Reducer<EventParticipantsState, EventParticipantsStateAction>>(eventParticipantsStateReducer, initialState);

    return (
        <EventParticipantsStateContext.Provider
            value={[state, dispatch]}>{children}</EventParticipantsStateContext.Provider>
    )
}

export default EventParticipantsStateStateProvider;


export function useEventParticipantsStateContext() {
    const context = useContext(EventParticipantsStateContext)
    if (context === undefined) {
        throw new Error('useAppStateContext must be used within TestProvider')
    }
    return context
}

