import styled from 'styled-components';

export const Wrapper = styled.div`
  .register-title {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .social-login-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      --mainButtonHoverColor: rgba(66, 133, 244, .8);
      background-color: #4285F4;
      display: flex;
      align-items: center;
      padding: 0;
      border: 1px solid #4285F4;

      &:hover {
        background: var(--mainButtonHoverColor) radial-gradient(circle, transparent 1%, var(--mainButtonHoverColor) 1%) center/15000%;
      }

      &:active {
        background-color: var(--mainButtonHoverColor);
        background-size: 100%;
        transition: background 0s;
      }


      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 40px;
        background: #fff;
      }

      .text {
        flex: 1;
        text-align: center;
      }
    }
  }

  .or-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 20px auto;

    &::after {
      content: '';
      height: 1px;
      background: var(--fontColor);
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);

    }

    .text {
      font-size: 1.4rem;
      position: relative;
      background: #fff;
      z-index: 2;
      padding: 0 15px;
    }
  }

  .form {
    .personal-data {
      width: 100%;
      display: flex;
      gap: 10px;

      & > * {
        flex: 1;
      }
    }

    .submit-button {
      margin: 20px 0 0;
      width: 100%;
    }
  }
  
  .data-hint{
    font-size: 1.2rem;margin-bottom: 20px;
  }
  .expand {
    color: var(--fontMutedColor);
    cursor: pointer;
  }
  .expanded{
    margin-top: 16px;
  }
`
