import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: stretch;
    height: calc(100% - 90px);
    position: relative;
    menu {
      border-right: 1px solid var(--disabledColor);
      width: 300px;
      padding: 0;
      margin: 0;
      flex-shrink: 0;
      background: #fff;
      transition: all .3s ease-in-out;
      @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        transform: translate(-100%, 0);
        &::after {
          content: '';
          width: 20px;
          position: absolute;
          top: 0;
          left: 100%;
          bottom: 0;
          z-index: 1;
          background: transparent;
        }
        &:hover {
          transform: translate(0, 0);

        }
      }
      .label {
        text-transform: uppercase;
        font-size: 1.2rem;
        padding: 5px 20px 10px;
      }

      .nav-link {
        padding: 15px 17px;
        display: flex;
        flex-direction: column;
        color: var(--fontColor);
        gap: 3px;
        border-left: 3px solid transparent;
        cursor: pointer;
        transition: all .2s linear;
        text-decoration: none;
        
        &.active,
        &:hover {
          border-left: 3px solid var(--mainColor);
          background: var(--disabledColor);
        }
      }
      
    }
    main {
      flex: 1;
      overflow: auto;
      background: #fff;
    }
`
