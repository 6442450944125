import NotLoggedBaseScreen from '../../components/NotLoggedBaseScreen';
import { Wrapper } from './ForgetPasswordScreen.styles';
import FormField from '../../components/FormField';
import Input from '../../components/Input';
import { FormEvent, useState } from 'react';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import { useForm } from '../../logic/core/form.hook';
import { resetPasswordRequest } from '../../logic/auth/auth';
import { Violations } from '../../logic/core/failures';


const RegisterScreen = () => {

    const {t, loading, setLoading, renderError, setError} = useBaseScreenHook();
    const {formControl, formValid, setValidations, renderValidationMessage, formValue} = useForm<{email: string}>({
        email: {
            value: ''
        }
    });
    const [sent, setSent] = useState<boolean>(false);

    const submit = async (e: FormEvent) => {
        e.preventDefault();
        if (loading || !formValid()) {
            return;
        }
        setLoading(true);
        const res = await resetPasswordRequest(formValue.email);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setSent(true);

    }

    return <NotLoggedBaseScreen>
        <Wrapper>

            <h2 className="title">
                {
                    sent
                        ? t('forgetPassword.sent')
                        : t('forgetPassword.title')
                }
            </h2>
            <p>
                {
                    sent
                        ? t('forgetPassword.sentDescription', {mail: formValue.email})
                        : t('forgetPassword.description')
                }
            </p>
            {
                sent
                    ? null
                    : <form onSubmit={submit}>
                        <FormField>
                            <Input label={t('forgetPassword.emailLabel')}>
                                <input type="text" value={formControl('email').value} onChange={(e) => formControl('email').patchValue(e.target.value)}/>
                            </Input>
                            {renderValidationMessage('email')}
                        </FormField>

                        <button className={'button'} disabled={loading}>
                            {t('forgetPassword.submitButton')}
                        </button>
                    </form>
            }

        </Wrapper>

        {renderError()}
    </NotLoggedBaseScreen>
};

export default RegisterScreen;
