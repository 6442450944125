import React from 'react';
import { Wrapper } from './MainContainer.styles';
import { Outlet } from 'react-router-dom';

const MainContainer = ({children}: { children: any }) => {
    return <Wrapper className={'container'}>
            <menu>
                {children}
            </menu>
            <main>
                <Outlet/>
            </main>
    </Wrapper>
}

export default MainContainer;
