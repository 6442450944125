import styled from 'styled-components';

export const Wrapper = styled.div`
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .description{
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    button {
      height: 24px;
    }
    .menu {
      display: flex;
      justify-content: flex-end;

      .remove-btn {
        color: var(--errorColor)
      }
    }
  }
  .statuses {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .status {
    border-radius: 5px;
    min-width: 130px;
    white-space: nowrap;
    color: #fff;
    background: var(--mainColor);
    padding: 0 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    font-size: 1.2rem;
    font-weight: 500;
    background: var(--fontColor);
  }

`