import React from "react";
import {Wrapper} from "./EventGuestScreen.styles";
import TopBar from "../../components/TopBar";
import MainContainer from "../../components/MainContainer";
import {useLocation} from "react-router-dom";


const EventGuestScreen = () => {
    const location = useLocation();

    return <Wrapper>
        <TopBar backArrow={false} url={location.pathname}/>
        <MainContainer>
            <></>
        </MainContainer>
    </Wrapper>
}


export default EventGuestScreen;