import React, { useContext, useEffect, useState } from 'react';
import { Wrapper } from './OrganizationBodiesCreateModal.styles';
import FormField from '../../../../components/FormField';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import iconArrowDown from '../../../../assets/icon-arrow-down.svg'
import iconArrowUp from '../../../../assets/icon-arrow-up.svg'
import { useParams } from 'react-router-dom';
import {
    LegalFormBody,
    LegalFormBodyType,
    Organization,
} from '../../../../logic/organizations/organizations.model';
import { createBody, deleteBody, updateBody } from '../../../../logic/bodies/bodies';
import { Body, CreateBody } from '../../../../logic/bodies/bodies.model';
import Modal from '../../../../components/Modal';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import { useForm } from '../../../../logic/core/form.hook';
import { Violations } from '../../../../logic/core/failures';
import { ModalType } from '../../../../components/Toast';
import { AppRoutes } from '../../../../logic/core/routes';
import { ToastContext } from '../../../../components/Toast/toastStateProvider';
import {useAppStateHook} from "../../../../logic/core/appState.hook";
import Radio from "../../../../components/Radio";

export type ModalCloseFunction = (refreshData: boolean, bodyId?: string) => void;

const OrganizationBodiesCreateModal = ({
                                           onClose,
                                           bodyToEdit
                                       }: { onClose: ModalCloseFunction, bodyToEdit?: Body | null }) => {

    const {t, loading, setLoading, setError, navigate, renderError} = useBaseScreenHook();
    const [,setToastState] = useContext(ToastContext);
    const {getOrganization} = useAppStateHook();
    const {formControl, formValue, setValidations, renderValidationMessage, formValid} = useForm<CreateBody>({
        legalFormBodyId: {value: bodyToEdit?.legalFormBody.id ?? ''},
        name: {value: bodyToEdit?.name ?? ''},
        type: {
            value: bodyToEdit?.type ?? null,
            disabled: !!bodyToEdit?.legalFormBody.type,
        },
        voteCount: {
            value: bodyToEdit?.voteCount ?? 0,
        },
    });

    const {organizationId} = useParams();
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [bodyToDelete, setBodyToDelete] = useState<Body | null>(null);


    useEffect(() => {
        if (!organizationId) {
            return;
        }
        getOrganization().then((res) => {
            if (res.isFailure()) {
                return
            }

            setOrganization(res.data!);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, bodyToEdit])

    const submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        let res;

        const body: CreateBody = {
            name: formValue.name || null,
            legalFormBodyId: formValue.legalFormBodyId || null,
            voteCount: formValue.voteCount === 0 ? 0 : formValue.voteCount || null,
            type: formValue.type || null,
        }

        if (bodyToEdit) {
            res = await updateBody(organizationId!, bodyToEdit.id, body);
        } else {
            res = await createBody(organizationId!, body);
        }
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {

            setError(res.failure!);

            return;
        }

        if (bodyToEdit) {
            setToastState({
                type: ModalType.success,
                message: t('createBodyModal.saved')
            });

            return;
        }
        onClose(true, res.data!);
        navigate(`/${AppRoutes.organization}/${organizationId}/${AppRoutes.host}/${AppRoutes.bodies}/${res.data!}/${AppRoutes.members}`);
    }

    const _deleteBody = async () => {
        setBodyToDelete(null);
        setLoading(true);
        const res = await deleteBody(organizationId!, bodyToEdit!.id);
        setLoading(false);

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        navigate(`/${AppRoutes.organization}/${organizationId}/${AppRoutes.host}/${AppRoutes.bodies}`);
    }


    return <Wrapper className={bodyToEdit ? 'edit' : ''}>

        {
            bodyToEdit
                ? null
                : <>
                    <div className="organization-name">
                        {organization?.name}
                    </div>

                    <div className="title">
                        {t('createBodyModal.title')}
                    </div>
                </>
        }


        <FormField>
            <div className="label">
                {
                    bodyToEdit
                        ? t('createBodyModal.bodyEdit')
                        : t('createBodyModal.body') + '*'
                }
            </div>
            <div className="hint">
                {t('createBodyModal.bodyDescription')}
            </div>
            <Select label={''}
                    currentValue={formControl('legalFormBodyId').value}
                    onChange={(id) => {
                        formControl('legalFormBodyId').patchValue(id);
                        const body: LegalFormBody | undefined = organization?.legalForm.bodies.find((b) => b.id === id);
                        if (body) {
                            formControl('name').patchValue(body.label);
                        }
                    if (!body || body?.type) {
                            formControl('type').patchValue(null);
                            formControl('type').disable();
                        } else {
                            formControl('type').enable();
                        }
                    }}
                    disabled={!!bodyToEdit}
            >
                <option/>
                {organization?.legalForm?.bodies?.map((b) => {
                    return <option value={b.id} key={b.id}>{b.label}</option>
                })}
            </Select>
            {renderValidationMessage('legalFormBodyId')}


        </FormField>

        {
            formControl('type').control.disabled
                ? null
                : <FormField>
                    <div className="label type-label">
                        {t('createBodyModal.type')} *
                    </div>
                    <FormField>

                        <Radio labelElement={<div className="label">
                            {t('createBodyModal.typeCapital')}

                        </div>}>
                            <input type="radio" value={LegalFormBodyType.Capital} name="type"
                                   disabled={formControl('type').control.disabled}
                                   checked={formControl('type').value === LegalFormBodyType.Capital}
                                   onChange={() => {
                                       formControl('type').patchValue(LegalFormBodyType.Capital)
                                   }
                                   }/>

                        </Radio>
                    </FormField>
                    <FormField>

                        <Radio labelElement={<div className="label">
                            {t('createBodyModal.typePersonal')}

                        </div>}>
                            <input type="radio" value={LegalFormBodyType.Personal} name="type"
                                   disabled={formControl('type').control.disabled}
                                   checked={formControl('type').value === LegalFormBodyType.Personal}
                                   onChange={() => {
                                       formControl('type').patchValue(LegalFormBodyType.Personal)
                                   }
                                   }/>

                        </Radio>
                    </FormField>
                    {renderValidationMessage('type')}

                </FormField>
        }


        <FormField>
            <Input label={t('createBodyModal.name') + '*'}>
                <input type="text" value={formControl('name').value}
                       onChange={(e) => formControl('name').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('name')}

        </FormField>

        <FormField>
            <div className="label">
                {t('createBodyModal.data') + '*'}
                <div className="hint">
                    {t('createBodyModal.dataDescription')}
                </div>
            </div>
        </FormField>

        <FormField>
            <div className="data-values">
                <Input>
                    <input type="number" value={formControl('voteCount').value} step={1}
                           onChange={(e) => formControl('voteCount').patchValue(e.target.value)}/>
                    <div className="arrows">
                        <img src={iconArrowUp} alt="up"
                             onClick={() => formControl('voteCount').patchValue(formControl('voteCount').value + 1)}/>
                        <img src={iconArrowDown} alt="down"
                             onClick={() => formControl('voteCount').patchValue(formControl('voteCount').value - 1)}/>
                    </div>
                </Input>
                <div className="label">
                    {t('createBodyModal.votesAmount')}
                </div>
            </div>
            {renderValidationMessage('voteCount')}

        </FormField>

        {
            bodyToEdit
                ? <div className="edit-buttons">
                    <button className={'button'} onClick={submit} disabled={loading}>
                        {t('createBodyModal.editBtn')}
                    </button>

                    <button className="button text delete" onClick={() => setBodyToDelete(bodyToEdit)} disabled={loading}>
                        {t('createBodyModal.deleteBtn')}
                    </button>
                </div>
                : <div className="buttons">
                    <button className="button text muted" onClick={() => onClose(false)}>
                        {t('createBodyModal.cancelBtn')}
                    </button>
                    <button className={'button'} onClick={submit} disabled={loading}>
                        {t('createBodyModal.saveBtn')}
                    </button>
                </div>
        }


        {
            bodyToEdit
                ? null
                : <div className="next-step-info">
                    {t('createBodyModal.nextStepInfo')}
                </div>
        }


        {renderError()}
        <Modal width={600} show={!!bodyToDelete} onClose={() => setBodyToDelete(null)}>
            <ConfirmationModal
                reject={() => setBodyToDelete(null)}
                accept={() => _deleteBody()}
                title={`${t('bodies.deleteModalTitle')} ${bodyToDelete?.name ?? ''}?`}
                body={t('bodies.deleteModalBody')}
            />
        </Modal>


    </Wrapper>;
}

export default OrganizationBodiesCreateModal;
