import React, { useContext, useEffect, useState } from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import LoginScreen from './containers/LoginScreen';
import RegisterScreen from './containers/RegisterScreen';
import RegisterConfirmEmailScreen from './containers/RegisterConfirmEmailScreen';
import ForgetPasswordScreen from './containers/ForgetPasswordScreen';
import HomeScreen from './containers/Home';
import { LoggedInGuard, NotLoggedInGuard, OrganizationRoleGuard } from './components/Guards';
import OrganizationScreen from './containers/OrganizationScreen';
import OrganizationDashboardScreen from './containers/OrganizationScreen/OrganizationDashboardScreen';
import { OrganizationRole } from './logic/organizations/organizations.model';
import OrganizationEventsScreen from './containers/OrganizationScreen/OrganizationEventsScreen';
import OrganizationBodiesScreen from './containers/OrganizationScreen/OrganizationBodiesScreen';
import OrganizationBodyDetails from './containers/OrganizationScreen/OrganizationBodiesScreen/OrganizationBodyDetails';
import OrganizationBodyDetailsSettings
    from './containers/OrganizationScreen/OrganizationBodiesScreen/OrganizationBodyDetails/OrganizationBodyDetailsSettings';
import OrganizationBodiesDetailsMembers
    from './containers/OrganizationScreen/OrganizationBodiesScreen/OrganizationBodyDetails/OrganizationBodiesDetailsMembers';
import OrganizationData from './containers/OrganizationScreen/OrganizationData';
import OrganizationDataDetails from './containers/OrganizationScreen/OrganizationData/OrganizationDataDetails';
import OrganizationDataInvitationEmails
    from './containers/OrganizationScreen/OrganizationData/OrganizationDataInvitationEmails';
import { useServiceWorker } from './logic/core/useServiceWorker.hook';
import Loader from './components/Loader';
import RegisterAccountActivationScreen from './containers/RegisterAccountActivationScreen';
import ResetPasswordScreen from './containers/ResetPasswordScreen';
import OrganizationUsers from './containers/OrganizationScreen/OrganizationUsers';
import { AppRoutes } from './logic/core/routes';
import ProfileScreen from './containers/AccountScreen/ProfileScreen';
import AccountScreen from './containers/AccountScreen';
import ProfileSettingsScreen from './containers/AccountScreen/ProfileScreen/ProfileSettingsScreen';
import ProfileDataScreen from './containers/AccountScreen/ProfileScreen/ProfileDataScreen';
import Toast from './components/Toast';
import { ToastContext } from './components/Toast/toastStateProvider';
import { useAppStateHook } from './logic/core/appState.hook';
import { UserRole } from './logic/auth/auth.model';
import Event from './containers/Event';
import EventInfo from './containers/Event/EventInfo';
import EventAgendaScreen from './containers/Event/EventAgenda';
import EventParticipants from './containers/Event/EventParticipants';
import Participants from "./containers/Event/EventParticipants/Participants";
import EventParticipantsStateStateProvider from "./containers/Event/EventParticipants/participantsProvider";
import Guests from "./containers/Event/EventParticipants/Guests";
import EventGuestInvitationScreen from "./containers/EventGuestScreen/EventGuestInvitation";
import EventGuestScreen from "./containers/EventGuestScreen";
import EventParticipantInvitationScreen from "./containers/EventParticipantInvitationScreen";
import EventParticipantInvitationDetails
    from "./containers/EventParticipantInvitationScreen/EventParticipantInvitationDetails";
import UserEvents from "./containers/AccountScreen/UserEvents";
import OrganizationParticipantEventsScreen from "./containers/OrganizationScreen/OrganizationParticipantEventsScreen";
import UserDocuments from "./containers/AccountScreen/UserDocuments";
import UserEventDocuments from "./containers/AccountScreen/UserDocuments/EventDocuments";
import UserOrganizationDocuments from "./containers/AccountScreen/UserDocuments/OrganizationDocuments";
import OrganizationDocumentsScreen from "./containers/OrganizationScreen/OrganizationDocumentsScreen";
import OrganizationEventDocuments from './containers/OrganizationScreen/OrganizationDocumentsScreen/EventDocuments';
import OrganizationOrganizationDocuments
    from "./containers/OrganizationScreen/OrganizationDocumentsScreen/OrganizationDocuments";
import OrganizationParticipantDocumentsScreen from './containers/OrganizationScreen/OrganizationParticipantDocumentsScreen';
import OrganizationParticipantOrganizationDocuments
    from "./containers/OrganizationScreen/OrganizationParticipantDocumentsScreen/OrganizationParticipantOrganizationDocuments";
import OrganizationParticipantEventDocuments from './containers/OrganizationScreen/OrganizationParticipantDocumentsScreen/OrganizationParticipantEventDocuments';
import EventDocuments from "./containers/Event/EventDocuments";
import EventEventDocuments from "./containers/Event/EventDocuments/EventEventDocuments";
import EventOrganizatorDocuments from "./containers/Event/EventDocuments/EventOrganizatorDocuments";
import InvitationEmailVerificationScreen
    from "./containers/InvitationEmailVerificationScreen/invitation-email-verification";
import Moderators from "./containers/Event/EventParticipants/Moderators";
import OrganizationBillingsScreen from "./containers/OrganizationScreen/OrganizationBillingsScreen";
import OrganizationBillingsBillingsScreen
    from "./containers/OrganizationScreen/OrganizationBillingsScreen/OrganizationBillignsBillingsScreen";
import OrganizationBillingsPaymentHistoryScreen
    from "./containers/OrganizationScreen/OrganizationBillingsScreen/OrganizationBillingsPaymentHistoryScreen";
import OrganizationBillingsPricingScreen
    from "./containers/OrganizationScreen/OrganizationBillingsScreen/OrganizationBillingsPricingScreen";

function App() {
    const {waitingWorker, showReload, reloadPage} = useServiceWorker();
    const [ready, setReady] = useState<boolean>(false);
    const [toastState, setToastState] = useContext(ToastContext);
    const {getMe} = useAppStateHook();
    const { search } = useLocation();
    useEffect(() => {
        if (showReload && waitingWorker) {
            console.log('A new version of this page is available');
        } else {
            console.log('A new version of this page is NOT available');
        }
    }, [waitingWorker, showReload, reloadPage]);

    const _me = async () => {
        await getMe();

        setReady(true);
    }

    useEffect(() => {
        _me();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _closeToast = () => {
        setToastState({
            ...toastState,
            message: null,
        })
    }

    if (!ready) {
        return <Loader/>
    }
    return <>
        <Routes>
            <Route path={AppRoutes.login} element={<NotLoggedInGuard><LoginScreen/></NotLoggedInGuard>}/>
            <Route path={AppRoutes.register} element={<NotLoggedInGuard><RegisterScreen/></NotLoggedInGuard>}/>
            <Route path={`${AppRoutes.register}/${AppRoutes.confirmEmail}`}
                   element={<NotLoggedInGuard><RegisterConfirmEmailScreen/></NotLoggedInGuard>}/>

            <Route path={`${AppRoutes.register}/${AppRoutes.activation}`}
                   element={<NotLoggedInGuard><RegisterAccountActivationScreen/></NotLoggedInGuard>}/>
            <Route path={AppRoutes.forgetPassword}
                   element={<NotLoggedInGuard><ForgetPasswordScreen/></NotLoggedInGuard>}/>
            <Route path={`${AppRoutes.passwordReset}`}
                   element={<NotLoggedInGuard><ResetPasswordScreen/></NotLoggedInGuard>}/>
            <Route path={`invitation-email-verification/:email`}
                   element={<InvitationEmailVerificationScreen/>}/>

            <Route path={`${AppRoutes.eventGuest}`} element={<EventGuestScreen/>}>
                <Route path={`${AppRoutes.eventInvitation}/:guestId`} element={<EventGuestInvitationScreen/>}/>
                <Route path='' element={<Navigate to={''}/>}/>
                <Route path='*' element={<Navigate to={''}/>}/>
            </Route>

            <Route path={`${AppRoutes.participant}/${AppRoutes.eventInvitation}/:participantId`} element={<EventParticipantInvitationScreen/>}>
                <Route path='' element={<EventParticipantInvitationDetails/>}/>
                <Route path='*' element={<Navigate to={''}/>}/>
            </Route>

            <Route path='/' element={<LoggedInGuard><HomeScreen/></LoggedInGuard>}>
                <Route path={`${AppRoutes.organization}/:organizationId`} element={<OrganizationScreen/>}/>
                <Route path={`${AppRoutes.organization}/:organizationId/${AppRoutes.host}`}
                       element={<OrganizationScreen role={OrganizationRole.Host}/>}>
                    <Route path={AppRoutes.dashboard} element={<OrganizationDashboardScreen/>}/>
                    <Route path={AppRoutes.events} element={<OrganizationEventsScreen/>}/>
                    <Route path={AppRoutes.bodies} element={<OrganizationBodiesScreen/>}/>
                    <Route path={`${AppRoutes.bodies}/:bodyId`} element={<OrganizationBodyDetails/>}>
                        <Route path={AppRoutes.settings} element={<OrganizationBodyDetailsSettings/>}/>
                        <Route path={AppRoutes.members} element={<OrganizationBodiesDetailsMembers/>}/>
                    </Route>
                    <Route path={`${AppRoutes.documents}/`} element={<OrganizationDocumentsScreen/>}>
                        <Route path={AppRoutes.events} element={<OrganizationEventDocuments/>}/>
                        <Route path={AppRoutes.organization} element={<OrganizationOrganizationDocuments/>}/>
                        <Route path='' element={<Navigate to={AppRoutes.events}/>}/>
                        <Route path='*' element={<Navigate to={AppRoutes.events}/>}/>
                    </Route>
                    <Route path={AppRoutes.data} element={<OrganizationRoleGuard roles={[UserRole.Administrator, UserRole.SuperAdministrator]}><OrganizationData/></OrganizationRoleGuard>}>
                        <Route path={AppRoutes.details} element={<OrganizationDataDetails/>}/>
                        <Route path={AppRoutes.invitationEmails} element={<OrganizationDataInvitationEmails/>}/>
                        <Route path='' element={<Navigate to={AppRoutes.details}/>}/>
                        <Route path='*' element={<Navigate to={AppRoutes.details}/>}/>
                    </Route>
                    <Route path={AppRoutes.billings} element={<OrganizationRoleGuard roles={[UserRole.Administrator, UserRole.SuperAdministrator]}><OrganizationBillingsScreen/></OrganizationRoleGuard>}>
                        <Route path={AppRoutes.billings} element={<OrganizationBillingsBillingsScreen/>}/>
                        <Route path={AppRoutes.paymentHistory} element={<OrganizationBillingsPaymentHistoryScreen/>}/>
                        <Route path={AppRoutes.pricing} element={<OrganizationBillingsPricingScreen/>}/>
                        <Route path='' element={<Navigate to={AppRoutes.billings}/>}/>
                        <Route path='*' element={<Navigate to={AppRoutes.billings}/>}/>
                    </Route>
                    <Route path={AppRoutes.users} element={<OrganizationRoleGuard roles={[UserRole.Administrator, UserRole.SuperAdministrator]}><OrganizationUsers/></OrganizationRoleGuard>}/>
                    <Route path='*' element={<Navigate to={AppRoutes.dashboard}/>}/>
                </Route>
                <Route path={`${AppRoutes.organization}/:organizationId/${AppRoutes.participant}`}
                       element={<OrganizationScreen role={OrganizationRole.Participant}/>}>
                    <Route path={AppRoutes.events} element={<OrganizationParticipantEventsScreen/>}/>
                    <Route path={AppRoutes.documents} element={<OrganizationParticipantDocumentsScreen/>}>
                        <Route path={AppRoutes.events} element={<OrganizationParticipantEventDocuments/>}/>
                        <Route path={AppRoutes.organization} element={<OrganizationParticipantOrganizationDocuments/>}/>
                        <Route path='' element={<Navigate to={AppRoutes.events}/>}/>
                        <Route path='*' element={<Navigate to={AppRoutes.events}/>}/>
                    </Route>
                    <Route path='*' element={<Navigate to={AppRoutes.events}/>}/>
                </Route>
                <Route path={`${AppRoutes.account}`} element={<AccountScreen/>}>
                    <Route path={`${AppRoutes.profile}`} element={<ProfileScreen/>}>
                        <Route path={AppRoutes.profileData} element={<ProfileDataScreen/>}/>
                        <Route path={AppRoutes.profileSettings} element={<ProfileSettingsScreen/>}/>
                        <Route path='' element={<Navigate to={AppRoutes.profileData}/>}/>
                        <Route path='*' element={<Navigate to={AppRoutes.profileData}/>}/>
                    </Route>
                    <Route path={`${AppRoutes.documents}`} element={<UserDocuments/>}>
                        <Route path={AppRoutes.events} element={<UserEventDocuments/>}/>
                        <Route path={AppRoutes.organization} element={<UserOrganizationDocuments/>}/>
                        <Route path='' element={<Navigate to={AppRoutes.events}/>}/>
                        <Route path='*' element={<Navigate to={AppRoutes.events}/>}/>

                    </Route>
                    <Route path={`${AppRoutes.events}`} element={<UserEvents/>}/>
                    <Route path='' element={<Navigate to={AppRoutes.profile}/>}/>
                    <Route path='*' element={<Navigate to={AppRoutes.profile}/>}/>
                </Route>

            </Route>

            <Route path={`/${AppRoutes.organization}/:organizationId/${AppRoutes.event}/:eventId`} element={<Event/>}>
                <Route path={AppRoutes.eventInfo} element={<EventInfo/>}/>
                <Route path={AppRoutes.eventAgenda} element={<EventAgendaScreen/>}/>
                <Route path={AppRoutes.eventParticipants} element={<EventParticipantsStateStateProvider><EventParticipants/></EventParticipantsStateStateProvider>}>
                    <Route path={AppRoutes.eventParticipants} element={<Participants/>}/>
                    <Route path={AppRoutes.eventModerators} element={<Moderators/>}/>
                    <Route path={AppRoutes.eventGuests} element={<Guests/>}/>
                    <Route path='' element={<Navigate to={`${AppRoutes.eventParticipants}${search}`}/>}/>
                    <Route path='*' element={<Navigate to={`${AppRoutes.eventParticipants}${search}`}/>}/>
                </Route>
                <Route path={AppRoutes.documents} element={<EventDocuments/>}>
                    <Route path={AppRoutes.events} element={<EventEventDocuments/>}/>
                    <Route path={AppRoutes.organization} element={<EventOrganizatorDocuments/>}/>
                    <Route path='*' element={<Navigate to={`${AppRoutes.events}${search}`}/>}/>
                    <Route path='' element={<Navigate to={`${AppRoutes.events}${search}`}/>}/>

                </Route>

                <Route path='' element={<Navigate to={`${AppRoutes.eventInfo}${search}`}/>}/>
                <Route path='*' element={<Navigate to={`${AppRoutes.eventInfo}${search}`}/>}/>
            </Route>

            <Route path='*' element={<Navigate to={AppRoutes.login}/>}/>
        </Routes>

        <Toast onClose={_closeToast} message={toastState.message} type={toastState.type}/>
    </>;
}

export default App;
