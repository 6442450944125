export enum AppRoutes {
    login = 'login',
    register = 'register',
    confirmEmail = 'confirm-email',
    activation = 'activation',
    forgetPassword = 'forget-password',
    passwordReset = 'password/reset',
    organization = 'organization',
    host = 'host',
    account = 'account',
    profile = 'profile',
    profileData = 'data',
    profileSettings = 'settings',
    dashboard = 'dashboard',
    bodies = 'bodies',
    settings = 'settings',
    members = 'members',
    data = 'data',
    details = 'details',
    invitationEmails = 'invitation-emails',
    users = 'users',
    participant = 'participant',
    events = 'events',
    event = 'event',
    repositories = 'repositories',
    billings = 'billings',
    paymentHistory = 'payment-history',
    pricing = 'pricing',
    documents = 'documents',
    eventInfo = 'information',
    eventAgenda = 'agenda',
    eventParticipants = 'participants',
    eventGuests = 'guests',
    eventModerators = 'moderators',
    eventInvitation = 'invitation',
    eventGuest = 'guest',

}
