import React from "react";
import {Wrapper} from "./EventParticipantInvitationScreen.styles";
import {useLocation} from "react-router-dom";
import TopBar from "../../components/TopBar";
import MainContainer from "../../components/MainContainer";
import {useLogout} from "../../logic/auth/auth";

const EventParticipantInvitationScreen = () => {
    const location = useLocation();
    const {logout} = useLogout();
    return <Wrapper>
        <TopBar backArrow={false} url={location.pathname} logoutAction={() => logout(false)}/>
        <MainContainer>
            <></>
        </MainContainer>
    </Wrapper>
}

export default EventParticipantInvitationScreen;