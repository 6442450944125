import React from 'react';
import { Navigate, useParams } from "react-router-dom";
import { useAppStateHook } from '../../logic/core/appState.hook';
import { UserRole } from '../../logic/auth/auth.model';

export const LoggedInGuard = ({children}: { children: React.ReactElement }) => {
    const {appState} = useAppStateHook();
    return appState.user ? children : <Navigate to={'/login'}/>
}

export const NotLoggedInGuard = ({children}: { children: React.ReactElement }) => {
    const {appState} = useAppStateHook();
    return !appState?.user ? children : <Navigate to={'/'}/>
}


export const OrganizationRoleGuard = ({children, roles}: { children: React.ReactElement, roles: UserRole[] }) => {
    const {appState} = useAppStateHook();
    const {organizationId} = useParams();

    if (appState.user!.roles.some((role) => role.organizationId === organizationId && roles.includes(role.role))) {
        return children;
    }
    return <Navigate to="/"/>;
}

