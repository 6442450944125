import React from 'react';
import { Wrapper } from './OrganizationDataInvitationEmailsNewEmail.styles';
import Input from '../../../../../components/Input';
import FormField from '../../../../../components/FormField';
import { useBaseScreenHook } from '../../../../../logic/core/base-screen.hook';
import { useForm } from '../../../../../logic/core/form.hook';
import { addInvitationEmail } from '../../../../../logic/organizations/organizations';
import { useParams } from 'react-router-dom';
import { Violations } from '../../../../../logic/core/failures';

const OrganizationDataInvitationEmailsNewEmail = ({onClose}: {onClose: (refresh: boolean) => void}) => {
    const {organizationId} = useParams();
    const {t, loading, setLoading, renderError, setError} = useBaseScreenHook();
    const {formControl, renderValidationMessage, formValid, formValue, setValidations} = useForm<{email: string}>({
        email: {
            value: ''
        }
    })

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);

        const res = await addInvitationEmail(organizationId!, formValue.email)
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose(true);
    }


    return <Wrapper>
        <div className="title">
            {t('organizationDataInvitationEmailsNewEmail.title')}
        </div>

        <FormField>
            <Input label={t('organizationDataInvitationEmailsNewEmail.email') + ' *'}>
                <input type="text"
                       value={formControl('email').value}
                       onChange={(e) => formControl('email').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('email')}
        </FormField>

        <button className={'button'} onClick={_submit} disabled={loading}>
            {t('organizationDataInvitationEmailsNewEmail.addBtn')}
        </button>

        {renderError()}
    </Wrapper>
}

export default OrganizationDataInvitationEmailsNewEmail;
