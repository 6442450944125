import {LegalFormBody, LegalFormBodyType} from '../organizations/organizations.model';

export interface Body {
    id: string;
    legalFormBody: LegalFormBody;
    name: string;
    voteCount: number;
    type: LegalFormBodyType | null;
}

export interface CreateBody {
    legalFormBodyId: string | null;
    name: string | null;
    voteCount: number | null;
    type: LegalFormBodyType | null;
}

export interface BodyMemberFilters {
    name?: string;
    email?: string;
    addresseeName?: string;
}
export enum BodyMemberType {
    organizationMember = 'organization-member',
    personMember = 'person-member'
}


export type BodyMember  = BodyMemberOrganization | BodyMemberPersonal;

export interface BodyMemberOrganization {
    type: BodyMemberType.organizationMember;
    id?: string;
    organizationName: string | null;
    nip: string | null;
    votesNumber: number | null;
    shares: number | null;
    sharePercentage?: number | null;
    email: string | null;
    address: Address | null;
    addressee: {
        firstName: string | null;
        lastName: string | null;
        position: string | null;
        phoneNumber: string | null;
    } | null;
}

export interface Address {
    country: string | null;
    city: string | null;
    street: string | null;
    postalCode: string | null;
}

export interface BodyMemberPersonal {
    type: BodyMemberType.personMember;
    id?: string;
    firstName: string | null;
    lastName: string | null;
    pesel: string | null;
    votesNumber: number | null;
    shares: number | null;
    sharePercentage?: number | null;
    email: string | null;
    addressee: {
        firstName: string | null;
        lastName: string | null;
        phoneNumber: string | null;
    } | null;
}
