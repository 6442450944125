import React, { useEffect } from 'react';
import { Wrapper } from './OrganizationScreen.styles';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrganizationRole } from '../../logic/organizations/organizations.model';
import { AppRoutes } from '../../logic/core/routes';
import { useAppStateContext } from '../../appSateProvider';

const OrganizationScreen = ({role}: { role?: OrganizationRole }) => {
    const {organizationId} = useParams();
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();
    const [, , isAdmin, isSuperAdmin] = useAppStateContext();

    useEffect(() => {
        if (!role) {
            navigate(selectRole(OrganizationRole.Host));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, organizationId, role]);

    const selectRole = (role: OrganizationRole) => {
        if (role === OrganizationRole.Host) {
            return `/${AppRoutes.organization}/${organizationId}/${role}/${AppRoutes.dashboard}`;
        } else {
            return `/${AppRoutes.organization}/${organizationId}/${role}/${AppRoutes.events}`;
        }
    }
    const selectTab = (name: string) => {
        return `/${AppRoutes.organization}/${organizationId}/${role}/${name}`;
    }

    const isTabActive = (name: string) => {
        return location.pathname.includes(selectTab(name));
    }

    const _renderHostTabs = () => {
        const tabs: React.ReactNode[] = [];

        tabs.push(<Link key={'dashboard'} className={`tab ${isTabActive(AppRoutes.dashboard) ? 'active' : ''}`}
                        to={selectTab(AppRoutes.dashboard)}>{t('organization.tabs.dashboard')}</Link>);
        tabs.push(<Link key={'events'} className={`tab ${isTabActive(AppRoutes.events) ? 'active' : ''}`}
                        to={selectTab(AppRoutes.events)}>{t('organization.tabs.events')}</Link>);
        tabs.push(<Link key={'bodies'} className={`tab ${isTabActive(AppRoutes.bodies) ? 'active' : ''}`}
                        to={selectTab(AppRoutes.bodies)}>{t('organization.tabs.bodies')}</Link>);
        tabs.push(<Link key={'documents'} className={`tab ${isTabActive(AppRoutes.documents) ? 'active' : ''}`}
                        to={selectTab(AppRoutes.documents)}>{t('organization.tabs.repositories')}</Link>);
        (isAdmin(organizationId!) || isSuperAdmin(organizationId!)) && tabs.push(<Link key={'users'}
                                                                                       className={`tab ${isTabActive(AppRoutes.users) ? 'active' : ''}`}
                                                                                       to={selectTab(AppRoutes.users)}>{t('organization.tabs.users')}</Link>);
        (isAdmin(organizationId!) || isSuperAdmin(organizationId!)) && tabs.push(<Link key={'data'} className={`tab ${isTabActive(AppRoutes.data) ? 'active' : ''}`}
                        to={selectTab(AppRoutes.data)}>{t('organization.tabs.data')}</Link>);
        (isAdmin(organizationId!) || isSuperAdmin(organizationId!)) && tabs.push(<Link key={'billings'} className={`tab ${isTabActive(AppRoutes.billings) ? 'active' : ''}`}
                        to={selectTab(AppRoutes.billings)}>{t('organization.tabs.billings')}</Link>);
        return tabs;
    }
    return <Wrapper>

        <div className="roles">
            <Link className={`role ${role === OrganizationRole.Host ? 'active' : ''}`}
                  to={selectRole(OrganizationRole.Host)}>
                {t('organization.roleHost')}
            </Link>
            <Link className={`role ${role === OrganizationRole.Participant ? 'active' : ''}`}
                  to={selectRole(OrganizationRole.Participant)}>
                {t('organization.roleParticipant')}
            </Link>
        </div>

        {
            role === OrganizationRole.Host
                ? <div className="tabs">
                    {_renderHostTabs()}
                </div>
                : <div className="tabs">
                    <Link className={`tab ${isTabActive(AppRoutes.events) ? 'active' : ''}`}
                          to={selectTab(AppRoutes.events)}>{t('organization.tabs.events')}</Link>
                    <Link className={`tab ${isTabActive(AppRoutes.documents) ? 'active' : ''}`}
                          to={selectTab(AppRoutes.documents)}>{t('organization.tabs.documents')}</Link>
                </div>
        }


        <div className="outlet">
            <Outlet/>
        </div>
    </Wrapper>
}

export default OrganizationScreen;
