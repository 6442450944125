import styled from 'styled-components';

export const Wrapper = styled.div`
    .frame-box-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .frame-box {
            display: flex;
            height: 50px;
            padding: 0 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin: 0 auto 20px;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            border-radius: 5px;
            border: 1px solid #212121;

            .cta {
                display: flex;
                height: 24px;
                padding: 0 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                background: #FCE502;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                cursor: pointer;
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .plans {
        display: flex;
        gap: 5px;
        margin: 0 auto 34px;
        justify-content: center;
        flex-wrap: wrap;
        .plan {
            display: flex;
            width: 260px;
            padding: 24px 4px;
            flex-direction: column;
            align-items: center;
            align-self: stretch;
            border-radius: 5px;

            &:nth-child(2) {
                background: var(--disabledColor);
            }

            .name {
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                text-transform: capitalize;
            }

            .invitations {
                display: flex;
                height: 24px;
                padding: 0px 4px;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                color: #FFF;
                width: calc(100% - 10px);
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                background: var(--mainColor);
                margin-bottom: 12px;
            }

            .description {
                min-height: 61px;
                text-align: center;
                margin-bottom: 12px;
            }

            .price-gross {
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px;
                text-align: center;
            }

            .price-net {
                font-size: 18px;
                font-weight: 400;
                line-height: 1;
                text-align: center;
                margin-bottom: 24px;
            }

            .button {
                height: 50px;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 24px;
            }
        }
    }
    
    .pros-list {
        width: calc(3 * 270px);
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 
        margin: 0 auto 34px;
        @media screen and (max-width: 1500px) {
            width: 100%;
        }
        ul {
            padding-left: 20px;
            margin: 0;
        }
    }
    
    .steps {
        display: flex;
        gap: 5px;
        margin: 0 auto 34px;
        justify-content: center;
        flex-wrap: wrap;
        .step {
            display: flex;
            width: 260px;
            padding: 24px 4px;
            flex-direction: column;
            align-items: center;
            align-self: stretch;
            border-radius: 5px;
            .number {
                display: flex;
                width: 40px;
                height: 40px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 45px;
                background: #FAD274;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px; 
            }
            
            .name {
                color: #000;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px; 
                margin-bottom: 12px;
            }
            .description {
                color: #000;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px; 
            }
        }
    }
`
