import styled from 'styled-components';

export const Wrapper = styled.div`

    .title {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
.list-description {
    color: #888;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px; 
    margin-bottom: 20px;
}
    .info {
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        .info-title {
            font-size: 15px;
            font-weight: 700;
            line-height: 18px;
        }
    }
    
    .product-name {
        text-transform: capitalize;
    }
`
