import React from "react";
import {Wrapper} from "./EventDocuments.styled";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {AppRoutes} from "../../../logic/core/routes";
import EventHeader from "../EventHeader";
import {useEvent} from "../../../logic/events/events";
import Loader from "../../../components/Loader";
import {EventPermission, EventType} from "../../../logic/events/events.model";

const EventDocuments = () => {
    const {t} = useBaseScreenHook();
    const {event, organization} = useEvent();
    const {search} = useLocation();
    const location = useLocation();

    if (!event || !organization) {
        return <Loader/>
    }

    return <Wrapper>

        <EventHeader event={event} organization={organization}/>

        <h1 className="sub-title">
            Dokumenty
        </h1>

        <div className="document-tabs">
            <NavLink className={`tab`} to={`${AppRoutes.events}${search}`}>
                {t('documents.events')}
            </NavLink>
            <NavLink className={`tab`} to={`${AppRoutes.organization}${search}`}>
                {t('documents.organizatorDocuments')}
            </NavLink>
        </div>

        <Outlet/>


        {
            event.permissions[EventPermission.canSeeNavigationButtons]
                ?
                <div className={'nav-buttons'}>
                    <NavLink to={`../${AppRoutes.eventAgenda}${location.search}`}>
                        <button className="button outline muted">
                            {event?.content?.type === EventType.LIVE
                                ? t('event.navigation.backToAgenda')
                                : t('event.navigation.backToVoting')
                            }
                        </button>
                    </NavLink>
                    {event?.permissions.canManageParticipants || event?.permissions.canManageGuests
                        ? <NavLink to={`../${AppRoutes.eventParticipants}${location.search}`}>
                            <button className="button">
                                {t('event.navigation.goToParticipants')}
                            </button>
                        </NavLink>
                        : null
                    }
                </div>
                : null
        }
    </Wrapper>
}

export default EventDocuments;
