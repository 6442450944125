import FormField from '../../components/FormField';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import {FormEvent} from 'react';
import NotLoggedBaseScreen from '../../components/NotLoggedBaseScreen';
import {Wrapper} from './LoginScreen.styles';
import {Link} from 'react-router-dom';
import {login} from '../../logic/auth/auth';
import {useBaseScreenHook} from '../../logic/core/base-screen.hook';
import {AuthError, Violations} from '../../logic/core/failures';
import {useForm} from '../../logic/core/form.hook';
import {AppRoutes} from '../../logic/core/routes';
import {useAppStateHook} from '../../logic/core/appState.hook';

const LoginScreen = () => {

    const {setValidations, formValid, formControl, renderValidationMessage, formValue} = useForm<{username: string, password: string, rememberMe: boolean}>({
        username: {
            value: ''
        },
        password: {
            value: ''
        },
        rememberMe: {
            value: false
        },
    });
    const {getMe} = useAppStateHook();
    const {t, navigate, setLoading, loading, setError, renderError} = useBaseScreenHook()

    const submit = async (e: FormEvent) => {
        e.preventDefault();
        if (!formValid()) {
            return;
        }
        setLoading(true);

        const res = await login(formValue.username, formValue.password, formValue.rememberMe);
        if (res.isViolations()) {
            setLoading(false);
            setValidations((res.failure! as Violations).getValidations())
            return;
        }


        if (res.isFailure()) {
            setLoading(false);
            if (res.failure?.is(AuthError.getCode)) {
                setError(new AuthError(t('errors.BadCredentials')));
            } else {
                setError(res.failure!);
            }
            return;
        }
        const resMe = await getMe();

        if (resMe.isFailure()) {
            setLoading(false);
            setError(resMe.failure!);
            return;
        }

        navigate('/');
    }


    return <NotLoggedBaseScreen>
        <Wrapper>
            <h2 className="login-title">
                {t('login.title')}
            </h2>

            <form className="form" onSubmit={submit}>

                <FormField>
                    <Input label={t('login.emailLabel')}>
                        <input type="email" value={formControl('username').value} onChange={(e) => formControl('username').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('username')}
                </FormField>

                <FormField>
                    <Input label={t('login.passwordLabel')}>
                        <input type="password" value={formControl('password').value} onChange={(e) => formControl('password').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('password')}

                </FormField>

                <div className="inline">
                    <Checkbox label={t('login.keepLoggedInLabel')}>
                        <input type="checkbox" checked={formControl('rememberMe').value} onChange={() => formControl('rememberMe').patchValue(!formControl('rememberMe').value)}/>
                    </Checkbox>

                    <Link to={`/${AppRoutes.forgetPassword}`} className="forgot-password">{t('login.forgotPassword')}</Link>
                </div>

                <button type="submit" className="button submit-button" disabled={loading}>
                    {t('login.submitButton')}
                </button>

                <div className="register-text">
                    {t('login.registerInfo')} <Link to={`/${AppRoutes.register}`}>{t('login.register')}</Link>
                </div>
            </form>

        </Wrapper>

        {renderError()}
    </NotLoggedBaseScreen>
};

export default LoginScreen;
