import React, {useState} from "react";
import {Wrapper} from "./AgendaPointsModal.styles";
import {AgendaPoint, Voting} from "../../../../logic/events/events.model";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {moveVotingToOtherPoint} from "../../../../logic/events/events";
import {useParams} from "react-router-dom";
import {ModalCloseFunction} from "../../../../components/Modal";

const AgendaPointsModal = ({
                               item,
                               vote,
                               points,
                               onClose
                           }: { points?: AgendaPoint[], item?: AgendaPoint, vote?: Voting, onClose: ModalCloseFunction }) => {
    const {t, loading, setLoading, renderError, setError} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const [selected, setSelected] = useState<AgendaPoint | null>(null);

    const _move = async (p: AgendaPoint) => {
        if (loading) {
            return;
        }
        setLoading(true);
        const res = await moveVotingToOtherPoint(organizationId!, eventId!, item!.id, vote!.id, p.id);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        onClose();
    }
    const _select = async (p: AgendaPoint) => {
        setSelected(p);
    }
    const _renderNo = (index: number): string => {
        index = index + 1;

        if (index < 10) {
            return `0${index}`
        } else {
            return `${index}`
        }

    }
    return <Wrapper>
        <div className="title">
            {t('agendaPointsModal.title')}
        </div>
        <div className="description">
            {vote?.name}
        </div>

        <strong className={'move-label'}>Przenieś do punktu:</strong>

        <div className="points">
            {
                points?.map((p, i) => {
                    if (p.id === item?.id) {
                        return null;
                    }
                    return <div
                        className={`point ${loading ? 'disabled' : ''} ${selected?.id === p.id ? 'selected' : ''}`}
                        key={p.id} onClick={() => _select(p)}>
                        <div className="no">
                            {_renderNo(i)}
                        </div>
                        <div className="name">
                            {p.title}
                        </div>
                    </div>
                }).filter(Boolean)
            }
        </div>

        <div className="buttons">
            <button className="button text muted" onClick={onClose}>
                Anuluj
            </button>
            <button className="button" disabled={loading || !selected} onClick={() => _move(selected!)}>
                Zapisz
            </button>
        </div>
        {renderError()}
    </Wrapper>
}

export default AgendaPointsModal;