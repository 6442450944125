import React, {useEffect} from 'react';
import {Wrapper} from './EventParticipants.styles';
import EventHeader from '../EventHeader';
import {useEvent} from '../../../logic/events/events';
import Loader from '../../../components/Loader';
import {useBaseScreenHook} from '../../../logic/core/base-screen.hook';
import {NavLink, Outlet, useLocation} from 'react-router-dom';
import {useEventParticipantsStateHook} from "./participantsHooh";
import {AppRoutes} from "../../../logic/core/routes";
import {EventPermission} from "../../../logic/events/events.model";


const EventParticipants = () => {
    const {t, renderError} = useBaseScreenHook();
    const {event, organization} = useEvent();
    const {participantsState, getParticipants, getGuests, getModerators} = useEventParticipantsStateHook();
    const {search} = useLocation();
    const location = useLocation();


    useEffect(() => {
        getParticipants({});
        getGuests();
        getModerators();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!event || !organization) {
        return <Loader/>
    }
    return <Wrapper>
        <EventHeader event={event} organization={organization}/>

        <div className="tabs">
            {
                event.permissions[EventPermission.canManageParticipants]
                    ? <NavLink className={`tab`} to={`${AppRoutes.eventParticipants}${search}`}>
                        <>
                            {t('eventParticipants.participants')} ({participantsState.participants?.stats?.all})
                        </>
                    </NavLink>
                    : null
            }

            {
                event.permissions[EventPermission.canManageModerators]
                    ? <NavLink className={`tab`} to={`${AppRoutes.eventModerators}${search}`}>
                        <>
                            {t('eventParticipants.moderators')} ({participantsState.moderators?.moderators?.length})
                        </>
                    </NavLink>
                    : null
            }
            {
                event.permissions[EventPermission.canManageGuests]
                    ? <NavLink className={`tab`} to={`${AppRoutes.eventGuests}${search}`}>
                        <>
                            {t('eventParticipants.guests')} ({participantsState.guests?.guests?.length})
                        </>
                    </NavLink>
                    : null
            }

        </div>


        <Outlet/>

        {
            event.permissions[EventPermission.canSeeNavigationButtons]
                ?
                <div className={'nav-buttons'}>
                    <NavLink to={`../${AppRoutes.documents}${location.search}`}>
                        <button className="button outline muted">
                            {t('event.navigation.backToDocuments')}
                        </button>
                    </NavLink>
                </div>
                : null
        }

        {renderError()}
    </Wrapper>
}

export default EventParticipants;
