import React, { useState } from 'react';
import { Wrapper } from './ProfileSettingsScreen.styles';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import FormField from '../../../../components/FormField';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../../logic/core/routes';
import Modal from '../../../../components/Modal';
import ProfileSettingChangePassword from './ProfileSettingChangePassword';
import { useAppStateHook } from '../../../../logic/core/appState.hook';

const ProfileSettingsScreen = () => {

    const {appState} = useAppStateHook();
    const {t} = useBaseScreenHook();
    const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);

    return <Wrapper>

        <div className="title">
            {t('profileSettingsScreen.title')}
        </div>

        <p className="description">
            {t('profileSettingsScreen.description')}
        </p>


        <div className="data">

                <FormField>
                    <div className="label">
                        {t('profileSettingsScreen.login')}
                    </div>
                    <div className="value">
                        {appState.user?.email}
                    </div>
                    <div className="hint login-hint">
                        {t('profileSettingsScreen.loginHint')} <Link to={`/${AppRoutes.account}/${AppRoutes.events}`}>{t('profileSettingsScreen.events')}</Link>
                    </div>
                </FormField>

                <FormField>
                    <div className="label">
                        {t('profileSettingsScreen.password')}
                    </div>
                    <div className="value">
                        ************************
                    </div>
                    <button className={'button text change-password-button'} onClick={() => setChangePasswordModal(true)}>
                        {t('profileSettingsScreen.changePasswordBtn')}
                    </button>
                </FormField>

        </div>

        <Modal onClose={ () =>setChangePasswordModal(false)} show={changePasswordModal} backDropClose={false}>
            <ProfileSettingChangePassword onClose={() => setChangePasswordModal(false)}/>
        </Modal>

    </Wrapper>
}

export default ProfileSettingsScreen;
