import NotLoggedBaseScreen from '../../components/NotLoggedBaseScreen';
import { Link, useSearchParams, } from 'react-router-dom';
import { Wrapper } from './RegisterAccountActivationScreen.styles';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import Loader from '../../components/Loader';
import { useEffect, useState } from 'react';
import { activateAccount } from '../../logic/auth/auth';


const RegisterAccountActivationScreen = () => {
    const [params] = useSearchParams();
    const {t, loading, setLoading, renderError, navigate} = useBaseScreenHook()
    const [errorScreen, setErrorScreen] = useState<boolean>(false);
    useEffect(() => {
        const token = params.get('token');
        const accountId = params.get('accountId');
        if (accountId && token) {
            _activate(accountId, token);
        } else {
            setErrorScreen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const _activate = (accountId: string, token: string) => {
        setLoading(true);
        activateAccount(accountId, token).then((res) => {
            setLoading(false);

            if (res.isFailure()) {
                setErrorScreen(true);
                return;
            }

            navigate('/login');
        })
    }

    return <NotLoggedBaseScreen>
        <Wrapper>

            <h2 className="title">
                {errorScreen
                    ? t('registerConfirmErrorScreen.title')
                    : t('RegisterAccountActivationScreen.title')}
            </h2>
            {
                loading ?
                    <Loader/>
                    : null

            }
            {
                errorScreen ? <>
                        <p>
                            {t('registerConfirmErrorScreen.description')}
                        </p>


                        <strong>
                            {t('registerConfirm.needHelpInfo')} <Link
                            to=""><strong>{t('registerConfirm.needHelpLink')}</strong></Link>
                        </strong>
                    </>
                    : null
            }
            {renderError()}
        </Wrapper>
    </NotLoggedBaseScreen>
};

export default RegisterAccountActivationScreen;
