import {cloneDeep} from "lodash";
import {EventParticipantsStateStateActionType, useEventParticipantsStateContext} from "./participantsProvider";
import {useParams} from "react-router-dom";
import {getGuests, getModerators, getParticipants} from "../../../logic/events/events";
import {EventParticipantFilters} from "../../../logic/events/events.model";

export const useEventParticipantsStateHook = () => {
    const [state, setState] = useEventParticipantsStateContext();
    const {organizationId, eventId} = useParams();

    const _getParticipants = async  (filters: EventParticipantFilters) => {
        const res = await getParticipants(organizationId!, eventId!, filters);

        if (res.data) {
            setState({
                type: EventParticipantsStateStateActionType.setParticipants,
                state: {
                    ...cloneDeep(state),
                    participants: res.data,
                }
            })
        }

        return res;
    }
    const _getGuests = async  () => {
        const res = await getGuests(organizationId!, eventId!);

        if (res.data) {
            setState({
                type: EventParticipantsStateStateActionType.setGuests,
                state: {
                    ...cloneDeep(state),
                    guests: res.data,
                }
            })
        }

        return res;
    }
    const _getModerators = async  () => {
        const res = await getModerators(organizationId!, eventId!);

        if (res.data) {
            setState({
                type: EventParticipantsStateStateActionType.setModerators,
                state: {
                    ...cloneDeep(state),
                    moderators: res.data,
                }
            })
        }

        return res;
    }


    const _clearParticipants = () => {
        setState({
            type: EventParticipantsStateStateActionType.setParticipants,
            state: {
                ...cloneDeep(state),
                participants: {
                    ...state.participants!,
                    participants: null,
                },
            }
        })
    }

    return {
        participantsState: state,
        getParticipants: _getParticipants,
        clearParticipants: _clearParticipants,
        getGuests: _getGuests,
        getModerators: _getModerators,
    }
}
