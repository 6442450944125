import styled from 'styled-components';

export const Textarea = styled.div`
  textarea {
    width: 100%;
    padding: 10px;
    max-width: 100%;
    min-width: 100%;
    border: 1px solid var(--disabledColor);
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
    outline: none;
  }
`
