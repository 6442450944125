import { Failure } from './failure';
import i18next from 'i18next';
const getLanguage = () => i18next.language || window.localStorage.i18nextLng

export class Canceled extends Failure {
  public static getCode: string = 'Canceled';
  constructor() {
    super(Canceled.getCode, '');
  }
}
export class Unexpected extends Failure {
  public static getCode: string = 'Unexpected';
  constructor(public msg: string | undefined = undefined) {
    let _message: string;
    if (!msg) {
      _message = (i18next.getDataByLanguage(getLanguage()) as any).common.errors?.Unexpected;
    } else {
      _message = msg;
    }

    super(Unexpected.getCode, _message);
  }
}

export class Violations extends Failure {
  public static getCode: string = 'Violations';
  constructor(public violations: {field: string, message: string}[]) {
    const _message: string = (i18next.getDataByLanguage(getLanguage()) as any).common.errors?.Violations;


    super(Violations.getCode, _message);
  }

  public getValidations = () => {
    const validations: {[key: string]: string} = {};

    this.violations.forEach((v) => {
      validations[v.field] = v.message;
    })

    return validations;
  }
}

export class NotFound extends Failure {
  public static getCode: string = 'NotFound';
  constructor(public msg: string | undefined = undefined) {
    let _message: string;
    if (!msg) {
      _message = (i18next.getDataByLanguage(getLanguage()) as any).common.errors?.NotFound;
    } else {
      _message = msg;
    }
    super(NotFound.getCode, _message);
  }
}

export class NoInternetConnection extends Failure {
  public static getCode: string = 'NoInternetConnection';
  constructor(public msg: string  | undefined = undefined) {
    let _message: string;
    if (!msg) {
      _message = (i18next.getDataByLanguage(getLanguage()) as any).common.errors?.NotFound;
    } else {
      _message = msg;
    }
    super(NoInternetConnection.getCode, _message);
  }
}

export class AuthError extends Failure {
  public static getCode: string = 'AuthError';
  constructor(public msg: string  | undefined = undefined) {
    let _message: string;
    if (!msg) {
      _message = (i18next.getDataByLanguage(getLanguage()) as any).common.errors?.AuthError;
    } else {
      _message = msg;
    }
    super(AuthError.getCode, _message);
  }
}
