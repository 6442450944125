import React, { useEffect, useState } from 'react';
import { Wrapper } from './EventMembersModal.styles';
import { useParams } from 'react-router-dom';
import {editMembers, getMembers, useEvent} from '../../../../logic/events/events';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import FormField from '../../../../components/FormField';
import Input from '../../../../components/Input';
import iconSearch from '../../../../assets/icon-search.svg';
import { EventMember, MemberAction, MemberModifyAction } from '../../../../logic/events/events.model';
import Loader from '../../../../components/Loader';
import iconCompany from '../../../../assets/icon-company.svg';
import iconPerson from '../../../../assets/icon-person.svg';
import { BodyMemberType } from '../../../../logic/bodies/bodies.model';
import { Table } from '../../../../components/Table/table';
import OrganizationBodyAddMemberModal
    from "../../../OrganizationScreen/OrganizationBodiesScreen/OrganizationBodyDetails/OrganizationBodiesDetailsMembers/OrganizationBodyAddMemberModal";
import Modal from "../../../../components/Modal";


const EventMembersModal = ({onClose}: { onClose: (refresh: boolean) => void }) => {
    const {organizationId, eventId} = useParams();
    const {event} = useEvent();
    const {t, setError, renderError, loading, setLoading} = useBaseScreenHook();
    const [members, setMembers] = useState<EventMember[] | null>(null);
    const [filteredMembers, setFilteredMembers] = useState<EventMember[] | null>(null);
    const [membersToEdit, setMembersToEdit] = useState<Map<string, MemberAction>>(new Map());
    const [addMemberModal, setAddMemberModal] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    useEffect(() => {
        _getMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {

        setFilteredMembers(members?.filter((m) => m.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const _getMembers = async () => {
        const res = await getMembers(organizationId!, eventId!);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }

        setMembers(res.data!);
        setFilteredMembers(res.data!);
    }

    const _save = async () => {

        const body: MemberModifyAction[] = Array.from(membersToEdit).map((values) => {
            return {
                id: values[0],
                action: values[1]
            }
        });
        setLoading(true);
        const res = await editMembers(organizationId!, eventId!, body);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }


        onClose(true);
    }

    const _add = (m: EventMember) => {

        if (membersToEdit.has(m.id)) {
            membersToEdit.delete(m.id);
        } else {
            membersToEdit.set(m.id, MemberAction.Add)
        }
        setMembersToEdit(new Map(membersToEdit));
    }

    const _remove = (m: EventMember) => {
        if (membersToEdit.has(m.id)) {
            membersToEdit.delete(m.id);
        } else {
            membersToEdit.set(m.id, MemberAction.Delete)
        }
        setMembersToEdit(new Map(membersToEdit));
    }

    const _onCloseModal = (refresh: boolean) => {
        setAddMemberModal(false);
        if (refresh) {
            _getMembers();
        }
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>
        <div className="title">
            {t('eventMembersModal.title')}
        </div>
        <p className="description">
            {t('eventMembersModal.description')}
        </p>

        <div className="search">
            <FormField>
                <Input>
                    <input type="text" value={query} onChange={(e) => setQuery(e.target.value)}
                           placeholder={t('eventMembersModal.searchPlaceholder')}/>
                    <img src={iconSearch} alt="search" className={'icon'}/>
                </Input>
            </FormField>
        </div>

        <div className="list">
            {
                filteredMembers
                    ? <Table className={'members'}>
                        <tbody>
                        {
                            filteredMembers.map((m) => {
                                const onGoingAction: MemberAction | undefined = membersToEdit.get(m.id);
                                return <tr key={m.id}
                                           className={(m.added && !onGoingAction) || onGoingAction === MemberAction.Add ? 'added' : ''}>

                                    <td className={'type'}>
                                        {
                                            m.type === BodyMemberType.organizationMember
                                                ? <img src={iconCompany} alt=""/>
                                                : <img src={iconPerson} alt=""/>
                                        }
                                    </td>
                                    <td className={'name'}>
                                        {m.name}
                                        {
                                            (m.added && !onGoingAction) || onGoingAction === MemberAction.Add ?
                                                <div className="added-label">
                                                    {t('eventMembersModal.addedLabel')}
                                                </div>
                                                : null
                                        }

                                    </td>
                                    <td className={'action'}>
                                        {
                                            (m.added && !onGoingAction) || onGoingAction === MemberAction.Add
                                                ?
                                                <button className={'button warn outline'} onClick={() => _remove(m)}>
                                                    {t('eventMembersModal.remove')}
                                                </button>
                                                :
                                                <button className={'button outline'} onClick={() => _add(m)}>
                                                    {t('eventMembersModal.add')}
                                                </button>
                                        }
                                    </td>

                                </tr>
                            })
                        }


                        </tbody>
                        {
                            !filteredMembers.length ? <tfoot><tr><td colSpan={3}>Brak wyników</td></tr></tfoot> : null
                        }
                    </Table>
                    : <Loader/>

            }



        </div>
        <div className="add-body-member">
            <p>
                {t('eventMembersModal.addBodyMember')}
            </p>

            <button className="button text" onClick={() => setAddMemberModal(true)} disabled={!event?.content?.basic.body.id}>
                {t('eventMembersModal.addBodyMemberBtn')}
            </button>
        </div>
        <div className="buttons">

            <button className={'button text muted'} onClick={() => onClose(false)} disabled={loading}>
                {t('eventMembersModal.cancel')}
            </button>
            <button className={'button'} onClick={_save} disabled={loading}>
                {t('eventMembersModal.save')}
            </button>

        </div>

        <Modal width={700} onClose={() => _onCloseModal(false)} show={addMemberModal} backDropClose={false}>
            <OrganizationBodyAddMemberModal memberToEdit={null}
                                            contextBodyId={event?.content?.basic.body.id}
                                            onClose={(refresh) => _onCloseModal(refresh)}/>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default EventMembersModal;
