import styled from 'styled-components';

export const Wrapper = styled.div`

    .title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 30px;
      .passed {
        color: var(--successColor);
      }
      .not-passed {
        color: var(--errorColor);
      }
    }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
`