import React, {ReactNode, useEffect, useState} from 'react';
import { ContextMenuOverlay, Wrapper } from './ContextMenu.styles';
import { CSSTransition } from 'react-transition-group';
const ContextMenu = ({icon, element, children}: { icon?: string, element?: ReactNode, children: ReactNode[] }) => {
    const [state, setState] = useState<boolean>(false);
    const [reversePosition, setReversePositionState] = useState<boolean>(false);
    const nodeRef = React.useRef(null);

    useEffect(() => {
        if (state) {
            const el: HTMLElement | null = document.getElementById('context-menu-overlay-element');
            if (el && !reversePosition) {
                const top = el.getBoundingClientRect().top;
                const height = el.getBoundingClientRect().height;
                const isInTheView = (top) >= 0 && (top + height) <= window.innerHeight;
                setReversePositionState(!isInTheView)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    return <Wrapper className="context-menu">
        { children.filter(Boolean).length
            ?<div className={element ? '' : 'icon'} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setState(!state);

            }}>
                {element ? element : <img src={icon} alt="icon"/>}
            </div>
            : null}


        <CSSTransition
            in={state}
            unmountOnExit
            timeout={{enter: 0, exit: 100}}
            nodeRef={nodeRef}
        >
            <ContextMenuOverlay>
                <div className="backdrop" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setState(false);
                }}/>
                <div className={`overlay ${reversePosition ? 'reverse' : ''}`} id="context-menu-overlay-element">
                    {
                        children.map((el, index) => {
                            return <div key={index} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setState(false);
                            }}>{el}</div>;
                        })
                    }
                </div>
            </ContextMenuOverlay>
        </CSSTransition>


    </Wrapper>;
}

export default ContextMenu;
