import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  .document-tabs {
      padding: 0;
      height: 40px;
      display: flex;
      gap: 20px;
      align-items: stretch;
      margin-bottom: 30px;
      overflow: auto;
      text-transform: uppercase;
      .tab {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1.3rem;
        padding: 0 10px;
        text-align: center;
        text-decoration: none;
        color: var(--fontColor);

        &.active,
        &:hover {
          border-bottom: 3px solid var(--mainColor);
          border-top: 3px solid transparent;
        }
      }
  }


`