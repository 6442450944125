import { Languages } from '../core/models';
import i18next from 'i18next';

export function getLanguage(): Languages {

   const browserLang: string = window.navigator.language.split('-')[0].toUpperCase();
   const savedLang: Languages | null = localStorage.getItem('LANGUAGE_CODE') as Languages;

   if (savedLang && Object.values(Languages).includes(savedLang)) {
       return savedLang;
   } else if (browserLang && Object.values(Languages).includes(savedLang)) {
       return browserLang as Languages;
   }

    return Languages.PL;
}


export function changeLanguage(lang: Languages): void {

    localStorage.setItem('LANGUAGE_CODE', lang);
    i18next.changeLanguage(lang);
    window.location.reload();
}
