import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 20px;
  
  .form-data {
    width: 500px;
    max-width: 100%;
    margin-bottom: 30px;
    .row {
      display: flex;
      gap: 20px;

      & > * {
        flex: 1;
      }


      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
  
  .buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    .button {
      width: 500px;
      max-width: 100%;
    }
    
  }

  .invitation-for {
    margin-bottom: 50px;
    .label {
      color: var(--fontMutedColor);
      margin-bottom: 5px;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    .name {
      color: var(--fontColor);
      font-weight: 600;
    }
  }
  
  .data-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }

`