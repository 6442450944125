import styled from 'styled-components';

export const Wrapper = styled.div`
    .title {
        font-size: 25px;
        font-weight: 500;
        line-height: 30px; 
        margin-bottom: 20px;
        text-align: left;
    }
    .description {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }
    .close-modal {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-decoration-line: underline;
        cursor: pointer;
    }
`
