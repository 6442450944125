import React from "react";
import {Wrapper} from "./EventStartCountdown.styles";
import {useBaseScreenHook} from "../../logic/core/base-screen.hook";
import {EventTimerData, MeetingStatus} from "../../logic/events/events.model";
import {useEventTimer} from "../../logic/events/event-timer";

const EventStartCountdown = ({status, eventId}: {status: MeetingStatus | null, eventId?: string}) => {
    const {t} = useBaseScreenHook();
    const eventTimer: EventTimerData = useEventTimer({eventIdd: eventId});
    if (eventTimer.canStart || status !== MeetingStatus.Draft) {
        return <></>;
    }
    const {days, hours, minutes, seconds} = eventTimer.startIn;
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        return <></>;
    }
    return <Wrapper className={'event-start-countdown'}>
       
       <div className="event-start-counter">
                    {t('eventGuestInvitationScreen.eventStartIn')} {`${days === 0 ? '' : `${days} d `}${days === 0 && hours === 0 ? '' : `${hours} g `}${days === 0 && hours === 0 && minutes === 0 ? '' : `${minutes} min `}${days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? '' : `${seconds} sek `}`}
                </div>
    </Wrapper>
}


export default EventStartCountdown;
