import styled from 'styled-components';

export const Wrapper = styled.div`

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .set,
  .unset {
    text-align: left;
    color: var(--mainColor);
    margin-bottom: 2px;
  }
  .unset {
    color: var(--errorColor);
  }

  .member {
    text-align: left;
    margin-bottom: 20px;
  }

  .hint {
    text-align: left;
    color: var(--fontMutedColor);
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  
  .custom-message {
    text-align: left;
    margin-bottom: 20px;
  }
`