import styled from 'styled-components';

export const Wrapper = styled.div`
  
  &.disabled {
    border-color: transparent;
  }
  label {
    position: relative;
    text-align: left;
    display: block;
    .label {
      font-size: 1.2rem;
      color: var(--fontColor);
      text-align: left;
      margin-bottom: 3px;
      display: block;
    }
    .value {
      display: flex;
      border: 1px solid var(--disabledColor);
      border-radius: 5px;
      overflow: hidden;
      height: 40px;
      .suffix {
        font-size: 14px;
        margin-left: 3px;
        display: flex;
        align-items: center;
        
      }
      input {
        padding: 10px;
        outline: none;
        width: 100%;
        border: none;
        appearance: none;
        font-size: 1.4rem;
        line-height: 2rem;
        font-family: var(--fontPrimary);
        &:disabled {
          background: var(--bgLighColor);
          cursor: not-allowed;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type=number] {
          -moz-appearance: textfield;
        }
      }

    }
  }
`
