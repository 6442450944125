import React, {useEffect, useState} from "react";
import {Wrapper} from "./InvitationsTable.styles";
import Loader from "../Loader";
import FormField from "../FormField";
import Select from "../Select";
import {Table} from "../Table/table";
import {EventParticipantInvitation} from "../../logic/events/events.model";
import {useBaseScreenHook} from "../../logic/core/base-screen.hook";
import iconDown from '../../assets/icon-arrow-down.svg'

const InvitationsTable = ({data}: { data: EventParticipantInvitation[] | null }) => {
    const {i18n} = useBaseScreenHook();

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const [searchOrganizationId, setSearchOrganizationId] = useState<string | null>(null);
    const [filteredEvents, setFilteredEvents] = useState<EventParticipantInvitation[] | null>(null);
    const [organizations, setOrganizations] = useState<{id: string, name: string}[]>([]);
    const [expandedEvents, setExpandedEvents] = useState<string[]>([]);

    useEffect(() => {

        let events: EventParticipantInvitation[] | null = data;

        if (searchOrganizationId) {
            events = data?.filter((e) => e.event.organizationId === searchOrganizationId) ?? null;
        }
        setFilteredEvents(events);
        data?.forEach(e => {
            if (!organizations.some(o => o.id === e.event.organizationId)) {
                organizations.push({
                    id: e.event.organizationId,
                    name: e.event.organizationName,
                })
            }

        });
        setOrganizations(organizations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, searchOrganizationId])

    const _goToInvitation = (event: EventParticipantInvitation) => {
        localStorage.setItem('event-source-location', window.location.pathname + window.location.search);
        window.open(`${event.invitationUrl}`, '_blank', 'noreferrer');
    }

    const _expand = (e: React.MouseEvent<HTMLDivElement>, event: EventParticipantInvitation) => {
        e.stopPropagation();

        const index: number = expandedEvents.indexOf(event.event.id)
        if (index > -1) {
            expandedEvents.splice(index, 1);
        } else {
            expandedEvents.push(event.event.id);
        }

        setExpandedEvents([...expandedEvents]);
    }

    return <Wrapper>
        {filteredEvents === null ? <Loader/> : null}
        {
            filteredEvents?.length
                ? <>
                    <div className="search">
                        <FormField>
                            <Select label={'Organizator wydarzenia'}
                                    currentValue={searchOrganizationId}
                                    onChange={setSearchOrganizationId}
                            >
                                <option/>
                                {Array.from(organizations)?.map((p) => {
                                    return <option value={p.id}
                                                   key={'opt-' + p.id}>{p.name}</option>
                                })}
                            </Select>
                        </FormField>
                    </div>

                    <Table>
                        <thead>
                        <tr>
                            <th>
                                Data
                            </th>
                            <th>
                                Nazwa
                            </th>
                            <th>
                                Organizator
                            </th>
                            <th>
                                Zaproszenie dla
                            </th>
                            <th>
                                Uczestnik
                            </th>
                            <th></th>
                        </tr>
                        </thead>

                        {
                            filteredEvents.map((event) => {
                                return <tbody key={event.event.id + '-' + event.participant.id}>
                                    <tr  className={'pointer'}
                                        onClick={() => _goToInvitation(event)}>

                                        <td>
                                            {dateFormatter(new Date(event.event.startDate))}
                                        </td>

                                        <td>
                                            {event.event.name}
                                        </td>

                                        <td>
                                            {event.event.organizationName}
                                        </td>

                                        <td>
                                            {event.participant.bodyMemberName}
                                        </td>

                                        <td>
                                            {event.participant.firstName} {event.participant.lastName}
                                        </td>
                                        <td>
                                            <div className={`expand ${expandedEvents.includes(event.event.id) ? 'expanded' : ''}`}>
                                                <div className="icon" onClick={(e) => _expand(e, event)}>
                                                    <img src={iconDown} alt="icon"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        expandedEvents.includes(event.event.id)
                                            ? <tr key={`details-${event.event.id}`} className={'exclude'}>
                                                <td colSpan={6}>

                                                    <div className="details">
                                                        <div className="row">
                                                            <div className="label">
                                                                Organizator
                                                            </div>

                                                            <div className="value">
                                                                {event.event.organizationName}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="label">
                                                                Dla organu
                                                            </div>

                                                            <div className="value">
                                                                {event.event.bodyName}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="label">
                                                                Odbiorca
                                                            </div>

                                                            <div className="value">
                                                                {event.participant.bodyMemberName}
                                                            </div>
                                                        </div>



                                                        <div className="row">
                                                            <div className="label">
                                                                Uczestnik
                                                            </div>

                                                            <div className="value">
                                                                {event.participant.firstName} {event.participant.lastName}
                                                            </div>
                                                        </div>


                                                    </div>

                                                </td>
                                            </tr>
                                            : null
                                    }

                                </tbody>
                            })
                        }
                    </Table>
                </>
                :
                <div className="empty-state">
                    Brak wydarzeń
                </div>
        }
    </Wrapper>
}

export default InvitationsTable;