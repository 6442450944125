import styled from 'styled-components';

export const Wrapper = styled.div`

    .title {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 60px;
    }

    .plans {
        border-bottom: 1px solid #363636;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .plan {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            padding: 0 10px;
            gap: 20px;
            .name {
                text-transform: capitalize;
            }
            .price {
                margin-left: auto;
                margin-right: 0;
            }
            .quantity {
                display: flex;
                align-items: center;
                gap: 4px;
                .change-quantity {
                    border: 1px solid var(--disabledColor);
                    border-radius: 2px;
                    .add {
                        border-bottom: 1px solid var(--disabledColor);
                        
                    }
                    .remove,
                    .add {
                        box-sizing: border-box;
                        color: var(--disabledColor);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                        font-size: 14px;
                        &:hover {
                            color: var(--fontColor);
                        }
                    }
                    
                }
            }
        }
    }
    
    .prices {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
    .button {
        width: 100%;
        height: 50px;
        padding: 0 50px;
        &:first-of-type {
            margin-top: 34px;
            margin-bottom: 10px;
        }
        &:last-of-type {
            margin-bottom: 34px;
        }
    }
    
    .info {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
    }

`
