import React, {useEffect, useState} from "react";

import {Wrapper} from "./SendAllInvitationModal.styles";
import {getOrganizationEventParticipantInvitations, sendAllInvitations} from "../../../../../logic/events/events";
import {useParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {EventInvitationStatus, EventModel, EventParticipant} from "../../../../../logic/events/events.model";
import {Organization} from "../../../../../logic/organizations/organizations.model";
import Wysiwyg from "../../../../../components/Wysiwyg";
import {useForm} from "../../../../../logic/core/form.hook";
import Switch from "../../../../../components/Switch";
import FormField from "../../../../../components/FormField";
import {Violations} from "../../../../../logic/core/failures";
import Input from "../../../../../components/Input";
import Loader from "../../../../../components/Loader";

const SendAllInvitationModal = ({
                                    onClose,
                                    event,
                                    organization,
                                }: { onClose: (res: EventInvitationStatus[]) => void, event: EventModel, organization: Organization }) => {
    const {organizationId, eventId} = useParams();
    const {t, renderError, setError, loading, setLoading} = useBaseScreenHook();
    const [invitations, setInvitations] = useState<EventParticipant[] | null>(null);
    const {formControl, renderValidationMessage, formValue, formValid, setValidations} = useForm<{ message: string,conferenceCallLink: string, messageEnabled: boolean }>({
        message: {
            value: ''
        },
        conferenceCallLink: {
            value: ''
        },
        messageEnabled: {
            value: false,
        }
    })

    useEffect(() => {
        _getInvitations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getInvitations = async () => {
        const res = await getOrganizationEventParticipantInvitations(organizationId!, eventId!);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setInvitations(res.data!);
    }
    const _onChange = (value: string) => {
        formControl('message').patchValue(value);
    }
    const _sendAllInvitations = async () => {
        if (!formValid()) {
            return;
        }
        const message: string | null = formValue.messageEnabled ? formValue.message : null;
        const conferenceCallLink: string | null = formValue.conferenceCallLink || null;
        setLoading(true);
        const res = await sendAllInvitations(organizationId!, eventId!, message, conferenceCallLink);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        // setToastState({
        //     type: ModalType.success,
        //     message: t('eventParticipants.invitationSent')
        // });


        onClose(res.data!);
    }

    return <Wrapper>
        <div className="event-name">
            {event.content?.basic.name}
        </div>
        <div className="title">
            {t('sendAllInvitationsModal.title')}
        </div>

        <div className="description">
            {t('sendAllInvitationsModal.description')}
        </div>

        <FormField>
            <Switch label={t('sendAllInvitationsModal.messageEnabled')}>
                <input type="checkbox" checked={formControl('messageEnabled').value}
                       onChange={() => formControl('messageEnabled').patchValue(!formControl('messageEnabled').value)}/>
            </Switch>
        </FormField>
        {
            formControl('messageEnabled').value
                ? <div className="message">
                    <Wysiwyg content={''} onChange={_onChange}/>
                    {renderValidationMessage('message')}
                </div>
                : null

        }

        <FormField>
            <Input label={t('sendAllInvitationsModal.conferenceCallLink')}>
                <input type="text"
                       value={formControl('conferenceCallLink').value}
                       onChange={(e) => formControl('conferenceCallLink').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('conferenceCallLink')}
        </FormField>

        <div className="organization">
            <div className="name">{organization.name}</div>
            <div className="nip">
                NIP: {organization.nip ?? '-'}
            </div>
        </div>

        <div className="list">
            <div className="title">
                {t('sendAllInvitationsModal.receivers')} ({invitations?.length})
            </div>
            <table>
                <tbody>
                {
                    invitations?.map((i) => {
                        return <tr key={i.id}>
                            <td>
                                {i.bodyMemberName}
                            </td>
                            <td>
                                {i.invitationEmail}
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>

        </div>

        <div className="buttons">
            <button className="button muted text">
                {t('sendAllInvitationsModal.cancel')}
            </button>

            <button className="button" disabled={loading} onClick={_sendAllInvitations}>
                {
                    loading ? <Loader/> : t('sendAllInvitationsModal.send')}
            </button>
        </div>
        {renderError()}
    </Wrapper>
}

export default SendAllInvitationModal;

