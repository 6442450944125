import { DataObject } from '../core/dataObject';
import { Failure } from '../core/failure';
import HttpClient from '../httpClient/httpClient';
import { handleError } from '../core/handleError';
import { AxiosError } from 'axios';
import { Body, BodyMember, BodyMemberFilters, CreateBody } from './bodies.model';


export async function getBodies(organizationId: string): Promise<DataObject<Failure, Body[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/bodies`);
        return new DataObject<Failure, Body[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function createBody(organizationId: string, createBody: CreateBody): Promise<DataObject<Failure, string | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${organizationId}/bodies`, createBody);
        return new DataObject<Failure, string>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function updateBody(organizationId: string,bodyId: string, createBody: CreateBody): Promise<DataObject<Failure, string | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/bodies/${bodyId}`, createBody);
        return new DataObject<Failure, string>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getBody(organizationId: string, bodyId: string): Promise<DataObject<Failure, Body | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/bodies/${bodyId}`);
        return new DataObject<Failure, Body>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function deleteBody(organizationId: string,bodyId: string): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.delete(`/organizations/${organizationId}/bodies/${bodyId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function getBodyMembers(bodyId: string, filters?: BodyMemberFilters, abortController?: AbortController): Promise<DataObject<Failure, BodyMember[] | null>> {
    try {
        const res = await HttpClient.get<BodyMember[]>(`/organizations/bodies/${bodyId}/members`, {
            params: filters,
            signal: abortController?.signal,
        });
        return new DataObject<Failure, BodyMember[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function createBodyMember(bodyId: string, member: BodyMember): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/bodies/${bodyId}/members`, member);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function updateBodyMember(bodyId: string, memberId: string, member: BodyMember): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/bodies/${bodyId}/members/${memberId}`, member);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getBodyMember(bodyId: string, memberId: string): Promise<DataObject<Failure, BodyMember | null>> {
    try {
        const res = await HttpClient.get(`/organizations/bodies/${bodyId}/members/${memberId}`);
        return new DataObject<Failure, BodyMember>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function deleteBodyMember(bodyId: string, memberId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/bodies/${bodyId}/members/${memberId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

