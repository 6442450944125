import React, {useEffect, useState} from 'react';
import {Wrapper} from './OrganizationBodiesDetailsMembers.styles';
import iconAdd from "../../../../../assets/icon-plus-white.svg"
import iconMenu from "../../../../../assets/icon-menu.svg"
import {Table} from '../../../../../components/Table/table';
import ContextMenu from '../../../../../components/ContextMenu';
import ContextMenuItem from '../../../../../components/ContextMenu/ContextMenuItem';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import OrganizationBodyAddMemberModal from './OrganizationBodyAddMemberModal';
import {useBaseScreenHook} from '../../../../../logic/core/base-screen.hook';
import {useParams} from 'react-router-dom';
import {deleteBodyMember, getBody, getBodyMembers} from '../../../../../logic/bodies/bodies';
import {Body, BodyMember, BodyMemberFilters, BodyMemberType} from '../../../../../logic/bodies/bodies.model';
import Loader from '../../../../../components/Loader';
import iconCompany from '../../../../../assets/icon-company.svg'
import iconPerson from '../../../../../assets/icon-person.svg'
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import {Canceled} from '../../../../../logic/core/failures';
import OrganizationBodyPreviewMemberModal from './OrganizationBodyPreviewMemberModal';
import {LegalFormBodyType} from "../../../../../logic/organizations/organizations.model";

let inputDebouncer: ReturnType<typeof setTimeout> | null = null;
let abortController: AbortController | null = null;

const OrganizationBodiesDetailsMembers = () => {
    const {t, i18n, setError, renderError} = useBaseScreenHook();
    const [addMemberModal, setAddMemberModal] = useState<boolean>(false);
    const [members, setMembers] = useState<BodyMember[] | null>(null);
    const [memberToDelete, setMemberToDelete] = useState<BodyMember | null>(null);
    const [memberToEdit, setMemberToEdit] = useState<BodyMember | null>(null);
    const [memberToPreview, setMemberToPreview] = useState<BodyMember | null>(null);
    const [filters, setFilters] = useState<BodyMemberFilters>({});
    const [body, setBody] = useState<Body | null>(null);
    const {organizationId, bodyId} = useParams();

    useEffect(() => {
        if (!organizationId || !bodyId) {
            return;
        }
        getBody(organizationId!, bodyId!).then((res) => {
            if (res.isFailure()) {
                return;
            }
            setBody(res.data!);
        })

    }, [bodyId, organizationId]);
    const cleatInputDebouncer = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
        if (abortController) {
            abortController.abort();
            abortController = null;
        }
    }

    useEffect(() => {
        setMembers(null);
        cleatInputDebouncer();
        inputDebouncer = setTimeout(() => {
            _getBodyMembers();
        }, 300);

        return () => {
            cleatInputDebouncer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const _getBodyMembers = () => {
        abortController = new AbortController();
        getBodyMembers(bodyId!, filters, abortController).then((res) => {
            if (res.isFailure()) {
                if (res.failure?.is(Canceled.getCode)) {
                    return;
                }
                setError(res.failure!);
                return
            }

            setMembers(res.data!);
        });
    }

    const _deleteBody = () => {
        deleteBodyMember(bodyId!, memberToDelete!.id!).then((res) => {
            setMemberToDelete(null);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            _getBodyMembers();
        })
    }
    const _onCloseModal = (refresh: boolean) => {
        setAddMemberModal(false);
        setMemberToEdit(null);
        if (refresh) {
            _getBodyMembers();
        }
    }

    const _onClosePreviewModal = (refresh: boolean, memberToEdit?: BodyMember | null) => {
        setMemberToPreview(null);
        if (memberToEdit) {
            setMemberToEdit(memberToEdit);
            setAddMemberModal(true);
        }
        if (refresh) {
            _getBodyMembers();
        }
    }


    return <Wrapper>

        <div className="descriptions">
            {t('organizationBodiesDetailsMembers.description')}
        </div>

        <div className="data">

            <div className="box">
                <div className="label">
                    {t('organizationBodiesDetailsMembers.membersCount')}
                </div>
                <div className="value">
                    {members ? members.length : <Loader className="data-loader"/>}
                </div>
            </div>
            <div className="box">
                <div className="label">
                    {t('organizationBodiesDetailsMembers.votesCount')}
                </div>
                <div className="value">

                    {members ? new Intl.NumberFormat(i18n.language,).format(members!.reduce((acc, curr) => {
                        return acc + curr.votesNumber!;
                    }, 0)) : <Loader className="data-loader"/>}

                </div>
            </div>

            <button className="button icon" onClick={() => setAddMemberModal(true)}>
                <img src={iconAdd} alt="icon"/>
                {t('organizationBodiesDetailsMembers.addMemberBtn')}
            </button>

        </div>

        <div className="table-wrapper">

            <Table>
                <thead>
                <tr>
                    <th>
                        {t('organizationBodiesDetailsMembers.memberName')}
                        <div className="filter-input">
                            <Input>
                                <input type="text" value={filters.name ?? ''} onChange={(e) => setFilters({
                                    ...filters,
                                    name: e.target.value
                                })}/>
                            </Input>
                        </div>

                    </th>
                    {
                        body?.type === LegalFormBodyType.Capital
                            ? <th>
                                {t('organizationBodiesDetailsMembers.share')}
                            </th>
                            : null
                    }
                    <th>
                        {t('organizationBodiesDetailsMembers.votes')}
                    </th>
                    <th>
                        {t('organizationBodiesDetailsMembers.invitationEmail')}
                        <div className="filter-input">
                            <Input>
                                <input type="text" value={filters.email ?? ''} onChange={(e) => setFilters({
                                    ...filters,
                                    email: e.target.value
                                })}/>
                            </Input>
                        </div>
                    </th>
                    <th>
                        {t('organizationBodiesDetailsMembers.recipientName')}
                        <div className="filter-input">
                            <Input>
                                <input type="text" value={filters.addresseeName ?? ''} onChange={(e) => setFilters({
                                    ...filters,
                                    addresseeName: e.target.value
                                })}/>
                            </Input>
                        </div>
                    </th>
                    <th>

                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    members?.map((m) => {
                        return <tr key={m.id!} className="pointer" onClick={() => setMemberToPreview(m)}>
                            <td>
                                <div className="body-name">
                                    <img src={m.type === BodyMemberType.organizationMember ? iconCompany : iconPerson}
                                         alt="icon"/>
                                    {m.type === BodyMemberType.organizationMember ? m.organizationName : `${m.firstName} ${m.lastName}`}
                                </div>

                            </td>
                            {
                                body?.type === LegalFormBodyType.Capital
                                    ? <td>
                                        {new Intl.NumberFormat(i18n.language, {minimumFractionDigits: 2,  maximumFractionDigits: 2}).format(m.sharePercentage!)}%
                                    </td>
                                    : null
                            }

                            <td>
                                {new Intl.NumberFormat(i18n.language,).format(m.votesNumber!)}
                            </td>
                            <td>
                                {m.email}
                            </td>
                            <td>
                                {m.addressee?.firstName} {m.addressee?.lastName}
                            </td>
                            <td>
                                <div className="menu">
                                    <ContextMenu icon={iconMenu}>
                                        <ContextMenuItem onClick={() => {
                                            setMemberToEdit(m);
                                            setAddMemberModal(true);
                                        }}>
                                            {t('organizationBodiesDetailsMembers.edit')}
                                        </ContextMenuItem>
                                        <ContextMenuItem onClick={() => {
                                            setMemberToPreview(m);
                                        }}>
                                            {t('organizationBodiesDetailsMembers.preview')}
                                        </ContextMenuItem>
                                        <ContextMenuItem onClick={() => setMemberToDelete(m)}>
                                            <span className="remove-btn">
                                            {t('organizationBodiesDetailsMembers.delete')}
                                            </span>
                                        </ContextMenuItem>
                                    </ContextMenu>
                                </div>
                            </td>
                        </tr>

                    })
                }
                </tbody>
            </Table>
            {
                members ? null : <Loader/>
            }

        </div>

        <Modal width={700} onClose={() => _onCloseModal(false)} show={addMemberModal} backDropClose={false}>
            <OrganizationBodyAddMemberModal memberToEdit={memberToEdit}
                                            onClose={(refresh) => _onCloseModal(refresh)}/>
        </Modal>

        <Modal reset={true} onClose={() => setMemberToPreview(null)} show={!!memberToPreview} backDropClose={false}>
            <OrganizationBodyPreviewMemberModal memberData={memberToPreview}
                                                bodyId={bodyId!}
                                            onClose={(refresh, edit) => _onClosePreviewModal(refresh, edit)}/>
        </Modal>

        <Modal width={600} show={!!memberToDelete} onClose={() => setMemberToDelete(null)}>
            <ConfirmationModal
                reject={() => setMemberToDelete(null)}
                accept={() => _deleteBody()}
                title={`${t('organizationBodiesDetailsMembers.deleteConfirmation')} ${memberToDelete?.type === BodyMemberType.personMember ? (memberToDelete.firstName + ' ' + memberToDelete.lastName) : (memberToDelete?.organizationName)}?`}
                body={t('organizationBodiesDetailsMembers.deleteConfirmationBody')}
            />
        </Modal>

        {renderError()}

    </Wrapper>
}

export default OrganizationBodiesDetailsMembers;
