import {DataObject} from "../core/dataObject";
import {Failure} from "../core/failure";
import HttpClient from "../httpClient/httpClient";
import {handleError} from "../core/handleError";
import {AxiosError} from "axios";
import {Credit, CreditDefinition, CreditInvoiceData, CreditOrderBody, CreditsPaymentHistory} from "./credits.model";

export async function getCredits(organizationId: string): Promise<DataObject<Failure, Credit[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/credits`);
        return new DataObject<Failure, Credit[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getCreditsPaymentHistory(organizationId: string): Promise<DataObject<Failure, CreditsPaymentHistory[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/credits/orders`);
        return new DataObject<Failure, CreditsPaymentHistory[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getCreditsOffer(organizationId: string): Promise<DataObject<Failure, CreditDefinition[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/credits/offer`);
        return new DataObject<Failure, CreditDefinition[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getInvoiceData(organizationId: string): Promise<DataObject<Failure, CreditInvoiceData | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/credits/invoice-data`);
        return new DataObject<Failure, CreditInvoiceData>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function validateInvoiceData(organizationId: string, body: CreditOrderBody): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${organizationId}/credits/invoice-data/validation`, body);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function buyCredits(organizationId: string, body: CreditOrderBody): Promise<DataObject<Failure, string | null>> {
    try {
        const res = await HttpClient.post<{redirectUrl: string}>(`/organizations/${organizationId}/credits`, body);
        return new DataObject<Failure, string>(null, res.data.redirectUrl);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

