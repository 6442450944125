import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --fontColor: #363636;
    --mainColor: #334bfa;
    --mainButtonHoverColor: rgba(51, 75, 250, .8);
    --lightBlueColor: #ecf2fe;
    --fontMutedColor: #888888;
    --disabledColor: #D4D4D4;
    --bgLighColor: #faf8f9;
    --errorColor: red;
    --successColor: #07bc0c;
    --waringColor: #fdd078;
    --highlightColor: orange;
    --fontPrimary: 'Rubik', sans-serif;
    --fontAccent: 'Montserrat', sans-serif;
    color: var(--fontColor);
  }

  * {
    box-sizing: border-box;
    font-family: var(--fontPrimary);
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  strong {
    font-weight: bold;

    & * {
      font-weight: bold;
    }
  }

  html {
    font-size: 10px;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #root,
  body {
    font-size: 1.5rem;
    min-height: 100%;
    height: 100%;
    font-family: var(--fontPrimary);
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  button.button {
    font-family: var(--fontPrimary);
    outline: none;
    border-radius: 5px;
    border: none;
    height: 40px;
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    background: var(--mainColor);
    border-color: var(--mainColor);
    cursor: pointer;
    transition: background 0.8s;
    background-position: center;
    overflow: hidden;
    min-width: 180px;
    padding: 0 10px;

    &.text {
      --mainButtonHoverColor: rgba(51, 75, 250, .1);
      background: transparent center;
      color: var(--mainColor);
    }

    &.accent {
      background-color: #E8F0FE;
      color: #1875F0;
      --mainColor: #E8F0FE;
      --mainButtonHoverColor: rgba(232, 240, 254, 0.8);
    }


    &.outline {
      background: transparent center;
      border-width: 1px;
      border-style: solid;
      color: var(--mainColor);

      &:hover {
        color: #fff;
      }

      &.muted {
        border-color: var(--fontMutedColor);
      }
    }

    &.muted {
      color: var(--fontMutedColor);
      --mainButtonHoverColor: var(--disabledColor);

      &:hover {
        color: var(--fontMutedColor);
      }
    }

    &.icon {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
      min-width: auto;
    }

    &.warn {
      --mainColor: rgba(255, 0, 0, 1);
      --mainButtonHoverColor: rgba(255, 0, 0, .8);
    }

    &.success {
      --mainColor: var(--successColor);
      --mainButtonHoverColor: rgba(7, 188, 12, .8);
    }

    &:hover {
      background: var(--mainButtonHoverColor) radial-gradient(circle, transparent 1%, var(--mainButtonHoverColor) 1%) center/15000%;
    }

    &:active {
      background-color: var(--mainButtonHoverColor);
      background-size: 100%;
      transition: background 0s;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--disabledColor);;

      &:active,
      &:hover {
        background: var(--disabledColor);
      }
    }

  }

`;
