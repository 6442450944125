import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  button {
    width: 100%;
  }

  .row {
    display: flex;
    gap: 20px;

    & > * {
      flex: 1;
    }


    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    button {
      width: unset;
    }
  }`