import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      vertical-align: top;
      text-align: left;
      font-size: 1.2rem;
      color: var(--fontMutedColor);
      font-weight: bold;
      padding: 10px;
      white-space: nowrap;
    }
  }

  tbody {
    position: relative;

    td {
      //position: relative;
      padding: 10px;
      font-size: 1.4rem;
      z-index: 1;

      &.align-right {
        text-align: right;
      }
    }

    tr {
      &.pointer {
        cursor: pointer;
      }

      &:not(.exclude):nth-child(odd) {
        td {
          background: var(--bgLighColor);

          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          &:last-child {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }

  }
  

  
  &.multibody {
    tbody {
      tr {
        &:not(.exclude):nth-child(odd) {
          td {
            background: #fff;
          }
        }
      }
      
      &:nth-child(odd) {
        tr {
          &:not(.exclude) {
            td {
              background: var(--bgLighColor);

              &:first-child {
                border-radius: 8px 0 0 8px;
              }

              &:last-child {
                border-radius: 0 8px 8px 0;
              }
            }
          }
        }
      }
    }
  }
`
