import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  #input-file {
    display: none;
   
  }
  
  .document-list {
    min-height: 100%;

    .react-pdf__Document {
      display: flex;
      flex-direction: column;
    }

    .react-pdf__Page {
      max-width: 100%;
      min-width: unset !important;
      min-height: unset !important;
    }

    .react-pdf__Page canvas {
      max-width: 100%;
      height: auto !important;
    }
    
    .document {
      position: relative;
      .options {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 1;
        button {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          appearance: none;
          background: none;
          outline: none;
          border: 1px solid;
          border-radius: 5px;
          cursor: pointer;
          opacity: .2;
          transition: all .3s linear;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

`
