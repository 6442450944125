import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 340px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 20px;

  .description {
    font-size: 1.4rem;
    color: var(--fontMutedColor);
    margin-bottom: 30px;
  }
  button {
    width: 100%;
  }
`
