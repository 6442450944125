import React, { useEffect, useState } from 'react';
import { Wrapper } from './OrganizationBodyDetailsSettings.styles';
import OrganizationBodiesCreateModal from '../../OrganizationBodiesCreateModal';
import { Body } from '../../../../../logic/bodies/bodies.model';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../components/Loader';
import { getBody } from '../../../../../logic/bodies/bodies';

const OrganizationBodyDetailsSettings = () => {
    const [body, setBody] = useState<Body | null>(null);
    const {organizationId, bodyId} = useParams();

    useEffect(() => {
        if (!organizationId || !bodyId) {
            return;
        }
        getBody(organizationId!, bodyId!).then((res) => {
            if (res.isFailure()) {
                return;
            }
            setBody(res.data!);
        })

    }, [bodyId, organizationId]);
    return <Wrapper>
        {
            body
                ? <OrganizationBodiesCreateModal onClose={() => {
                }} bodyToEdit={body}/>

                : <Loader/>
        }
    </Wrapper>
}

export default OrganizationBodyDetailsSettings;
