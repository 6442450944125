import React from 'react';
import { Wrapper } from './EventDescription.styles';
import { ModalCloseFunction } from '../../../../components/Modal';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import { changeEventDescription } from '../../../../logic/events/events';
import { useParams } from 'react-router-dom';
import { useForm } from '../../../../logic/core/form.hook';
import { Violations } from '../../../../logic/core/failures';
import Wysiwyg from '../../../../components/Wysiwyg';

const EventDescriptionModal = ({onClose, description}: { description: string, onClose: ModalCloseFunction }) => {
    const {t, renderError, setError} = useBaseScreenHook()
    const {organizationId, eventId} = useParams();

    const {renderValidationMessage, setValidations, formControl, formValid, formValue} = useForm<{description: string}>({
        description: {
            value: ''
        }
    })


    const _save = async () => {
        formValid();

        const res = await changeEventDescription(organizationId!, eventId!, formValue.description);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose();
    }

    const _onChange = (value: string) => {
        formControl('description').patchValue(value);
    }

    return <Wrapper>
        <div className="title">
            {t('eventDescriptionModal.title')}
        </div>

        <div className='description'>

            <Wysiwyg content={description} onChange={_onChange}/>

            {renderValidationMessage('description')}

        </div>

        <div className="buttons">
            <button className={'button text muted'} onClick={onClose}>
                {t('eventDescriptionModal.cancel')}
            </button>
            <button className={'button'} onClick={_save}>
                {t('eventDescriptionModal.save')}
            </button>
        </div>

        {renderError()}
    </Wrapper>
}

export default EventDescriptionModal;
