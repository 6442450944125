import {DataObject} from '../core/dataObject';
import {Failure} from '../core/failure';
import HttpClient from '../httpClient/httpClient';
import {handleError} from '../core/handleError';
import {AxiosError} from 'axios';
import {
    AttendanceOption,
    EventBody,
    EventGuestBody,
    EventGuestInvitation, EventGuestResponse, EventInvitationStatus,
    EventList,
    EventMember,
    EventModel, EventModeratorsResponse, EventParticipant,
    EventParticipantFilters,
    EventParticipantInvitation, EventParticipantInvitationAcceptBody,
    EventParticipantResponse,
    MemberModifyAction, SpecialMarker, VoteParticipant,
    Voting,
    VotingBody,
    VotingChoice
} from './events.model'
import {SortableResponse} from '../core/sortableResponse.model';
import {useEffect, useState} from 'react';
import {Client, StompSubscription} from '@stomp/stompjs';
import {useParams, useSearchParams} from 'react-router-dom';
import {Organization} from '../organizations/organizations.model';
import {useAppStateHook} from "../core/appState.hook";
import {BodyMember} from "../bodies/bodies.model";

export async function createEvent(organizationId: string, body: EventBody): Promise<DataObject<Failure, string | null>> {
    try {
        const res = await HttpClient.post<{ id: string }>(`/organizations/${organizationId}/events/live`, body);
        return new DataObject<Failure, string>(null, res.data.id);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getEvents(organizationId: string, page: number = 0, size: number = 20): Promise<DataObject<Failure, SortableResponse<EventList> | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${organizationId}/events`, {
            params: {
                page,
                size,
            }
        });
        return new DataObject<Failure, SortableResponse<EventList>>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function changeEventDescription(organizationId: string, eventId: string, description: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}`, {
            description,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function addAgendaPoint(organizationId: string, eventId: string, title: string, description: string,position?: number): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${organizationId}/events/live/${eventId}/agenda`, {
            title,
            description,
        }, {
            params:{
                position,
            }
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function updateAgendaPoint(organizationId: string, eventId: string, itemId: string, title: string, description: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}`, {
            title,
            description,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function deleteAgendaPoint(organizationId: string, eventId: string, itemId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function changePositionAgendaPoint(organizationId: string, eventId: string, itemId: string, position: number): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}`, {
            position,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function changePositionVoting(organizationId: string, eventId: string, itemId: string, voteId: string, position: number): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/position`, {
            agendaItemId: itemId,
            position,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function moveVotingToOtherPoint(organizationId: string, eventId: string, sourceItemId: string, sourceVoteId: string, destinationPointId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${sourceItemId}/votes/${sourceVoteId}/position`, {
            agendaItemId: destinationPointId,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function startMeeting(organizationId: string, eventId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/meeting/start`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function endMeeting(organizationId: string, eventId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/meeting/finish`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function activateMeetingPoint(organizationId: string, eventId: string, itemId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/activate`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function activateVoting(organizationId: string, eventId: string, itemId: string, voteId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/activate`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function cancelVoting(organizationId: string, eventId: string, itemId: string, voteId: string, reason: string | null): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/cancel`, {
            reason,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function deleteVoting(organizationId: string, eventId: string, itemId: string, voteId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function editVoting(organizationId: string, eventId: string, itemId: string, voteId: string, body: VotingBody): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}`, body);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function finishVoting(organizationId: string, eventId: string, itemId: string, voteId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/finish`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function closeVoting(organizationId: string, eventId: string, itemId: string, voteId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/close`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function makeVote(organizationId: string, eventId: string, itemId: string, voteId: string, choice: VotingChoice): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/cast`, {
            choice,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function createVoting(organizationId: string, eventId: string, itemId: string, body: VotingBody): Promise<DataObject<Failure, string | null>> {
    try {
        const res = await HttpClient.post<{ id: string }>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes`, body);
        return new DataObject<Failure, string>(null, res.data.id);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getLastVotingConfiguration(): Promise<DataObject<Failure, Voting | null>> {
    try {
        const res = await HttpClient.get<Voting>(`/organizations/events/live/agenda/votes/last`);
        return new DataObject<Failure, Voting>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getAttendanceOptions(organizationId: string, eventId: string): Promise<DataObject<Failure, AttendanceOption[] | null>> {
    try {
        const res = await HttpClient.get<AttendanceOption[]>(`/organizations/${organizationId}/events/live/${eventId}/attendance-options`);
        return new DataObject<Failure, AttendanceOption[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getParticipants(organizationId: string, eventId: string, filters: EventParticipantFilters): Promise<DataObject<Failure, EventParticipantResponse | null>> {
    try {
        const res = await HttpClient.get<EventParticipantResponse>(`/organizations/${organizationId}/events/${eventId}/participants`, {
            params: filters,
        });
        return new DataObject<Failure, EventParticipantResponse>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function sendParticipantInvitation(organizationId: string, eventId: string, participantId: string): Promise<DataObject<Failure, EventInvitationStatus | null>> {
    try {
        const res = await HttpClient.put<EventInvitationStatus>(`/organizations/${organizationId}/events/${eventId}/participants/${participantId}/invitation`, {});
        return new DataObject<Failure, EventInvitationStatus>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function sendAllInvitations(organizationId: string, eventId: string, message: string | null, conferenceCallLink: string | null): Promise<DataObject<Failure, EventInvitationStatus[] | null>> {
    try {
        const res = await HttpClient.put<EventInvitationStatus[]>(`/organizations/${organizationId}/events/${eventId}/participants/invitation`, {message, conferenceCallLink});
        return new DataObject<Failure, EventInvitationStatus[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getParticipantInvitation(participantId: string): Promise<DataObject<Failure, EventParticipantInvitation | null>> {
    try {
        const res = await HttpClient.get<EventParticipantInvitation>(`/public/organizations/events/participants/${participantId}`, {});
        return new DataObject<Failure, EventParticipantInvitation>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function acceptParticipantInvitation(participantId: string, body: EventParticipantInvitationAcceptBody, token: string | null): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post<void>(`/public/organizations/events/participants/${participantId}`, body, {
            params: {
                token,
            }
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function getGuests(organizationId: string, eventId: string): Promise<DataObject<Failure, EventGuestResponse | null>> {
    try {
        const res = await HttpClient.get<EventGuestResponse>(`/organizations/${organizationId}/events/${eventId}/guests`,);
        return new DataObject<Failure, EventGuestResponse>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getModerators(organizationId: string, eventId: string): Promise<DataObject<Failure, EventModeratorsResponse | null>> {
    try {
        const res = await HttpClient.get<EventModeratorsResponse>(`/organizations/${organizationId}/events/${eventId}/moderators`,);
        return new DataObject<Failure, EventModeratorsResponse>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function sendGuestInvitation(organizationId: string, eventId: string, guestBody: EventGuestBody): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post<void>(`/organizations/${organizationId}/events/${eventId}/guests`, guestBody);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getGuestInvitation(guestId: string): Promise<DataObject<Failure, EventGuestInvitation | null>> {
    try {
        const res = await HttpClient.get<EventGuestInvitation>(`/public/organizations/events/guests/${guestId}`);
        return new DataObject<Failure, EventGuestInvitation>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function acceptGuestInvitation(guestId: string, phoneNumber: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post<void>(`/public/organizations/events/guests/${guestId}`, {
            phoneNumber,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function deleteGuest(organizationId: string, eventId: string, guestId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete<void>(`/organizations/${organizationId}/events/${eventId}/guests/${guestId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function deleteParticipant(organizationId: string, eventId: string, participantId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete<void>(`/organizations/${organizationId}/events/${eventId}/participants/${participantId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getParticipant(organizationId: string, eventId: string, participantId: string): Promise<DataObject<Failure, EventParticipant | null>> {
    try {
        const res = await HttpClient.get<EventParticipant>(`/organizations/${organizationId}/events/${eventId}/participants/${participantId}`);
        return new DataObject<Failure, EventParticipant>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function blockParticipant(organizationId: string, eventId: string, participantId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put<void>(`/organizations/${organizationId}/events/${eventId}/participants/${participantId}/block`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function unBlockParticipant(organizationId: string, eventId: string, participantId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put<void>(`/organizations/${organizationId}/events/${eventId}/participants/${participantId}/unblock`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function getMembers(organizationId: string, eventId: string): Promise<DataObject<Failure, EventMember[] | null>> {
    try {
        const res = await HttpClient.get<EventMember[]>(`/organizations/${organizationId}/events/${eventId}/members`);
        return new DataObject<Failure, EventMember[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getMember(organizationId: string, eventId: string, memberId: string): Promise<DataObject<Failure, BodyMember | null>> {
    try {
        const res = await HttpClient.get<BodyMember>(`/organizations/${organizationId}/events/${eventId}/members/${memberId}`);
        return new DataObject<Failure, BodyMember>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getVoteParticipants(organizationId: string, eventId: string, itemId: string, voteId: string): Promise<DataObject<Failure, VoteParticipant[] | null>> {
    try {
        const res = await HttpClient.get<VoteParticipant[]>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/participants`);
        return new DataObject<Failure, VoteParticipant[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function excludeParticipantFromVoting(organizationId: string, eventId: string, itemId: string, voteId: string, participantId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete<void>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/participants/${participantId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function includeParticipantToVoting(organizationId: string, eventId: string, itemId: string, voteId: string, participantId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch<void>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/participants/${participantId}`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function removeMarker(organizationId: string, eventId: string, itemId: string, voteId: string, participantId: string, marker: SpecialMarker): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete<void>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/participants/${participantId}/markers`, {
            data: {
                marker,
            }
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function addMarker(organizationId: string, eventId: string, itemId: string, voteId: string, participantId: string, marker: SpecialMarker): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post<void>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/participants/${participantId}/markers`, {
            marker,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function outcomeDecision(organizationId: string, eventId: string, itemId: string, voteId: string, passed: boolean, reason: string | null): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post<void>(`/organizations/${organizationId}/events/live/${eventId}/agenda/${itemId}/votes/${voteId}/outcome`, {
            passed,
            reason
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}



export async function editMembers(organizationId: string, eventId: string, membersToEdit: MemberModifyAction[]): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put<void>(`/organizations/${organizationId}/events/${eventId}/members`, {
            bodyMembersToModify: membersToEdit
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function makeChairman(organizationId: string, eventId: string, participantId: string | null, guestId?: string | null, moderatorId?: string | null): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch<void>(`/organizations/${organizationId}/events/live/${eventId}/chairman`, {
            participantId,
            guestId,
            moderatorId,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function removeChairman(organizationId: string, eventId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete<void>(`/organizations/${organizationId}/events/live/${eventId}/chairman`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function makeOutcomeMaker(organizationId: string, eventId: string, participantId: string | null, guestId?: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.patch<void>(`/organizations/${organizationId}/events/live/${eventId}/vote-outcome-maker`, {
            participantId,
            guestId,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function removeOutcomeMaker(organizationId: string, eventId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete<void>(`/organizations/${organizationId}/events/live/${eventId}/vote-outcome-maker`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getUserEvents(): Promise<DataObject<Failure, EventParticipantInvitation[] | null>> {
    try {
        const res = await HttpClient.get<EventParticipantInvitation[]>(`/organizations/events/my`);
        return new DataObject<Failure, EventParticipantInvitation[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getOrganizationEventsInvites(organizationId: string): Promise<DataObject<Failure, EventParticipantInvitation[] | null>> {
    try {
        const res = await HttpClient.get<EventParticipantInvitation[]>(`/organizations/${organizationId}/events/invites`);
        return new DataObject<Failure, EventParticipantInvitation[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getOrganizationEventParticipantInvitations(organizationId: string, eventId: string): Promise<DataObject<Failure, EventParticipant[] | null>> {
    try {
        const res = await HttpClient.get<EventParticipant[]>(`/organizations/${organizationId}/events/${eventId}/participants/invitation`);
        return new DataObject<Failure, EventParticipant[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getEventBodyMember(organizationId: string, eventId: string, memberId: string): Promise<DataObject<Failure, BodyMember | null>> {
    try {
        const res = await HttpClient.get<BodyMember>(`/organizations/${organizationId}/events/${eventId}/members/${memberId}`);
        return new DataObject<Failure, BodyMember>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function updateBodyMemberForEvent(organizationId: string, eventId: string, memberId: string, member: BodyMember): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/events/${eventId}/members/${memberId}`, member);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export const useEvent = () => {
    const {getOrganization} = useAppStateHook();
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [event, setEvent] = useState<EventModel | null>(null);
    const {eventId, organizationId} = useParams();
    const [params] = useSearchParams();
    const participantId = params.get('representedParticipantId');

    useEffect(() => {
        getOrganization().then((res) => {
            if (res.data) {
                setOrganization(res.data);
            }
        })
        const client = new Client({
            brokerURL: process.env.REACT_APP_WS_URL,
            // connectHeaders: {
            //     login: 'user',
            //     passcode: 'password',
            // },
            // debug: function (str) {
            //     console.log(str);
            // },
            reconnectDelay: 2000,
            // heartbeatIncoming: 4000,
            // heartbeatOutgoing: 4000,
        });
        let subscription: StompSubscription;
        client.onConnect = function (frame) {
            let url = `/user/queue/events/${eventId}`;
            if (participantId) {
                url = `/user/queue/events/${eventId}?representedParticipantId=${participantId}`;
            }
            subscription = client.subscribe(url, (message) => {
                const event: EventModel = JSON.parse(message.body);
                // console.clear();
                // console.log(JSON.stringify(event, null, 2));
                setEvent(event);
            });
        };

        client.onStompError = function (frame) {
            console.log('error');
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
        };


        client.activate();

        return () => {
            subscription?.unsubscribe();
            client.deactivate();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId, organizationId, participantId])


    return {organization, event}
}
