import axios, { AxiosError } from 'axios';
import { DataObject } from './dataObject';
import { Failure } from './failure';
import { AuthError, Canceled, NoInternetConnection, Unexpected, Violations } from './failures';
import i18next from 'i18next';

const getLanguage = () => i18next.language || window.localStorage.i18nextLng

export function handleError<T>(e: AxiosError | any, fun?: () => DataObject<Failure, null>): DataObject<Failure, T | null> {
    console.log(e);
    if (!e) {
        return new DataObject(new Unexpected(), null);
    }
    if (axios.isCancel(e)) {
        return new DataObject(new Canceled());
    }
    if (e?.response?.status === 401) {
        return new DataObject(new AuthError(), null);
    }

    if (e?.response?.status === 504) {
        return new DataObject(new NoInternetConnection(), null);
    }

    if (e?.response?.status === 403) {
        const translations = i18next.getDataByLanguage(getLanguage()) as any;
        return new DataObject(new Unexpected(translations.common.errors.authError));
    }

    if (fun) {
        const ret: DataObject<Failure, null> = fun();

        if (ret) {
            return ret;
        }
    }
    if (e?.response?.data?.violations) {
        return new DataObject(new Violations(e?.response?.data?.violations))
    }
    return new DataObject(new Unexpected(e?.response?.data?.detail));
}
