
import React, {useEffect, useState} from "react";
import {Wrapper} from "./ImageViewer.styled";

const ImageViewer = ({file}:{file: File}) => {
    const [src, setSrc] = useState<string | null>(null);
    useEffect(() => {
        if(file){
            let reader = new FileReader();
            reader.onload = (e) => {
                setSrc(e!.target!.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, [file])

    return <Wrapper>
        {src
            ? <img src={src} alt={file.name}/>
            : null
        }

    </Wrapper>
}

export default ImageViewer;