import React from 'react';
import { Wrapper } from './Input.styles';


const Input = (props: {
    label?: string,
    disabled?: boolean,
    suffix?: string,
    children: any,
}) => {
    return <Wrapper className={props.disabled ? 'disabled' : ''}>
        <label>
            {props.label
                ? <span className="label">{props.label}</span>
                : null}
            <span className="value">
                {props.children}
                {props.suffix
                    ? <div className="suffix">{props.suffix}</div>
                    : null}
            </span>

        </label>
    </Wrapper>
}


export default Input;
