import {useEffect, useState} from "react";
import {EventTimerData} from "./events.model";
import {useParams} from "react-router-dom";
import {Client, StompSubscription} from "@stomp/stompjs";

export const useEventTimer = ({eventIdd}: {eventIdd?: string}) => {
    const [eventTimer, setEventTimer] = useState<EventTimerData | null>(null);
    const {eventId, organizationId} = useParams();

    useEffect(() => {
        const client = new Client({
            brokerURL: process.env.REACT_APP_WS_URL,
            // connectHeaders: {
            //     login: 'user',
            //     passcode: 'password',
            // },
            // debug: function (str) {
            //     console.log(str);
            // },
            reconnectDelay: 2000,
            // heartbeatIncoming: 4000,
            // heartbeatOutgoing: 4000,
        });

        let subscription: StompSubscription;
        client.onConnect = function (frame) {
            // console.log(eventId ?? eventIdd);
            let url = `/topic/event-timing/${eventId ?? eventIdd}`;

            subscription = client.subscribe(url, (message) => {
                const timer: EventTimerData = JSON.parse(message.body);
                // console.clear();
                // console.log(JSON.stringify(timer, null, 2));
                setEventTimer(timer);
            });
        };

        client.onStompError = function (frame) {
            console.log('error');
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
        };


        client.activate();

        return () => {
            subscription?.unsubscribe();
            client.deactivate();

        }

    }, [eventIdd, eventId, organizationId]);

    return {
        startIn: {
            days: eventTimer?.startIn?.days || 0,
            hours: eventTimer?.startIn?.hours || 0,
            minutes: eventTimer?.startIn?.minutes || 0,
            seconds: eventTimer?.startIn?.seconds || 0,
        },
        canSignIn: eventTimer?.canSignIn || false,
        canStart: eventTimer?.canStart || false,
    };
}
