import React, { useContext } from 'react';
import { Wrapper } from './ProfileSettingChangePassword.styled';
import { useBaseScreenHook } from '../../../../../logic/core/base-screen.hook';
import { useForm } from '../../../../../logic/core/form.hook';
import { Violations } from '../../../../../logic/core/failures';
import FormField from '../../../../../components/FormField';
import Input from '../../../../../components/Input';
import { changePassword } from '../../../../../logic/auth/auth';
import { passwordStrongValidator } from '../../../../../logic/core/password-strength';
import PasswordStrengthValidator from '../../../../../components/PasswordStrengthValidator/passwordStrengthValidator';
import { ToastContext } from '../../../../../components/Toast/toastStateProvider';
import { ModalType } from '../../../../../components/Toast';

const ProfileSettingChangePassword = ({onClose}: { onClose: () => void }) => {
    const {t, loading, setLoading, renderError, setError} = useBaseScreenHook();
    const [, setToastState] = useContext(ToastContext);

    const {
        formControl,
        renderValidationMessage,
        formValid,
        formValue,
        setValidations,
        formTouched
    } = useForm<{ oldPassword: string, newPassword: string }>({
        oldPassword: {
            value: ''
        },
        newPassword: {
            value: '',
            validators: [
                (val) => {
                    const a = passwordStrongValidator(val);
                    if (a) {
                        return 'test'
                    }
                    return null;
                },
            ]
        },
        repeatPassword: {
            value: '',
            validators: [
                (val) => {
                    if (val !== formControl('newPassword').value) {
                        return t('resetPassword.passwordNotMatch')
                    }
                    return null;
                },
            ]
        },
    })

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);

        const res = await changePassword(formValue.oldPassword, formValue.newPassword)
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setToastState({
            type: ModalType.success,
            message: t('profileSettingChangePassword.successMessage'),
        })
        onClose();
    }


    return <Wrapper>
        <div className="title">
            {t('profileSettingChangePassword.title')}
        </div>

        <FormField>
            <Input label={t('profileSettingChangePassword.oldPassword') + ' *'}>
                <input type="password"
                       value={formControl('oldPassword').value}
                       onChange={(e) => formControl('oldPassword').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('oldPassword')}
        </FormField>
        <FormField>
            <Input label={t('profileSettingChangePassword.newPassword') + ' *'}>
                <input type="password"
                       value={formControl('newPassword').value}
                       onChange={(e) => formControl('newPassword').patchValue(e.target.value)}/>
            </Input>
            <PasswordStrengthValidator control={formControl('newPassword')} formTouched={formTouched}/>
        </FormField>
        <FormField>
            <Input label={t('profileSettingChangePassword.repeatPassword') + ' *'}>
                <input type="password"
                       value={formControl('repeatPassword').value}
                       onChange={(e) => formControl('repeatPassword').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('repeatPassword')}
        </FormField>


        <div className="buttons">
            <button onClick={onClose} disabled={loading} className={'text muted button'}>
                {t('profileSettingChangePassword.cancel')}
            </button>
            <button className={'button'} onClick={_submit} disabled={loading}>
                {t('profileSettingChangePassword.change')}
            </button>
        </div>
        {renderError()}
    </Wrapper>
}

export default ProfileSettingChangePassword;
