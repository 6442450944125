import React, {useEffect, useState} from 'react';
import {Wrapper} from './OrganizationBodyPreviewMemberModal.styles';
import {BodyMember, BodyMemberType} from '../../../../../../logic/bodies/bodies.model';
import iconCompany from '../../../../../../assets/icon-company.svg'
import iconPerson from '../../../../../../assets/icon-person.svg'
import iconCloseRed from '../../../../../../assets/icon-close-red.svg'
import iconEdit from '../../../../../../assets/icon-edit.svg'
import {useBaseScreenHook} from '../../../../../../logic/core/base-screen.hook';
import ConfirmationModal from '../../../../../../components/ConfirmationModal';
import Modal from '../../../../../../components/Modal';
import {deleteBodyMember} from '../../../../../../logic/bodies/bodies';
import {EventParticipant} from "../../../../../../logic/events/events.model";
import Loader from "../../../../../../components/Loader";
import {getMember, getParticipant} from "../../../../../../logic/events/events";
import {useParams} from "react-router-dom";
import FilesPreview from "../../../../../../components/FilesPreview";

const OrganizationBodyPreviewMemberModal = ({
                                                memberData,
                                                bodyId,
                                                onClose,
                                                participant,
                                            }: {
    bodyId?: string,
    participant?: EventParticipant | null,
    memberData?: BodyMember | null, onClose: (refresh: boolean, memberToEdit?: BodyMember | null) => void
}) => {

    const [isPerson, setIsPerson] = useState<boolean>(memberData?.type === BodyMemberType.personMember);
    const {organizationId, eventId} = useParams();

    const {i18n, setError, t, renderError} = useBaseScreenHook();
    const [memberToDelete, setMemberToDelete] = useState<BodyMember | null>(null);
    const [member, setMember] = useState<BodyMember | null>(null);
    const [authorizationDocumentId, setAuthorizationDocumentId] = useState<string | null>(null);
    const [documentPreviewId, setDocumentPreviewId] = useState<string | null>(null);

    useEffect(() => {
        if (memberData) {
            setMember(memberData);
        } else if (participant) {
            getMember(organizationId!, eventId!, participant?.bodyMemberId!).then((res) => {
                if (res.isFailure()) {
                    setError(res.failure!);
                    return;
                }

                setIsPerson(res.data!.type === BodyMemberType.personMember);
                getParticipant(organizationId!, eventId!, participant?.bodyMemberId!).then((resData) => {
                    if (resData.isFailure()) {
                        setError(resData.failure!);
                    }

                    setAuthorizationDocumentId(resData.data?.authorizationDocumentId ?? null);

                    setMember(res.data!);
                });
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const _deleteBody = () => {
        deleteBodyMember(bodyId!, memberToDelete!.id!).then((res) => {
            setMemberToDelete(null);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            onClose(true, null);
        })
    }

    const _showAuthorizationDocument = () => {
        if (!authorizationDocumentId) {
            return;
        }

        setDocumentPreviewId(authorizationDocumentId);

    }

    if (!member) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>;
    }
    return <Wrapper className={`${!memberData ? 'with-participant' : ''}`}>
        <div className="content">
            <div className="icon">
                <img src={isPerson ? iconPerson : iconCompany} alt="icon"/>
            </div>
            <div className="title">
                {
                    member?.type === BodyMemberType.personMember
                        ? `${member.firstName} ${member.lastName}`
                        : member?.organizationName
                }
            </div>

            {
                member?.type === BodyMemberType.personMember
                    ? <div className="data">
                        <div className="row">
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.name')}
                                </div>
                                <div className="value">
                                    {member.firstName} {member.lastName}
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.pesel')}
                                </div>
                                <div className="value">
                                    {member.pesel || '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="data">
                        <div className="row">
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.organizationName')}
                                </div>
                                <div className="value">
                                    {member?.organizationName}
                                </div>
                            </div>
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.nip')}
                                </div>
                                <div className="value">
                                    {member?.nip || '-'}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.address')}
                                </div>
                                <div className="value">
                                    {member?.address?.street || '-'}
                                </div>
                            </div>
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.postalCode')}
                                </div>
                                <div className="value">
                                    {member?.address?.postalCode || '-'}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.city')}
                                </div>
                                <div className="value">
                                    {member?.address?.city || '-'}
                                </div>
                            </div>
                            <div className="cell">
                                <div className="label">
                                    {t('organizationBodyPreviewMemberModal.country')}
                                </div>
                                <div className="value">
                                    {member?.address?.country || '-'}
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className="votes">
                <div className="row">
                    <div className="label">
                        {t('organizationBodyPreviewMemberModal.votesNumber')}
                    </div>
                    <div className="value">
                        {new Intl.NumberFormat(i18n.language).format(member?.votesNumber || 0)}
                    </div>
                </div>
                <div className="row">
                    <div className="label">
                        {t('organizationBodyPreviewMemberModal.shares')}
                    </div>
                    <div className="value">
                        {member?.shares ?? '-'}
                    </div>
                </div>
            </div>

            {
                participant
                    ?
                    <div className="participant">
                        <div className="title">
                            {t('organizationBodyPreviewMemberModal.participantData')}
                        </div>

                        {
                            participant.name ?
                                <>
                                    <div className="label name">
                                        {participant.name}
                                    </div>

                                    <div className="label position">
                                        {participant.position}
                                    </div>

                                    <div className="label email">
                                        {participant.email}
                                    </div>
                                    <div className="label phone-number">
                                        {participant.phoneNumber}
                                    </div>
                                    {
                                        authorizationDocumentId ?
                                            <div className="label">
                                                <button className="button" onClick={_showAuthorizationDocument}>
                                                    {t('organizationBodyPreviewMemberModal.showAuthorizationDocument')}
                                                </button>
                                            </div>
                                            : null
                                    }

                                </>
                                : <div className="label name">
                                    Brak
                                </div>
                        }


                    </div>
                    : null
            }

            <div className="notifications">
                <div className="title">
                    {t('organizationBodyPreviewMemberModal.notificationsData')}
                </div>

                <div className="label name">
                    {member?.addressee?.firstName} {member?.addressee?.lastName}
                </div>
                {
                    member?.type === BodyMemberType.organizationMember
                        ? <div className="label position">
                            {member?.addressee?.position}
                        </div>
                        : null
                }

                <div className="label email">
                    {member?.email}
                </div>
                <div className="label phone-number">
                    {member?.addressee?.phoneNumber}
                </div>
            </div>
            {
                memberData
                    ? <div className="buttons">
                        <div className="btn edit" onClick={() => onClose(false, member)}>
                            <img src={iconEdit} alt="edit"/>
                        </div>
                        <div className="btn" onClick={() => member && setMemberToDelete(member)}>
                            <img src={iconCloseRed} alt="delete"/>
                        </div>
                    </div>
                    : null
            }

        </div>


        <Modal width={600} show={!!memberToDelete} onClose={() => setMemberToDelete(null)}>
            <ConfirmationModal
                reject={() => setMemberToDelete(null)}
                accept={() => _deleteBody()}
                title={`${t('organizationBodiesDetailsMembers.deleteConfirmation')} ${memberToDelete?.type === BodyMemberType.personMember ? ((memberToDelete.firstName ?? '') + ' ' + (memberToDelete.lastName ?? '')) : (memberToDelete?.organizationName ?? '')}?`}
                body={t('organizationBodiesDetailsMembers.deleteConfirmationBody')}
            />
        </Modal>

        <Modal width={800} show={!!documentPreviewId} reset={true} onClose={() => {
            setDocumentPreviewId(null);
        }} backDropClose={false}>
            <FilesPreview url={`/file/${documentPreviewId}`} onClose={() => setDocumentPreviewId(null)}/>
        </Modal>
    </Wrapper>
}


export default OrganizationBodyPreviewMemberModal;
