import React, { useEffect, useState } from 'react';
import { Wrapper } from './HomeScreen.styles';
import Loader from '../../components/Loader';
import { Link, NavLink, useParams } from 'react-router-dom';
import NewOrganizationModal from '../../components/NewOrganizationModal';
import Modal from '../../components/Modal';
import iconPlus from '../../assets/icon-plus.svg'
import { AppRoutes } from '../../logic/core/routes';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import { useAppStateHook } from '../../logic/core/appState.hook';
import TopBar from '../../components/TopBar';
import MainContainer from '../../components/MainContainer';

const HomeScreen = () => {
    const [openNewOrganizationModal, setOpenNewOrganizationModal] = useState(false);
    const {t} = useBaseScreenHook();
    const params = useParams();
    const {getOrganizations, appState} = useAppStateHook();
    useEffect(() => {
        getOrganizations().then((res) => {
            if (res.isFailure()) {

            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <Wrapper>
        <TopBar/>
            <MainContainer>
                <div className="label">
                    {t('home.yourAccount')}
                </div>
                <NavLink to={AppRoutes.account} className="user">
                    <div className="avatar">
                        {appState.user?.firstName.substr(0, 1)}
                        {appState.user?.lastName.substr(0, 1)}
                    </div>
                    <div className="data">
                        <div className="name">
                            {appState.user?.name}
                        </div>
                        <div className="email">
                            {appState.user?.email}
                        </div>
                    </div>
                </NavLink>

                <div className="label">
                    {t('home.organizations')}
                </div>

                <div className="organizations">
                    {
                        appState.organizations
                            ? appState.organizations.map((o) => {
                                return <Link className={'organization ' + (params.organizationId === o.id ? 'active' : '')}
                                             to={AppRoutes.organization + '/' + o.id} key={o.id}>
                                    <div className="name">
                                        {o.name}
                                    </div>
                                    <div className="plan">
                                        Plan Free
                                    </div>
                                </Link>
                            })
                            : <Loader/>
                    }
                </div>
                <div className="add-organization" onClick={() => setOpenNewOrganizationModal(true)}>
                    <div className="add-icon">
                        <img src={iconPlus} alt=""/>
                    </div>
                    {t('home.addOrganization')}
                </div>
            </MainContainer>

        <Modal title={t('newOrganizationModal.title')} show={openNewOrganizationModal}
               onClose={() => setOpenNewOrganizationModal(false)} backDropClose={false}>
            <NewOrganizationModal onClose={() => setOpenNewOrganizationModal(false)}/>
        </Modal>
    </Wrapper>;
}

export default HomeScreen;
