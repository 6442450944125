import React, {useEffect, useState} from "react";
import {Wrapper} from "./OrganizationParticipantEventDocuments.styles";
import {
    AvailableEvent,
    ContextDocumentType,
    Document,
    DocumentFilers,
    IdName
} from "../../../../logic/documents/documents.model";
import {Link, useParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {
    participantAvailableBodies, participantAvailableEvents,
    participantAvailableOrganizations, participantDocumentUrl,
    participantOrganizationDocuments
} from "../../../../logic/documents/documents";
import {Canceled} from "../../../../logic/core/failures";
import {downloadByUrl, saveFile} from "../../../../logic/files/files.service";
import {Table} from "../../../../components/Table/table";
import Select from "../../../../components/Select";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";
import FilesPreview from "../../../../components/FilesPreview";
import {getOrganization} from "../../../../logic/organizations/organizations";
import {Organization} from "../../../../logic/organizations/organizations.model";
import {AppRoutes} from "../../../../logic/core/routes";
import Annotations from "../../../../components/Annotations";


let inputDebouncer: ReturnType<typeof setTimeout> | null = null;
let abortController: AbortController | null = null;


const OrganizationParticipantEventDocuments = () => {
    const [availableFilters, setAvailableFilters] = useState<{ organizations: IdName[], bodies: IdName[], events: AvailableEvent[] } | null>(null);
    const [filters, setFilters] = useState<DocumentFilers>({
        type: ContextDocumentType.Event
    });
    const [organization, setOrganization] = useState<Organization | null>(null);
    const {organizationId} = useParams();
    const {renderError, setError, i18n, t} = useBaseScreenHook();
    const [downloading, setDownloading] = useState<Set<string>>(new Set([]));
    const [preview, setPreview] = useState<{ url: string, urls?: string[] } | null>(null);

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const [documents, setDocuments] = useState<Document[] | null>(null);
    useEffect(() => {
        _getDocuments(filters);
        getOrganization(organizationId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setOrganization(res.data!);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const _getAvailableFilters = (filters: DocumentFilers) => {
        Promise.all([
            participantAvailableOrganizations(organizationId!, ContextDocumentType.Event),
            participantAvailableBodies(organizationId!, ContextDocumentType.Event),
            participantAvailableEvents(organizationId!)
        ]).then((res) => {
            const [
                organizationsRes,
                bodiesRes,
                eventsRes,
            ] = res;
            const failure = res.find(r => r.isFailure());
            if (failure) {
                setError(failure.failure!);
                return;
            }
            const _availabelFilters = {
                organizations: organizationsRes.data!,
                bodies: bodiesRes.data!,
                events: eventsRes.data!
            }
            setAvailableFilters(_availabelFilters);
            if (!filters.eventId && _availabelFilters.events.length) {
                _setFilters({
                    ...filters,
                    eventId: _availabelFilters.events[_availabelFilters.events.length - 1].id,
                })
            }
        })

    }

    const cleatInputDebouncer = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
        if (abortController) {
            abortController.abort();
            abortController = null;
        }
    }

    const _setFilters = (filters: DocumentFilers) => {
        setFilters(filters);
        setDocuments(null);
        cleatInputDebouncer();
        inputDebouncer = setTimeout(() => {
            _getDocuments(filters)
        }, 300);
    }

    const _getDocuments = (filters: DocumentFilers) => {
        abortController = new AbortController();
        _getAvailableFilters(filters);
        if (!filters.eventId) {
            return;
        }
        participantOrganizationDocuments(organizationId!, filters, abortController).then((res) => {
            if (res.isFailure()) {
                if (res.failure?.is(Canceled.getCode)) {
                    return;
                }
                setError(res.failure!);
                return
            }
            setDocuments(res.data!);
        });
    }

    const _download = async (d: Document) => {
        downloading.add(d.id)
        setDownloading(new Set(Array.from(downloading)));
        const res = await downloadByUrl(participantDocumentUrl(organizationId!, d));
        downloading.delete(d.id)
        setDownloading(new Set(Array.from(downloading)));
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        const saveRes = await saveFile(res.data!.data!, res.data?.name!, res.data?.type!);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }


    }

    if (!organization) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>

        <p className="description">
            Dokumenty z posiedzeń i głosowań, w których {organization.name} wzięło udział jako uczestnik.
        </p>

        <Table>
            <thead>
            <tr>
                <th>
                    Wybierz organizację
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.organizationId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    organizationId: e
                                })}
                        >
                            <option/>
                            {availableFilters?.organizations?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
                <th>
                    {t('documents.selectBody')}
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.bodyId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    bodyId: e
                                })}
                        >
                            <option/>
                            {availableFilters?.bodies?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
                <th>
                    <div className="event-label">
                        {t('documents.selectEvent')}

                        {
                            filters.eventId
                                ? <Link
                                    to={`/${AppRoutes.organization}/${availableFilters?.events.find(e => e.id === filters.eventId)?.organizationId}/${AppRoutes.event}/${filters.eventId}/${AppRoutes.documents}`}
                                    target={'_blank'}>{t('documents.goToEvent')}</Link> : null}

                    </div>
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.eventId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    eventId: e
                                })}
                        >
                            {availableFilters?.events?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
            </tr>
            </thead>
        </Table>
        <Table className={'multibody'}>
            <thead>
            <tr>
                <th>{t('documents.date')}</th>
                <th>{t('documents.document')}</th>
                <th>{t('documents.organizationName')}</th>
                <th>{t('documents.body')}</th>
                <th></th>
            </tr>
            </thead>

            {
                documents?.map(d => {
                    return <tbody key={d.id}><tr>
                        <td>
                            {dateFormatter(new Date(d.timestamp))}
                        </td>
                        <td>
                            {d.name}
                        </td>
                        <td>
                            {availableFilters?.organizations.find(o => o.id === d.organizationId)?.name}
                        </td>
                        <td>
                            {
                                d.bodyIds.map((id) => {
                                    return <div
                                        key={d.id + '-' + id}>{availableFilters?.bodies.find(b => b.id === id)?.name}</div>;
                                })
                            }
                        </td>
                        <td>
                            <div className="actions">

                                <button className="button text" onClick={() => setPreview({
                                    url: participantDocumentUrl(organizationId!, d),
                                    urls: documents?.map((doc) => participantDocumentUrl(organizationId!, doc))
                                })}>
                                    {t('documents.preview')}

                                </button>
                                <button className="button text" disabled={downloading.has(d.id)}
                                        onClick={() => _download(d)}>
                                    {downloading.has(d.id) ? <Loader/> : t('documents.download')}
                                </button>


                            </div>

                        </td>
                    </tr>
                    <Annotations
                        document={d}
                        onPreview={(doc) => {
                            setPreview({
                                url: participantDocumentUrl(organizationId!, doc),
                                urls: d.annotations?.map(doc => participantDocumentUrl(organizationId!, doc),)
                            })
                        }}
                        onDownload={_download}
                        downloading={downloading}
                    />
                    </tbody>

                })
            }
        </Table>
        {
            documents
                ? documents.length ? null : t('documents.emptyList')
                : availableFilters?.events.length
                    ? <Loader/>
                    : t('documents.emptyList')
        }

        <Modal width={800} show={!!preview} reset={true} onClose={() => {
            setPreview(null);
        }} backDropClose={false}>
            <FilesPreview url={preview?.url} urls={preview?.urls} onClose={() => setPreview(null)}/>
        </Modal>

        {renderError()}
    </Wrapper>
}
export default OrganizationParticipantEventDocuments;
