import styled from 'styled-components';

export const Wrapper = styled.div`
  .event-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--fontMutedColor);
    margin-bottom: 10px;
    word-break: break-word;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .message {
    margin-bottom: 20px;
  }
  .description {
    font-size: 1.2rem;
    color: var(--fontMutedColor);
    text-align: left;
    margin-bottom: 30px;
  }

  .organization {
    text-align: left;
    margin-bottom: 20px;
    .name {
      font-weight: 500;
      margin-bottom: 5px;
    }
    .nip {
      color: var(--fontMutedColor);
    }
  }
  
  .list {
    text-align: left;
    .title {
      font-size: inherit;
      font-weight: 500;
      margin-bottom: 10px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      color: var(--fontMutedColor);
      td {
        padding: 5px 0;
      }
    }
  }
  
  .buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
  }
  
`