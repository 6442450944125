import styled from 'styled-components';

export const Wrapper = styled.div`

    .hint {
      font-size: 1.2rem;
      color: var(--fontMutedColor);
      margin-bottom: 20px;
    }
  
  .buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

`