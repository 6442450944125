import styled from 'styled-components';

export const Wrapper = styled.div`

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .type {
    display: flex;
    gap: 40px;

    .label {
      display: flex;
      align-items: center;
      gap: 5px
    }
  }

  .section-title {
    text-align: left;
    font-weight: bold;
    font-size: 1.8rem;
    margin: 10px 0;
    color: var(--fontMutedColor);
  }

  .section-subtitle {
    text-align: left;
    font-weight: 500;
    font-size: 1.6rem;
    margin: 0 0 10px;
    color: var(--fontMutedColor);
  }

  .row {
    display: flex;
    gap: 20px;

    & > * {
      flex: 1;
    }

    .zip-code {
      max-width: 180px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      .zip-code {
        max-width: unset;
      }
    }
  }


  .data-values {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    input {
      padding-right: 60px !important;
      width: 180px !important;
    }
  }

  .arrows {
    position: absolute;
    right: 30px;
    top: 0;
    display: flex;
    flex-direction: column;
    bottom: 0;
    align-items: center;
    justify-content: center;

    img {
      padding: 5px 0;
    }

  }

  .buttons {
    margin-top: 20px;
    gap: 20px;
    display: flex;
    justify-content: flex-end;
  }
`
export const ConfirmEditModal = styled.div`
    .buttons {
      .warn {
        --mainColor: #334bfa;
        --mainButtonHoverColor: rgba(51, 75, 250, .1);
      }
    }
`