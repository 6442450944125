import styled from 'styled-components';

export const Wrapper = styled.div`

  .title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
  }
  .row {
    display: flex;
    gap: 20px;

    & > * {
      flex: 1;
    }
    .zip-code {
      max-width: 180px;
    }
  }
  
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
`
