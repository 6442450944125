import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  .label {
    font-size: 1.2rem;
    color: var(--fontColor);
    text-align: left;
    margin-bottom: 3px;
    display: block;
  }
  .hint {
    padding: 5px 0 5px;
    text-align: left;
    font-size: 1.2rem;
    color: var(--fontMutedColor);
  }
  .value {
    color: var(--fontMutedColor);
    font-size: 1.4rem;
  }
  & > * {
    & + .validation {
      //position: absolute;
      //left: 0;
      //right: 0;
      //top: calc(100% + 2px);
      //margin-bottom: 0;
      //overflow: hidden;
      //text-overflow: ellipsis;
      //display: -webkit-box;
      //-webkit-line-clamp: 1;
      //line-clamp: 1;
      //-webkit-box-orient: vertical;
    }
  }
`
