import styled from 'styled-components';

export const Wrapper = styled.div`

  .description {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .filter-input {
    margin-top: 10px;

    .value {
      height: 30px;

      input {
        padding: 0 10px;
      }
    }

    select {
      height: 30px;
      padding: 0 10px;
    }
  }


  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .button {
      width: auto;
      min-width: unset;
    }
  }
  .event-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: var(--mainColor);
    }
  }
`