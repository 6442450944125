import React, { useEffect, useState } from 'react';
import { Wrapper } from './OrganizationDataDetailsEditModal.styles';
import {
    LegalForm,
    Organization,
    OrganizationUpdateBody
} from '../../../../../logic/organizations/organizations.model';
import { useForm } from '../../../../../logic/core/form.hook';
import { useBaseScreenHook } from '../../../../../logic/core/base-screen.hook';
import FormField from '../../../../../components/FormField';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import { getLegalForms } from '../../../../../logic/organizations/organizations';
import { Violations } from '../../../../../logic/core/failures';
import { useAppStateHook } from '../../../../../logic/core/appState.hook';

const OrganizationDataDetailsEditModal = ({
                                              data,
                                              onClose
                                          }: { data: Organization, onClose: (refresh: boolean) => void }) => {
    const [legalForms, setLegalForms,] = useState<LegalForm[] | null>(null);

    const {updateOrganization} = useAppStateHook();

    const {
        renderValidationMessage,
        formControl,
        formValid,
        formValue,
        setValidations
    } = useForm<OrganizationUpdateBody>({
        name: {
            value: data.name || '',
        },
        legalFormId: {
            value: data.legalForm.id || '',
        },
        nip: {
            value: data.nip || '',
        },
        address: {
            controls: {
                country: {
                    value: data.address?.country || '',
                },
                street: {
                    value: data.address?.street || '',
                },
                postalCode: {
                    value: data.address?.postalCode || '',
                },
                city: {
                    value: data.address?.city || '',
                },
            }
        },
        phoneNumber: {
            value: data.phoneNumber || '',
        },
        email: {
            value: data.email || '',
        },
        webPage: {
            value: data.webPage || '',
        }
    });

    useEffect(() => {
        getLegalForms().then((res) => {
            if (res.data) {
                setLegalForms(res.data);
            }
        })
    }, [])

    const {t, setLoading, loading, setError, renderError} = useBaseScreenHook();

    const _submit = async () => {
        if (!formValid()) {
            return;
        }

        setLoading(true);

        const body: OrganizationUpdateBody = {
            name: formValue.name,
            nip: formValue.nip || null,
            webPage: formValue.webPage || null,
            phoneNumber: formValue.phoneNumber || null,
            email: formValue.email || null,
            address: {
                country: formValue.address?.country || null,
                postalCode: formValue.address?.postalCode || null,
                street: formValue.address?.street || null,
                city: formValue.address?.city || null,
            }
        }

        const res = await updateOrganization(data.id, body);
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }


        onClose(true);
    }
    return <Wrapper>
        <div className="title">
            {t('organizationDataDetailsEditModal.title')}
        </div>

        <FormField>
            <Input label={t('organizationDataDetailsEditModal.name') + ' *'}>
                <input type="text" value={formControl('name').value}
                       onChange={(e) => formControl('name').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('name')}
        </FormField>

        <FormField>
            <Select label={t('organizationDataDetailsEditModal.legalForm') + ' *'}
                    currentValue={formControl('legalFormId').value}
                    onChange={formControl('legalFormId').patchValue}
                    disabled={true}
            >
                <option/>
                {legalForms?.map((p) => {
                    return <option value={p.id} key={p.id}>{p.label}</option>
                })}
            </Select>
            {renderValidationMessage('legalFormId')}
        </FormField>

        <FormField>
            <Input label={t('organizationDataDetailsEditModal.nip')}>
                <input type="text" value={formControl('nip').value}
                       onChange={(e) => formControl('nip').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('nip')}
        </FormField>

        <FormField>
            <Input label={t('organizationDataDetailsEditModal.country')}>
                <input type="text" value={formControl('address.country').value}
                       onChange={(e) => formControl('address.country').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('address.country')}
        </FormField>

        <FormField>
            <Input label={t('organizationDataDetailsEditModal.street')}>
                <input type="text" value={formControl('address.street').value}
                       onChange={(e) => formControl('address.street').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('address.street')}
        </FormField>

        <div className="row">
            <div className="zip-code">
                <FormField>
                    <Input label={t('organizationDataDetailsEditModal.postalCode')}>
                        <input type="text" value={formControl('address.postalCode').value}
                               onChange={(e) => formControl('address.postalCode').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('address.postalCode')}
                </FormField>
            </div>
            <FormField>
                <Input label={t('organizationDataDetailsEditModal.city')}>
                    <input type="text" value={formControl('address.city').value}
                           onChange={(e) => formControl('address.city').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('address.city')}
            </FormField>
        </div>

        <FormField>
            <Input label={t('organizationDataDetailsEditModal.phoneNumber')}>
                <input type="text" value={formControl('phoneNumber').value}
                       onChange={(e) => formControl('phoneNumber').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('phoneNumber')}
        </FormField>
        <FormField>
            <Input label={t('organizationDataDetailsEditModal.email')}>
                <input type="text" value={formControl('email').value}
                       onChange={(e) => formControl('email').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('email')}
        </FormField>
        <FormField>
            <Input label={t('organizationDataDetailsEditModal.www')}>
                <input type="text" value={formControl('webPage').value}
                       onChange={(e) => formControl('webPage').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('webPage')}
        </FormField>


        <div className="buttons">
            <button className={'button text'} disabled={loading} onClick={() => onClose(false)}>
                {t('organizationDataDetailsEditModal.cancelBtn')}
            </button>
            <button className={'button'} disabled={loading} onClick={_submit}>
                {t('organizationDataDetailsEditModal.saveBtn')}
            </button>
        </div>

        {renderError()}
    </Wrapper>
}

export default OrganizationDataDetailsEditModal;
