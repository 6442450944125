import React, {useEffect, useState} from "react";
import {Wrapper} from "./Guests.styles";
import {useEventParticipantsStateHook} from "../participantsHooh";
import iconAdd from "../../../../assets/icon-plus-white.svg";
import {Table} from "../../../../components/Table/table";
import iconMenu from "../../../../assets/icon-menu.svg";
import ContextMenuItem from "../../../../components/ContextMenu/ContextMenuItem";
import ContextMenu from "../../../../components/ContextMenu";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import Modal from "../../../../components/Modal";
import InviteGuestModal from "./InviteGuestModal";
import {EventGuest} from "../../../../logic/events/events.model";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import {
    deleteGuest,
    makeChairman,
    makeOutcomeMaker,
    removeChairman,
    removeOutcomeMaker,
    useEvent
} from "../../../../logic/events/events";
import {useParams} from "react-router-dom";
import {StatusModalConfirmationWrapper} from "../../../OrganizationScreen/OrganizationUsers/OrganizationUsers.styles";
import Loader from "../../../../components/Loader";

const Guests = () => {
    const {participantsState, getGuests} = useEventParticipantsStateHook();
    const {t, renderError, setError} = useBaseScreenHook();
    const [newGuestModal, setNewGuestModal] = useState<boolean>(false);
    const {organizationId, eventId} = useParams();
    const {event} = useEvent();
    const [guestToRemove, setGuestToRemove] = useState<EventGuest | null>(null);
    const [guestToMakeChairman, setGuestToMakeChairman] = useState<EventGuest | null>(null);
    const [guestToRemoveChairman, setGuestToRemoveChairman] = useState<EventGuest | null>(null);
    const [guestToRemoveOutcomeMaker, setGuestToRemoveOutcomeMaker] = useState<EventGuest | null>(null);
    const [guestToMakeOutcomeMaker, setGuestToMakeOutcomeMaker] = useState<EventGuest | null>(null);

    useEffect(() => {
        _getGuests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    const _getGuests = () => {
        getGuests().then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
        })
    }
    const _onNewGuestModalClose = () => {
        _getGuests();
        setNewGuestModal(false);
    }
    const _deleteGuest = async () => {
        const res = await deleteGuest(organizationId!, eventId!, guestToRemove!.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setGuestToRemove(null);
        _getGuests();
    }
    const _setChairman = async () => {
        const res = await makeChairman(organizationId!, eventId!, null, guestToMakeChairman!.id);
        setGuestToMakeChairman(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _removeChairman = async () => {
        const res = await removeChairman(organizationId!, eventId!);
        setGuestToRemoveChairman(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _setOutcomeMaker = async () => {
        const res = await makeOutcomeMaker(organizationId!, eventId!, null, guestToMakeOutcomeMaker!.id);
        setGuestToMakeOutcomeMaker(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _removeOutcomeMaker = async () => {
        const res = await removeOutcomeMaker(organizationId!, eventId!);
        setGuestToRemoveOutcomeMaker(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    const canModify: boolean = participantsState.guests?.canModify ?? false;

    return <Wrapper>

        <div className="top-bar">
            <p className="description">
                {t('eventGuests.description')}
            </p>

            {
                canModify
                    ? <button className={'button icon'} onClick={() => setNewGuestModal(true)}>
                        <img src={iconAdd} alt=""/>
                        {t('eventGuests.newGuestBtn')}
                    </button>
                    : null
            }

        </div>


        <Table>
            <thead>
            <tr>
                <th>
                    {t('eventGuests.name')}
                </th>

                <th>
                    {t('eventGuests.email')}
                </th>
                <th>
                    {t('eventGuests.comment')}
                </th>
                <th>
                    {t('eventGuests.status')}
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                participantsState.guests?.guests?.map((g) => {
                    return <tr key={g.id}>

                        <td>
                            {g.lastName} {g.firstName}
                        </td>
                        <td>
                            {g.email}
                        </td>
                        <td>
                            <div className="comment">
                                {g.comment ?? '---'}
                            </div>
                        </td>
                        <td>
                            <div className="statuses">
                                <div className={`status ${g.status}`}>
                                    {t(`eventGuests.statuses.${g.status}`)}
                                </div>
                                {
                                    event?.content?.chairmanReference?.guestId === g.id
                                        ? <div className="status">
                                            {t(`eventParticipants.chairman`)}
                                        </div>
                                        : null
                                }
                                {

                                    event?.content?.voteOutcomeMakerReference?.guestId === g.id
                                        ? <div className="status">
                                            {t(`eventParticipants.outcomeMaker`)}
                                        </div>
                                        : null
                                }
                            </div>
                        </td>

                        <td>
                            <div className="actions">
                                <div className="menu">
                                    {
                                        canModify
                                            ? <ContextMenu icon={iconMenu}>
                                                {event?.permissions.canAssignChairman ?
                                                    event?.content?.chairmanReference?.guestId === g.id
                                                        ? <ContextMenuItem onClick={() => setGuestToRemoveChairman(g)}>
                                                            {t('eventParticipants.removeChairman')}
                                                        </ContextMenuItem>
                                                        : <ContextMenuItem onClick={() => setGuestToMakeChairman(g)}>
                                                            {t('eventParticipants.setChairman')}
                                                        </ContextMenuItem>
                                                    : null
                                                }
                                                {
                                                    event?.permissions.canAssignDecidingOnVoteOutcome ?
                                                        event?.content?.canAssignVoteOutcomeMaker
                                                            ? event?.content?.voteOutcomeMakerReference?.guestId === g.id
                                                                ?
                                                                <ContextMenuItem
                                                                    onClick={() => setGuestToRemoveOutcomeMaker(g)}>
                                                                    {t('eventParticipants.removeOutcomeMaker')}
                                                                </ContextMenuItem>
                                                                :
                                                                <ContextMenuItem onClick={() => setGuestToMakeOutcomeMaker(g)}>
                                                                    {t('eventParticipants.setOutcomeMaker')}
                                                                </ContextMenuItem>
                                                            : null
                                                        : null
                                                }
                                                <ContextMenuItem onClick={() => setGuestToRemove(g)}>
                                        <span className={'remove-btn'}>
                                    {t('eventGuests.removeGuest')}
                                        </span>
                                                </ContextMenuItem>
                                            </ContextMenu>

                                            : null
                                    }
                                </div>
                            </div>
                        </td>

                    </tr>
                })
            }
            </tbody>
        </Table>
        <Modal width={700} show={newGuestModal} onClose={() => setNewGuestModal(false)}>
            <InviteGuestModal onClose={() => _onNewGuestModalClose()}/>
        </Modal>

        <Modal width={600} show={!!guestToRemove} onClose={() => setGuestToRemove(null)}>
            <ConfirmationModal
                reject={() => setGuestToRemove(null)}
                accept={() => _deleteGuest()}
                body={t('eventGuests.deleteGuestBody')}
                title={
                    event?.content?.chairmanReference?.guestId === guestToRemove?.id
                        ? t('eventGuests.deleteChairmanTitle', {
                            firstName: guestToRemove?.firstName,
                            lastName: guestToRemove?.lastName
                        })
                        : t('eventGuests.deleteGuestTitle', {
                            firstName: guestToRemove?.firstName,
                            lastName: guestToRemove?.lastName
                        })}
            />
        </Modal>
        <Modal width={600} show={!!guestToMakeChairman} onClose={() => setGuestToMakeChairman(null)}>
            <StatusModalConfirmationWrapper className={'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setGuestToMakeChairman(null)}
                    accept={() => _setChairman()}
                    acceptLabel={t('eventParticipants.makeChairmanAcceptLabel')}
                    body={t('eventParticipants.makeChairmanBody', {name: guestToMakeChairman?.name})}
                    title={t('eventParticipants.makeChairmanTitle', {name: guestToMakeChairman?.name})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!guestToRemoveChairman} onClose={() => setGuestToRemoveChairman(null)}>
            <StatusModalConfirmationWrapper>
                <ConfirmationModal
                    reject={() => setGuestToRemoveChairman(null)}
                    accept={() => _removeChairman()}
                    acceptLabel={t('eventParticipants.removeChairmanAcceptLabel')}
                    body={t('eventParticipants.removeChairmanBody', {name: guestToMakeChairman?.name})}
                    title={t('eventParticipants.removeChairmanTitle', {name: guestToMakeChairman?.name})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>
        <Modal width={600} show={!!guestToMakeOutcomeMaker} onClose={() => setGuestToMakeOutcomeMaker(null)}>
            <StatusModalConfirmationWrapper className={'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setGuestToMakeOutcomeMaker(null)}
                    accept={() => _setOutcomeMaker()}
                    acceptLabel={t('eventParticipants.makeOutcomeMakerAcceptLabel')}
                    title={t('eventParticipants.makeOutcomeMakerTitle', {name: guestToMakeOutcomeMaker?.name})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!guestToRemoveOutcomeMaker} onClose={() => setGuestToRemoveOutcomeMaker(null)}>
            <StatusModalConfirmationWrapper>
                <ConfirmationModal
                    reject={() => setGuestToRemoveOutcomeMaker(null)}
                    accept={() => _removeOutcomeMaker()}
                    acceptLabel={t('eventParticipants.removeOutcomeMakerAcceptLabel')}
                    title={t('eventParticipants.removeOutcomeMakerTitle', {name: guestToRemoveOutcomeMaker?.name})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>
        {renderError()}
    </Wrapper>
}


export default Guests;