import styled from 'styled-components';

export const Wrapper = styled.div`
  .file-picker-wrapper {
    .file-picker {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px dashed var(--disabledColor);
      border-radius: 5px;
      padding: 20px;

      &.over {
        border-style: solid;
        border-color: var(--mainColor);

        .drag-and-drop-label {
          .default {
            display: none;
          }
          .drop {
            display: block;
          }
        }
      }

      input {
        display: none;
      }

      .drag-and-drop-label {
        font-size: 1.8rem;
        color: var(--fontMutedColor);
        font-weight: 500;

        .drop {
          display: none;
          color: var(--mainColor);
        }
      }

      .add-file {
        font-size: 1.8rem;
        color: var(--mainColor);
        font-weight: 500;
        cursor: pointer;
      }
    }

    .description {
      text-align: left;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--fontMutedColor);

      .value {
        font-weight: 500;
      }
    }
  }`
