import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  .form-wrapper {
    padding: 30px 40px;
    width: 493px;
    max-width: 100%;
    flex-shrink: 0;
    overflow: auto;

    .logo {
      margin-bottom: 90px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 30px 20px;
    }
  }

  .image {
    flex: 1;
    @media screen and (max-width: 768px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
