import React, { ReactNode } from 'react';
import { Wrapper } from './Radio.styles';


const Checkbox = (props: {
    label?: string,
    labelElement?: ReactNode,
    children: any,
}) => {
    return <Wrapper>
        <label>
            {props.children}
            <span/>
            {props.label
                ? <span className="label" dangerouslySetInnerHTML={{__html: props.label}}></span>
                : null}
            {props.labelElement
                ? <span className="label">{props.labelElement}</span>
                : null}

        </label>
    </Wrapper>
}


export default Checkbox;
