import styled from 'styled-components';

export const Wrapper = styled.div`
  .empty-state {

  }

  .search {
    width: 600px;
    max-width: 100%;
  }
  
  .expand {
    width: 40px;
  
    cursor: pointer;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    &.expanded {
      transform: rotate(180deg);
    }
    .icon {
      position: absolute;
      z-index: 1;
      width: 40px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center center;
      &:hover{
        img {
          width: 16px;
        }
      }
    }
  }
  
  
  .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;
    .row {
      display: flex;
      align-items: center;
      gap: 10px;
      .label {
        width: 150px;
        color: var(--fontMutedColor);
      }
      .value {
        font-weight: 600;
      }
    }
  }
`