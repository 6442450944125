import React, { ChangeEvent, useEffect, useState } from 'react';
import { Wrapper } from './Select.styles';
import iconDropDown from '../../assets/icon-dropdown.svg';

const Select = (props: {
    label?: string,
    placeholder?: string,
    currentValue: any;
    onChange: (value: any) => void,
    disabled?: boolean,
    children: any,
}) => {
    const [value, setValue] = useState<any | null>(props.currentValue);

    useEffect(() => {
        setValue(props.currentValue);
    }, [props.currentValue])

    const _onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value || null);
        props.onChange(e.target.value);
    }

    return <Wrapper>
        <label>
            <span className="label">{props.label}</span>
            {value === null || value === undefined || value === '' ?
                <span className="placeholder">{props.placeholder}</span> : null}
            <div className={`helper-layer ${props.disabled ? 'disabled' : null}`}>
                <select disabled={!!props.disabled} onChange={_onChange} value={value ?? ''}>
                    {props.children}
                </select>
                <img src={iconDropDown} alt="icon" className='icon-dropdown'/>
            </div>
        </label>


    </Wrapper>
}


export default Select;
