import styled from 'styled-components';

export const Wrapper = styled.div`
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(54, 54, 54, 0.7);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
    z-index: 999;

    &.enter-done {
      opacity: 1;
      pointer-events: visible;

      .modal-content {
        transform: translateY(0);
      }
    }

    &.exit {
      opacity: 0;

      .modal-content {
        transform: translateY(-200px);
      }
    }

    .modal-content {
      width: 500px;
      border-radius: 5px;
      max-height: 100%;
      overflow: auto;
      transition: all 0.3s ease-in-out;
      transform: translateY(-200px);
      padding: 40px 20px 20px;
      background-color: #fff;
      &.reset {
        padding: 0;
        background: transparent;
        overflow: unset;
      }
      position: relative;
      &.error {
      }

      &.warn {
      }

      .close {
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 1;
        cursor: pointer;
        img {
          width: 40px;
        }
      }
      
      .modal-title {
        margin: 0 0 20px;
        font-size: 2.4rem;
        font-weight: 500;
        text-align: center;
      }

      .modal-body {
        text-align: center;

      }
    }
  }
`;
