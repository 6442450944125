import axios, {AxiosInstance} from 'axios';
import i18next from 'i18next';

const getLanguage = (): string => i18next.language || window.localStorage.i18nextLng
const httpClient = (): AxiosInstance => {

    const instance: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
    });

    instance.interceptors.request.use((config) => {
        const urlParams = new URLSearchParams(window.location.search);
        const representedParticipantId = urlParams.get('representedParticipantId');
        config.headers = {
            ...config.headers,
            "Accept-Language": getLanguage(),
        };
        if (representedParticipantId) {
            config.headers = {
                ...config.headers,
                "X-RepresentedParticipantId": representedParticipantId,
            };
        }

        return config;
    });

    // instance.interceptors.response.use(
    //     (response) => {
    //         return response;
    //     },
    //     (error) => {
    //         // console.log(error.response.request.responseURL);
    //         if (error.response.status === 401) {
    //             // window.location.reload();
    //         }
    //
    //         return error;
    //     });

    return instance;
};


export default httpClient();
