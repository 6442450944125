import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100%;

  .user {
    display: flex;
    align-items: center;
    padding: 15px 17px;
    margin-top: -10px;
    gap: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    color: var(--fontColor);
    border-left: 3px solid transparent;
    transition: all .2s linear;

    &.active,
    &:hover {
      border-left: 3px solid var(--mainColor);
      background: var(--disabledColor);
    }
    .avatar {
      height: 30px;
      width: 30px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid var(--fontColor );
      font-weight: bold;
      font-family: var(--fontAccent);
      color: var(--fontColor);
    }
    .data {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .name {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1;
        color: var(--fontColor);
      }
      .email {
        color: var(--fontMutedColor);
        font-size: 1.2rem;
        line-height: 1;
        a {
          color: var(--fontMutedColor);

        }
      }
    }
  }
  .organizations {
    margin-top: -10px;
    .organization {
      padding: 15px 17px;
      display: flex;
      flex-direction: column;
      color: var(--fontColor);
      gap: 3px;
      border-left: 3px solid transparent;
      cursor: pointer;
      transition: all .2s linear;
      text-decoration: none;
      .name {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .plan {
        font-size: 1.2rem;
        line-height: 1;
        color: var(--fontMutedColor);
      }
      &.active,
      &:hover {
        border-left: 3px solid var(--mainColor);
        background: var(--disabledColor);
      }
    }
  }

  .add-organization {
    padding: 15px 17px;
    font-weight: bold;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: all .2s linear;

    .add-icon {
      width: 25px;
      height: 25px;
      color: var(--mainColor);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--disabledColor);
      border-radius: 5px;
      transition: all .2s linear;
    }
    &:hover {
      background: var(--disabledColor);
      .add-icon{
        border: 1px solid var(--mainColor);
      }
    }
  }
`
