import styled from 'styled-components';

const Loader = styled.div`
    margin: auto;
    border: 4px solid #fff;
    border-top: 4px solid var(--mainColor);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 700ms linear infinite;

    &.small {
        width: 20px;
        height: 20px;
    border-width: 3px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default Loader;
