import { Failure } from './failure';
import { Violations } from './failures';

export class DataObject<M extends Failure, T> {
  constructor(
    public failure?: M | null,
    public data?: T | null,
  ) {
  }

  public isFailure(): boolean {
    return !!this.failure;
  }
  public isViolations(): boolean {
    return !!this.failure && this.failure.is(Violations.getCode);
  }
}
