import styled from 'styled-components';

export const Wrapper = styled.div`
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .button {
      width: auto;
      min-width: unset;
    }
  }

  .delete {
    color: var(--errorColor);
  }
`