import React, {useEffect, useState} from "react";
import {Wrapper} from "./EventEventDocuments.styled";
import {ContextDocumentType, Document} from "../../../../logic/documents/documents.model";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import {deleteEventDocument, eventDocuments, eventDocumentUrl} from "../../../../logic/documents/documents";
import {Canceled} from "../../../../logic/core/failures";
import {downloadByUrl, saveFile} from "../../../../logic/files/files.service";
import {Table} from "../../../../components/Table/table";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";
import FilesPreview from "../../../../components/FilesPreview";
import iconMenu from "../../../../assets/icon-menu.svg";
import ContextMenuItem from "../../../../components/ContextMenu/ContextMenuItem";
import ContextMenu from "../../../../components/ContextMenu";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import {useEvent} from "../../../../logic/events/events";
import AddAnnotationModal from "./AddAnnotationModal";
import Annotations from "../../../../components/Annotations";


const EventEventDocuments = () => {
    const {renderError, setError, i18n, t} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const [documents, setDocuments] = useState<Document[] | null>(null);
    const [preview, setPreview] = useState<{ url: string, urls?: string[] } | null>(null);
    const [addAnnotationModal, setAddAnnotationModal] = useState<Document | null>(null);
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null);

    const [downloading, setDownloading] = useState<Set<string>>(new Set([]));

    const {event} = useEvent();

    useEffect(() => {
        _getDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const _getDocuments = async () => {

        eventDocuments(organizationId!, eventId!, {
            type: ContextDocumentType.Event
        }).then((res) => {
            if (res.isFailure()) {
                if (res.failure?.is(Canceled.getCode)) {
                    return;
                }
                setError(res.failure!);
                return
            }
            setDocuments(res.data!);
        });
    }
    const _download = async (d: Document) => {
        downloading.add(d.id)
        setDownloading(new Set(Array.from(downloading)));
        const res = await downloadByUrl(eventDocumentUrl(organizationId!, eventId!, d));
        downloading.delete(d.id)
        setDownloading(new Set(Array.from(downloading)));
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        const saveRes = await saveFile(res.data!.data!, res.data?.name!, res.data?.type!);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }


    }

    const _delete = async (d: Document) => {
        const res = await deleteEventDocument(organizationId!, eventId!, d.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setDocumentToDelete(null);
        _getDocuments();
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>
        <Table className={'multibody'}>
            <thead>
            <tr>
                <th> {t('documents.date')}</th>
                <th> {t('documents.name')}</th>
                <th> {t('documents.category')}</th>
                <th></th>
            </tr>
            </thead>

            {
                documents?.map(d => {
                    return <tbody key={d.id}>
                        <tr >
                            <td>
                                {dateFormatter(new Date(d.timestamp))}
                            </td>
                            <td>
                                {d.name}
                            </td>
                            <td>
                                {d.categoryName ?? '-'}
                            </td>
                            <td>
                                <div className="actions">

                                    {
                                        event?.permissions.canManageDocument
                                            ? <button className="button text" onClick={() => {
                                                setAddAnnotationModal(d)
                                            }}>{t('documents.addAnnotation')}</button>
                                            : null
                                    }

                                    <button className="button text" onClick={() => setPreview({
                                        url: eventDocumentUrl(organizationId!, eventId!, d),
                                        urls: documents?.map(doc => eventDocumentUrl(organizationId!, eventId!, doc),)
                                    })}>
                                        {t('documents.preview')}

                                    </button>
                                    <button className="button text" disabled={downloading.has(d.id)}
                                            onClick={() => _download(d)}>
                                        {downloading.has(d.id) ? <Loader/> : t('documents.download')}
                                    </button>

                                    {
                                        event?.permissions.canManageDocument
                                            ? <ContextMenu icon={iconMenu}>
                                                <ContextMenuItem onClick={() => setDocumentToDelete(d)}>
                                                    <span className={'delete'}>{t('documents.delete')}</span>
                                                </ContextMenuItem>
                                                <></>
                                            </ContextMenu>
                                            : null
                                    }

                                </div>

                            </td>

                        </tr>

                    <Annotations
                        document={d}
                        onDelete={event?.permissions.canManageDocument ? setDocumentToDelete : undefined}
                        onPreview={(doc) => {
                            setPreview({
                                url: eventDocumentUrl(organizationId!, eventId!, doc),
                                urls: d.annotations?.map(doc => eventDocumentUrl(organizationId!, eventId!, doc),)
                            })
                        }}
                        onDownload={_download}
                        downloading={downloading}
                    />
                    </tbody>
                })
            }


        </Table>
        {
            documents ? documents.length ? null : t('documents.emptyList') : <Loader/>
        }


        <Modal width={800} show={!!preview} reset={true} onClose={() => {
            setPreview(null);
        }} backDropClose={false}>
            <FilesPreview url={preview?.url} urls={preview?.urls} onClose={() => setPreview(null)}/>
        </Modal>
        <Modal width={800} show={!!addAnnotationModal} onClose={() => {
            setAddAnnotationModal(null);
        }} backDropClose={false}>
            <AddAnnotationModal document={addAnnotationModal} onClose={() => {
                _getDocuments();
                setAddAnnotationModal(null)
            }}/>
        </Modal>

        <Modal width={600} show={!!documentToDelete} onClose={() => setDocumentToDelete(null)}>
            <ConfirmationModal
                reject={() => setDocumentToDelete(null)}
                accept={() => _delete(documentToDelete!)}
                title={`${t('documents.confirmDocumentDeletionTitle')}`}
            />
        </Modal>

        {renderError()}
    </Wrapper>
}

export default EventEventDocuments;