import React from 'react';
import { Wrapper } from './Switch.styles';


const Switch = (props: {
    label?: string,
    children: any,
}) => {
    return <Wrapper className="switch">
        <label>
            {props.children}
            <span/>
            {props.label
                ? <span className="label">{props.label}</span>
                : null}

        </label>
    </Wrapper>
}


export default Switch;
