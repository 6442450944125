import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from './GlobalStyle';
import { Languages } from './logic/core/models';
import common_en from "./i18n/en.json";
import common_pl from "./i18n/pl.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import AppStateProvider from './appSateProvider';
import { getLanguage } from './logic/language/language';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ToastStateProvider from './components/Toast/toastStateProvider';

i18next.init({
    interpolation: {escapeValue: false},
    lng: getLanguage(),
    react: {
        transSupportBasicHtmlNodes: true,
    },
    resources: {
        [Languages.EN]: {
            common: common_en
        },
        [Languages.PL]: {
            common: common_pl
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <BrowserRouter>
                <AppStateProvider>
                    <ToastStateProvider>
                        <App/>
                    </ToastStateProvider>
                </AppStateProvider>
                <GlobalStyle/>
            </BrowserRouter>
        </I18nextProvider>
    // </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
