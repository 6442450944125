import React, {useEffect, useState} from "react";
import {Wrapper} from "./AddNewOrganizationDocument.styled";
import {ModalCloseFunction} from "../../../../components/Modal";
import {useForm} from "../../../../logic/core/form.hook";
import {DocumentBody} from "../../../../logic/documents/documents.model";
import FormField from "../../../../components/FormField";
import Input from "../../../../components/Input";
import {addNewDocument} from "../../../../logic/documents/documents";
import {useParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {Violations} from "../../../../logic/core/failures";
import {getBodies} from "../../../../logic/bodies/bodies";
import {Body} from "../../../../logic/bodies/bodies.model";
import FilesPicker from "../../../../components/FilesPicker";
import Checkbox from "../../../../components/Checkbox";
import removeIcon from '../../../../assets/icon-close-red.svg'
import Loader from "../../../../components/Loader";

const AddNewOrganizationDocument = ({onClose}: { onClose: ModalCloseFunction }) => {
    const {setError, renderError, loading, setLoading, t} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [bodies, setBodies] = useState<Body[]>([])
    const {formControl, formValid, renderValidationMessage, formValue, setValidations} = useForm<DocumentBody>({
        name: {
            value: '',
        },
        bodyIds: {
            value: [],
        },
        file: {
            value: null,
        }

    })

    useEffect(() => {

        getBodies(organizationId!).then((res) => {
            if (res.isFailure()) {
                return
            }
            setBodies(res.data!);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _save = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);

        const data: DocumentBody = {
            ...formValue
        }

        const res = await addNewDocument(organizationId!, data)
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }

        onClose();

    }
    return <Wrapper>

        <h1 className="title">
            {t('documents.newDocTitle')}
        </h1>

        <FormField>
            <Input label={t('documents.name') + ' *'}>
                <input type="text"
                       value={formControl('name').value}
                       onChange={(e) => formControl('name').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('name')}
        </FormField>

        {
            formControl('file').value
                ? <>
                    <div className={'selected-file'}>
                        <div className="name">
                            {formControl('file').value.name}
                        </div>
                        <div className="delete" onClick={() => formControl('file').patchValue(null)}>
                            <img src={removeIcon} alt="delete"/>
                        </div>
                    </div>
                    {renderValidationMessage('file')}
                </>
                : <div className="document-field">
                    <FormField>
                        <div className="label">
                            {t('documents.document')} *
                        </div>
                        <div className="hint">
                            {t('documents.docHint')}
                        </div>
                        <FilesPicker maxSizeInMB={20}
                                     supportedFiles={[
                                         {
                                             mimeType: 'application/pdf',
                                             displayValue: 'PDF'
                                         },
                                         {
                                             mimeType: 'application/msword',
                                             displayValue: 'DOC'
                                         },
                                         {
                                             mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                             displayValue: 'DOCX'
                                         },
                                         {
                                             mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
                                             displayValue: 'ODS'
                                         },
                                         {
                                             mimeType: 'application/vnd.oasis.opendocument.text',
                                             displayValue: 'ODT'
                                         },
                                         {
                                             mimeType: 'application/vnd.ms-excel',
                                             displayValue: 'XLS'
                                         },
                                         {
                                             mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                             displayValue: 'XLSX'
                                         },
                                         {
                                             mimeType: 'image/jpeg',
                                             displayValue: 'JPG'
                                         },
                                         {
                                             mimeType: 'image/png',
                                             displayValue: 'PNG'
                                         },
                                     ]}
                                     fileChange={(file) => formControl('file').patchValue(file)}/>

                        {renderValidationMessage('file')}

                    </FormField>

                </div>
        }
        <div className="select-bodies">
            {t('documents.selectBodies')}
        </div>
        <div className="bodies">

            {
                bodies.map(b => {
                    return <FormField key={b.id}>
                        <Checkbox label={b.name}>
                            <input type="checkbox" checked={formControl('bodyIds').value.includes(b.id)}
                                   onChange={() => {
                                       const index: number = formControl('bodyIds').value.findIndex((id: string) => id === b.id);
                                       if (index === -1) {
                                           formControl('bodyIds').patchValue([
                                               ...formControl('bodyIds').value,
                                               b.id,
                                           ])
                                       } else {
                                           formControl('bodyIds').value.splice(index, 1);
                                           formControl('bodyIds').patchValue(formControl('bodyIds').value);
                                       }

                                   }}/>
                        </Checkbox>
                    </FormField>
                })
            }

            {renderValidationMessage('bodyIds')}
        </div>

        <div className="buttons">
            <button className="button text muted" onClick={onClose} disabled={loading}>
                {t('documents.cancel')}
            </button>

            <button className="button" onClick={_save} disabled={loading}>
                {
                    loading ? <Loader/> : t('documents.save')
                }
            </button>
        </div>
        {renderError()}
    </Wrapper>
}

export default AddNewOrganizationDocument;