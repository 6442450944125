
import React from "react";
import {Wrapper} from "./EventInfoDescription.styled";
import HtmlRenderer from "../../../components/HtmlRenderer";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import {EventModel} from "../../../logic/events/events.model";
import {Organization} from "../../../logic/organizations/organizations.model";

const EventInfoDescription = ({event, organization}:{event: EventModel, organization: Organization}) => {
    const{t, i18n} = useBaseScreenHook();
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    return <Wrapper>
        <table className="info">
            <tbody>

            <tr>
                <td>{t('eventInfo.date')}</td>
                <td>{event?.content?.basic.startDate && dateFormatter(new Date(event?.content?.basic.startDate))}</td>
            </tr>
            {
                event?.content?.basic.finishDate
                    ?<tr>
                        <td>{t('eventInfo.finishDate')}</td>
                        <td>{dateFormatter(new Date(event?.content?.basic.finishDate))}</td>
                    </tr>
                    : null
            }

            <tr>
                <td>{t('eventInfo.host')}</td>
                <td>
                    <div className="host-data">
                        {organization.name}
                        <div className="organization-data">
                            {
                                organization.address?.street
                                    ? <p>
                                        {organization.address?.street}
                                    </p>
                                    : null
                            }
                            {
                                organization.address
                                    ? <p>
                                        {organization.address?.postalCode} {organization.address?.city} ,
                                        {organization.address?.country}
                                    </p> : null
                            }
                            {
                                organization.nip
                                    ? <p>
                                        {t('eventInfo.nip')} {organization.nip}
                                    </p>
                                    : null
                            }
                            {
                                organization.email
                                    ? <p>
                                        {organization.email}
                                    </p>
                                    : null
                            }
                            {
                                organization.webPage
                                    ? <p>
                                        {organization.webPage}
                                    </p>
                                    : null
                            }


                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>{t('eventInfo.body')}</td>
                <td>
                    {event?.content?.basic.body.name}
                </td>
            </tr>
            </tbody>
        </table>

        <div className="event-description">
            <HtmlRenderer html={event?.content?.description.value ?? ''}/>
        </div>

    </Wrapper>
}

export default EventInfoDescription;