import styled from 'styled-components';

export const Wrapper = styled.div`
  .top-bar{
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    border-bottom: 1px solid var(--disabledColor);
    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .left-bar {
      display: flex;
      align-items: center;
      gap: 10px;
      .divider {
        width: 1px;
        height: 30px;
        background: var(--disabledColor);
      }
      .lang {
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--fontMutedColor);
        cursor: pointer;
        .icon {
          width: 20px;
          height: 20px;
          border: 1px solid var(--fontMutedColor);
          border-radius: 50%;
          overflow: hidden;
          img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 1.2rem;
          font-weight: bold;
          font-family: var(--fontAccent);
          text-transform: capitalize;
        }
      }
      .user-box {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: var(--disabledColor);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-family: var(--fontAccent);
        }
      }
    }
  }
`
