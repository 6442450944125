import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  overflow: auto;
  max-height: 90vh;

  .content {
    padding: 20px;
  }

  .description {
    font-size: 1.4rem;
    color: var(--fontMutedColor);
    margin-bottom: 10px;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .form {
    width: 100%;
    display: flex;
    align-items: stretch;

    & > * {
      width: 50%;
      flex-shrink: 0;
      padding: 0 20px;

      & > *:last-child {
        margin-bottom: 0 !important;
      }
    }

  }

  .document-wrapper {
    border: 1px solid var(--disabledColor);
    border-radius: 5px;
    height: calc(100% - 17px);
    margin-top: -17px;
    overflow: hidden;
    max-height: calc(100vh - 300px);
  }

  .buttons {
    border-top: 1px solid var(--disabledColor);
    margin-top: 30px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .border-box {
    border: 1px solid var(--disabledColor);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .value-type {
    display: flex;
    gap: 20px;

    & > :first-child {
      flex: 1;
    }

    &.margin-top {
      .number,
      .divide,
      .percentage {
        margin-top: 17px;
      }
    }

    .number,
    .divide,
    .percentage {
      display: flex;
      align-items: flex-start;
      gap: 5px;

      .sign {
        margin-top: 12px;
      }

      .form-field {
        width: 80px;
      }
    }
  }
.file-picker-wrapper {
  margin-top: -17px;
}
`
