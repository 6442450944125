import React, { useEffect, useState } from 'react';
import { Wrapper } from './OrganizationDashboardScreen.styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Organization } from '../../../logic/organizations/organizations.model';
import ErrorModal from '../../../components/ErrorModal';
import { Failure } from '../../../logic/core/failure';
import Modal from '../../../components/Modal';
import OrganizationBodiesCreateModal from '../OrganizationBodiesScreen/OrganizationBodiesCreateModal';
import { AppRoutes } from '../../../logic/core/routes';
import EventCreateModal from '../OrganizationEventsScreen/EventCreateModal';
import {useAppStateHook} from "../../../logic/core/appState.hook";

const OrganizationDashboardScreen = () => {
    const [t] = useTranslation('common');
    const {organizationId} = useParams();
    const [error, setError] = useState<Failure | null>(null);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [openBodyCreateModal, setOpenBodyCreateModal] = useState<boolean>(false);
    const [openEventCreateModal, setOpenEventCreateModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const {getOrganization} = useAppStateHook();
    useEffect(() => {
        if (organizationId){
            getOrganization().then((res) => {
                if (res.isFailure()){
                    return;
                }

                setOrganization(res.data!);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId])

    const _createBodyModalClosed = (refreshData: boolean, bodyId?: string) => {
        setOpenBodyCreateModal(false);
        if (refreshData) {
           navigate(`/${AppRoutes.organization}/${organizationId}/${AppRoutes.host}/${AppRoutes.bodies}/${bodyId}/${AppRoutes.members}`);
        }
    }

    return <Wrapper>

        <div className="welcome-box">
            <h1 className="title">{organization?.name ?? ''}</h1>

            <p className="description">
                {t('organizationDashboard.description')}
            </p>

            <div className="buttons">
                <button className={'button'} onClick={() => setOpenEventCreateModal(true)}>
                    {t('organizationDashboard.createEventBtn')}
                </button>
                <button className="button text" onClick={() => setOpenBodyCreateModal(true)}>
                    {t('organizationDashboard.addBodyBtn')}
                </button>
            </div>
        </div>

        <ErrorModal failure={error} onClose={()=> setError(null)}/>
        <Modal width={700} show={openBodyCreateModal} onClose={() => setOpenBodyCreateModal(false)} backDropClose={false}>
            <OrganizationBodiesCreateModal onClose={(refreshData: boolean, bodyId: string | undefined) => _createBodyModalClosed(refreshData, bodyId)}/>
        </Modal>
        <Modal width={700} show={openEventCreateModal} onClose={() => setOpenEventCreateModal(false)} backDropClose={false}>
            <EventCreateModal onClose={() => setOpenEventCreateModal(false)}/>
        </Modal>
    </Wrapper>
}

export default OrganizationDashboardScreen;
