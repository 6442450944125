import React from "react";
import {Wrapper} from "./Annotations.styled";
import {Document} from "../../logic/documents/documents.model";
import {useBaseScreenHook} from "../../logic/core/base-screen.hook";
import ContextMenu from "../ContextMenu";
import ContextMenuItem from "../ContextMenu/ContextMenuItem";
import Loader from "../Loader";
import iconMenu from "../../assets/icon-menu.svg";

const Annotations = ({
                         document,
                         onPreview,
                         onDownload,
                         onDelete,
                         downloading,
                     }: { document: Document, onPreview: (doc: Document) => void, onDownload: (doc: Document) => void, onDelete?: (doc: Document) => void, downloading: Set<string> }) => {
    const {i18n, t} = useBaseScreenHook();

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;


    return <>
        {
            document.annotations?.map((annotation, i) => {
                return <Wrapper key={annotation.id} className={'exclude'}>
                    <td>
                        {dateFormatter(new Date(annotation.timestamp))}
                    </td>
                    <td className={'annotation-name'}>
                        <div className="helper">
                            <div className={`decoration ${i === document.annotations!.length - 1 ? 'last' : ''}`}></div>
                            {annotation.name}
                        </div>

                    </td>
                    <td>
                        {annotation.categoryName ?? '-'}
                    </td>
                    <td>
                        <div className="actions">

                            <button className="button text" onClick={() => onPreview(annotation)}>
                                {t('documents.preview')}

                            </button>
                            <button className="button text" disabled={downloading.has(annotation.id)}
                                    onClick={() => onDownload(annotation)}>
                                {downloading.has(annotation.id) ? <Loader/> : t('documents.download')}
                            </button>

                            {
                                onDelete
                                    ? <ContextMenu icon={iconMenu}>
                                        <ContextMenuItem onClick={() => onDelete(annotation)}>
                                            <span className={'delete'}>{t('documents.delete')}</span>
                                        </ContextMenuItem>
                                        <></>
                                    </ContextMenu>
                                    : null
                            }

                        </div>

                    </td>
                </Wrapper>
            })
        }

    </>
}

export default Annotations;