import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;

  .tabs {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    height: 50px;
    overflow: auto;
    margin-bottom: 20px;

    .tab {
      white-space: nowrap;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-top: 1px solid var(--disabledColor);
      font-size: 1.4rem;
      text-decoration: none;
      color: var(--fontColor);
      border-bottom: 1px solid var(--disabledColor);

      &.active,
      &:hover {
        border-bottom: 3px solid var(--mainColor);
        border-top: 1px solid var(--disabledColor);;
        font-weight: bold;
      }
    }
  }
    .nav-buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
    }

`
