import React, { useEffect, useRef, useState } from 'react';
import ValidationMessage from '../../components/ValidationMessage';

export type FormControlValidator = (value: any) => string | null;

export enum FormState {
    invalid = 'invalid',
    valid = 'valid',
}

export interface FormControls {
    [key: string]: FormControlConfig;
}

export interface FormControlConfig {
    value?: any;
    disabled?: boolean;
    validators?: FormControlValidator[];
    state?: FormState;
    controls?: FormControls;
}

export interface FormConfig {
    controls: FormControls;
}

export interface FormControl {
    value: any,
    patchValue: (value: any) => void,
    control: FormControlConfig,
}


export function useForm<T>(registerControls: FormControls) {
    const VALIDATIONS = useRef<{ [key: string]: string | undefined }>({});
    const [validationsData, setValidationsData] = useState<{ [key: string]: string | undefined }>(VALIDATIONS.current);
    const [formTouched, setFormTouched] = useState<boolean>(false);
    const [formConfig, setFormConfig] = useState<FormConfig>({
        controls: {...registerControls},
    });

    useEffect(() => {
        _triggerValidations(formConfig.controls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setValidations = (v: { [key: string]: string | undefined }) => {
        VALIDATIONS.current = {
            ...v,
        }
        setValidationsData(VALIDATIONS.current);
    }

    const formValid = () => {
        setFormTouched(true);
        _triggerValidations(formConfig.controls);
        return !_findInvalidControl(formConfig.controls);
    }

    const formControl = (name: string) => {
        const namePath = name.split('.');
        const {control, patchValue, value} = _getFormControl(formConfig.controls, namePath, name)
        const disable = () => {
            control.disabled = true;
            setFormConfig({...formConfig});
        }
        const enable = () => {
            control.disabled = false;
            setFormConfig({...formConfig});
        }
        return {control, patchValue, value, disable, enable};
    }

    const formValue: T = _getValues(formConfig.controls, true) as T;

    const formRawValue: T = _getValues(formConfig.controls) as T;

    const renderValidationMessage = (name: string) => {
        return <ValidationMessage show={formTouched && !!validationsData[name]}>
            {validationsData[name]}
        </ValidationMessage>
    }

    const _getFormControl = (controls: FormControls, namePath: string[], name: string, parentControl?: FormControlConfig): FormControl => {
        const control: FormControlConfig = controls[namePath[0]];
        if (!control) {
            throw Object.assign(
                new Error(`Form control "${namePath[0]}" not found. \nPossible options: \n${Object.keys(controls)}`),
                {}
            );
        }
        if (control.controls && namePath.length > 1) {
            namePath.shift();
            return _getFormControl(control.controls, namePath, name, control)
        }

        const patchValue = (value: any) => {
            control.value = value;
            _validate(control, name);
            if (parentControl) {
                let parentName = '';
                const splitName = name.split('.');
                splitName.forEach((name, index) => {
                    if (index === splitName.length - 1) {
                        return;
                    }
                    if (index !== 0) {
                            parentName = parentName + '.'
                        }
                        parentName = parentName +  name;
                })
                _validate(parentControl, parentName);
            }
            controls[namePath[0]] = {
                ...control,
                value,
            };
            setFormConfig({...formConfig});
        }

        const value = control?.value;

        return {control, patchValue, value}
    }

    const _triggerValidations = (controls: FormControls) => {
        Object.keys(controls).forEach((key) => {
            const c = controls[key];
            if (c.controls && (c.controls.length || Object.values(c.controls).length)) {
                _triggerValidations(c.controls);
            } else {
                _validate(c, key);
            }

        });
    }

    const _validate = (control: FormControlConfig, name: string) => {
        if (control?.validators?.some((fn) => fn(control.value)) && !control.disabled) {
            control.validators.forEach((fn) => {
                const msg = fn(control.value);
                if (msg) {
                    control.state = FormState.invalid;
                    VALIDATIONS.current = {
                        ...VALIDATIONS.current,
                        [name]: msg!,
                    }
                }
            });
        } else {
            control.state = FormState.valid;
            VALIDATIONS.current = {
                ...VALIDATIONS.current,
                [name]: undefined,
            }
        }
        setValidations(VALIDATIONS.current);
    }
    return {
        formConfig,
        formControl,
        formValue,
        formRawValue,
        setFormConfig,
        renderValidationMessage,
        setValidations,
        formValid,
        formTouched
    };
}

const _getValues = (controls: FormControls, raw: boolean = false) => {
    return Object.keys(controls).reduce((acc: { [key: string]: any }, val: string) => {
        acc[val] = raw && controls[val].disabled
            ? undefined
            : controls[val].controls
                ? _getValues(controls[val].controls!, raw)
                : controls[val].value || null;
        return {...acc};

    }, {});
}


const _findInvalidControl = (controls: FormControls): boolean => {
    return Object.values(controls).some((c) => {
        if (c.controls && Object.values(c.controls)?.length) {
            return _findInvalidControl(c.controls);
        }
        return c.state === FormState.invalid;
    })
}

