import styled from 'styled-components';

export const Wrapper = styled.div`
    
    .title {
        font-size: 24px;
        margin-bottom: 24px;
    }

    table {
        text-align: left;
    }
    .status {
        font-weight: 500;
        color: var(--errorColor);
        &.success {
            color: var(--successColor);
        }
    }
    .buttons {
        margin-top: 20px;
    }

`
