import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  
  .sub-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  

  .add-description-hint {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
    margin-top: 10px;
      margin-bottom: 20px;
  }
`
