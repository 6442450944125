import styled from 'styled-components';

export const Wrapper = styled.div`
  .event-start-counter {
    margin-bottom: 20px;
    padding: 10px 20px;
    background: var(--waringColor);
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
  }

`