import React from 'react';
import { Wrapper } from './AccountScreen.styles';
import { NavLink, Outlet } from 'react-router-dom';
import { AppRoutes } from '../../logic/core/routes';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';

const AccountScreen = () => {

    const {t} = useBaseScreenHook();

    return <Wrapper>
        <div className="tabs">
            <NavLink className={`tab`} to={AppRoutes.events}>
                {t('accountScreen.events')}
            </NavLink>
            <NavLink className={`tab`} to={AppRoutes.documents}>
                {t('accountScreen.docs')}
            </NavLink>
            <NavLink className={`tab`} to={AppRoutes.profile}>
                {t('accountScreen.profile')}
            </NavLink>
        </div>

        <Outlet/>
    </Wrapper>
}

export default AccountScreen;
