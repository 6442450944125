import React from 'react';
import { Wrapper } from './OrganizationData.styles';
import { NavLink, Outlet } from 'react-router-dom';
import { useBaseScreenHook } from '../../../logic/core/base-screen.hook';
import { AppRoutes } from '../../../logic/core/routes';

const OrganizationData = () => {
    const {t} = useBaseScreenHook();
    return <Wrapper>

        <div className="menu">
            <NavLink to={AppRoutes.details}>{t('organizationData.data')}</NavLink>
            <NavLink to={AppRoutes.invitationEmails}>{t('organizationData.invitationEmails')}</NavLink>
        </div>
        <div className="outlet">
            <Outlet/>
        </div>

    </Wrapper>
}

export default OrganizationData;
