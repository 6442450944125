import React from 'react';
import { Wrapper } from './ProfileDataScreen.styles';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import FormField from '../../../../components/FormField';
import { useAppStateHook } from '../../../../logic/core/appState.hook';

const ProfileDataScreen = () => {
    const {appState} = useAppStateHook();

    const {t} = useBaseScreenHook();

    return <Wrapper>
        <div className="title">
            {t('profileDataScreen.title')}
        </div>

        <p className="description">
            {t('profileDataScreen.description')}
        </p>

        <div className="data">
            <FormField>
                <div className="label">
                    {t('profileDataScreen.name')}
                </div>
                <div className="value">
                    {appState.user?.name}
                </div>
            </FormField>
        </div>
    </Wrapper>
}

export default ProfileDataScreen;
