import React from "react";
import {NavLink, Outlet} from "react-router-dom";
import {AppRoutes} from "../../../logic/core/routes";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import { Wrapper } from "./OrganizationDocumentsScreen.styled";

const OrganizationDocumentsScreen = () => {
    const {t} = useBaseScreenHook();

    return <Wrapper>


        <h1 className="title">
            Repozytorium dokumentów
        </h1>

        <div className="document-tabs">
            <NavLink className={`tab`} to={AppRoutes.events}>
                {t('documents.events')}
            </NavLink>
            <NavLink className={`tab`} to={AppRoutes.organization}>
                {t('documents.organization')}
            </NavLink>
        </div>

        <Outlet/>

    </Wrapper>
}

export default OrganizationDocumentsScreen;