import React, {useEffect, useState} from "react";
import {Wrapper} from "./moderators.styled";
import {useEventParticipantsStateHook} from "../participantsHooh";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import {makeChairman, removeChairman, useEvent} from "../../../../logic/events/events";
import Loader from "../../../../components/Loader";
import {Table} from "../../../../components/Table/table";
import ContextMenu from "../../../../components/ContextMenu";
import iconMenu from "../../../../assets/icon-menu.svg";
import ContextMenuItem from "../../../../components/ContextMenu/ContextMenuItem";
import Modal from "../../../../components/Modal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import {StatusModalConfirmationWrapper} from "../../../OrganizationScreen/OrganizationUsers/OrganizationUsers.styles";
import {EventModerator} from "../participantsProvider";

const Moderators = () => {
    const {participantsState, getModerators} = useEventParticipantsStateHook();
    const {t, renderError, setError} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const {event, organization} = useEvent();
    const [moderatorToMakeChairman, setModeratorToMakeChairman] = useState<EventModerator | null>(null);
    const [moderatorToRemoveChairman, setModeratorToRemoveChairman] = useState<EventModerator | null>(null);

    useEffect(() => {
        _getModerators();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    const _getModerators = () => {
        getModerators().then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
        })
    }


    const _setChairman = async () => {
        const res = await makeChairman(organizationId!, eventId!, null, null, moderatorToMakeChairman!.id);
        setModeratorToMakeChairman(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _removeChairman = async () => {
        const res = await removeChairman(organizationId!, eventId!);
        setModeratorToRemoveChairman(null);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }
    const canModify: boolean = participantsState.moderators?.canModify ?? false;

    return <Wrapper>

        <div className="top-bar">
            <p className="description">
                {t('eventModerators.description', {organizationName: organization?.name})}
            </p>
        </div>


        <Table>
            <thead>
            <tr>
                <th>
                    {t('eventModerators.name')}
                </th>

                <th>
                    {t('eventModerators.email')}
                </th>
                <th>
                    {t('eventModerators.permissions')}
                </th>

                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                participantsState.moderators?.moderators?.map((g) => {
                    return <tr key={g.id}>

                        <td>
                            {g.name}
                        </td>
                        <td>
                            {g.email}
                        </td>
                        <td>
                            <div className="statuses">

                                {
                                    event?.content?.chairmanReference?.moderatorId === g.id
                                        ? <div className="status">
                                            {t(`eventParticipants.chairman`)}
                                        </div>
                                        : null
                                }
                            </div>
                        </td>

                        <td>
                            <div className="actions">
                                <div className="menu">
                                    {
                                        canModify && event?.permissions.canAssignChairman
                                            ? <ContextMenu icon={iconMenu}>
                                                {
                                                    event?.content?.chairmanReference?.moderatorId === g.id
                                                        ? <ContextMenuItem onClick={() => setModeratorToRemoveChairman(g)}>
                                                            {t('eventParticipants.removeChairman')}
                                                        </ContextMenuItem>
                                                        : <ContextMenuItem onClick={() => setModeratorToMakeChairman(g)}>
                                                            {t('eventParticipants.setChairman')}
                                                        </ContextMenuItem>
                                                }
                                                <></>
                                            </ContextMenu>
                                            : null
                                    }
                                </div>
                            </div>
                        </td>

                    </tr>
                })
            }
            </tbody>
        </Table>

        <Modal width={600} show={!!moderatorToMakeChairman} onClose={() => setModeratorToMakeChairman(null)}>
            <StatusModalConfirmationWrapper className={'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setModeratorToMakeChairman(null)}
                    accept={() => _setChairman()}
                    acceptLabel={t('eventParticipants.makeChairmanAcceptLabel')}
                    body={t('eventParticipants.makeChairmanBody', {name: moderatorToMakeChairman?.name})}
                    title={t('eventParticipants.makeChairmanTitle', {name: moderatorToMakeChairman?.name})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        <Modal width={600} show={!!moderatorToRemoveChairman} onClose={() => setModeratorToRemoveChairman(null)}>
            <StatusModalConfirmationWrapper>
                <ConfirmationModal
                    reject={() => setModeratorToRemoveChairman(null)}
                    accept={() => _removeChairman()}
                    acceptLabel={t('eventParticipants.removeChairmanAcceptLabel')}
                    body={t('eventParticipants.removeChairmanBody', {name: moderatorToMakeChairman?.name})}
                    title={t('eventParticipants.removeChairmanTitle', {name: moderatorToMakeChairman?.name})}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default Moderators;