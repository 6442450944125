import React from 'react';
import Modal, { ModalCloseFunction, ModalType } from '../Modal';
import { Failure } from '../../logic/core/failure';
import { useTranslation } from 'react-i18next';

export interface ModalErrorData {
    failure: Failure | null;
    onClose: ModalCloseFunction;
}

const ModalError = ({failure, onClose}: ModalErrorData) => {
    const [t] = useTranslation('common');
    return <Modal type={ModalType.error} title={t('errorModal.title')} show={!!failure} onClose={onClose}>
        {failure?.message}
    </Modal>
}

export default ModalError;
