import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 2;
  border-top: 1px solid var(--bgLighColor);
  margin-top: -0;
  position: sticky;
  bottom: 0;
  padding: 10px 0px;
  background: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pagination-state {
    padding: 16px 0;
  }
  .paginator {
    display: flex;
    align-items: center;
    gap: 24px;
    .size {
      display: flex;
      align-items: center;
      gap: 7px;
      .form-field {
        margin-bottom: 0;
        width: 60px;
      }
    }
    .navigator {
      display: flex;
      align-items: center;
      .prev,
      .next {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          transform: rotate(90deg);
        }
        &.disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }
      .next {
        img {
          transform: rotate(-90deg);
        }
      }
      .pages {
        display: flex;
        .page {
          border-radius: 4px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.more {
            cursor: default;
            pointer-events: none;
          }
          &.active {
            background: #dddddd;
          }
          &:hover {
            background: var(--bgLighColor);
          }
        }
      }
    }
  }

`
