import React from "react";
import {Wrapper} from "./InviteGuestModal.styles";
import {ModalCloseFunction} from "../../../../../components/Modal";
import Input from "../../../../../components/Input";
import FormField from "../../../../../components/FormField";
import {useForm} from "../../../../../logic/core/form.hook";
import {Textarea} from "../../../../../components/Textarea/Textarea.styles";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {EventGuestBody} from "../../../../../logic/events/events.model";
import {useParams} from "react-router-dom";
import {sendGuestInvitation} from "../../../../../logic/events/events";
import {Violations} from "../../../../../logic/core/failures";

const InviteGuestModal = ({onClose}: { onClose: ModalCloseFunction }) => {
    const {organizationId, eventId} = useParams();

    const {formControl, formValid, renderValidationMessage, formValue, setValidations} = useForm<EventGuestBody>({
        email: {
            value: ''
        },
        firstName: {
            value: ''
        },
        lastName: {
            value: ''
        },
        comment: {
            value: ''
        },
    })

    const {loading, setLoading, setError, renderError, t} = useBaseScreenHook();

    const _submit = async () => {
        if (!formValid()) {
            return;
        }

        setLoading(true);

        const res = await sendGuestInvitation(organizationId!, eventId!, formValue);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose();
    }

    return <Wrapper>
        <div className="title">
            {t('inviteGuestModal.title')}
        </div>

        <FormField>
            <Input label={t('inviteGuestModal.email') + ' *'}>
                <input type="text"
                       value={formControl('email').value}
                       onChange={(e) => formControl('email').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('email')}
        </FormField>

        <div className="row">

            <FormField>
                <Input label={t('inviteGuestModal.firstName') + ' *'}>
                    <input type="text"
                           value={formControl('firstName').value}
                           onChange={(e) => formControl('firstName').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('firstName')}
            </FormField>

            <FormField>
                <Input label={t('inviteGuestModal.lastName') + ' *'}>
                    <input type="text"
                           value={formControl('lastName').value}
                           onChange={(e) => formControl('lastName').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('lastName')}
            </FormField>

        </div>

        <FormField>
            <div className="label">
                {t('inviteGuestModal.comment')}
            </div>
            <Textarea>
                        <textarea
                            value={formControl('comment').value}
                            onChange={(e) => formControl('comment').patchValue(e.target.value)}/>
            </Textarea>
            {renderValidationMessage('comment')}
        </FormField>

        <button className={'button'} onClick={_submit} disabled={loading}>
            {t('inviteGuestModal.send')}
        </button>

        {renderError()}
    </Wrapper>
}

export default InviteGuestModal;