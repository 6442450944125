import React from 'react';
import { Wrapper } from './EventTitleModal.styles';
import { ModalCloseFunction } from '../../../../components/Modal';
import { useBaseScreenHook } from '../../../../logic/core/base-screen.hook';
import { useForm } from '../../../../logic/core/form.hook';
import FormField from '../../../../components/FormField';
import Input from '../../../../components/Input';
import { addAgendaPoint, updateAgendaPoint } from '../../../../logic/events/events';
import { useParams } from 'react-router-dom';
import { Violations } from '../../../../logic/core/failures';
import { AgendaPoint } from '../../../../logic/events/events.model';
import Wysiwyg from "../../../../components/Wysiwyg";

const EventTitleModal = ({onClose, point, closingItemIndex}: { onClose: ModalCloseFunction, point?: AgendaPoint | null, closingItemIndex: number | undefined }) => {
    const {t, renderError, setError, loading, setLoading} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const {formValue, formControl, renderValidationMessage, formValid, setValidations} = useForm<{title :string, description:string}>({
        title: {
            value: point?.title ?? ''
        },
        description: {
            value: point?.description ?? ''
        }
    })

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        let res;
        setLoading(true);
        if (point) {
            res = await updateAgendaPoint(organizationId!, eventId!, point.id, formValue.title, formValue.description);

        } else {
            res = await addAgendaPoint(organizationId!, eventId!, formValue.title,  formValue.description, closingItemIndex === -1 ? undefined : closingItemIndex);
        }
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose();

    }
    return <Wrapper>
        <div className="title">
            {
                point ? t('eventTitleModal.titleEdit') : t('eventTitleModal.title')
            }
        </div>

        <form>

            <FormField>
                <Input label={t('eventTitleModal.name') + ' *'}>
                    <input type="text"
                           value={formControl('title').value}
                           onChange={(e) => formControl('title').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('title')}
            </FormField>
            <FormField>
                <div className="label">{t('eventTitleModal.description')}</div>

                <Wysiwyg content={point?.description ?? ''} onChange={(value) => formControl('description').patchValue(value)}/>
            </FormField>
        </form>

        <div className="buttons">

            <button className="button text muted" onClick={onClose} disabled={loading}>
                {t('eventTitleModal.cancel')}
            </button>

            <button className="button" onClick={_submit} disabled={loading}>
                {t('eventTitleModal.save')}

            </button>

        </div>
        {renderError()}
    </Wrapper>
}

export default EventTitleModal;
