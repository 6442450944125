import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100%;
  
  .context-selector {
    margin-left: auto;
    margin-right: 20px;
  }
  
  .error {
    padding: 0 20px;
    text-align: center;
    margin-top: 100px;
    font-size: 2rem;
    color: var(--errorColor);
  }
  .event-start-countdown {
    margin-bottom: -20px;
  }
`
