import React, {useEffect, useState} from "react";
import {Wrapper} from "./OrganizationParticipantOrganizationDocuments.styles";
import {Table} from "../../../../components/Table/table";
import Select from "../../../../components/Select";
import {useParams} from "react-router-dom";
import {
    participantAvailableBodies,
    participantAvailableOrganizations,
    participantDocumentUrl,
    participantOrganizationDocuments
} from "../../../../logic/documents/documents";
import {ContextDocumentType, Document, DocumentFilers, IdName} from "../../../../logic/documents/documents.model";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {Canceled} from "../../../../logic/core/failures";
import Loader from "../../../../components/Loader";
import {downloadByUrl, saveFile} from "../../../../logic/files/files.service";
import FilesPreview from "../../../../components/FilesPreview";
import Modal from "../../../../components/Modal";
import Annotations from "../../../../components/Annotations";

let inputDebouncer: ReturnType<typeof setTimeout> | null = null;
let abortController: AbortController | null = null;


const OrganizationParticipantOrganizationDocuments = () => {
    const [availableFilters, setAvailableFilters] = useState<{ organizations: IdName[], bodies: IdName[] } | null>(null);
    const [filters, setFilters] = useState<DocumentFilers>({
        type: ContextDocumentType.Organization
    });
    const {organizationId} = useParams();
    const {renderError, setError, i18n, t} = useBaseScreenHook();
    const [downloading, setDownloading] = useState<Set<string>>(new Set([]));
    const [preview, setPreview] = useState<{ url: string, urls?: string[] } | null>(null);

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const [documents, setDocuments] = useState<Document[] | null>(null);
    useEffect(() => {
        _getAvailableFilters();
        _getDocuments(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getAvailableFilters = () => {
        Promise.all([
            participantAvailableOrganizations(organizationId!, ContextDocumentType.Organization),
            participantAvailableBodies(organizationId!, ContextDocumentType.Organization),
        ]).then((res) => {
            const [
                organizationsRes,
                bodiesRes,
            ] = res;
            const failure = res.find(r => r.isFailure());
            if (failure) {
                setError(failure.failure!);
                return;
            }

            setAvailableFilters({
                organizations: organizationsRes.data!,
                bodies: bodiesRes.data!,
            })
        })

    }

    const cleatInputDebouncer = () => {
        if (inputDebouncer) {
            clearTimeout(inputDebouncer);
            inputDebouncer = null;
        }
        if (abortController) {
            abortController.abort();
            abortController = null;
        }
    }

    const _setFilters = (filters: DocumentFilers) => {
        setFilters(filters);
        setDocuments(null);
        cleatInputDebouncer();
        inputDebouncer = setTimeout(() => {
            _getDocuments(filters)
        }, 300);
    }

    const _getDocuments = (filters: DocumentFilers) => {
        abortController = new AbortController();
        _getAvailableFilters();

        participantOrganizationDocuments(organizationId!, filters, abortController).then((res) => {
            if (res.isFailure()) {
                if (res.failure?.is(Canceled.getCode)) {
                    return;
                }
                setError(res.failure!);
                return
            }
            setDocuments(res.data!);
        });
    }

    const _download = async (d: Document) => {
        downloading.add(d.id)
        setDownloading(new Set(Array.from(downloading)));
        const res = await downloadByUrl(participantDocumentUrl(organizationId!, d));
        downloading.delete(d.id)
        setDownloading(new Set(Array.from(downloading)));
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        const saveRes = await saveFile(res.data!.data!, res.data?.name!, res.data?.type!);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }


    }


    return <Wrapper>

        <p className="description">
            Dokumenty udostępnione przez organizacje dla członków organów.
        </p>

        <Table>
            <thead>
            <tr>
                <th>
                    Wybierz organizację
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.organizationId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    organizationId: e
                                })}
                        >
                            <option/>
                            {availableFilters?.organizations?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
                <th>
                    Wybierz organ
                    <div className="filter-input">
                        <Select label={''}
                                currentValue={filters.bodyId ?? ''}
                                onChange={(e) => _setFilters({
                                    ...filters,
                                    bodyId: e
                                })}
                        >
                            <option/>
                            {availableFilters?.bodies?.map((p) => {
                                return <option value={p.id}
                                               key={'opt-' + p.id}>{p.name}</option>
                            })}
                        </Select>
                    </div>
                </th>
            </tr>
            </thead>
        </Table>
        <Table className={'multibody'}>
            <thead>
            <tr>
                <th>{t('documents.date')}</th>
                <th>{t('documents.document')}</th>
                <th>{t('documents.organizationName')}</th>
                <th>{t('documents.body')}</th>
                <th></th>
            </tr>
            </thead>

            {
                documents?.map(d => {
                    return <tbody key={d.id}><tr>
                        <td>
                            {dateFormatter(new Date(d.timestamp))}
                        </td>
                        <td>
                            {d.name}
                        </td>
                        <td>
                            {availableFilters?.organizations.find(o => o.id === d.organizationId)?.name }
                        </td>
                        <td>
                            {
                                d.bodyIds.map((id) => {
                                    return <div
                                        key={d.id + '-' + id}>{availableFilters?.bodies.find(b => b.id === id)?.name}</div>;
                                })
                            }
                        </td>
                        <td>
                            <div className="actions">

                                <button className="button text" onClick={() => setPreview({
                                    url: participantDocumentUrl(organizationId!, d),
                                    urls: documents?.map((doc) => participantDocumentUrl(organizationId!, doc))
                                })}>
                                    {t('documents.preview')}

                                </button>
                                <button className="button text" disabled={downloading.has(d.id)}
                                        onClick={() => _download(d)}>
                                    {downloading.has(d.id) ? <Loader/> : t('documents.download')}
                                </button>


                            </div>

                        </td>
                    </tr>
                    <Annotations
                        document={d}
                        onPreview={(doc) => {
                            setPreview({
                                url: participantDocumentUrl(organizationId!, doc),
                                urls: d.annotations?.map(doc => participantDocumentUrl(organizationId!, doc),)
                            })
                        }}
                        onDownload={_download}
                        downloading={downloading}
                    />
                    </tbody>

                })
            }
        </Table>
        {
            documents ? documents.length ? null : t('documents.emptyList') : <Loader/>
        }

        <Modal width={800} show={!!preview} reset={true} onClose={() => {
            setPreview(null);
        }} backDropClose={false}>
            <FilesPreview url={preview?.url} urls={preview?.urls} onClose={() => setPreview(null)}/>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default OrganizationParticipantOrganizationDocuments;
