import React, {useEffect, useRef, useState} from "react";
import {Wrapper} from "./EventGuestInvitation.styles";
import {acceptGuestInvitation, getGuestInvitation} from "../../../logic/events/events";
import {useParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import {EventGuestInvitation, EventGuestStatus, EventModel, EventType} from "../../../logic/events/events.model";
import Loader from "../../../components/Loader";
import {Organization, ProceedingType} from "../../../logic/organizations/organizations.model";
import EventHeader from "../../Event/EventHeader";
import {useForm} from "../../../logic/core/form.hook";
import Input from "../../../components/Input";
import FormField from "../../../components/FormField";
import {Violations} from "../../../logic/core/failures";
import {AppRoutes} from "../../../logic/core/routes";
import EventStartCountdown from "../../../components/EventStartCountdown";

const EventGuestInvitationScreen = () => {
    const {t, setError, renderError, loading, setLoading, navigate} = useBaseScreenHook();
    const {
        formValue,
        formValid,
        formControl,
        renderValidationMessage,
        setValidations
    } = useForm<{ phoneNumber: string }>({
        phoneNumber: {
            value: ''
        }
    })
    const {guestId} = useParams();
    const [invitation, setInvitation] = useState<EventGuestInvitation | null>(null);
    const [event, setEvent] = useState<EventModel | null>(null);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const startDate = useRef<Date | null>(null);
    useEffect(() => {
        _getInvitation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getInvitation = async () => {
        const res = await getGuestInvitation(guestId!);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
        startDate.current = new Date(res.data!.event.startDate);

        const event: EventModel = {
            content: {
                basic: {
                    name: res.data!.event.name,
                    startDate: res.data!.event.startDate,
                    body: {
                        name: res.data!.event.bodyName,
                        id: ''
                    },
                    id: '',
                    type: EventType.LIVE,
                    proceedings: ProceedingType.ORDINARY,
                },
                meetingStatus: res.data!.event.status,
            }

        } as EventModel;

        setEvent(event);

        const organization: Organization = {
            name: res.data!.event.organizationName,
        } as Organization;


        setOrganization(organization);
        setInvitation(res.data!);
        formControl('phoneNumber').patchValue(res.data!.guest.phoneNumber ?? '')
        if (res.data!.guest.status === EventGuestStatus.Active) {
            formControl('phoneNumber').disable();
            navigate(`/${AppRoutes.organization}/${res.data?.event.organizationId}/${AppRoutes.event}/${res.data!.event.id}`)
        }

    }

    const _activate = async (): Promise<void> => {

        if (!formValid()) {
            return;
        }
        setLoading(true);
        const res = await acceptGuestInvitation(guestId!, formValue.phoneNumber);
        _getInvitation();
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

    }

    if (!invitation) {
        return <Wrapper>
            <Loader/>
            {renderError()}

        </Wrapper>;
    }
    return <Wrapper>
        {invitation?.event?.id
            ? <EventStartCountdown status={event?.content?.meetingStatus ?? null} eventId={invitation.event.id}/>
            : null}

        <EventHeader event={event!} organization={organization!}/>

        <div className="invitation-for">
            <p className={'label'}>
                {t('eventGuestInvitationScreen.invitationFor')}
            </p>
            <p className={'name'}>
                {invitation.guest.firstName} {invitation.guest.lastName}
            </p>
        </div>
        <div className="data-title">
            {t('eventGuestInvitationScreen.yourData')}
        </div>

        <div className="form-data">
            <div className="row">
                <FormField>
                    <Input label={t('eventGuestInvitationScreen.firstName') + ' *'}>
                        <input type="text"
                               disabled={true}
                               value={invitation.guest.firstName}/>
                    </Input>
                </FormField>
                <FormField>
                    <Input label={t('eventGuestInvitationScreen.lastName') + ' *'}>
                        <input type="text"
                               disabled={true}
                               value={invitation.guest.lastName}/>
                    </Input>
                </FormField>
            </div>
            <FormField>
                <Input label={t('eventGuestInvitationScreen.phoneNumber')}>
                    <input type="text"
                           disabled={invitation.guest.status === EventGuestStatus.Active}
                           value={formControl('phoneNumber').value}
                           onChange={(e) => formControl('phoneNumber').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('phoneNumber')}
            </FormField>
        </div>


        <div className="buttons">
            <button className={'button'} onClick={_activate}
                    disabled={loading || invitation.guest.status === EventGuestStatus.Active}>
                {
                    loading ? <Loader/> : t('eventGuestInvitationScreen.activateBtn')
                }

            </button>

        </div>
        {renderError()}
    </Wrapper>
}

export default EventGuestInvitationScreen;
