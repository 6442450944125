import React from 'react';
import logo from '../../assets/logo.svg';
import imageBg from '../../assets/login-bg.jpg';
import { Wrapper } from './NotLoggedBaseScreen.styles';
import { Link } from 'react-router-dom';

const NotLoggedBaseScreen = ({children}: {children: any}) => {
    return <Wrapper>
        <div className="form-wrapper">
            <Link to={'/'}>
            <img src={logo} alt="logo" className="logo"/>
            </Link>
            {children}

        </div>

        <div className="image">
            <img src={imageBg} alt="bg"/>
        </div>


    </Wrapper>
}

export default NotLoggedBaseScreen;
