import {useParams} from "react-router-dom";
import {Wrapper} from "./SendInvitationSummaryModal.styles";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {EventInvitationStatus} from "../../../../../logic/events/events.model";
import {Table} from "../../../../../components/Table/table";

const SendInvitationSummaryModal = ({
                                        onClose,
                                        invitationResults,
                                    }: { onClose: () => void, invitationResults: EventInvitationStatus[] }) => {
    const {organizationId} = useParams();
    const {navigate} = useBaseScreenHook();
    return <Wrapper>

        <div className="title">
            Status wysyłki zaproszeń
        </div>

        <Table>
            <thead>
            <tr>
                <th>Uczestnik</th>
                <th>Użyty plan</th>
                <th>Status</th>
            </tr>
            </thead>
<tbody>
{
            invitationResults?.map((result, index) => {
                return <tr key={index}>
                    <td>{result.participant.bodyMemberName}</td>
                    <td>
                        {

                            result.result.success && !result.result.consumedCredit
                                ? <div className={'description'}>Użyto wcześniej wykorzystany plan</div>
                                : null
                        }
                        {
                            result.result.success && result.result.consumedCredit
                                ? <div className="description">
                                    {result.result.consumedCredit.description}
                                </div>
                                : null
                        }

                        {
                            !result.result.success
                                ? <div className={'description'}>Brak planu do wykorzystania</div>
                                : null
                        }
                    </td>
                    <td>
                        <div className={result.result.success ? 'status success' : 'status'}>
                            {result.result.success ? 'Wysłano' : 'Nie wysłano'}
                        </div>
                    </td>
                </tr>
            })
}
</tbody>
        </Table>

        <div className="buttons">
            <button className="button text mutes" onClick={onClose}>Zamknij</button>
            {
                invitationResults?.some(result => !result.result.success) &&
                <button className="button" onClick={() => {
                    navigate(`/organization/${organizationId}/host/billings/pricing`)
                }}>Kup plan
                </button>
            }
        </div>

    </Wrapper>
}

export default SendInvitationSummaryModal;
