import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: left;
    .label {
      margin-left: 11px;
    }
  }
  input + span {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
  input + span::before {
    content: '';
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 5px;
    border: 1px solid var(--disabledColor);
    cursor: pointer;
    background: #fff;
  }

  input:checked + span {
    &::before {
      background: var(--mainColor) url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='iso-8859-1'?%3e%3c!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e%3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e%3csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='78.369px' height='78.369px' viewBox='0 0 78.369 78.369' style='enable-background:new 0 0 78.369 78.369;' xml:space='preserve'%3e%3cg%3e%3cpath fill='%23FFF' d='M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z'/%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3c/svg%3e ");
      background-position: center;
      background-size: 11px;
      background-repeat: no-repeat;
      border: 1px solid var(--mainColor);

    }
  }
  
`
