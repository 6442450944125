import styled from 'styled-components';

export const Wrapper = styled.div`

.description {
  font-size: 1.2rem;
  color: var(--fontMutedColor);
}
  .spacer {
    margin-bottom: 40px;
  }
  .email-update {
    margin-left: -10px;
  }

`