import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {Wrapper} from "./OrganizationBillingsPaymentThankYou.styles";

const OrganizationBillingsPaymentThankYou = ({
                                                 onClose,
                                             }: { onClose: () => void }) => {
    const {t} = useBaseScreenHook();
    return <Wrapper>
        <div className="title">
            {t('organizationBillings.thankYou.title')}
        </div>

        <div className="description" dangerouslySetInnerHTML={{__html: t('organizationBillings.thankYou.description')}}>
        </div>

        <div className="close-modal" onClick={() => onClose()}>
            {t('organizationBillings.thankYou.closeButton')}
        </div>

    </Wrapper>
}

export default OrganizationBillingsPaymentThankYou;
