import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .description {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .date {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 7px;
    font-size: 13px;
    margin-bottom: 20px;
  }
`
