import styled from 'styled-components';

export const Wrapper = styled.div`
  .signing {
    .data-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }

    .permission-description {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }

    .power-of-attorney {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
    }

    .form {
      width: 356px;

      .row {
        display: flex;
        gap: 10px;
      }
    }

    .file-picker-element {
      margin-bottom: 20px;

      .add-file,
      .drag-and-drop-label {
        font-size: 1.4rem;
      }
    }

    .selected-file {
      margin-bottom: 20px;
      font-size: 1.4rem;
      color: var(--mainColor);
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
      }
    }

    .description-summary {
      font-size: 1.4rem;
      margin-bottom: 20px;

      .label {
        font-weight: 700;
        margin-bottom: 8px;
      }

      .value {
        margin-bottom: 12px;
      }
    }

    .send-button {
      width: 100%;
    }
  }

  .list-signed {
    font-size: 1.2rem;
    text-align: center;
    color: var(--errorColor);
  }

  .files-error-wrapper {
    transform: translateY(-20px);
  }
`