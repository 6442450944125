import ReactDOM from "react-dom";
import React, { useEffect } from 'react';
import { CSSTransition } from "react-transition-group";
import { Wrapper } from './Toast.styles';
import IconClose from '../../assets/icon-close.svg';

export type ModalCloseFunction = () => void;

export enum ModalType {
    error = 'error',
    warn = 'warn',
    success = 'success',
    info = 'info',
}

export interface ToastOptions {
    type?: ModalType;
    onClose: ModalCloseFunction;
    message: string | null;
}
let timeout: ReturnType<typeof setTimeout> | null = null;

const Toast = (props: ToastOptions) => {
    const nodeRef = React.useRef(null);

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => {
            props.onClose();
        }, props.type === ModalType.error ? 3000 : 1500);
    }, [props])

    return ReactDOM.createPortal(
        <CSSTransition
            in={!!props.message}
            unmountOnExit
            timeout={{enter: 0, exit: 100}}
            nodeRef={nodeRef}
        >
            <Wrapper>
                <div className="toast" ref={nodeRef}>
                    <div className={'modal-content ' + props.type} onClick={e => e.stopPropagation()}>
                        <div className="close" onClick={props.onClose}>
                            <img src={IconClose} alt="close"/>
                        </div>
                        <div className="message">{props.message}</div>

                    </div>
                </div>
            </Wrapper>
        </CSSTransition>,
        document.getElementById('root') as any
    );
}

export default Toast;
