import {DataObject} from '../core/dataObject';
import {Failure} from '../core/failure';
import HttpClient from '../httpClient/httpClient';
import {handleError} from '../core/handleError';
import {AxiosError, AxiosResponse} from 'axios';
import {FileResponse} from './files.model';
import * as FileSaver from 'file-saver';



export async function saveFile(file: Uint8Array | File | Blob, filename: string, contentType: string): Promise<DataObject<Failure, void | null>> {
    try {
        if (typeof (file as File).name === 'string') {
            FileSaver.saveAs(file as any);
        } else {
            const blob: Blob = new Blob([new Uint8Array(file as Uint8Array)], {type: contentType});
            FileSaver.saveAs(blob, filename);
        }
        return new DataObject<Failure, void | null>(null, null)
    } catch (e) {
        return handleError(e as any);
    }
}

export async function uploadFile(file: File): Promise<DataObject<Failure, string | null>> {
    try {
        const body: FormData = new FormData();
        body.append('file', file);
        const res = await HttpClient.post<{ id: string }>(`/file`, body);
        return new DataObject<Failure, string>(null, res.data.id);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function downloadFile(id: string): Promise<DataObject<Failure, FileResponse<File> | null>> {
    try {
        const res = await HttpClient.get(`/file/${id}`, {
            responseType: 'blob'
        });
        const {name, type} = getNameAndTypeFromResponse(res);
        const resData: Blob = res.data!;
        const blob: Blob = resData.slice(0, resData.size, resData.type);
        const file: File = new File([blob], name, {
            type: type ?? undefined,
        });
        return new DataObject<Failure, FileResponse<File>>(null, {
            data: file,
            name,
            type,
        } as FileResponse<File>);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function downloadByUrl(url: string): Promise<DataObject<Failure, FileResponse<File> | null>> {
    try {
        const res = await HttpClient.get(url, {
            responseType: 'blob'
        });
        const {name, type} = getNameAndTypeFromResponse(res);
        const resData: Blob = res.data!;
        const blob: Blob = resData.slice(0, resData.size, resData.type);
        const file: File = new File([blob], name, {
            type: type ?? undefined,
        });
        return new DataObject<Failure, FileResponse<File>>(null, {
            data: file,
            name,
            type,
        } as FileResponse<File>);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


function getNameAndTypeFromResponse(res: AxiosResponse): { name: string; type: string | null } {
    const type: string | null = res.headers["content-type"];
    const contentDisposition: string | null = res.headers["content-disposition"];
    const filenameRegex: RegExp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches: RegExpExecArray | null = contentDisposition ? filenameRegex.exec(contentDisposition) : null;
    let name: string = "";
    if (matches != null && matches[1]) {
        name = matches[1].replace(/['"]/g, "");
        name = decodeURI(name);
    }
    return {name, type};
}
