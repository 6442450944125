import React, {useState} from 'react';
import {Wrapper} from './EventInfo.styles';
import {useEvent} from '../../../logic/events/events';
import {useBaseScreenHook} from '../../../logic/core/base-screen.hook';
import Loader from '../../../components/Loader';
import iconAdd from '../../../assets/icon-plus.svg'
import Modal from '../../../components/Modal';
import EventDescriptionModal from './EventDescriptionModal';
import EventHeader from '../EventHeader';
import {EventPermission, EventType} from "../../../logic/events/events.model";
import EventInfoDescription from "../EventInfoDescription";
import {AppRoutes} from "../../../logic/core/routes";
import {NavLink, useLocation} from "react-router-dom";

const EventInfo = () => {
    const {renderError, t} = useBaseScreenHook();
    const {event, organization} = useEvent();
    const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
    const location = useLocation();
    if (!event || !organization) {
        return <Loader/>
    }
    return <Wrapper>

        <EventHeader event={event} organization={organization}/>

        <div className="sub-title">
            {t('eventInfo.infoTitle')}
        </div>

        <EventInfoDescription event={event} organization={organization}/>

        {
            event.permissions[EventPermission.canChangeDescription] && event.content?.canBeUpdated ? event?.content?.description.value
                    ? <button onClick={() => setOpenDescriptionModal(true)} className={'button text'}>
                        {t('eventInfo.changeDescription')}
                    </button>
                    : <>
                        <button onClick={() => setOpenDescriptionModal(true)} className={'button text icon'}>
                            <img src={iconAdd} alt="icon"/>
                            {t('eventInfo.addDescription')}
                        </button>
                        <p className="add-description-hint">
                            {t('eventInfo.addDescriptionHint')}
                        </p>
                    </>
                : null
        }

        {
            event.permissions[EventPermission.canSeeNavigationButtons]
                ?
                <NavLink to={`../${AppRoutes.eventAgenda}${location.search}`}>
                    <button className="button">
                        {event?.content?.type === EventType.LIVE
                        ? t('event.navigation.goToAgenda')
                        : t('event.navigation.goToVoting')
                    }
                    </button>
                </NavLink>

                : null
        }


        <Modal width={700} show={openDescriptionModal} onClose={() => setOpenDescriptionModal(false)}
               backDropClose={false} reset={true}>
            <EventDescriptionModal description={event?.content?.description.value ?? ''}
                                   onClose={() => setOpenDescriptionModal(false)}/>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default EventInfo;
