import styled from 'styled-components';

export const Wrapper = styled.div`
  .rdw-editor-wrapper {

    font-family: var(--fontPrimary);

    & * {
      box-sizing: unset !important;
      font-weight: unset;
    }

    .public-DraftStyleDefault-block {
      margin: 0 !important;
    }
    
    figure {
      margin: 0 !important;
    }
  }

  .rdw-editor-main {
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
  }
`