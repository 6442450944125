import React, { useEffect, useState } from 'react';
import { Wrapper } from './OrganizationBodyDetails.styles';
import { useTranslation } from 'react-i18next';
import { Failure } from '../../../../logic/core/failure';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { getBody } from '../../../../logic/bodies/bodies';
import { Body } from '../../../../logic/bodies/bodies.model';
import ErrorModal from '../../../../components/ErrorModal';
import Loader from '../../../../components/Loader';
import { AppRoutes } from '../../../../logic/core/routes';

const OrganizationBodyDetails = () => {

    const [t] = useTranslation('common');
    const [error, setError] = useState<Failure | null>(null);
    const [body, setBody] = useState<Body | null>(null);
    const {organizationId, bodyId} = useParams();
    const location = useLocation();
    useEffect(() => {
        if (!organizationId || !bodyId) {
            return;
        }
        getBody(organizationId!, bodyId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            setBody(res.data!);
        })

    }, [bodyId, organizationId]);

    const selectTab = (name: string) => {
        return `/${AppRoutes.organization}/${organizationId}/${AppRoutes.host}/${AppRoutes.bodies}/${bodyId}/${name}`;
    }

    const isTabActive = (name: string) => {
        return location.pathname.includes(selectTab(name));
    }
    return <Wrapper>

        {
            body
                ? <>
                    <div className="title">
                        {body.name}
                    </div>

                    <div className="tabs">
                        <Link className={`tab ${isTabActive(AppRoutes.members) ? 'active' : ''}`} to={selectTab(AppRoutes.members)}>
                            {t('organizationBodyDetails.tabs.members')}
                        </Link>
                        <Link className={`tab ${isTabActive(AppRoutes.settings) ? 'active' : ''}`} to={selectTab(AppRoutes.settings)}>
                            {t('organizationBodyDetails.tabs.settings')}
                        </Link>
                    </div>

                    <div className="outlet">
                        <Outlet/>
                    </div>
                </>
                : <Loader/>
        }


        <ErrorModal failure={error} onClose={() => setError(null)}/>
    </Wrapper>
}

export default OrganizationBodyDetails;
