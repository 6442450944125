import styled from 'styled-components';

export const Wrapper = styled.div`

  .sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;

    .data-box {
      flex: 1;
      border-radius: 5px;
      background: var(--bgLighColor);
      padding: 12px 16px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 10px;
      font-size: 1.2rem;
      line-height: 1.6rem;

      .value {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
      }
    }
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 30px;
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .filter {
      border-radius: 5px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6rem;
      border: 1px solid;
      width: 130px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.all {
        border-color: var(--fontMutedColor);
        color: var(--fontMutedColor);
        &.active {
          background: var(--fontMutedColor);
        }
      }
      &.not-sent {
        border-color: var(--mainColor);
        color: var(--mainColor);
        &.active {
          background: var(--mainColor);
        }
      }
      &.sent {
        border-color: var(--waringColor);
        color: var(--waringColor);
        &.active {
          background: var(--waringColor);
        }
      }
      &.signed {
        border-color: var(--successColor);
        color: var(--successColor);
        &.active {
          background: var(--successColor);
        }
      }
      &.blocked {
        border-color: var(--errorColor);
        color: var(--errorColor);
        &.active {
          background: var(--errorColor);
        }
      }
      &.active {
        color: #fff;
      }
      &:hover {
        border-width: 2px;
      }

    }


  }

  table {
    .filter-input {
      margin-top: 10px;
      .value {
        height: 30px;
        input {
          padding: 0 10px;
        }
      }
    }
    .type {
      width: 20px;
    }
    
    .name {
      padding: 3px 5px;
      border: 2px solid transparent;
      display: inline-flex;
      position: relative;
      
      &.plenipotentiary {
        color: var(--highlightColor);
      }
      &.invalid {
        border-color: var(--errorColor);
        cursor: default;
        
        &:hover {
          .invalid-tooltip {
            display: flex;
          }
        }
      }
      
      .invalid-tooltip {
        display: none;
        position: absolute;
        right: calc(100% + 2px);
        width: 170px;
        border-radius: 5px;
        border: 1px solid var(--errorColor);
        padding: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background: #fff;
      }
    }

    .statuses {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .status {
      border-radius: 5px;
      min-width: 130px;
      white-space: nowrap;
      color: #fff;
      background: var(--fontColor);
      padding: 0 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      font-size: 1.2rem;
      font-weight: 500;
      &.NotSentInvitation {
        background: var(--mainColor);
      }
      &.SentInvitation {
        background: var(--waringColor);
      }
      &.Signed {
        background: var(--successColor);
      }
      &.Blocked {
        background: var(--errorColor);
      }
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      button {
        height: 24px;
      }
      .menu {
        display: flex;
        justify-content: flex-end;

        .remove-btn {
          color: var(--errorColor)
        }
      }
    }
  }

  .send-all-invitations {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
`