import FormField from '../../components/FormField';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import NotLoggedBaseScreen from '../../components/NotLoggedBaseScreen';
import {Wrapper} from './RegisterScreen.styles';
import {useBaseScreenHook} from '../../logic/core/base-screen.hook';
import {useForm} from '../../logic/core/form.hook';
import {register} from '../../logic/auth/auth';
import {RegisterBody} from '../../logic/auth/auth.model';
import {Violations} from '../../logic/core/failures';
import {passwordStrongValidator} from '../../logic/core/password-strength';
import PasswordStrengthValidator from '../../components/PasswordStrengthValidator/passwordStrengthValidator';
import {AppRoutes} from '../../logic/core/routes';
import {useSearchParams} from "react-router-dom";
import {useState} from "react";

const RegisterScreen = () => {
    const [expandedMarketing, setExpandedMarketing] = useState<boolean>(false);
    const [params] = useSearchParams();
    const {t, navigate, setError, loading, setLoading, renderError} = useBaseScreenHook();
    const {
        formValid,
        formValue,
        setValidations,
        renderValidationMessage,
        formControl,
        formTouched
    } = useForm<RegisterBody>({
        email: {
            value: ''
        },
        password: {
            value: '',
            validators: [
                (val) => {
                    const a = passwordStrongValidator(val);
                    if (a) {
                        return 'test'
                    }
                    return null;
                },
            ]
        },
        repeatPassword: {
            value: '',
            validators: [
                (val) => {
                    if (val !== formControl('password').value) {
                        return t('resetPassword.passwordNotMatch')
                    }
                    return null;
                },
            ]
        },
        firstName: {
            value: ''
        },
        lastName: {
            value: ''
        },
        consents: {
            controls: {
                termsAndConditions: {
                    value: false
                },
                marketing: {
                    value: false
                },
            }
        }
    })
    const submit = async () => {
        if (!formValid()) {
            return;
        }

        setLoading(true);

        const body: RegisterBody = {
            email: formValue.email || null,
            password: formValue.password || null,
            firstName: formValue.firstName || null,
            lastName: formValue.lastName || null,
            consents: {
                termsAndConditions: formValue.consents.termsAndConditions || false,
                marketing: formValue.consents.marketing || false,
            }

        };

        const res = await register(body);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        navigate({
            pathname: AppRoutes.confirmEmail,
            search: `email=${encodeURIComponent(formValue.email!)}&participantInvitationId=${params.get('participantInvitationId')}`
        });
    }

    const expandMarketing = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setExpandedMarketing(!expandedMarketing);
    }

    return <NotLoggedBaseScreen>
        <Wrapper>
            <h2 className="register-title">
                {t('register.title')}
            </h2>

            <div className="form">

                <FormField>
                    <Input label={t('register.emailLabel')}>
                        <input type="email" value={formControl('email').value}
                               onChange={(e) => formControl('email').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('email')}
                </FormField>

                <FormField>
                    <Input label={t('register.passwordLabel')}>
                        <input type="password" value={formControl('password').value}
                               onChange={(e) => formControl('password').patchValue(e.target.value)}/>
                    </Input>
                    <PasswordStrengthValidator control={formControl('password')} formTouched={formTouched}/>
                </FormField>
                <FormField>
                    <Input label={t('register.repeatPasswordLabel')}>
                        <input type="password" value={formControl('repeatPassword').value}
                               onChange={(e) => formControl('repeatPassword').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('repeatPassword')}
                </FormField>

                <div className="personal-data">

                    <FormField>
                        <Input label={t('register.firstNameLabel')}>
                            <input type="text" value={formControl('firstName').value}
                                   onChange={(e) => formControl('firstName').patchValue(e.target.value)}/>
                        </Input>
                        {renderValidationMessage('firstName')}
                    </FormField>

                    <FormField>
                        <Input label={t('register.lastNameLabel')}>
                            <input type="text" value={formControl('lastName').value}
                                   onChange={(e) => formControl('lastName').patchValue(e.target.value)}/>
                        </Input>
                        {renderValidationMessage('lastName')}
                    </FormField>

                </div>
                <div className="data-hint">
                    {t('register.dataHint')}

                </div>

                <FormField>
                    <Checkbox
                        labelElements={<div className={'label'}>
                            {t('register.termsAndConditions')} <a href="pdf/230412-Regulamin-VOTENTI.pdf" target={'_blank'}>{t('register.termsAndConditionsLink')}</a>
                    </div>}>
                        <input type="checkbox" checked={formControl('consents.termsAndConditions').value}
                               onChange={(e) => formControl('consents.termsAndConditions').patchValue(!formControl('consents.termsAndConditions').value)}/>
                    </Checkbox>
                    {renderValidationMessage('consents.termsAndConditions')}

                </FormField>

                <FormField>
                    <Checkbox
                        labelElements={<div className={'label'}>
                            {t('register.marketing')}
                            {
                                !expandedMarketing ? <span className={'expand'} onClick={expandMarketing}>&nbsp;{t('register.expand')}</span> : <span className={'expand'} onClick={expandMarketing}>&nbsp;{t('register.collapse')}</span>
                            }
                            {
                                expandedMarketing
                                    ? <div className="expanded"  dangerouslySetInnerHTML={{__html: `${t('register.marketingExpanded')}<a href="pdf/2304_Polityka-prywatnosci.pdf" target="_blank">${t('register.marketingLink')}</a>` }}>
                                    </div>
                                    : null
                            }
                        </div>}>
                        <input type="checkbox" checked={formControl('consents.marketing').value}
                               onChange={(e) => formControl('consents.marketing').patchValue(!formControl('consents.marketing').value)}/>
                    </Checkbox>
                    {renderValidationMessage('consents.marketing')}

                </FormField>

                <button className=" button submit-button" onClick={submit} disabled={loading}>
                    {t('register.submitButton')}
                </button>


            </div>

        </Wrapper>
        {renderError()}
    </NotLoggedBaseScreen>
};

export default RegisterScreen;
