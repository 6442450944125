import styled from 'styled-components';

export const Wrapper = styled.div`
  label {
    position: relative;
    text-align: left;
    display: block;

    .label {
      font-size: 1.2rem;
      color: var(--fontColor);
      text-align: left;
      margin-bottom: 3px;
      display: block;
    }

    .placeholder {
      color: #B9B9B9;
      pointer-events: none;
      position: absolute;
      font-size: 14px;
      left: 0;
      bottom: 6px;
    }

    .helper-layer {
      position: relative;

      &.disabled {
        cursor: not-allowed;

        select {
          cursor: not-allowed;
        }
      }

      .icon-dropdown {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        pointer-events: none;
      }
    }

    select {
      background: transparent;
      cursor: pointer;
      outline: none;
      width: 100%;
      appearance: none;
      padding: 10px 25px 10px 10px;
      font-size: 14px;
      border: 1px solid var(--disabledColor);
      border-radius: 5px;
      height: 40px;

    }
  }

`
