import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .description {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
`
