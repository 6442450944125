import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .description {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
  }
  
  .data {
    margin-top: 20px;
    
    .label {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .value {
      margin-bottom: 20px;
    }
  }
`
