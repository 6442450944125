import React, {useEffect, useState} from 'react';
import {Wrapper} from './OrganizationBillingsBillingsScreen.styles';
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams, useSearchParams} from "react-router-dom";
import {Failure} from "../../../../logic/core/failure";
import {getCredits} from "../../../../logic/credits/credits";
import {Credit} from "../../../../logic/credits/credits.model";
import Loader from "../../../../components/Loader";
import ErrorModal from "../../../../components/ErrorModal";
import Modal from "../../../../components/Modal";
import OrganizationBillingsPaymentThankYou from "./OrganizationBillingsPaymentThankYou";
import {Unexpected} from "../../../../logic/core/failures";

const OrganizationBillingsBillingsScreen = () => {
    const {t} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [error, setError] = useState<Failure | null>(null);
    const [credits, setCredits] = useState<Credit[] | null>(null);
    const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState<boolean>(false);
    const [params, setSearchParams] = useSearchParams();

    useEffect(() => {
        _getCredits();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    useEffect(() => {
        if (params.get('error')) {
            params.delete('error');
            params.delete('paymentId');
            setSearchParams(params);
            setError(new Unexpected('Nieudana płatność'));
        } else if (params.get('paymentId')) {
            setOpenPaymentSuccessModal(true);
            params.delete('paymentId');
            setSearchParams(params);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getCredits = () => {
        if (!organizationId) {
            return;
        }
        getCredits(organizationId).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setCredits(res.data!);

        });
    }

    const _thankYouClosed = () => {
        setOpenPaymentSuccessModal(false);
        _getCredits();
    }

    return <Wrapper>

        <div className="title">
            {t('organizationBillings.billingsScreen.title')}
        </div>
        {
            credits ? null : <Loader/>
        }
        <table className='credits-table'>
            <tbody>
            {
                credits?.map((credit, index) => {

                    return <tr key={index}>
                        <td>
                        <span className="name">
                            {credit.definition.name}
                        </span>
                            &nbsp;
                            <span>
                         {
                             credit.definition.invitationAmount === null
                                 ? `(${t('organizationBillings.billingsScreen.noLimit')}):`
                                 : `(${t('organizationBillings.billingsScreen.invitationLimit', {invitationLimit: credit.definition.invitationAmount})}):`
                         }

                        </span>
                        </td>
                        <td>
                            <strong className="credit-quantity">
                                {credit.quantity}
                            </strong>
                        </td>
                    </tr>
                })
            }
            </tbody>
        </table>

        <div className='close-account-info'>
            {t('organizationBillings.billingsScreen.closeAccountInfo')} <a
            href='mailto:biuro@votenti.pl'>biuro@votenti.pl</a>
        </div>

        <div className="info"
             dangerouslySetInnerHTML={{__html: t('organizationBillings.billingsScreen.info')}}
        >
        </div>

        <ErrorModal failure={error} onClose={() => setError(null)}/>

        <Modal width={800} show={openPaymentSuccessModal} onClose={() => _thankYouClosed()} backDropClose={false}>
            <OrganizationBillingsPaymentThankYou
                onClose={() => _thankYouClosed()}></OrganizationBillingsPaymentThankYou>
        </Modal>
    </Wrapper>
}

export default OrganizationBillingsBillingsScreen;
