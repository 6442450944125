import styled from 'styled-components';

export const Wrapper = styled.div`

    .welcome-box {
      border-radius: 20px;
      padding: 20px 20px 10px;
      background: var(--lightBlueColor);
      
      .title {
        font-size: 2.5rem;
        margin-bottom: 20px;
        white-space: break-spaces;
        word-break: break-word;
      }
      .description {
        width: 450px;
        max-width: 100%;
        margin-bottom: 30px;
      }
      
      .buttons {
        display: flex;
        gap: 20px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }
    }

`
