import styled from 'styled-components';

export const Wrapper = styled.div`

    .title {
      font-size: 2.5rem;
      margin-bottom: 30px;
    }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  
  .document-field {
    padding: 20px;
    border: 1px solid var(--disabledColor);
    border-radius: 10px;
    margin-bottom: 20px;
    .form-field {
      margin-bottom: 0;
    }
    .file-picker-element {
      margin-top: 10px;
    }
  }
  
  .select-bodies {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: left;
  }
  .bodies {
    padding-left: 10px;
  }
  .selected-file {
    background: var(--bgLighColor);
    border-radius: 5px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    .name {
      font-size: 1.4rem;
    }
    .delete {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`