import styled from 'styled-components';

export const Wrapper = styled.div`
  .password-validations {
    display: flex;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 768px) {
      gap: 5px;
    }

    .password-validation {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #67D06E;
      white-space: nowrap;

      &.not-valid {
        color: var(--disabledColor);

        .dot {
          border-color: #d5d5d5;
          background: #fff;
        }
      }

      .dot {
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #67D06E;
        background: #67D06E;
      }
    }

    &.error {
      .password-validation {
        &.not-valid {
          color: var(--errorColor);

          .dot {
            border-color: var(--errorColor);
          }
        }
      }
    }
  }
`
