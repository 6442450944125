import { DataObject } from '../core/dataObject';
import { Failure } from '../core/failure';
import HttpClient from '../httpClient/httpClient';
import { handleError } from '../core/handleError';
import { AxiosError } from 'axios';
import {
    InvitationEmail,
    LegalForm,
    Organization,
    OrganizationBody,
    OrganizationUpdateBody
} from './organizations.model';

import { OrganizationUser, OrganizationUserBody } from '../auth/auth.model';

export async function getOrganizations(): Promise<DataObject<Failure, Organization[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations`);
        return new DataObject<Failure, Organization[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function createOrganization(body: OrganizationBody): Promise<DataObject<Failure, string | null>> {
    try {
        const res = await HttpClient.post<string>(`/organizations`, body);
        return new DataObject<Failure, string | null>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function updateOrganization(organizationId: string, body: OrganizationUpdateBody): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.put<void>(`/organizations/${organizationId}`, body);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}



export async function getLegalForms(): Promise<DataObject<Failure, LegalForm[] | null>> {
    try {
        const res = await HttpClient.get(`/legal-forms`);
        return new DataObject<Failure, LegalForm[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getOrganization(id: string): Promise<DataObject<Failure, Organization | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${id}`);
        return new DataObject<Failure, Organization>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function getPublicOrganization(id: string): Promise<DataObject<Failure, Organization | null>> {
    try {
        const res = await HttpClient.get(`/public/organizations/${id}`);
        return new DataObject<Failure, Organization>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function getInvitationEmails(id: string): Promise<DataObject<Failure, InvitationEmail[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${id}/invitation-emails`);
        return new DataObject<Failure, InvitationEmail[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function addInvitationEmail(id: string, email: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${id}/invitation-emails`, {email});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function resendInvitationEmail(organizationId: string, email: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${organizationId}/invitation-emails/${email}/verifications`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function verificationInvitationEmail(token: string, email: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/invitation-emails/verifications`, {
            token,
            email,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function deleteInvitationEmail(id: string, invitationsEmailId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/${id}/invitation-emails/${invitationsEmailId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function getUsers(id: string): Promise<DataObject<Failure, OrganizationUser[] | null>> {
    try {
        const res = await HttpClient.get(`/organizations/${id}/users`);
        return new DataObject<Failure, OrganizationUser[]>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function addUser(id: string, body: OrganizationUserBody): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/organizations/${id}/users`, body);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function updateUser(organizationId: string, userId: string, body: OrganizationUserBody): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/users/${userId}`, body);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function resendUserInvitation(organizationId: string, userId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/users/${userId}/invitation`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function deleteUser(organizationId: string, userId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.delete(`/organizations/${organizationId}/users/${userId}`);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function blockUser(organizationId: string, userId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/users/${userId}/block`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function activateUser(organizationId: string, userId: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.put(`/organizations/${organizationId}/users/${userId}/unblock`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

