import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 20px;
    text-align: left;
  }

  .search {
    .icon {
      width: 20px;
      height: 20px;
      margin: 9px;
    }
  }

  .list {
    overflow: auto;
    max-height: calc(100vh - 300px);
    margin-bottom: 40px;

    .members {
      text-align: left;

      tr {
        .type {
          width: 20px;
        }

        .action {
          text-align: right;

          .button {
            height: 30px;
          }
        }

        .name {
          line-height: 1.4rem;
          font-weight: 600;

          .added-label {
            margin-top: 2px;
            font-weight: 400;
          }
        }
        
        &.added {
          .name {
            opacity: .6;
          }
          .action {
            opacity: .6;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--disabledColor);
    gap: 10px;
    padding-top: 20px;
  }

  .add-body-member {
    text-align: left;
    font-size: 1.2rem;
    button {
      margin-left: -10px;
    }
  }
`
