import React, {useEffect, useState} from "react";
import {Wrapper} from "./OrganizationParticipantEventsScreen.styles";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import {AppRoutes} from "../../../logic/core/routes";
import {useParams} from "react-router-dom";
import {getOrganization} from "../../../logic/organizations/organizations";
import {Organization} from "../../../logic/organizations/organizations.model";
import Loader from "../../../components/Loader";
import {getOrganizationEventsInvites} from "../../../logic/events/events";
import InvitationsTable from "../../../components/InvitationsTable";
import {EventParticipantInvitation} from "../../../logic/events/events.model";
import {useAppStateContext} from "../../../appSateProvider";

const OrganizationParticipantEventsScreen = () => {
    const {navigate, renderError, setError} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [organization, setOrganization] = useState<Organization | null>(null)
    const [invitations, setInvitations] = useState<EventParticipantInvitation[] | null>(null)
    const [, , isAdmin, isSuperAdmin] = useAppStateContext();

    useEffect(() => {
        getOrganization(organizationId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setOrganization(res.data!);
        })

        getOrganizationEventsInvites(organizationId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setInvitations(res.data!);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const _goToEmailUpdate = () => {
        navigate(`/${AppRoutes.organization}/${organizationId}/${AppRoutes.host}/${AppRoutes.data}/${AppRoutes.invitationEmails}`)
    }

    if (!organization) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>

        <div className="description">
            Poniżej znajdziesz zakończone posiedzenia i głosowania obiegowe, w których
            organizacja {organization.name} wzięła udział jako uczestnik.
        </div>

        <div className="spacer">
            {
                isAdmin(organizationId!) || isSuperAdmin(organizationId!) ?
                    <button className="button text email-update" onClick={_goToEmailUpdate}>
                        Zaktualizuj adres e-email, na który organizatorzy mają wysyłać zaproszenia
                        dla {organization.name}.
                    </button>
                    : null
            }
        </div>

        <InvitationsTable data={invitations}/>

    </Wrapper>
}


export default OrganizationParticipantEventsScreen;