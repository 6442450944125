import NotLoggedBaseScreen from '../../components/NotLoggedBaseScreen';
import { Wrapper } from './ResetPasswordScreen.styles';
import FormField from '../../components/FormField';
import Input from '../../components/Input';
import { FormEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import { useForm } from '../../logic/core/form.hook';
import { passwordStrongValidator } from '../../logic/core/password-strength';
import PasswordStrengthValidator from '../../components/PasswordStrengthValidator/passwordStrengthValidator';
import { resetPassword } from '../../logic/auth/auth';
import { Violations } from '../../logic/core/failures';
import { AppRoutes } from '../../logic/core/routes';


const ResetPasswordScreen = () => {
    const [params] = useSearchParams();
    const {t, loading, setLoading, renderError, navigate, setError} = useBaseScreenHook();

    const {
        formControl,
        formTouched,
        formValid,
        setValidations,
        formValue,
        renderValidationMessage
    } = useForm<{ newPassword: string, repeatPassword: string }>({
        newPassword: {
            value: '',
            validators: [
                (val) => {
                    const a = passwordStrongValidator(val);
                    if (a) {
                        return 'test'
                    }
                    return null;
                },
            ]
        },
        repeatPassword: {
            value: '',
            validators: [
                (val) => {
                    if (val !== formControl('newPassword').value) {
                        return t('resetPassword.passwordNotMatch')
                    }
                    return null;
                },
            ]
        }
    });

    useEffect(() => {
        const token = params.get('token');
        const accountId = params.get('accountId');
        if (accountId && token) {

        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])


    const submit = async (e: FormEvent) => {
        e.preventDefault();
        if (loading || !formValid()) {
            return;
        }
        const token: string = params.get('token')!;
        const accountId: string = params.get('accountId')!;

        setLoading(true);

        const res = await resetPassword(formValue.newPassword, token, accountId);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        navigate(`/${AppRoutes.login}`);
    }

    return <NotLoggedBaseScreen>
        <Wrapper>

            <h2 className="title">
                {t('resetPassword.title')}
            </h2>

            <form onSubmit={submit}>
                <FormField>
                    <Input label={t('resetPassword.passwordLabel')}>
                        <input type="password" value={formControl('newPassword').value}
                               onChange={(e) => formControl('newPassword').patchValue(e.target.value)}/>
                    </Input>

                    <PasswordStrengthValidator control={formControl('newPassword')} formTouched={formTouched}/>

                </FormField>
                <FormField>
                    <Input label={t('resetPassword.repeatPasswordLabel')}>
                        <input type="password" value={formControl('repeatPassword').value}
                               onChange={(e) => formControl('repeatPassword').patchValue(e.target.value)}/>
                    </Input>

                    {renderValidationMessage('repeatPassword')}

                </FormField>

                <button className={'button'} type="submit" disabled={loading}>
                    {t('resetPassword.submitButton')}
                </button>
            </form>
        </Wrapper>
        {renderError()}
    </NotLoggedBaseScreen>
};

export default ResetPasswordScreen;
