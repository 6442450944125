import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 25px;
  &.with-participant {
    .content {
      padding: 0;
    }
  }
  .content {
    padding: 40px 20px 80px;
    background: #fff;
    border-radius: 5px;
  }
  .icon {
    width: 40px;
    margin: auto auto 10px;

    img {
      width: 100%;
    }
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: left;

    .value {
      text-align: left;
    }

    .label {
      margin-bottom: 10px;
    }
  }

  .label {
    text-align: left;
    color: var(--fontMutedColor)
  }

  .value {
    font-weight: bold;
    text-align: right;
  }

  .row {
    display: flex;
    gap: 20px;
    width: 100%;

    & > * {
      flex: 1;
    }
  }

  .votes {
    border-top: 1px solid var(--disabledColor);
    border-bottom: 1px solid var(--disabledColor);
    padding: 20px 0;
    margin: 20px 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  
  .participant {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--disabledColor);
  }
  
  .participant,
  .notifications {
    .title {
      font-weight: normal;
    }

    .label {
      text-align: center;
      margin-bottom: 10px;
    }
    .name {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 20px;
    }
    
    .position {
      margin-top: -10px;
    }
  }
  .buttons {
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 1;
    transform: translate(-50%, 0px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .btn {
      background: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid var(--disabledColor);
      img {
        width: 50px;
      }
      &.edit {
        img {
          width: 20px;
        }
      }
    }
  }
`
