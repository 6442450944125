export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    name: string;
    roles: Role[]
}

export interface Role {
    organizationId: string;
    role: UserRole;
    type: string;
}

export enum UserRole {
    SuperAdministrator = 'SuperAdministrator',
    Administrator = 'Administrator',
    Employee = 'Employee'
}

export interface OrganizationUserBody extends Omit<OrganizationUser, 'id' | 'organizationId' | 'status'> {
}

export interface OrganizationUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    organizationId: string,
    status: OrganizationUserStatus;
    permissions: {
        canBeBlockedOrUnblocked: boolean;
        canBeDeleted: boolean;
        canBeUpdated: boolean;
        canRoleBeUpdated: boolean;
    }
}


export enum OrganizationUserStatus {
    Active = "Active",
    Pending = "Pending",
    Blocked = "Blocked"
}

export interface RegisterBody {
    email: string | null;
    password: string | null;
    firstName: string | null;
    lastName: string | null;
    consents: {
        termsAndConditions: boolean;
        marketing: boolean;
    }
}
