import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .description {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  .tabs {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: stretch;
    height: 50px;
    overflow: auto;
    .tab {
      white-space: nowrap;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-bottom: 1px solid var(--disabledColor);
      border-top: 1px solid transparent;
      font-size: 1.4rem;
      text-decoration: none;
      color: var(--fontColor);

      &.active,
      &:hover {
        border-bottom: 3px solid var(--mainColor);
        border-top: 3px solid transparent;
        font-weight: bold;
      }
    }
  }
  
  .login-hint {
    margin-top: 10px;
    a {
      text-decoration: none;
      color: var(--mainColor);
    }
  }
  .change-password-button {
    font-weight: 400;
    min-width: unset;
    padding: 0;
  }
`
