import React from "react";
import {Wrapper} from "./OutcomeConfirmation.styles";
import {ModalCloseFunction} from "../../../../../components/Modal";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import {Textarea} from "../../../../../components/Textarea/Textarea.styles";
import FormField from "../../../../../components/FormField";
import {useForm} from "../../../../../logic/core/form.hook";
import {outcomeDecision} from "../../../../../logic/events/events";
import {Violations} from "../../../../../logic/core/failures";

const OutcomeConfirmation = ({
                                 itemId,
                                 voteId,
                                 onClose,
                                 passed
                             }: { itemId: string, voteId: string, onClose: ModalCloseFunction, passed: boolean | null }) => {
    const {t, renderError, setError, setLoading, loading} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const {formControl, formValid, renderValidationMessage, formValue, setValidations} = useForm<{reason: string | null}>({
        reason: {
            value: '',
        }
    })

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        const res = await outcomeDecision(organizationId!, eventId!, itemId, voteId, passed!, formValue.reason);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        onClose();
    }


    return <Wrapper>
        <div className="title">
            {t('outcomeDecision.confirmationTitle')} <span
            className={`${passed ? 'passed' : 'not-passed'}`}>{passed ? t('outcomeDecision.confirmationPassed') : t('outcomeDecision.confirmationNotPassed')}</span>
        </div>

        <FormField>
            <div className="label">
                {t('eventAgenda.cancelReason')}
            </div>
            <Textarea>
                        <textarea
                            value={formControl('reason').value}
                            placeholder={t('outcomeDecision.cancelReasonPlaceholder')}
                            onChange={(e) => formControl('reason').patchValue(e.target.value)}/>
            </Textarea>
            {renderValidationMessage('reason')}
        </FormField>

        <div className="buttons">
            <button className="button muted text" onClick={onClose}>
                {t('outcomeDecision.cancel')}
            </button>
            <button className="button" onClick={_submit} disabled={loading}>
                {t('outcomeDecision.confirm')}
            </button>
        </div>
        {renderError()}
    </Wrapper>
}

export default OutcomeConfirmation;