import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--bgLighColor);
  overflow: hidden;
  border-radius: 5px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  .footer,
  .title {
    padding: 8px 20px;
    text-align: left;
    font-size: 2rem;
    background: #fff;
    margin-bottom: 20px;
    padding-right: 60px;
    color: var(--fontMutedColor);
    box-sizing: border-box;
    width: 100%;
    word-break: break-all;
  }
  .document-wrapper {
    flex: 1;
    overflow: auto;
    padding: 0 20px 20px;
  }
  .footer {
    margin-bottom: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`