import React, {ReactNode} from "react";
import {Wrapper} from "./VotingExcludeMemberConfirm.styles";
import {ModalCloseFunction} from "../../../../../components/Modal";
import {VoteParticipant} from "../../../../../logic/events/events.model";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {DataObject} from "../../../../../logic/core/dataObject";
import {Failure} from "../../../../../logic/core/failure";

const VotingEditMembersConfirm = ({
                                      onClose,
                                      participantToEdit,
                                      request,
                                      confirmationTitle,
                                      confirmationSetUnsetLabel,
                                      confirmationButtonLabel,
                                      customMessage
                                  }: {
    onClose: ModalCloseFunction,
    participantToEdit: VoteParticipant | null,
    request: (p: VoteParticipant) => Promise<DataObject<Failure, void | null>>
    confirmationTitle: (p: VoteParticipant) => string,
    confirmationSetUnsetLabel: (p: VoteParticipant) => ReactNode | null,
    confirmationButtonLabel: (p: VoteParticipant) => string,
    customMessage?: (p: VoteParticipant) => ReactNode | null,
}) => {
    const {t, loading, setLoading, renderError, setError} = useBaseScreenHook();

    const _save = async () => {

        setLoading(true);
        const res = await request(participantToEdit!);
        setLoading(false);

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose();
    }

    return <Wrapper>
        <div className="title">

            {confirmationTitle(participantToEdit!)}
        </div>
        {
            confirmationSetUnsetLabel(participantToEdit!)


        }


        <div className="member">
            {participantToEdit?.bodyMemberName}
        </div>

        {
            customMessage ? <div className={'custom-message'}>{customMessage(participantToEdit!)}</div> : null
        }


        <div className="hint">
            {t('votingExcludeMemberConfirm.hint')}
        </div>

        <div className="buttons">
            <button className="button text muted" onClick={onClose}>
                {t('votingExcludeMemberConfirm.cancel')}

            </button>
            <button className="button" onClick={_save} disabled={loading}>
                {
                    confirmationButtonLabel(participantToEdit!)
                }
            </button>
        </div>
        {renderError()}
    </Wrapper>
}

export default VotingEditMembersConfirm;