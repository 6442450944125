import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 17px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &:hover {
    background: var(--bgLighColor);
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
  }
`;
