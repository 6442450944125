import React from 'react';
import { Wrapper } from './ConfirmationModal.styles';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({
                               reject,
                               accept,
                               title,
                               body,
                               rejectLabel,
                               acceptLabel,
                           }: { reject: () => void, accept: () => void, title: string, body?: string, rejectLabel?: string, acceptLabel?: string, }) => {

    const [t] = useTranslation('common');

    return <Wrapper>

        <div className="title">
            {title}
        </div>

        {
            body
                ? <div className="body" dangerouslySetInnerHTML={{__html: body}}></div>
                : null
        }

        <div className="buttons">
            <button className="text muted button" onClick={reject}>
                {rejectLabel ?? t('confirmModal.reject')}
            </button>

            <button className="warn button" onClick={accept}>
                {acceptLabel ?? t('confirmModal.accept')}
            </button>
        </div>
    </Wrapper>
}


export default ConfirmationModal;
