import React, {useEffect, useState} from 'react';
import {Wrapper} from './EventCreateModal.styles';
import {ModalCloseFunction} from '../../../../components/Modal';
import {useBaseScreenHook} from '../../../../logic/core/base-screen.hook';
import {useParams} from 'react-router-dom';
import {Organization, ProceedingType} from '../../../../logic/organizations/organizations.model';
import FormField from '../../../../components/FormField';
import {useForm} from '../../../../logic/core/form.hook';
import Select from '../../../../components/Select';
import {Body} from '../../../../logic/bodies/bodies.model';
import {getBodies} from '../../../../logic/bodies/bodies';
import Radio from '../../../../components/Radio';
import Input from '../../../../components/Input';
import {EventBody, EventType} from '../../../../logic/events/events.model';
import {createEvent} from '../../../../logic/events/events';
import {Violations} from '../../../../logic/core/failures';
import {AppRoutes} from '../../../../logic/core/routes';
import {useAppStateHook} from "../../../../logic/core/appState.hook";

const EventCreateModal = ({onClose}: { onClose: ModalCloseFunction }) => {
    const {t, setError, renderError, navigate} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [bodies, setBodies] = useState<Body[] | null>(null);
    const {getOrganization} = useAppStateHook();
    const {renderValidationMessage, formControl, formValid, formValue, setValidations} = useForm<EventBody>({
        bodyId: {
            value: ''
        },
        proceedings: {
            value: '',
            disabled: true,
        },
        name: {
            value: ''
        },
        type: {
            value: ''
        },
        startDate: {
            value: '',
            disabled: true,
            validators: [(v) => {
                try {
                    new Date(v).toISOString();
                    return null;
                } catch (e) {
                    return t('errors.wrongDate')
                }
            },
            ]
        },
        finishDate: {
            value: '',
            disabled: true,
            validators: [(v) => {
                try {
                    new Date(v).toISOString();
                    return null;
                } catch (e) {
                    return t('errors.wrongDate')
                }
            },
            ]
        },
    })

    const selectedBodyId = formControl('bodyId').value;
    const selectedType: EventType = formControl('type').value;

    useEffect(() => {
        if (!bodies) {
            return;
        }
        const body = bodies.find((b) => b.id === selectedBodyId);

        if (body?.legalFormBody.selectableProceedingType) {
            formControl('proceedings').enable();
        } else {
            formControl('proceedings').disable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBodyId, bodies])
    useEffect(() => {
        if (!selectedType) {
            return;
        }


        if (selectedType === EventType.LIVE) {
            formControl('startDate').enable();
            formControl('finishDate').disable();
        } else {
            formControl('startDate').disable();
            formControl('finishDate').enable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType])

    useEffect(() => {
        _getBodies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (!organizationId) {
            return;
        }
        getOrganization().then((res) => {
            if (res.isFailure()) {
                return
            }

            setOrganization(res.data!);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId])

    const _getBodies = () => {
        if (!organizationId) {
            return;
        }
        getBodies(organizationId).then((res) => {

            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }

            setBodies(res.data!);

        });
    }
    const _submit = async () => {
        if (!formValid()) {
            return;
        }

        const res = await createEvent(organizationId!, {
            ...formValue,
            startDate: formValue.startDate ? new Date(formValue.startDate).toISOString() : null,
            finishDate: formValue.finishDate ? new Date(formValue.finishDate).toISOString() : null,
        });
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose();
        localStorage.setItem('event-source-location', window.location.pathname + window.location.search);
        if (formValue.type === EventType.LIVE) {
            navigate({
                pathname: `/${AppRoutes.organization}/${organizationId!}/${AppRoutes.event}/${res.data}`,
            });
         } else {
            navigate({
                pathname: `/${AppRoutes.organization}/${organizationId!}/${AppRoutes.event}/${res.data}/${AppRoutes.eventAgenda}`,
                search: `first=true`,
            });
        }

    }
    return <Wrapper>
        <div className="organization-name">
            {organization?.name}
        </div>
        <div className="title">
            {t('eventCreateModal.title')}
        </div>


        <FormField>
            <div className="label">
                {t('eventCreateModal.body') + ' *'}
            </div>
            <div className="hint">
                {t('eventCreateModal.bodyHint')}
            </div>


            <Select
                currentValue={formControl('bodyId').value}
                onChange={formControl('bodyId').patchValue}
            >
                <option/>
                {bodies?.map((p) => {
                    return <option value={p.id} key={p.id}>{p.name}</option>
                })}
            </Select>
            {renderValidationMessage('bodyId')}
        </FormField>

        {
            formControl('proceedings').control.disabled
                ? null
                : <>
                    <FormField>
                        <div className="label">
                            {t('eventCreateModal.proceedings') + ' *'}
                        </div>
                    </FormField>
                    <FormField>

                        <Radio labelElement={<div className="label">
                            {t('eventCreateModal.proceedingOrdinary')}

                        </div>}>
                            <input type="radio" value={ProceedingType.ORDINARY} name="proceedings"
                                   checked={formControl('proceedings').value === ProceedingType.ORDINARY}
                                   onChange={() => {
                                       formControl('proceedings').patchValue(ProceedingType.ORDINARY)
                                   }
                                   }/>

                        </Radio>
                    </FormField>
                    <FormField>

                        <Radio labelElement={<div className="label">
                            {t('eventCreateModal.proceedingExtraordinary')}

                        </div>}>
                            <input type="radio" value={ProceedingType.EXTRAORDINARY} name="proceedings"
                                   checked={formControl('proceedings').value === ProceedingType.EXTRAORDINARY}
                                   onChange={() => {
                                       formControl('proceedings').patchValue(ProceedingType.EXTRAORDINARY)
                                   }
                                   }/>

                        </Radio>
                        {renderValidationMessage('proceedings')}
                    </FormField>

                </>
        }

        <FormField>
            <Input label={t('eventCreateModal.name') + ' *'}>
                <input type="text"
                       value={formControl('name').value}
                       onChange={(e) => formControl('name').patchValue(e.target.value)}/>
            </Input>

            {renderValidationMessage('name')}
        </FormField>

        <FormField>
            <div className="label">
                {t('eventCreateModal.type') + ' *'}
            </div>
        </FormField>
        <FormField>

            <Radio labelElement={
                <div className="label">
                    <strong>{t('eventCreateModal.typeLive')}</strong>
                    <div className="label-description">
                        {t('eventCreateModal.liveDescription')}
                    </div>

                </div>}>
                <input type="radio" value={EventType.LIVE} name="type"
                       checked={formControl('type').value === EventType.LIVE}
                       onChange={() => {
                           formControl('type').patchValue(EventType.LIVE)
                       }
                       }/>

            </Radio>
        </FormField>
        <FormField>

            <Radio labelElement={<div className="label">
                <strong>{t('eventCreateModal.typeCircular')}</strong>
                <div className="label-description">
                    {t('eventCreateModal.circularDescription')}
                </div>
            </div>}>
                <input type="radio" value={EventType.CIRCULAR} name="type"
                       checked={formControl('type').value === EventType.CIRCULAR}
                       onChange={() => {
                           formControl('type').patchValue(EventType.CIRCULAR)
                       }
                       }/>

            </Radio>

            {renderValidationMessage('type')}

        </FormField>

        {
            formControl('finishDate').control.disabled
                ? null
                : <FormField>
                    <Input label={t('eventCreateModal.finishDate') + ' *'}>
                        <input type="datetime-local"
                               value={formControl('finishDate').value}
                               onChange={(e) => formControl('finishDate').patchValue(e.target.value)}/>
                    </Input>

                    {renderValidationMessage('finishDate')}
                </FormField>
        }

        {
            formControl('startDate').control.disabled
                ? null
                : <FormField>
                    <Input label={t('eventCreateModal.startDate') + ' *'}>
                        <input type="datetime-local"
                               value={formControl('startDate').value}
                               onChange={(e) => formControl('startDate').patchValue(e.target.value)}/>
                    </Input>

                    {renderValidationMessage('startDate')}
                </FormField>
        }


        <div className="buttons">
            <button className="button text muted" onClick={onClose}>
                {t('eventCreateModal.cancel')}
            </button>

            <button className={'button'} onClick={_submit}>
                {t('eventCreateModal.save')}
            </button>
        </div>

        <div className="next-step">
            {t('eventCreateModal.nextStep')}
        </div>

        {renderError()}

    </Wrapper>
}

export default EventCreateModal;
