import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import {Wrapper} from "./OrganizationBillingsInvoiceData.styles";
import {useForm} from "../../../../../logic/core/form.hook";
import React, {useEffect} from "react";
import FormField from "../../../../../components/FormField";
import Input from "../../../../../components/Input";
import {CreditInvoiceData} from "../../../../../logic/credits/credits.model";
import {validateInvoiceData} from "../../../../../logic/credits/credits";
import {useParams} from "react-router-dom";
import {Violations} from "../../../../../logic/core/failures";

const OrganizationBillingsInvoiceData = ({
    data,
                                             onClose,
                                         }: { onClose: (data: {cancel?: boolean, skip?: boolean, invoiceData?: CreditInvoiceData}) => void, data: CreditInvoiceData }, ) => {
    const {organizationId} = useParams();

    const _fieldRequiredValidator = (v: any) => {
        // if (v && v.trim() && v !== '') {
            return null;
        // }

        // return t('validations.required')
    };

    const {
        renderValidationMessage,
        formControl,
        formValid,
        formValue,
        setValidations,
    } = useForm<CreditInvoiceData>({
        companyName: {
            value: data.companyName || '',
            validators: [_fieldRequiredValidator]
        },
        nip: {
            value: data.nip || '',
            validators: [_fieldRequiredValidator]
        },
        address: {
            controls: {
                country: {
                    value: data.address?.country || '',
                    validators: [_fieldRequiredValidator]
                },
                street: {
                    value: data.address?.street || '',
                    validators: [_fieldRequiredValidator]
                },
                postalCode: {
                    value: data.address?.postalCode || '',
                    validators: [_fieldRequiredValidator]
                },
                city: {
                    value: data.address?.city || '',
                    validators: [_fieldRequiredValidator]
                },
            }
        },
        recipientEmail: {
            value: data.recipientEmail || '',
            validators: [_fieldRequiredValidator]
        },

    });

    useEffect(() => {

    }, [])

    const {t, setLoading, setError, loading, renderError} = useBaseScreenHook();

    const _submit = async () => {
        formValid();

        if (!formValid()) {
            return;
        }

        setLoading(true);

        const body: any = {
            companyName: formValue.companyName,
            nip: formValue.nip || null,
            recipientEmail: formValue.recipientEmail || null,
            address: {
                country: formValue.address?.country || null,
                postalCode: formValue.address?.postalCode || null,
                street: formValue.address?.street || null,
                city: formValue.address?.city || null,
            }
        }

        const res = await validateInvoiceData(organizationId!, body);
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }


        onClose({
            invoiceData: body
        });
    }
    return <Wrapper>
        <div className="title">
            {t('organizationBillingsInvoiceData.title')}
        </div>

        <FormField>
            <Input label={t('organizationBillingsInvoiceData.companyName')}>
                <input type="text" value={formControl('companyName').value}
                       onChange={(e) => formControl('companyName').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('companyName')}
        </FormField>


        <FormField>
            <Input label={t('organizationBillingsInvoiceData.nip')}>
                <input type="text" value={formControl('nip').value}
                       onChange={(e) => formControl('nip').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('nip')}
        </FormField>

        <FormField>
            <Input label={t('organizationBillingsInvoiceData.country')}>
                <input type="text" value={formControl('address.country').value}
                       onChange={(e) => formControl('address.country').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('address.country')}
        </FormField>

        <FormField>
            <Input label={t('organizationBillingsInvoiceData.street')}>
                <input type="text" value={formControl('address.street').value}
                       onChange={(e) => formControl('address.street').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('address.street')}
        </FormField>

        <div className="row">
            <div className="zip-code">
                <FormField>
                    <Input label={t('organizationBillingsInvoiceData.postalCode')}>
                        <input type="text" value={formControl('address.postalCode').value}
                               onChange={(e) => formControl('address.postalCode').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('address.postalCode')}
                </FormField>
            </div>
            <FormField>
                <Input label={t('organizationBillingsInvoiceData.city')}>
                    <input type="text" value={formControl('address.city').value}
                           onChange={(e) => formControl('address.city').patchValue(e.target.value)}/>
                </Input>
                {renderValidationMessage('address.city')}
            </FormField>
        </div>


        <FormField>
            <Input label={t('organizationBillingsInvoiceData.recipientEmail')}>
                <input type="text" value={formControl('recipientEmail').value}
                       onChange={(e) => formControl('recipientEmail').patchValue(e.target.value)}/>
            </Input>
            {renderValidationMessage('recipientEmail')}
        </FormField>

        <div className="email-hint">
            {t('organizationBillingsInvoiceData.emailHint')}
        </div>



        <div className="buttons">
            <button className={'button text'} disabled={loading} onClick={() => onClose({cancel: true})}>
                {t('organizationBillingsInvoiceData.backBtn')}
            </button>
            <button className={'button text'} disabled={loading} onClick={() => onClose({skip: true})}>
                {t('organizationBillingsInvoiceData.skip')}
            </button>
            <button className={'button save'} disabled={loading} onClick={_submit}>
                {t('organizationBillingsInvoiceData.saveBtn')}
            </button>
        </div>

        {renderError()}
    </Wrapper>
}

export default OrganizationBillingsInvoiceData;
