import React, {ReactNode, useEffect, useState} from "react";
import {Wrapper} from "./VotingExcludeMembersModal.styles";
import Modal, {ModalCloseFunction} from "../../../../components/Modal";
import {useParams} from "react-router-dom";
import {getVoteParticipants} from "../../../../logic/events/events";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {VoteParticipant, Voting} from "../../../../logic/events/events.model";
import FormField from "../../../../components/FormField";
import Input from "../../../../components/Input";
import iconSearch from "../../../../assets/icon-search.svg";
import {Table} from "../../../../components/Table/table";
import {BodyMemberType} from "../../../../logic/bodies/bodies.model";
import iconCompany from "../../../../assets/icon-company.svg";
import iconPerson from "../../../../assets/icon-person.svg";
import Loader from "../../../../components/Loader";
import VotingEditMembersConfirm from "./VotingExcludeMemberConfirm";
import {DataObject} from "../../../../logic/core/dataObject";
import {Failure} from "../../../../logic/core/failure";

const VotingEditMembersModal = ({
                                    onClose,
                                    itemId,
                                    vote,
                                    selectedMemberDescription,
                                    setButtons,
                                    isSelectedFn,
                                    title,
                                    description,
                                    listDescription,
                                    confirmationTitle,
                                    confirmationSetUnsetLabel,
                                    confirmationButtonLabel,
                                    request,
                                    customMessage,
                                }: {
    onClose: ModalCloseFunction,
    itemId?: string,
    vote?: Voting,
    isSelectedFn: (p: VoteParticipant) => boolean,
    selectedMemberDescription: (p: VoteParticipant) => string | null,
    setButtons: (p: VoteParticipant, set: (p: VoteParticipant) => void, unset: (p: VoteParticipant) => void) => ReactNode | null,
    title: string,
    description?: string,
    listDescription?: string,
    request: (p: VoteParticipant) => Promise<DataObject<Failure, void | null>>,
    confirmationTitle: (p: VoteParticipant) => string,
    confirmationSetUnsetLabel: (p: VoteParticipant) => ReactNode | null,
    confirmationButtonLabel: (p: VoteParticipant) => string,
    customMessage?: (p: VoteParticipant) => ReactNode | null,

}) => {
    const {organizationId, eventId} = useParams();
    const {t, setError, renderError} = useBaseScreenHook();
    const [participants, setParticipants] = useState<VoteParticipant[] | null>(null);
    const [filteredMembers, setFilteredMembers] = useState<VoteParticipant[] | null>(null);
    const [participantToEdit, setParticipantToEdit] = useState<VoteParticipant | null>(null);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        _getVoteParticipants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {

        setFilteredMembers(participants?.filter((m) => m.bodyMemberName.toLocaleLowerCase().includes(query.toLocaleLowerCase())) ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const _getVoteParticipants = async () => {
        const res = await getVoteParticipants(organizationId!, eventId!, itemId!, vote!.id!);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }

        setParticipants(res.data!);
        setFilteredMembers(res.data!);
    }

    const _unset: (m: VoteParticipant) => void = (m: VoteParticipant): void => {
        setParticipantToEdit(m);
    }

    const _set: (m: VoteParticipant) => void = (m: VoteParticipant): void => {
        setParticipantToEdit(m);
    }


    return <Wrapper>
        <div className="title">
            {title}
        </div>
        {
            description ?
                <p className="description">
                    {description}
                </p>
                : null
        }

        {
            listDescription ?
                <p className="list-description">
                    {listDescription}
                </p>
                : null
        }


        <div className="search">
            <FormField>
                <Input>
                    <input type="text" value={query} onChange={(e) => setQuery(e.target.value)}
                           placeholder={t('votingExcludeMembersModal.searchPlaceholder')}/>
                    <img src={iconSearch} alt="search" className={'icon'}/>
                </Input>
            </FormField>
        </div>

        <div className="list">
            {
                filteredMembers
                    ? <Table className={'members'}>
                        <tbody>
                        {
                            filteredMembers.map((m) => {
                                return <tr key={m.id}
                                           className={isSelectedFn(m) ? 'added' : ''}>

                                    <td className={'type'}>
                                        {
                                            m.bodyMemberType === BodyMemberType.organizationMember
                                                ? <img src={iconCompany} alt=""/>
                                                : <img src={iconPerson} alt=""/>
                                        }
                                    </td>
                                    <td className={'name'}>
                                        {m.bodyMemberName}
                                        {
                                            selectedMemberDescription(m)
                                                ? <div className="added-label">
                                                    {selectedMemberDescription(m)}
                                                </div>
                                                : null
                                        }

                                    </td>
                                    <td className={'action'}>
                                        {
                                            setButtons(m, _set, _unset)
                                        }
                                    </td>

                                </tr>
                            })
                        }


                        </tbody>
                        {
                            !filteredMembers.length ? <tfoot>
                            <tr>
                                <td colSpan={3}>Brak wyników</td>
                            </tr>
                            </tfoot> : null
                        }
                    </Table>
                    : <Loader/>

            }


        </div>

        <div className="buttons">
            <button className={'button'} onClick={onClose}>
                {t('votingExcludeMembersModal.close')}
            </button>

        </div>
        <Modal width={600} show={!!participantToEdit} onClose={() => setParticipantToEdit(null)}>
            <VotingEditMembersConfirm
                participantToEdit={participantToEdit}
                onClose={() => {
                    _getVoteParticipants();
                    setParticipantToEdit(null);
                }
                }
                request={request}
                confirmationTitle={confirmationTitle}
                confirmationSetUnsetLabel={confirmationSetUnsetLabel}
                confirmationButtonLabel={confirmationButtonLabel}
                customMessage={customMessage}
            />
        </Modal>
        {renderError()}
    </Wrapper>
}

export default VotingEditMembersModal;