import React, { useContext, useEffect, useState } from 'react';
import { StatusModalConfirmationWrapper, Wrapper } from './OrganizationUsers.styles';
import { useBaseScreenHook } from '../../../logic/core/base-screen.hook';
import { useParams } from 'react-router-dom';
import {
    activateUser,
    blockUser,
    deleteUser,
    getUsers,
    resendUserInvitation
} from '../../../logic/organizations/organizations';
import iconAdd from '../../../assets/icon-plus-white.svg';
import { Table } from '../../../components/Table/table';
import ContextMenu from '../../../components/ContextMenu';
import iconMenu from '../../../assets/icon-menu.svg';
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import OrganizationUserDetails from './OrganizationUserDetails';
import { OrganizationUser, OrganizationUserStatus } from '../../../logic/auth/auth.model';
import { ToastContext } from '../../../components/Toast/toastStateProvider';
import { ModalType } from '../../../components/Toast';

const OrganizationUsers = () => {
    const {t, setError, renderError} = useBaseScreenHook();
    const [addModal, setAddModal] = useState<boolean>(false);
    const [userToDelete, setUserToDelete] = useState<OrganizationUser | null>(null);
    const [userToEdit, setUserToEdit] = useState<OrganizationUser | null>(null);
    const [userToChangeStatus, setUserToChangeStatus] = useState<OrganizationUser | null>(null);
    const [users, setUsers] = useState<OrganizationUser[] | null>(null);
    const [sendingInvitations, setSendingInvitations] = useState(new Set<string>([]));
    const {organizationId} = useParams();
    const [, setToastState] = useContext(ToastContext);

    useEffect(() => {
        _getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getUsers = async () => {
        const res = await getUsers(organizationId!);

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setUsers(res.data!);
    }

    const _deleteUser = () => {
        deleteUser(organizationId!, userToDelete!.id).then((res) => {
            setUserToDelete(null);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            _getUsers();
        })
    }
    const _changeUserStatus = () => {
        let request;
        if (userToChangeStatus?.status === OrganizationUserStatus.Blocked) {
            request = activateUser(organizationId!, userToChangeStatus.id)
        } else {
            request = blockUser(organizationId!, userToChangeStatus!.id)
        }

        if (!request) return;

        request.then((res) => {
            setUserToChangeStatus(null);
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            _getUsers();
        })
    }

    const _modalClosed = (refresh: boolean) => {
        setAddModal(false);
        setUserToEdit(null);
        if (refresh) {
            _getUsers();
        }
    }


    const _renderStatus = (status: OrganizationUserStatus) => {
        switch (status) {
            case OrganizationUserStatus.Active:
                return <div className="status active"> {t('organizationUsers.statusActive')}</div>
            case OrganizationUserStatus.Pending:
                return <div className="status pending"> {t('organizationUsers.statusPending')}</div>
            default:
                return <div className="status notactive"> {t('organizationUsers.statusBlocked')}</div>
        }
    }

    const _resend = async (user: OrganizationUser) => {
        sendingInvitations.add(user.id)
        setSendingInvitations(sendingInvitations)
        const res = await resendUserInvitation(organizationId!, user.id);
        sendingInvitations.delete(user.id);
        setSendingInvitations(sendingInvitations)

        if (res.isFailure()) {
            setError(res.failure!);
        }
        setToastState({
            type: ModalType.success,
            message: t('organizationUsers.sentInvitation')
        });
    }

    const _renderContextMenu = (user: OrganizationUser) => {
        const elements: React.ReactNode[] = [];

        if (user.permissions.canBeUpdated || user.permissions.canRoleBeUpdated) {
            elements.push(
                <ContextMenuItem key="edit" onClick={() => {
                    setUserToEdit(user);
                    setAddModal(true);
                }
                }>
                    {t('organizationUsers.editBtn')}
                </ContextMenuItem>
            )
        }
        if (user.permissions.canBeBlockedOrUnblocked) {
            if (user.status === OrganizationUserStatus.Blocked) {
                elements.push(
                    <ContextMenuItem key="status" onClick={() => {
                        setUserToChangeStatus(user);
                    }
                    }>
                        {t('organizationUsers.activateUser')}
                    </ContextMenuItem>
                )
            } else {
                elements.push(
                    <ContextMenuItem key="status" onClick={() => {
                        setUserToChangeStatus(user);
                    }
                    }>
                        {t('organizationUsers.blockUser')}
                    </ContextMenuItem>
                )
            }


        }

        if (user.permissions.canBeDeleted) {
            elements.push(
                <ContextMenuItem key="delete" onClick={() => setUserToDelete(user)}>
                                                <span className="delete">
                                                    {t('organizationUsers.deleteBtn')}
                                                </span>
                </ContextMenuItem>
            )
        }

        if (!elements.length) {
            return null;
        }

        return <ContextMenu icon={iconMenu}>
            {elements}
        </ContextMenu>;
    }

    return <Wrapper>
        <div className="bar">
            <div className="left">
                <div className="title">
                    {t('organizationUsers.title')}
                </div>
                <div className="description">
                    {t('organizationUsers.description')}
                </div>
            </div>
            <button className={'button icon'} onClick={() => setAddModal(true)}>
                <img src={iconAdd} alt="icon"/>
                {t('organizationUsers.addBtn')}
            </button>

        </div>

        <Table>
            <thead>
            <tr>
                <th>
                    {t('organizationUsers.name')}
                </th>
                <th>
                    {t('organizationUsers.email')}
                </th>
                <th>
                    {t('organizationUsers.permissions')}
                </th>
                <th>
                    {t('organizationUsers.status')}
                </th>
                <th>
                </th>
            </tr>
            </thead>
            <tbody>
            {
                users?.map((e) => {
                    return <tr key={e.id}>
                        <td>
                            {e.firstName} {e.lastName}
                        </td>
                        <td>
                            {e.email}
                        </td>
                        <td>
                            {t(`roles.${e.role}`)}
                        </td>
                        <td>
                            {_renderStatus(e.status)}
                        </td>
                        <td>

                            <div className="action">
                                {
                                    e.status === OrganizationUserStatus.Pending
                                        ? <button className={'button text'} onClick={() => _resend(e)}
                                                  disabled={sendingInvitations.has(e.id)}>
                                            {t('organizationUsers.resend')}
                                        </button>
                                        : null
                                }

                                {_renderContextMenu(e)}


                            </div>
                        </td>
                    </tr>
                })
            }


            </tbody>
        </Table>

        <Modal width={700} onClose={() => _modalClosed(false)} show={addModal} backDropClose={false}>
            <OrganizationUserDetails onClose={(refresh) => _modalClosed(refresh)} userToEdit={userToEdit}/>
        </Modal>

        <Modal width={600} show={!!userToDelete} onClose={() => setUserToDelete(null)}>
            <ConfirmationModal
                reject={() => setUserToDelete(null)}
                accept={() => _deleteUser()}
                title={`${t('organizationUsers.deleteModalTitle')} ${userToDelete?.firstName ?? ''} ${userToDelete?.lastName ?? ''}?`}
                body={t('organizationUsers.deleteModalBody')}
            />
        </Modal>
        <Modal width={600} show={!!userToChangeStatus} onClose={() => setUserToChangeStatus(null)}>
            <StatusModalConfirmationWrapper
                className={userToChangeStatus?.status !== OrganizationUserStatus.Blocked ? '' : 'unblock-modal'}>
                <ConfirmationModal
                    reject={() => setUserToChangeStatus(null)}
                    accept={() => _changeUserStatus()}
                    title={`${userToChangeStatus?.status !== OrganizationUserStatus.Blocked ? t('organizationUsers.blockUserTitle') : t('organizationUsers.activateUserTitle')} ${userToChangeStatus?.firstName ?? ''} ${userToChangeStatus?.lastName ?? ''}?`}
                    body={userToChangeStatus?.status !== OrganizationUserStatus.Blocked ? t('organizationUsers.blockUserDescription') : t('organizationUsers.activateUserDescription')}
                    acceptLabel={userToChangeStatus?.status !== OrganizationUserStatus.Blocked ? t('organizationUsers.blockUser') : t('organizationUsers.activateUser')}
                />
            </StatusModalConfirmationWrapper>
        </Modal>

        {renderError()}
    </Wrapper>
}

export default OrganizationUsers;
