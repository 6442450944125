import React, {useEffect, useState} from "react";
import {Wrapper} from "./FilesPreview.styles";
import {ModalCloseFunction} from "../Modal";
import {downloadByUrl, saveFile} from "../../logic/files/files.service";
import {useBaseScreenHook} from "../../logic/core/base-screen.hook";
import Loader from "../Loader";
import PdfViewer from "../PdfViewer";
import ImageViewer from "../ImageViewer";


const FilesPreview = ({
                          url,
                          urls,
                          onClose
                      }: {
    url?: string, urls?: string[], onClose: ModalCloseFunction;
}) => {
    const {setError, renderError, t} = useBaseScreenHook();
    const [file, setFile] = useState<File | null>(null);
    const [currentUrl, setCurrentUrl] = useState<string | null>(null);
    const [index, setIndex] = useState<number>(0);
    useEffect(() => {
        if (url) {
            setCurrentUrl(url);
        }
        if (urls) {
            setIndex(urls.findIndex(u => u === url));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])
    useEffect(() => {
        if (currentUrl) {
            _preview(currentUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUrl])

    const _preview = async (url: string) => {
        setFile(null);
        const res = await downloadByUrl(url);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setFile(res.data?.data!);
    }
    const _download = async () => {
        const saveRes = await saveFile(file!, file!.name!, file!.type);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }
    }

    const _changeFile = (step: -1 | 1) => {

        if (step === 1) {
            let currentIndex: number = index + 1;
            if (currentIndex >= urls!.length) {
                currentIndex = 0;
            }
            setIndex(currentIndex);
            setCurrentUrl(urls![currentIndex]);
        } else {
            let currentIndex: number = index - 1;
            if (currentIndex < 0) {
                currentIndex = urls!.length - 1;
            }
            setIndex(currentIndex);
            setCurrentUrl(urls![currentIndex]);
        }


    }

    return <Wrapper>
        <div className="title">
            {file?.name}
        </div>
        {
            file
                ? file.type.includes('pdf') ? <div className={'document-wrapper'}>
                    <PdfViewer fileChanged={() => {
                    }} viewOnly={true} currentFile={file}/>
                </div> : file.type.includes('image/')
                    ? <ImageViewer file={file}/>
                    : file.name
                : <Loader/>
        }
        <div className="footer">
            {urls?.length ? <button className="button accent" onClick={() => _changeFile(-1)}>
                &lt;
            </button> : null}
            {
                urls?.length && urls.length > 1 ? <div className="counter">{index + 1}/{urls?.length || 1}</div> : <div/>
            }

            {urls?.length ? <button className="button accent" onClick={() => _changeFile(1)}>
                &gt;
            </button> : null}
            <button className="button text" onClick={_download}>
                {t('documents.download')}
            </button>
        </div>

        {renderError()}
    </Wrapper>
}

export default FilesPreview;