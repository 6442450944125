import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
    }
  }

`;

export const ContextMenuOverlay = styled.div `
  .backdrop {
    position: fixed;
    z-index: 1;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .overlay {
    z-index: 2;
    background: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 10px 20px #00000026;
    &.reverse {
      top: auto;
      bottom: 100%;
    }
    & > *:not(:last-child) {
      .tooltip-item {
        border-bottom: 1px solid var(--bgLighColor)
      }
    }
  }
`
