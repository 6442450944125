import React from 'react';
import { Wrapper } from './EventHeader.styles';
import iconCalendar from '../../../assets/icon-calendar.svg';
import { useBaseScreenHook } from '../../../logic/core/base-screen.hook';
import { EventModel } from '../../../logic/events/events.model';
import { Organization } from '../../../logic/organizations/organizations.model';

const EventHeader = ({event, organization}: {event: EventModel, organization: Organization}) => {
    const {i18n, t} = useBaseScreenHook();
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    return <Wrapper>
        <div className="title">
            {event?.content?.basic.name}
        </div>

        <div className="description">
            {t('eventInfo.description', {
                organizationName: organization.name,
                eventName: event?.content?.basic.body.name,
            })}
        </div>

        <div className="date">
            <img src={iconCalendar} alt="icon"/>
            {event?.content?.basic.startDate && dateFormatter(new Date(event?.content?.basic.startDate))}
        </div>
    </Wrapper>
}

export default EventHeader;
