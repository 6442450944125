
import React, {useContext} from "react";
import {Wrapper} from "./AddAnnotationModal.styled";
import {ModalCloseFunction} from "../../../../../components/Modal";
import {useBaseScreenHook} from "../../../../../logic/core/base-screen.hook";
import Wysiwyg from "../../../../../components/Wysiwyg";
import {useForm} from "../../../../../logic/core/form.hook";
import {Violations} from "../../../../../logic/core/failures";
import {ModalType} from "../../../../../components/Toast";
import {useParams} from "react-router-dom";
import {ToastContext} from "../../../../../components/Toast/toastStateProvider";
import FormField from "../../../../../components/FormField";
import {addDocumentAnnotation} from "../../../../../logic/documents/documents";
import {Document} from "../../../../../logic/documents/documents.model";
import Loader from "../../../../../components/Loader";

const AddAnnotationModal = ({onClose, document}: {onClose: ModalCloseFunction, document: Document | null}) => {
    const {t, loading, setLoading, setError, renderError} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const [, setToastState] = useContext(ToastContext);

    const {formControl, renderValidationMessage, formValue, formValid, setValidations} = useForm<{ content: string}>({
        content: {
            value: ''
        },
    })

    const _onChange = (value: string) => {
        formControl('content').patchValue(value);
    }

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        const content: string =  formValue.content;
        setLoading(true);
        const res = await addDocumentAnnotation(organizationId!, eventId!, document!.id, content);
        setLoading(false);
        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setToastState({
            type: ModalType.success,
            message: t('documents.annotationAdded'),
        });

        onClose();
    }

    return <Wrapper>

        <div className={'title'}>
            {t('documents.addAnnotation')}
        </div>
        <div className="description">
            {t('documents.addAnnotationDescription')}
        </div>

        <FormField>
            <div className="label">{t('documents.annotation')}</div>
            <div className="content">
                <Wysiwyg content={''} onChange={_onChange} />
                {renderValidationMessage('content')}
            </div>
        </FormField>



        <div className="buttons">
            <button className="button muted text" disabled={loading} onClick={onClose}>
                {t('documents.cancel')}
            </button>

            <button className="button" disabled={loading} onClick={_submit}>
                {
                    loading ? <Loader/> : t('documents.add')
                }
            </button>
        </div>


        {renderError()}
    </Wrapper>
}

export default AddAnnotationModal;