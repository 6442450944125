import React, {ReactNode} from 'react';
import { Wrapper } from './Checkbox.styles';


const Checkbox = (props: {
    label?: string,
    labelElements?:ReactNode
    children: any,
}) => {
    return <Wrapper>
        <label>
            {props.children}
            <span/>
            {props.label
                ? <span className="label" dangerouslySetInnerHTML={{__html: props.label}}></span>
                : props.labelElements ? props.labelElements : null  }

        </label>
    </Wrapper>
}


export default Checkbox;
