import React, {useEffect, useState} from 'react';
import {Wrapper} from './EventVotingCreationModal.styles';
import {ModalCloseFunction} from '../../../../components/Modal';
import FormField from '../../../../components/FormField';
import {useBaseScreenHook} from '../../../../logic/core/base-screen.hook';
import Input from '../../../../components/Input';
import {FormControls, useForm} from '../../../../logic/core/form.hook';
import Radio from '../../../../components/Radio';
import {
    EventType,
    Voting,
    VotingBody,
    VotingModeType,
    VotingQuorumType,
    VotingType
} from '../../../../logic/events/events.model';
import Select from '../../../../components/Select';
import Switch from '../../../../components/Switch';
import {createVoting, editVoting, getLastVotingConfiguration, useEvent} from '../../../../logic/events/events';
import {useParams, useSearchParams} from 'react-router-dom';
import {Violations} from '../../../../logic/core/failures';
import {Textarea} from '../../../../components/Textarea/Textarea.styles';
import PdfViewer from '../../../../components/PdfViewer';
import {downloadFile, uploadFile} from '../../../../logic/files/files.service';
import Loader from '../../../../components/Loader';
import FilesPicker, {SupportedFile} from '../../../../components/FilesPicker';
import Wysiwyg from "../../../../components/Wysiwyg";

const MODE = [
    {
        type: VotingModeType.relativeMajority,
    },
    {
        type: VotingModeType.absoluteMajority
    },
    {
        type: VotingModeType.qualifiedMajority,
        types: [
            {
                type: VotingModeType.mostThreeQuarters,
            },
            {
                type: VotingModeType.mostTwoThirds,
            },
            {
                type: VotingModeType.consensus,
            },
            {
                type: VotingModeType.percentMajority,
            },
            {
                type: VotingModeType.fractionMajority,
            },
            {
                type: VotingModeType.numberMajority,
            },
        ]
    }
]


const QUORUM = [
    {
        type: VotingQuorumType.all
    },
    {
        type: VotingQuorumType.atLeastHalf
    },
    {
        type: VotingQuorumType.atLeastTwoThirds
    },
    {
        type: VotingQuorumType.atLeastTreeQuarters
    },
    {
        type: VotingQuorumType.other,
        types: [
            {
                type: VotingQuorumType.percentMinimum
            },
            {
                type: VotingQuorumType.fractionMinimum
            },
            {
                type: VotingQuorumType.numberMinimum
            },
        ]
    },

]


const EventVotingCreationModal = ({
                                      onClose,
                                      voting,
                                      eventName,
                                      itemId,
                                      initialValues,
                                  }: { onClose: ModalCloseFunction, initialValues?: Voting | null, eventName: string, itemId: string, voting?: Voting | null }) => {
    const {organizationId, eventId} = useParams();
    const [params] = useSearchParams();
    const [file, setFile] = useState<File | null>(null);
    const [created, setCreated] = useState<boolean>(false);
    const {t, renderError, setError, loading, setLoading, navigate} = useBaseScreenHook();
    const {event} = useEvent();
    const _supportedFileType: SupportedFile = {
        mimeType: 'application/pdf',
        displayValue: "PDF",
    };
    const _intValidator = (v: any) => {
        // eslint-disable-next-line eqeqeq
        if (parseInt(v) == v) {
            return null;
        }

        return t('errors.onlyInt')
    };
    const {
        renderValidationMessage,
        formControl,
        formValue,
        formValid,
        setValidations,
        setFormConfig,
        formConfig
    } = useForm<VotingBody>({
        documentId: {
            value: null,
        },
        content: {
            value: '',
        },

        name: {
            value: '',
        },
        description: {
            value: ''
        },
        type: {
            value: VotingType.Open,
        },
        mode: {
            controls: {
                type: {
                    value: VotingModeType.absoluteMajority,
                },
                modeType: {
                    disabled: true,
                    controls: {
                        type: {
                            value: '',
                            disabled: true,
                        },
                        percent: {
                            value: '',
                            disabled: true,
                        },
                        numerator: {
                            value: '',
                            disabled: true,
                            validators: [_intValidator]
                        },
                        denominator: {
                            value: '',
                            disabled: true,
                            validators: [_intValidator]
                        },
                        number: {
                            value: '',
                            disabled: true,
                            validators: [_intValidator]
                        }
                    }
                }
            }
        },
        quorumEnabled: {
            value: false,
            disabled: true,
        },
        quorum: {
            disabled: true,
            controls: {
                type: {
                    value: '',
                    disabled: true,
                },
                quorumType: {
                    disabled: true,
                    controls: {
                        type: {
                            value: '',
                            disabled: true,
                        },
                        percent: {
                            value: '',
                            disabled: true,
                        },
                        numerator: {
                            value: '',
                            disabled: true,
                            validators: [_intValidator]
                        },
                        denominator: {
                            value: '',
                            disabled: true,
                            validators: [_intValidator]
                        },
                        number: {
                            value: '',
                            disabled: true,
                            validators: [_intValidator]
                        }
                    }
                }
            }
        }
    })
    const quorumEnabledControl = formControl('quorumEnabled');
    const modeTypeControl = formControl('mode.type');
    const modeTypeQualifiedControl = formControl('mode.modeType.type');
    const modeModeTypeControl = formControl('mode.modeType');
    const modeNumberControl = formControl('mode.modeType.number');
    const modePercentControl = formControl('mode.modeType.percent');
    const modeNumeratorControl = formControl('mode.modeType.numerator');
    const modeDenominatorControl = formControl('mode.modeType.denominator');

    const quorumControl = formControl('quorum');
    const quorumTypeControl = formControl('quorum.type');
    const quorumQuorumTypeControl = formControl('quorum.quorumType');
    const quorumQuorumTypeTypeControl = formControl('quorum.quorumType.type');
    const quorumQuorumTypePercentControl = formControl('quorum.quorumType.percent');
    const quorumQuorumTypeNumeratorControl = formControl('quorum.quorumType.numerator');
    const quorumQuorumTypeDenominatorControl = formControl('quorum.quorumType.denominator');
    const quorumQuorumTypeNumberControl = formControl('quorum.quorumType.number');

    useEffect(() => {
        if (!event || created) {
            return;
        }
        if (initialValues || voting) {
            const _voting: Voting = initialValues || voting!;
            if (_voting.documentId && !_voting.content) {
                downloadFile(_voting.documentId).then((res) => {
                    if (res.isFailure()) {
                        setError(res.failure!)
                        return;
                    }

                    setFile(res.data?.data!);
                })
            }
            setTimeout(() => {
                setCreated(true);
                _updateFormConfig(_voting, true);
            })

        } else {
            getLastVotingConfiguration().then((res) => {
                if (res.isFailure()) {
                    if (event?.content?.type === EventType.CIRCULAR) {
                        formControl('name').patchValue(eventName);
                    }
                    return;
                }
                setCreated(true);
                _updateFormConfig(res.data!);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    const _updateFormConfig = (data: Voting, duplicate: boolean = false) => {
        const config: FormControls = {
            ...formConfig.controls,
            name: {
                value: (duplicate && data.name) || (event?.content?.type === EventType.CIRCULAR ? eventName : null) || '',
            },
            description: {
                value: (duplicate && data.description) || '',
            },
            documentId: {
                value: (duplicate && !data.content && data.documentId) || null,
            },
            content: {
                value: (duplicate && data.content) || '',
            },
            type: {
                value: event?.content?.type === EventType.CIRCULAR ? VotingType.Open : data!.type,
            },
            mode: {
                controls: {
                    type: {
                        value: data!.mode.type,
                    },
                    modeType: {
                        disabled: !data!.mode.modeType,
                        controls: {
                            type: {
                                value: data!.mode.modeType?.type,
                                disabled: !data!.mode.modeType?.type,
                            },
                            percent: {
                                value: data!.mode.modeType?.percent,
                                disabled: !data!.mode.modeType?.percent,
                                validators: [_intValidator]
                            },
                            numerator: {
                                value: data!.mode.modeType?.numerator,
                                disabled: !data!.mode.modeType?.numerator,
                                validators: [_intValidator]
                            },
                            denominator: {
                                value: data!.mode.modeType?.denominator,
                                disabled: !data!.mode.modeType?.denominator,
                                validators: [_intValidator]
                            },
                            number: {
                                value: data!.mode.modeType?.number,
                                disabled: !data!.mode.modeType?.number,
                                validators: [_intValidator]
                            }
                        }
                    }
                }
            },
            quorumEnabled: {
                value: !!data?.quorum?.type,
                disabled: true,
            },
            quorum: {
                disabled: !data?.quorum?.type,
                controls: {
                    type: {
                        value: data?.quorum?.type,
                        disabled: !data?.quorum?.type,
                    },
                    quorumType: {
                        disabled: !data?.quorum?.quorumType?.type,
                        controls: {
                            type: {
                                value: data?.quorum?.quorumType?.type,
                                disabled: !data?.quorum?.quorumType?.type,
                            },
                            percent: {
                                value: data?.quorum?.quorumType?.percent,
                                disabled: !data?.quorum?.quorumType?.percent,
                                validators: [_intValidator]
                            },
                            numerator: {
                                value: data?.quorum?.quorumType?.numerator,
                                disabled: !data?.quorum?.quorumType?.numerator,
                                validators: [_intValidator]

                            },
                            denominator: {
                                value: data?.quorum?.quorumType?.denominator,
                                disabled: !data?.quorum?.quorumType?.denominator,
                                validators: [_intValidator]
                            },
                            number: {
                                value: data?.quorum?.quorumType?.number,
                                disabled: !data?.quorum?.quorumType?.number,
                                validators: [_intValidator]
                            }
                        }
                    }
                }
            }
        }
        setFormConfig({
            controls: config,
        })
    }

    useEffect(() => {
        if (quorumEnabledControl.value && !quorumTypeControl.value) {
            quorumTypeControl.patchValue(VotingQuorumType.atLeastHalf);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quorumEnabledControl.value])

    useEffect(() => {

        if (quorumEnabledControl.value) {
            quorumControl.enable();
            quorumTypeControl.enable();
        } else {
            quorumControl.disable();
            quorumTypeControl.disable();
            quorumTypeControl.patchValue('');
        }
        if (quorumQuorumTypeTypeControl.value === VotingQuorumType.percentMinimum) {
            quorumQuorumTypePercentControl.enable();
        } else {
            quorumQuorumTypePercentControl.disable();
            quorumQuorumTypePercentControl.patchValue('');
        }
        if (quorumQuorumTypeTypeControl.value === VotingQuorumType.fractionMinimum) {
            quorumQuorumTypeNumeratorControl.enable();
            quorumQuorumTypeDenominatorControl.enable();
        } else {
            quorumQuorumTypeNumeratorControl.disable();
            quorumQuorumTypeNumeratorControl.patchValue('');
            quorumQuorumTypeDenominatorControl.disable();
            quorumQuorumTypeDenominatorControl.patchValue('');
        }
        if (quorumQuorumTypeTypeControl.value === VotingQuorumType.numberMinimum) {
            quorumQuorumTypeNumberControl.enable();
        } else {
            quorumQuorumTypeNumberControl.disable();
            quorumQuorumTypeNumberControl.patchValue('');
        }

        if (quorumTypeControl.value === VotingQuorumType.other) {
            quorumQuorumTypeControl.enable();
            quorumQuorumTypeTypeControl.enable();
        } else {
            quorumQuorumTypeControl.disable();
            quorumQuorumTypeTypeControl.disable();
            quorumQuorumTypeTypeControl.patchValue('');
        }

        if (modeTypeControl.value === VotingModeType.qualifiedMajority) {
            modeTypeQualifiedControl.enable();
            modeModeTypeControl.enable();
        } else {
            modeModeTypeControl.disable();
            modeTypeQualifiedControl.disable();
            modeTypeQualifiedControl.patchValue('');
        }

        if (modeTypeQualifiedControl.value === VotingModeType.percentMajority) {
            modePercentControl.enable();
        } else {
            modePercentControl.disable();
            modePercentControl.patchValue('');
        }
        if (modeTypeQualifiedControl.value === VotingModeType.fractionMajority) {
            modeNumeratorControl.enable();
            modeDenominatorControl.enable();
        } else {
            modeNumeratorControl.disable();
            modeNumeratorControl.patchValue('');
            modeDenominatorControl.disable();
            modeDenominatorControl.patchValue('');
        }
        if (modeTypeQualifiedControl.value === VotingModeType.numberMajority) {
            modeNumberControl.enable();
        } else {
            modeNumberControl.disable();
            modeNumberControl.patchValue('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        quorumEnabledControl.value,
        modeTypeControl.value,
        modeTypeQualifiedControl.value,
        quorumTypeControl.value,
        quorumQuorumTypeTypeControl.value,
    ])

    const _saveAndGoToParticipants = async () => {
        const res = await _save();
        if (res?.isFailure()) {
            return;
        }
        navigate(`/organization/${organizationId}/event/${eventId}/participants`);
    }
    const _save = async () => {
        if (!formValid()) {
            return;
        }


        setLoading(true);
        let documentId: string | null = formControl('documentId').value;
        if (file && !formValue.documentId) {
            const uploadRes = await uploadFile(file);
            if (uploadRes.isFailure()) {
                setLoading(false);
                setError(uploadRes.failure!);
                return;
            }
            documentId = uploadRes.data!;
            formControl('documentId').patchValue(documentId);
        }
        let res;

        if (voting) {
            res = await editVoting(organizationId!, eventId!, itemId, voting.id, {...formValue, content: formValue.content, documentId,});
        } else {
            res = await createVoting(organizationId!, eventId!, itemId, {...formValue, documentId,});
        }
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return res;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return res;
        }

        onClose();
    }

    const _fileChange = (file: File | null) => {
        formControl('documentId').patchValue(null);
        formControl('content').patchValue(null);
        setFile(file);
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>
        <div className="content">
            <div className="description">
                {eventName}
            </div>
            <div className="title">
                {t('eventVotingCreationModal.title')}
            </div>

            <div className="form">

                <div className="left">
                    <FormField>
                        <div className="label">
                            {t('eventVotingCreationModal.document')} *
                        </div>
                    </FormField>

                    {
                        !file ?
                            <>
                                <div className="file-picker-wrapper">
                                    <FilesPicker fileChange={_fileChange} supportedFiles={[_supportedFileType]}
                                                 maxSizeInMB={10}/>
                                </div>
                                <Wysiwyg content={initialValues?.content ?? voting?.content ??''} onChange={formControl('content').patchValue}/>
                            </>
                            : <div className="document-wrapper">
                                <PdfViewer currentFile={file} fileChanged={_fileChange}/>
                            </div>
                    }
                </div>
                <div className="right">
                    <FormField>
                        <Input label={t('eventVotingCreationModal.name') + ' *'}>
                            <input type="text"
                                   disabled={event.content?.type === EventType.CIRCULAR}
                                   value={formControl('name').value}
                                   onChange={(e) => formControl('name').patchValue(e.target.value)}/>
                        </Input>
                        {renderValidationMessage('name')}
                    </FormField>
                    <FormField>
                        <div className="label">
                            {t('eventVotingCreationModal.description')}
                        </div>
                        <Textarea>
                        <textarea
                            value={formControl('description').value}
                            onChange={(e) => formControl('description').patchValue(e.target.value)}/>
                        </Textarea>
                        {renderValidationMessage('description')}
                    </FormField>
                    {
                        event.content?.type === EventType.LIVE
                            ? <>
                                <FormField>
                                    <div className="label">
                                        {t('eventVotingCreationModal.type')} *
                                    </div>

                                </FormField>
                                <FormField>

                                    <Radio labelElement={<div className="label">
                                        {t('eventVotingCreationModal.typeOpen')}

                                    </div>}>
                                        <input type="radio" value={VotingType.Open} name="type"
                                               checked={formControl('type').value === VotingType.Open}
                                               onChange={() => {
                                                   formControl('type').patchValue(VotingType.Open)
                                               }
                                               }/>

                                    </Radio>
                                </FormField>
                                <FormField>

                                    <Radio labelElement={<div className="label">
                                        {t('eventVotingCreationModal.typeSecret')}

                                    </div>}>
                                        <input type="radio" value={VotingType.Secret} name="type"
                                               checked={formControl('type').value === VotingType.Secret}
                                               onChange={() => {
                                                   formControl('type').patchValue(VotingType.Secret)
                                               }
                                               }/>

                                    </Radio>
                                </FormField>
                            </>
                            : null
                    }


                    <div className="border-box">

                        <FormField>
                            <div className="label">
                                {t('eventVotingCreationModal.mode')} *
                            </div>
                            <div className="hint">
                                {t('eventVotingCreationModal.modeHint')}
                            </div>
                            <Select
                                currentValue={modeTypeControl.value}
                                onChange={modeTypeControl.patchValue}
                            >
                                <option/>

                                {
                                    MODE.map((type) => {
                                        return <option value={type.type}
                                                       key={type.type}>{t(`eventVotingCreationModal.modeType.${type.type}`)}</option>
                                    })
                                }
                            </Select>
                            {renderValidationMessage('mode.type')}
                        </FormField>
                        <div className="value-type margin-top">

                            {
                                modeTypeQualifiedControl.control.disabled
                                    ? null
                                    : <FormField>
                                        <div className="label">
                                            {t('eventVotingCreationModal.modeQualified')} *
                                        </div>
                                        <Select
                                            currentValue={modeTypeQualifiedControl.value}
                                            onChange={modeTypeQualifiedControl.patchValue}
                                        >
                                            <option/>

                                            {
                                                MODE[2].types!.map((type) => {
                                                    return <option value={type.type}
                                                                   key={type.type}>{t(`eventVotingCreationModal.modeType.${type.type}`)}</option>
                                                })
                                            }
                                        </Select>
                                        {renderValidationMessage('mode.modeType.type')}
                                        {renderValidationMessage('mode.modeType')}

                                    </FormField>
                            }


                            {
                                modePercentControl.control.disabled
                                    ? null
                                    : <div className="percentage">

                                        <FormField>
                                            <Input>
                                                <input type="number"
                                                       value={modePercentControl.value}
                                                       onChange={(e) => modePercentControl.patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('mode.modeType.percent')}
                                        </FormField>
                                        <div className="sign">
                                            %
                                        </div>
                                    </div>
                            }

                            {
                                modeNumeratorControl.control.disabled
                                    ? null
                                    : <div className="divide">
                                        <FormField>
                                            <Input>
                                                <input type="number"
                                                       value={modeNumeratorControl.value}
                                                       onChange={(e) => modeNumeratorControl.patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('mode.modeType.numerator')}
                                        </FormField>
                                        <div className="sign">
                                            /
                                        </div>
                                        <FormField>
                                            <Input>
                                                <input type="number"
                                                       value={modeDenominatorControl.value}
                                                       onChange={(e) => modeDenominatorControl.patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('mode.modeType.denominator')}
                                        </FormField>
                                    </div>
                            }


                            {
                                modeNumberControl.control.disabled
                                    ? null
                                    : <div className="number">
                                        <FormField>
                                            <Input>
                                                <input type="number"
                                                       value={modeNumberControl.value}
                                                       onChange={(e) => modeNumberControl.patchValue(e.target.value)}/>
                                            </Input>
                                            {renderValidationMessage('mode.modeType.number')}
                                        </FormField>
                                    </div>
                            }

                        </div>
                    </div>

                    <FormField>
                        <Switch label={t('eventVotingCreationModal.quorumEnabled')}>
                            <input type="checkbox" checked={quorumEnabledControl.value}
                                   onChange={() => quorumEnabledControl.patchValue(!quorumEnabledControl.value)}/>
                        </Switch>
                    </FormField>

                    {
                        !quorumControl.control.disabled
                            ? <div className="border-box">

                                <FormField>
                                    <div className="label">
                                        {t('eventVotingCreationModal.quorum')} *
                                    </div>
                                    <div className="hint">
                                        {t('eventVotingCreationModal.quorumHint')}
                                    </div>
                                    <Select
                                        currentValue={quorumTypeControl.value}
                                        onChange={quorumTypeControl.patchValue}
                                    >
                                        <option/>
                                        {
                                            QUORUM.map((type) => {
                                                return <option value={type.type}
                                                               key={type.type}>{t(`eventVotingCreationModal.quorumType.${type.type}`)}</option>
                                            })
                                        }
                                    </Select>
                                    {renderValidationMessage('quorum.type')}
                                </FormField>


                                <div className="value-type">

                                    {
                                        quorumQuorumTypeTypeControl.control.disabled
                                            ? null
                                            : <FormField>
                                                <Select
                                                    currentValue={quorumQuorumTypeTypeControl.value}
                                                    onChange={quorumQuorumTypeTypeControl.patchValue}
                                                >
                                                    <option/>

                                                    {
                                                        QUORUM[4].types!.map((type) => {
                                                            return <option value={type.type}
                                                                           key={type.type}>{t(`eventVotingCreationModal.quorumType.${type.type}`)}</option>
                                                        })
                                                    }
                                                </Select>
                                                {renderValidationMessage('quorum.quorumType.type')}
                                                {renderValidationMessage('quorum.quorumType')}
                                            </FormField>
                                    }

                                    {
                                        quorumQuorumTypePercentControl.control.disabled
                                            ? null
                                            : <div className="percentage">

                                                <FormField>
                                                    <Input>
                                                        <input type="number"
                                                               value={quorumQuorumTypePercentControl.value}
                                                               onChange={(e) => quorumQuorumTypePercentControl.patchValue(e.target.value)}/>
                                                    </Input>
                                                    {renderValidationMessage('quorum.quorumType.percent')}
                                                </FormField>
                                                <div className="sign">
                                                    %
                                                </div>
                                            </div>
                                    }

                                    {
                                        quorumQuorumTypeNumeratorControl.control.disabled
                                            ? null
                                            : <div className="divide">
                                                <FormField>
                                                    <Input>
                                                        <input type="number"
                                                               value={quorumQuorumTypeNumeratorControl.value}
                                                               onChange={(e) => quorumQuorumTypeNumeratorControl.patchValue(e.target.value)}/>
                                                    </Input>
                                                    {renderValidationMessage('quorum.quorumType.numerator')}
                                                </FormField>
                                                <div className="sign">
                                                    /
                                                </div>
                                                <FormField>
                                                    <Input>
                                                        <input type="number"
                                                               value={quorumQuorumTypeDenominatorControl.value}
                                                               onChange={(e) => quorumQuorumTypeDenominatorControl.patchValue(e.target.value)}/>
                                                    </Input>
                                                    {renderValidationMessage('quorum.quorumType.denominator')}
                                                </FormField>
                                            </div>
                                    }


                                    {
                                        quorumQuorumTypeNumberControl.control.disabled
                                            ? null
                                            : <div className="number">
                                                <FormField>
                                                    <Input>
                                                        <input type="number"
                                                               value={quorumQuorumTypeNumberControl.value}
                                                               onChange={(e) => quorumQuorumTypeNumberControl.patchValue(e.target.value)}/>
                                                    </Input>
                                                    {renderValidationMessage('quorum.quorumType.number')}
                                                </FormField>
                                            </div>
                                    }

                                </div>


                            </div>
                            : null
                    }

                </div>
            </div>


        </div>
        <div className="buttons">
            <button className={'button text muted'} onClick={onClose} disabled={loading}>
                {t('eventVotingCreationModal.cancel')}
            </button>
            {
                params.get('first') === 'true' && event.content?.type === EventType.CIRCULAR
                    ?  <button className={'button'} onClick={_saveAndGoToParticipants} disabled={loading}>
                        {!loading ? t('eventVotingCreationModal.saveAndGoToParticipants') : <Loader/>}
                    </button>
                    : <button className={'button'} onClick={_save} disabled={loading}>
                        {!loading ? t('eventVotingCreationModal.save') : <Loader/>}
                    </button>
            }

        </div>

        {renderError()}
    </Wrapper>
}


export default EventVotingCreationModal;
