import styled from 'styled-components';

export const Wrapper = styled.div`
    .title {
        font-size: 2.5rem;
        margin-bottom: 30px;
        font-weight: bold;
    }
    .row {
        display: flex;
        gap: 20px;

        & > * {
            flex: 1;
        }
        .zip-code {
            max-width: 100px;
        }
    }
.email-hint {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 12px;
    margin-top: -10px;
}
    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        .button {
            min-width: 80px;
            &.save {
                min-width: 140px;
            }
        }
    }
`
