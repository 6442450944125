export interface SortableResponse<T> {
    content: T[];
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export const initSortableResponse: SortableResponse<any> = {
    content: [],
    size: 20,
    number: 0,
    totalElements: 0,
    totalPages: 0,
    numberOfElements: 0,
}
