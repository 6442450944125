import styled from 'styled-components';

export const Wrapper = styled.div`
padding: 20px;
    .title {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }
  .description {
    font-size: 1.2rem;
    color: var(--fontMutedColor);
    margin-bottom: 40px;
  }
  


`