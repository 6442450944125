import React, {useEffect, useState} from "react";
import {Wrapper} from "./SigningInvitation.styles";
import {BodyMemberType} from "../../../../logic/bodies/bodies.model";
import FormField from "../../../../components/FormField";
import Input from "../../../../components/Input";
import deleteIcon from "../../../../assets/icon-close-red.svg";
import FilesPicker from "../../../../components/FilesPicker";
import Loader from "../../../../components/Loader";
import {uploadFile} from "../../../../logic/files/files.service";
import {acceptParticipantInvitation, getParticipantInvitation} from "../../../../logic/events/events";
import {Violations} from "../../../../logic/core/failures";
import {AppRoutes} from "../../../../logic/core/routes";
import {useForm} from "../../../../logic/core/form.hook";
import {
    EventParticipantInvitation,
    EventParticipantInvitationAcceptBody, Representative,
} from "../../../../logic/events/events.model";
import {useParams, useSearchParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";

const SigningInvitation = () => {
    const {setError, renderError, loading, setLoading, navigate, t} = useBaseScreenHook();
    const [invitation, setInvitation] = useState<EventParticipantInvitation | null>(null);
    const {participantId} = useParams();

    const [params] = useSearchParams();
    const [file, setFile] = useState<File | null>(null);
    const {
        formControl,
        renderValidationMessage,
        formValid,
        formRawValue,
        setValidations,
    } = useForm<EventParticipantInvitationAcceptBody>({
        representative: {
            value: Representative.Personally
        },
        firstName: {
            value: ''
        },
        lastName: {
            value: ''
        },
        phoneNumber: {
            value: ''
        },
        position: {
            value: ''
        },
        pesel: {
            value: ''
        },
        authorizationDocumentId: {
            value: '',
            validators: []
        },
    })

    const _submit = async () => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        const token: string | null = params.get('token');
        let authorizationDocumentId: string = formControl('authorizationDocumentId').value;
        if (file && !formControl('authorizationDocumentId').value) {
            const uploadRes = await uploadFile(file);
            if (uploadRes.isFailure()) {
                setLoading(false);
                setError(uploadRes.failure!);
                return;
            }
            authorizationDocumentId = uploadRes.data!;
            formControl('authorizationDocumentId').patchValue(authorizationDocumentId);
        }


        const res = await acceptParticipantInvitation(participantId!, {...formRawValue, authorizationDocumentId,}, token);
        setLoading(false);

        if (res.isViolations()) {
            setValidations((res.failure! as Violations).getValidations())
            return;
        }

        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        navigate(`/${AppRoutes.organization}/${invitation?.event.organizationId}/${AppRoutes.event}/${invitation?.event.id}?representedParticipantId=${participantId!}`)

    }

    useEffect(() => {
        _getInvitation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const _getInvitation = async () => {
        const res = await getParticipantInvitation(participantId!);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
        setInvitation(res.data!);
        if (res!.data!.participant.alreadySigned) {
            navigate(`/${AppRoutes.organization}/${res.data?.event.organizationId}/${AppRoutes.event}/${res.data?.event.id}`)
        }
    }
    useEffect(() => {
        if (!invitation) {
            return;
        }
        if (invitation.participant.firstName) {
            formControl('firstName').patchValue(invitation.participant.firstName);
            formControl('firstName').disable();
            formControl('lastName').patchValue(invitation.participant.lastName);
            formControl('lastName').disable();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitation]);

    const representative = formControl('representative');
    const authorizationDocumentId = formControl('authorizationDocumentId');

    useEffect(() => {

        if (representative.value === Representative.Plenipotentiary && !file) {
            authorizationDocumentId.control.validators = [
                (val) => {
                    return t('validations.required')
                }
            ]
        } else {
            authorizationDocumentId.control.validators = []
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [representative.value, file])
    if (!invitation) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>
        <div className={'signing'}>

            <div className="data-title">
                Twoje dane
            </div>

            <p className="permission-description">
                {
                    invitation.participant.bodyMemberType === BodyMemberType.personMember
                        ? `Oświadczam, że jestem uprawniony/a do udziału w ${invitation.event.name}:`
                        : `Oświadczam, że jestem uprawniony/a do reprezentowania  ${invitation.participant.bodyMemberName} na podstawie:`
                }

            </p>

            <div className="power-of-attorney">

                <button className={`button ${formControl('representative').value === Representative.Plenipotentiary ? 'text muted' : 'accent'}`}
                        onClick={() => formControl('representative').patchValue(Representative.Personally)}>
                    {invitation.participant.bodyMemberType === BodyMemberType.personMember
                        ? `Osobiście`
                        : `Wpisu do właściwego rejestru`}

                </button>
                <button className={`button ${formControl('representative').value === Representative.Personally ? 'text muted' : 'accent'}`}
                        onClick={() => formControl('representative').patchValue(Representative.Plenipotentiary)}>

                    {invitation.participant.bodyMemberType === BodyMemberType.personMember
                        ? `Na podstawie pełnomocnictwa`
                        : `Pełnomocnictwa`}
                </button>

            </div>


            <div className="form">

                <div className="row">

                    <FormField>
                        <Input label={'Imię *'}>
                            <input type="text"
                                   disabled={formControl('firstName').control.disabled}
                                   value={formControl('firstName').value}
                                   onChange={(e) => formControl('firstName').patchValue(e.target.value)}/>
                        </Input>
                        {renderValidationMessage('firstName')}
                    </FormField>

                    <FormField>
                        <Input label={'Nazwisko *'}>
                            <input type="text"
                                   disabled={formControl('lastName').control.disabled}
                                   value={formControl('lastName').value}
                                   onChange={(e) => formControl('lastName').patchValue(e.target.value)}/>
                        </Input>
                        {renderValidationMessage('lastName')}
                    </FormField>

                </div>


                <FormField>
                    <Input label={'PESEL (opcjonalnie)'}>
                        <input type="number"
                               value={formControl('pesel').value}
                               onChange={(e) => formControl('pesel').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('pesel')}
                </FormField>

                {
                    invitation.participant.bodyMemberType === BodyMemberType.organizationMember
                        ? <FormField>
                            <Input label={'Stanowisko w organizacji (opcjonalnie)'}>
                                <input type="text"
                                       value={formControl('position').value}
                                       onChange={(e) => formControl('position').patchValue(e.target.value)}/>
                            </Input>
                            {renderValidationMessage('position')}
                        </FormField>
                        : null
                }


                <FormField>
                    <Input label={'Numer telefonu (opcjonalnie)'}>
                        <input type="number"
                               value={formControl('phoneNumber').value}
                               onChange={(e) => formControl('phoneNumber').patchValue(e.target.value)}/>
                    </Input>
                    {renderValidationMessage('phoneNumber')}
                </FormField>

                <FormField>
                    <div className="label">
                        Dokument potwierdzający umocowanie {formControl('representative').value === Representative.Plenipotentiary ? '' : '(opcjonalnie)'}
                    </div>
                </FormField>

                {
                    file
                        ? <div className="selected-file">
                            {file?.name}
                            <img src={deleteIcon} alt="delete" onClick={() => {
                                formControl('authorizationDocumentId').patchValue('');
                                setFile(null);
                            }}/>
                        </div>
                        : <FilesPicker maxSizeInMB={null} supportedFiles={null} fileChange={(file) => {
                            formControl('authorizationDocumentId').patchValue('');
                            setFile(file);
                        }}/>
                }
                {
                    formControl('representative').value === Representative.Plenipotentiary && !file
                        ? <div className="files-error-wrapper">
                            {renderValidationMessage('authorizationDocumentId')}
                        </div>
                        : null
                }


                <div className="description-summary">

                    <div className="label">
                        Podpisywanie listy obecności
                    </div>
                    <div className="value">
                        Organizator wydarzenia {invitation.event.organizationName} prosi Cię o podpisanie listy
                        obecności. Klikając w przycisk "Podpisz listę obecności i dołącz", potwierdzasz powyższe
                        dane i dołączysz do wydarzenia.
                    </div>

                    <div className="label">
                        Składasz podpis reprezentując:
                    </div>
                    <div className="value">

                        {invitation.participant.bodyMemberType === BodyMemberType.personMember
                            ? `osobę fizyczną - ${invitation.participant.bodyMemberName}`
                            : invitation.participant.bodyMemberName}


                    </div>

                    <div className="label">
                        Rodzaj składanego podpisu:
                    </div>

                    <div className="value">
                        ePodpis elektroniczny
                    </div>

                </div>

                <button className="button send-button" onClick={_submit} disabled={loading || invitation.participant.alreadySigned}>
                    {
                        loading ? <Loader/> : 'Podpisz listę obecności i dołącz'
                    }
                </button>
                {
                    invitation.participant.alreadySigned ? <div className="list-signed">
                        Lista została już podpisana
                    </div> : null
                }


            </div>

        </div>
        {renderError()}
    </Wrapper>
}

export default SigningInvitation;