import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, {useEffect, useState} from 'react';
import {Editor, SyntheticKeyboardEvent} from 'react-draft-wysiwyg';
import {ContentState, convertToRaw, EditorState, RichUtils} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Wrapper} from "./Wysiwyg.styles";

const Wysiwyg = ({content, onChange, options}: { content: string, onChange: (value: string) => void, options?: string[]}, ) => {

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const value: string = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const raw = convertToRaw(editorState.getCurrentContent());
        if (raw.blocks.length === 1 && raw.blocks[0].text === '') {
            onChange('');
            return;
        }
        onChange(value);

    }
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [state, setState] = useState<{uploadedImages: {file: File, localSrc: string;}[]}>({uploadedImages: []});
    const _handleReturn = (e: SyntheticKeyboardEvent) => {
        if (e.shiftKey) {
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return true;
        }
        return false;
    }
    useEffect(() => {
        const blocks = htmlToDraft(content)
        const _content = ContentState.createFromBlockArray(blocks.contentBlocks);
        setEditorState(EditorState.createWithContent(_content));
        onChange(content);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);


    const _uploadImageCallBack = (file: File) =>{
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        let uploadedImages = state.uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }

        uploadedImages.push(imageObject);
        setState({uploadedImages,})

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {

                let reader = new FileReader();
                reader.onload = (e) => {
                    const dataUrl = e!.target!.result as string;
                    resolve({ data: { link: dataUrl } });
                };
                reader.readAsDataURL(file);


            }
        );
    }
    return <Wrapper>
        <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        handleReturn={_handleReturn}
        toolbar={{
            options: options ?? ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
            inline: {
                inDropdown: false,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            },
            image: { uploadCallback: _uploadImageCallBack, previewImage: true, alt: { present: true, mandatory: false }, }
        }}
    />
    </Wrapper>
}

export default Wysiwyg;
