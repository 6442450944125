import styled from 'styled-components';

export const Wrapper = styled.div`
    .title {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .credits-table {
        margin-bottom: 20px;
        .name {
            text-transform: capitalize;
        }
        .credit-quantity {
            padding-left: 20px;
        }
    }

    .close-account-info {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 30px;
    }
    .info {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
`
