import React, { useContext, useEffect, useRef, useState } from 'react';
import { Wrapper } from './PdfViewer.styles';
import { Document, Page, pdfjs } from 'react-pdf';
import Loader from '../Loader';
import { useBaseScreenHook } from '../../logic/core/base-screen.hook';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import iconClose from '../../assets/icon-close.svg';
import { ToastContext } from '../Toast/toastStateProvider';
import { ModalType } from '../Toast';
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;

const PdfViewer = ({fileChanged, currentFile, viewOnly = false}: {fileChanged: (file: File | null) => void, currentFile?: File, viewOnly?: boolean}) => {
    const {t} = useBaseScreenHook();
    const [instructionDocumentWidth, setInstructionDocumentWidth] = useState<number>(0);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [settingFile, setSettingFile] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [, setToastState] = useContext(ToastContext);


    const instructionWrapperRef = useRef(null);
    const _supportedFileType = 'application/pdf';
    const inputFileRef = useRef(null);
    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (currentFile){
            setFile(currentFile)
        }
    }, [currentFile])

    useEffect(() => {
        if (instructionWrapperRef.current) {
            const el: HTMLElement = instructionWrapperRef.current;
            setInstructionDocumentWidth(el.offsetWidth);
            el.ondragover = el.ondragenter = function (evt) {
                evt.preventDefault();
            };
            el.ondrop = (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                _onFileChange({
                    target: {
                        files: evt.dataTransfer!.files,
                    }
                });
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructionWrapperRef.current]);


    const _onFileChange = (e: any) => {
        setFile(null);
        fileChanged(null);
        const _file: File = e.target.files[0];
        if (_file.type === _supportedFileType) {
            setSettingFile(true);
            setNumPages(null);
            setTimeout(() => {
                setFile(_file);
                setSettingFile(false);
                fileChanged(_file);
            })
        } else {
            setToastState({
                type: ModalType.warn,
                message: t('errors.fileError')
            });
        }
        e.target.value = null
    }

    const _wrapperClick = () => {
        if (file) return;
        (inputFileRef.current as any).click()
    }

    const _removeDoc = () => {
        setFile(null);
        fileChanged(null);

    }
    return <Wrapper>
        <div className="document-list" ref={instructionWrapperRef} onClick={_wrapperClick}>
            <input type="file" accept={_supportedFileType} id="input-file" ref={inputFileRef} onChange={_onFileChange}/>

            {settingFile
                ? <Loader/>
                : <div className="document">
                    {
                        file && !viewOnly ?
                        <div className="options">
                            <button onClick={_removeDoc}>
                                <img src={iconClose} alt="remove"/>
                            </button>
                        </div>
                            : null
                    }

                    <Document
                    file={file}
                    // file={file ?? manual.variants[0].content?.documentUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Loader/>}
                    onLoadProgress={() => <Loader/>}
                    noData={''}
                    error={<div className="file-error">{t('errors.fileError')}</div>}
                    options={{workerSrc: `${process.env.PUBLIC_URL}/pdf.worker.js`}}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1}
                              width={instructionDocumentWidth}/>
                    ))}
                </Document>
            </div>
            }
        </div>
    </Wrapper>
}

export default PdfViewer;
