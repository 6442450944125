import Select from '../Select';
import IconDropDown16 from '../../assets/icon-arrow-down.svg';
import React from 'react';
import { Wrapper } from './Paginator.styles';
import { Trans, useTranslation } from 'react-i18next';
import { SortableResponse } from '../../logic/core/sortableResponse.model';
import FormField from '../FormField';

export type PaginationChanged = (size: number, number: number) => void;

const Paginator = ({paging, onChanged, elementsFound, pageSizes}: { paging: SortableResponse<any>, onChanged: PaginationChanged, elementsFound: number, pageSizes: number[] }) => {
    const [t] = useTranslation('common');

    const renderPages = () => {
        const pages: any[] = [];

        const aFewPages: boolean = paging.totalPages <= 6;
        const middlePageStart: boolean = paging.number > 2;
        const middlePageEnd: boolean = paging.number < paging.totalPages - 4;
        const startPages: boolean = paging.number <= 2;
        const endPages: boolean = paging.number >= paging.totalPages - 4;

        if (aFewPages) {
            for (let i = 0; i < paging.totalPages; i++) {
                pages.push(<div key={i} className={`page ${paging.number === i ? 'active' : ''}`}
                                onClick={() => changePage(i)}>{i + 1}</div>);
            }
        } else {
            pages.push(<div key={'first'} className={`page ${paging.number === 0 ? 'active' : ''}`}
                            onClick={() => changePage(0)}>1</div>);

            if (middlePageStart) {
                pages.push(<div key={'more-before'} className={`page more`}>...</div>);
            }

            let startIndex: number = 0;
            let endIndex: number = 0;

            if (middlePageStart && middlePageEnd) {
                startIndex = paging.number - 1;
                endIndex = paging.number + 2;

            } else if (startPages) {
                startIndex = 1;
                endIndex = 5;

            } else if (endPages) {
                startIndex = paging.totalPages - 5;
                endIndex = paging.totalPages - 1;
            }
            for (let i = startIndex; i < endIndex; i++) {
                pages.push(<div key={i}
                                className={`page ${paging.number === i ? 'active' : ''}`}
                                onClick={() => changePage(i)}>
                    {i + 1}
                </div>);
            }


            if (middlePageEnd) {
                pages.push(<div key={'more-after'} className={`page more`}>...</div>);
            }


            pages.push(<div key={'last'} className={`page ${paging.number === paging.totalPages - 1 ? 'active' : ''}`}
                            onClick={() => changePage(paging.totalPages - 1)}>{paging.totalPages}</div>);

        }

        return pages;
    }

    const changePage = (page: number) => {
        if (paging.number === page || page < 0 || page > paging.totalPages - 1) return;
        onChanged(paging.size, page);
    }

    return <Wrapper>
        <div className="pagination-state">

            <Trans t={t}>
                {t('paginator.state', {
                    from: elementsFound <= 0 ? 0 : (paging.number * paging.size) + 1,
                    to: (paging.number * paging.size) + elementsFound,
                    total: paging.totalElements,
                })}
            </Trans>
        </div>
        <div className="paginator">
            <div className="size">
                {t('paginator.rowsPerPage')}

                <FormField>
                    <Select
                            currentValue={paging.size}
                            onChange={(val) => {
                                onChanged(val * 1, 0);
                            }}
                    >
                        {pageSizes.map((size) => {
                            return  <option key={size} value={size}>{size}</option>
                        })}
                    </Select>
                </FormField>

            </div>

            <div className="navigator">

                <div className={`prev ${paging.number === 0 ? 'disabled' : ''}`}
                     onClick={() => changePage(paging.number - 1)}>
                    <img src={IconDropDown16} alt="prev"/>
                </div>
                <div className="pages">

                    {renderPages()}


                </div>
                <div className={`next ${paging.number === paging.totalPages - 1 ? 'disabled' : ''}`}
                     onClick={() => changePage(paging.number + 1)}>
                    <img src={IconDropDown16} alt="next"/>
                </div>
            </div>

        </div>
    </Wrapper>;
}

export default Paginator;
