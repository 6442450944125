import {DataObject} from '../core/dataObject';
import {handleError} from '../core/handleError';
import HttpClient from '../httpClient/httpClient';
import {AxiosError} from 'axios';
import {Failure} from '../core/failure';
import {RegisterBody, User} from './auth.model';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from '../core/routes';
import {useAppStateHook} from '../core/appState.hook';
import {AppStateActionType} from "../../appSateProvider";
import {cloneDeep} from "lodash";

export async function login(username: string, password: string, rememberMe: boolean): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/login`, {username, password,}, {
            params: {
                rememberMe,
            }
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}
export async function logout(): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/logout`, {});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function me(): Promise<DataObject<Failure, User | null>> {
    try {
        const res = await HttpClient.get(`/me`);
        return new DataObject<Failure, User>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function changePassword(oldPassword: string, newPassword: string): Promise<DataObject<Failure, void | null>> {
    try {
        const res = await HttpClient.post(`/me/password`, {
            oldPassword,
            newPassword,
        });
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export const useLogout = () => {
    const {appState, setAppState} = useAppStateHook();
    const navigate = useNavigate();

    const _logout = async (redirect: boolean = true) => {
        await logout()
        appState.user = null;

        setAppState(
            {
                type: AppStateActionType.setUser,
                state: {
                    ...cloneDeep(appState),
                    user: null,
                }
            }
        )
        if (redirect) {
            navigate(`/${AppRoutes.login}`);
        }
    }

    return {logout: _logout}
}

export async function register(body: RegisterBody): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.post(`/public/accounts`, body);
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function activateAccount(accountId: string, token: string): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.post(`/public/accounts/activations/confirmations`, {accountId, token});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function resendActivationLink(email: string): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.post(`/public/accounts/activations`, {email});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}


export async function resetPasswordRequest(email: string): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.post(`/public/accounts/passwords/resets/requests`, {email});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

export async function resetPassword(newPassword: string, token: string, accountId: string): Promise<DataObject<Failure, void>> {
    try {
        const res = await HttpClient.post(`/public/accounts/passwords/resets`, {newPassword, token, accountId});
        return new DataObject<Failure, void>(null, res.data);
    } catch (e) {
        return handleError(e as AxiosError);
    }
}

