import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--disabledColor);
  border-radius: 5px;


  .voting {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 20px;
    max-height: 90vh;
    height: 100%;

    & > * {
      width: calc(50% - 10px);
      flex-shrink: 0;
    }
  }

  .left {
    background: #fff;
    display: flex;
    flex-direction: column;
  }

  .right {
    overflow: auto;
  }

  .document-wrapper {
    padding: 10px;
    overflow: hidden;
    background: #fff;
    position: relative;
    flex: 1;
  }

  .document-label {
    margin: 10px;
    background: var(--bgLighColor);
    border-radius: 5px;
    padding: 15px 10px 15px 27px;
    display: flex;
    align-items: center;
    gap: 17px;

    .label {
      display: flex;
      flex-direction: column;
      text-align: left;

      .name {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--fontMutedColor);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }


  .content {
    box-sizing: border-box;
    background: var(--bgLighColor);
    padding: 10px;
    text-align: left;

    .status {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      border-radius: 5px;
      padding: 10px 15px;

      .voting-type {
        font-size: 1.2rem;
        color: var(--fontMutedColor);
      }

      .voting-status {
        .dot {
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          border-radius: 50%;

          &.Pending {
            background: var(--waringColor);
          }

          &.InProgress {
            background: var(--mainColor);
          }

          &.Passed {
            background: var(--successColor);
          }

          &.Finished {
            background: #000;
          }

          &.Canceled {
            background: var(--fontMutedColor);
          }

          &.NotPassed {
            background: var(--errorColor);
          }
        }

        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.2rem;
        color: var(--fontMutedColor);
      }
    }

    .intent {
      margin: 0 10px;
    }

    .title {
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .description {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 10px;
    }

    .time {
      display: flex;
      align-items: center;
      gap: 17px;
      margin-bottom: 15px;

      .start,
      .end {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 1.4rem;
      }
    }

    .actions {
      margin-bottom: 26px;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .separator {
      background: var(--fontMutedColor);
      height: 1px;
      margin-bottom: 20px;
      width: 100%;
    }

    .my-vote {
      margin-top: 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 20px;

      .vote {
        padding: 20px 15px;
        height: 152px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .selection {
          text-align: center;
          font-size: 2.4rem;
          font-weight: 600;

          &.selected {
            color: #fff;
          }
        }

        .timer {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          font-size: 1.6rem;
          font-weight: 600;
        }

        &.For {
          background: var(--successColor);
        }

        &.Against {
          background: var(--errorColor);
        }

        &.Abstain {
          background: var(--waringColor);
        }
      }

      .options {
        padding: 10px 15px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .option {
          height: 64px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          font-size: 1.6rem;
          font-weight: 500;
          color: #fff;
          cursor: pointer;

          &.For {
            background: var(--successColor);
          }

          &.Against {
            background: var(--errorColor);
          }

          &.Abstain {
            background: var(--waringColor);
          }

          &.disabled {
            cursor: not-allowed;
            opacity: .5;
          }

          &.selected {
            opacity: 1;
          }
        }
      }
    }

    .results-wrapper {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }

    .results {
      flex: 1;
      margin-top: 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      padding: 20px 15px;

      .results-title {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .users {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        .user {
          padding: 5px 10px;
          border-radius: 5px;
          color: var(--fontMutedColor);
          font-size: 1.2rem;
          line-height: 2rem;
          font-weight: 500;
          background: var(--disabledColor);

          &.casted-vote {
            color: #fff;
            background: var(--mainColor);
          }

          &.For {
            color: #fff;
            background: var(--successColor);
          }

          &.Against {
            color: #fff;
            background: var(--errorColor);
          }

          &.Abstain {
            color: #fff;
            background: var(--waringColor);
          }
        }
      }
    }

    .results-votes {
      width: 215px;
      box-sizing: border-box;
      margin-top: 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      padding: 49px 15px 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .results-info {
        color: var(--fontMutedColor);
        font-size: 1.4rem;
        text-align: center;
      }

      .vote {
        display: flex;
        align-items: center;
        gap: 25px;
        &.link {
          cursor: pointer;
        }
        .counter {
          min-width: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 2px;
          border-radius: 5px;
          color: #fff;
          font-size: 1.2rem;
          line-height: 2rem;
          font-weight: 500;

          &.For {
            background: var(--successColor);
          }

          &.Against {
            background: var(--errorColor);
          }

          &.Abstain {
            background: var(--waringColor);
          }

          &.NoVote {
            background: var(--disabledColor);
            color: var(--fontMutedColor);
          }

          &.Absent {
            background: var(--disabledColor);
            color: var(--fontMutedColor);
          }
        }

        .label {
          font-size: 1.4rem;
        }
      }

      .separator {
        margin: 5px 0;
        background: var(--disabledColor);
      }
    }

    .summary {
      margin-top: 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      padding: 20px 15px;
      margin-bottom: 20px;

      .row {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 2rem;
        gap: 5px;

        .value {
          font-weight: 500;
        }
      }

      .table {
        margin-top: 20px;
        font-size: 1.4rem;
        line-height: 2rem;

        thead {
          td {
            padding: 0 3px;
          }
        }
      }

    }

    .settings {
      margin-top: 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      padding: 20px 15px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .row {
        .label {
          font-size: 1.4rem;
          margin-bottom: 10px;
          line-height: 2rem;
        }

        .value {
          padding-left: 20px;
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 500;
        }
      }
    }

    .outcome-decision {
      margin-top: 10px;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      padding: 20px 15px;
      margin-bottom: 20px;
    }
  }
`
