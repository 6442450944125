import styled from 'styled-components';

export const Wrapper = styled.div`

  display: flex;
  .outlet {
    width: 100%;
  }
  .menu {
    padding: 20px 20px 20px 10px;
    display: flex;
    flex-direction: column;
    width: 300px;
    flex-shrink: 0;
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 0;
      color: var(--fontColor);
      text-decoration: none;
      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 3px;
        background: transparent;
      }
      &:hover,
      &.active {
        font-weight: bold;
        &::before{
          background: var(--mainColor);
        }
      }
    }
  }
    
`
