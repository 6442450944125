import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .description {
    color: var(--fontMutedColor);
    font-size: 1.2rem;
    a {
      text-decoration: none;
      color: var(--mainColor);
    }
  }
  
  .bar {
    display: flex;
    gap: 100px;
    align-items: flex-start;
    margin-bottom: 50px;
    button {
      flex-shrink: 0;
    }
    .left {
      flex: 1;
    }
  }
  .action {
    display: flex;
    justify-content: flex-end;
    .delete{
      color: var(--errorColor);
    }
  }
  .inline-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  .status {
    border-radius: 8px;
    padding: 5px 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background: var(--successColor);
    }
    &.notactive {
      background: var(--errorColor);
    }
    &.pending {
      background: var(--waringColor);
    }
  }
`
